<div class="range-daily">
  <h2 mat-dialog-title translate>
    Select the range of days you want see in graph
  </h2>
  <mat-dialog-content>
    <div class="range-daily__date_range">
      <span class="range-daily__label" translate>From</span>
      <mat-form-field class="range-daily__data-interval">
        <input
          matInput
          [min]="thirdyDaysAgo"
          [max]="endDate"
          [matDatepicker]="picker1"
          [(ngModel)]="startDate"
          (click)="picker1.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker1">
        </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="range-daily__date_range">
      <span class="range-daily__label" translate>Until</span>
      <mat-form-field class="range-daily__data-interval">
        <input
          matInput
          [max]="currentDate"
          [matDatepicker]="picker"
          [(ngModel)]="endDate"
          (click)="picker.open()"
          (ngModelChange)="changeEndDate()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <div *ngIf="!widthSizeSuitable" class="range-daily__btn-actions">
    <button mat-button mat-dialog-close class="btn-cancel">
      <span class=" btn-label"  translate>Cancel</span>
    </button>
    <button
      mat-button
      class="main-btn"
      [mat-dialog-close]="[startDate, endDate]"
      cdkFocusInitial
      translate
    >
      <span class="btn-label" translate>Apply</span>
    </button>
  </div>
  <div *ngIf="widthSizeSuitable">
    <button mat-button mat-dialog-close class=" btn-cancel-float">
      <span class=" btn-label"  translate>Cancel</span>
    </button>
    <button
      mat-button
      class="main-btn-float"
      [mat-dialog-close]="[startDate, endDate]"
      cdkFocusInitial
      translate
    >
      <span class="btn-label" translate>Apply</span>
    </button>
  </div>
</div>
