<div class="create-group">

  <div class="text-center">
    <h5 mat-dialog-title translate>New Group</h5>
  </div>
  <div class="create-group__messageError" *ngIf="messageError">
    <fa-icon class="icon-warning" icon="exclamation-triangle"></fa-icon>
    <p translate>{{messageError}}</p>
  </div>
  <div class="create-group__step-header-second">
    <h6><fa-icon *ngIf="currentStepIndex !== 1" (click)="previousStep(stepper)" class="mrg-right-5"
        icon="arrow-left"></fa-icon>{{labelStepSelected}} - {{currentStepIndex}}/6 </h6>
  </div>
  <mat-horizontal-stepper class="create-group__stepper" linear="true"
    (selectionChange)="onChangeHeader($event, stepper)" (click)="triggerClick($event, stepper)" #stepper>
    <mat-step [label]="'1. Information' | translate" [completed]="false">
      <app-group-info (infoSet)="setInfo($event, stepper)"></app-group-info>
    </mat-step>

    <mat-step [label]="'2. Restrictions' | translate" [completed]="false" hasError="true">
      <app-group-restrictions actionText="Next" (restrictionsSet)="setRestrictions($event, stepper)"
        (previousItem)="backStep(stepper)"></app-group-restrictions>
    </mat-step>

    <mat-step [label]="'3. Doors' | translate" [completed]="false">
      <app-group-doors actionText="Next" (devicesSet)="setDevices($event, stepper)" (previousItem)="backStep(stepper)">
      </app-group-doors>
    </mat-step>

    <mat-step [label]="'4. Users and Roles' | translate" [completed]="false"> 
      <app-search-members actionText="Next" (usersSet)="setUsers($event, stepper)" (previousItem)="backStep(stepper)"
        [selectedUsers]="data ? data.users : null" [roleSearch]="false">
      </app-search-members>
    </mat-step>

    <mat-step [label]="'5. Summary' | translate" [completed]="false">
      <app-group-summary actionText="Submit" (submitAccessGroup)="submitAccessGroup($event, stepper)"
        [accessGroup]="groupParams" (previousItem)="backStep(stepper)"></app-group-summary>
    </mat-step>

    <mat-step [label]="'6. Result' | translate">
      <app-group-result actionText="Finish" (finishEvent)="finishEvent()" [responseAccessGroup]="responseAccessGroup"
        [stepper]="stepper" (previousItem)="backStep(stepper)"></app-group-result>
    </mat-step>

  </mat-horizontal-stepper>
</div>