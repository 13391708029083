<div class="width-100 stepper-controller" *ngIf="!sizeWidthSuitable">
  <div class="no-pdd">
    <button class="mrg-right-5 btn-cancel" aria-label="cancel invite" matDialogClose (click)="onFormCleared.emit()">
      <span class="label-color">{{ 'Cancel' | translate }}</span>
    </button>
    <button matStepperPrevious class="back-btn" (click)="onFormCleared.emit()"
      *ngIf="isEditing === 'false' && isInfo === 'false'">
      <span class="label-color">{{ 'Back' | translate }}</span>
    </button>
  </div>
  <div class="no-pdd-right">
    <button aria-label="create invite" class="btn-accent" (click)="onFormSubmitted.emit()"
      [disabled]="blockButton === true">
      <span class="label-color">{{ actionText | translate }}</span>
    </button>
  </div>
</div>
<div class="width-100 stepper-controller" *ngIf="sizeWidthSuitable">
  <div class="no-pdd">
    <button aria-label="create invite" class="btn-accent" (click)="onFormSubmitted.emit()"
      [disabled]="blockButton === true">
      <span class="label-color">{{ actionText | translate }}</span>
    </button>
  </div>
  <div class="no-pdd-right">
    <button class="mrg-right-5 btn-cancel" aria-label="cancel invite" matDialogClose (click)="onFormCleared.emit()">
      <span class="label-color">{{ 'Cancel' | translate }}</span>
    </button>
  </div>
</div>