import { LKEvent, ILKEvent } from './LKEvent';

export interface ILKDeviceEvents {
  events: ILKEvent[];
  count: number;
}

export class LKDeviceEvents {
  events: LKEvent[];
  count: number;

  constructor(serverObject: Partial<ILKDeviceEvents>) {
    Object.assign(this, serverObject);
  }
}
