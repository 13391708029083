export default {
  heatmap_normal: [
    {
      name: '0',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '1',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '2',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '3',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '4',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '5',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '6',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '7',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '8',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '9',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '10',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '11',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '12',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '13',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '14',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '15',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '16',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '17',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '18',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '19',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '20',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '21',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '22',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '23',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
  ],

  heatmap_mobile: [
    {
      name: '0-3',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '4-7',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '8-11',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '12-15',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '16-19',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
    {
      name: '20-23',
      series: [
        {
          name: 'Sun',
          value: 0,
        },
        {
          name: 'Sat',
          value: 0,
        },
        {
          name: 'Fri',
          value: 0,
        },
        {
          name: 'Thr',
          value: 0,
        },
        {
          name: 'Wed',
          value: 0,
        },
        {
          name: 'Tue',
          value: 0,
        },
        {
          name: 'Mon',
          value: 0,
        },
      ],
    },
  ],
};
