import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { CorporationService } from '@modules/corporation/corporation.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'app-update-info',
  templateUrl: './update-info.component.html',
  styleUrls: ['./update-info.component.scss'],
})
export class UpdateInfoComponent implements OnInit {
  public corpForm: UntypedFormGroup;
  public widthSuitableSize = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private corporationService: CorporationService,
    private translateService: TranslateService,
    private loggingService: LoggingService,
    private dialog: MatDialogRef<UpdateInfoComponent>
  ) {
    this.corpForm = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [
        Validators.required,
        Validators.minLength(3),
      ]),
      id: new UntypedFormControl(data.id, []),
    });
  }

  ngOnInit() {
    this.widthSuitableSize = window.innerWidth <= 500;
  }

  get f() {
    return this.corpForm.controls;
  }

  cancel() {
    this.dialog.close();
  }

  onSubmit() {
    if (this.corpForm.valid) {
      Object.keys(this.corpForm.value).map((value) => {
        if (
          this.corpForm.value[value] == null ||
          this.corpForm.value[value] === ''
        )
          delete this.corpForm.value[value];
        return this.corpForm.value[value];
      });
      this.corporationService
        .updateCorp({
          ...this.corpForm.value,
        })
        .subscribe(
          (corpResponse) => {
            this.dialog.close(corpResponse);
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  'Corporation successfully updated!'
                ),
                text: '',
              },
              'success'
            );
          },
          (error) => {
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  'Error updating corporation!'
                ),
                text:
                  this.translateService.instant(error.error.errorDescription) ||
                  '',
              },
              'error'
            );
          }
        );
    }
  }
}
