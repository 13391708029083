import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ConfirmPasswordComponent } from '../confirm-password/confirm-password.component';

@Component({
  selector: 'app-delete-site',
  templateUrl: './delete-site.component.html',
  styleUrls: ['./delete-site.component.scss'],
})
export class DeleteSiteComponent {
  @Output() deleted = new EventEmitter<boolean>();
  public faExclamationTriangle = faExclamationTriangle;
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog) {}

  openConfirmPasswordDialog() {
    const config = {
      panelClass: 'dialog-container',
      width: '400px',
      maxWidth: '100vw',
      borderRadius: '60px',
      disableClose: true,
      data: {
        site: this.data.site,
      },
    };

    const dialogRef = this.dialog.open(ConfirmPasswordComponent, config);
    dialogRef.componentInstance.removedSite.subscribe(() => {
      this.dialog.closeAll();
      this.deleted.emit(true);
    });
  }
}
