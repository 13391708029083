<div class="lockers">
  <div *ngIf="loadingLockerData">
    <mat-spinner diameter="40" color="accent" class="center-spinner">
    </mat-spinner>
  </div>

  <div *ngIf="!loadingLockerData && data.selectedLocker">
    <div class="text-center">
      <h5 mat-dialog-title translate>Locker</h5>
    </div>

    <div mat-dialog-content>
      <div class="lockers__body">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="lockers__table"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span translate>Door Number</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.name }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <span class="actions" translate>ACTIONS</span>
            </th>
            <td mat-cell *matCellDef="let element" class="button-action">
              <button
                aria-label="unlock locker"
                (click)="unlockDoor(element)"
                *ngIf="!element.unlock && !data.sharings"
                [disabled]="element.status == 'OFFLINE'"
                class="lockers__btn-unlock lockers__unlocking"
                [matTooltip]="'UNLOCK' | translate"
                translate
              >
                Unlock
              </button>

              <button
                aria-label="unlocking locker"
                class="lockers__btn-unlock lockers__unlocking"
                *ngIf="element.unlock && !sharings"
                [disabled]="element.unlock"
                translate
              >
                <fa-icon
                  class="font-size-15 pdd-right-5"
                  icon="spinner"
                  pulse="true"
                ></fa-icon>
                <span translate>UNLOCKING</span>
              </button>

              <button
                aria-label="unlocking locker"
                class="lockers__btn-unlock lockers__btn-opened"
                *ngIf="data.sharings"
                translate
              >
                <span translate>Open</span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
            <hr />
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns">
            <hr />
          </tr>
        </table>

        <div class="lockers__btn-wrapper">
          <button class="lockers__btn-cancel" (click)="closeDialog()" translate>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
