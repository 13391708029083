import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-snack-message',
  templateUrl: './snack-message.component.html',
  styleUrls: ['./snack-message.component.scss'],
})
export class SnackMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  getIcon() {
    return this.data.iconType === 'success' ? faCheckCircle : faTimesCircle;
  }
}
