import { environment } from '@environments/environment';

export const endpoints = {
  GET_DEVICE_INFO: `${environment.endpoint}/corp/site/door/get`,
  GET_ONLINE_DEVICE_LIST: `${environment.endpoint}/corp/site/door/online`,
  SET_DOOR_TIME: `${environment.endpoint}/door/send/update_time`,
  SET_DOOR_TIMEZONE: `${environment.endpoint}/door/send/update_timezone`,
  UNLOCK_DOOR: `${environment.endpoint}/door/send/unlock`,
  SCHEDULE_STOP: `${environment.endpoint}/door/send/schedule_stop`,
  SCHEDULE_START: `${environment.endpoint}/door/send/schedule_start`,
  SCHEDULE_CHECK: `${environment.endpoint}/door/send/schedule_check`,
  DOOR_EVENTS: `${environment.endpoint}/corp/site/events/door`,
  USER_EVENTS: `${environment.endpoint}/corp/user/events`,
  DOOR_DAILY_STATS: `${environment.endpoint}/corp/events/door/dailyunlocks`,
  DOOR_HOURLY_STATS: `${environment.endpoint}/corp/events/door/hourlyunlocks`,
  USER_PERMISSIONS: `${environment.endpoint}/permission`,
  DEVICE_PERMISSIONS: `${environment.endpoint}/door/permissions`,
  LOGIN: `${environment.endpoint}/login`,
  GET_USER: `${environment.endpoint}/user`,
  UPDATE_USER: `${environment.endpoint}/user/update`,
  UPDATE_DOOR: `${environment.endpoint}/door/update`,
  UPDATE_GATEWAY: `${environment.endpoint}/corp/site/gateway/update`,
  UPDATE_CORP: `${environment.endpoint}/corp/update`,
  UPDATE_SITE: `${environment.endpoint}/corp/site/update`,
  CHANGE_PASSWORD: `${environment.endpoint}/user/changePassword`,
  RESET_PASSWORD: `${environment.endpoint}/user/resetPassword`,
  REQUEST_NEW_PASSWORD: `${environment.endpoint}/user/requestNewPassword`,
  AUTHENTICATION_METHODS: `${environment.endpoint}/user/authenticationMethods`,
  SEND_TOKEN: `${environment.endpoint}/token/send`,
  LOGIN_TOKEN: `${environment.endpoint}/login/token`,
  INVALIDATE_SESSION: `${environment.endpoint}/sessions/invalidate`,
  GET_CORPORATIONS: `${environment.endpoint}/corps`,
  GET_CORPORATION_SITES: `${environment.endpoint}/corp/sites`,
  GET_SITE_DOORS: `${environment.endpoint}/corp/site/doors`,
  CREATE_CORPORATION: `${environment.endpoint}/corp/new`,
  ADD_DOOR_TO_SITE: `${environment.endpoint}/corp/site/door/add`,
  CREATE_ROLE: `${environment.endpoint}/corp/site/role/new`,
  CREATE_CORPORATION_ROLE: `${environment.endpoint}/corp/site/role/new`,
  CREATE_SITE: `${environment.endpoint}/corp/site/new`,
  GET_SITE_ROLES: `${environment.endpoint}/corp/site/roles`,
  GET_EXTERNAL_ROLES: `${environment.endpoint}/corp/site/external/roles`,
  CREATE_ACCESS_GROUP: `${environment.endpoint}/corp/site/accessGroup/new`,
  UPDATE_ACCESS_GROUP: `${environment.endpoint}/corp/site/accessGroup/update`,
  UPDATE_DEVICES_ACCESS_GROUP: `${environment.endpoint}/corp/site/accessGroup/updateDoors`,
  UPDATE_USERS_AND_ROLES_ACCESS_GROUP: `${environment.endpoint}/corp/site/accessGroup/updateRolesAndUsers`,
  GET_ACCESS_GROUPS: `${environment.endpoint}/corp/site/accessGroups`,
  FIND_ACCESS_GROUPS: `${environment.endpoint}/corp/site/accessGroup/find`,
  REMOVE_GROUP: `${environment.endpoint}/corp/site/accessGroup/delete`,
  CREATE_USER: `${environment.endpoint}/corp/user/new`,
  FIND_USER_OR_ROLE: `${environment.endpoint}/corp/site/find`,
  GET_CORPORATION_USERS: `${environment.endpoint}/corp/user/list`,
  CHANGE_USER_STATUS: `${environment.endpoint}/corp/user/status`,
  GET_ROLE_USERS: `${environment.endpoint}/corp/role/users`,
  DELETE_ROLE: `${environment.endpoint}/corp/role/delete`,
  EDIT_ROLE: `${environment.endpoint}/corp/role/update`,
  UPDATE_GLOBAL_ROLE: `${environment.endpoint}/corp/role/updateGlobal`,
  UPDATE_ROLE_USERS: `${environment.endpoint}/corp/role/updateUsers`,
  UPDATE_LKUSER: `${environment.endpoint}/corp/user/update`,
  GET_DOORS_ACCESS_GROUP: `${environment.endpoint}/corp/site/accessGroup/doors`,
  GET_USERS_AND_ROLES_ACCESS_GROUP: `${environment.endpoint}/corp/site/accessGroup/userAndRoles`,
  GET_USER_ACCESS_GROUPS: `${environment.endpoint}/corp/user/accessGroups`,
  GET_USER_ROLES: `${environment.endpoint}/corp/user/roles`,
  SEND_FEEDBACK: `${environment.endpoint}/user/feedback`,
  GET_INTEGRATIONS: `${environment.endpoint}/corp/integrations`,
  CREATE_INTEGRATION: `${environment.endpoint}/corp/integration/guesty/new`,
  IMPORT_LISTINGS: `${environment.endpoint}/corp/guesty/importListings`,
  CONFIGURE_INTEGRATION: `${environment.endpoint}/session/client/create`,
  REMOVE_INTEGRATION: `${environment.endpoint}/corp/integration/delete`,
  GENERAL_EVENT_WEEK_USAGE: `${environment.endpoint}/corp/events/last7DaysUse`,
  GENERAL_EVENT_HOURLY_USAGE: `${environment.endpoint}/corp/events/hourlyUse`,
  GENERAL_EVENT_MONTH_USAGE: `${environment.endpoint}/corp/events/monthlyUse`,
  GENERAL_EVENTS_DASHBOARD: `${environment.endpoint}/corp/events/general`,
  BOOKINGS_STATS: `${environment.endpoint}/corp/site/statistics/bookings`,
  DEVICES_STATS: `${environment.endpoint}/corp/site/statistics/devices`,
  DASHBOARD_GENERAL_EVENTS_SITE: `${environment.endpoint}/corp/events`,
  GRAPH_WEEK_USAGE_DEVICE: `${environment.endpoint}/corp/events/door/weeklyunlocks`,
  DEVICES_GROUPS_AND_USERS: `${environment.endpoint}/corp/site/door/general`,
  GRAPH_HOURLY_USAGE_DEVICE: `${environment.endpoint}/corp/events/door/hourlyunlocks`,
  GRAPH_DAILY_USAGE_DEVICE: `${environment.endpoint}/corp/events/door/dailyunlocks`,
  MOST_ACCESSED_USERS: `${environment.endpoint}/corp/events/mostAccess`,
  DENIED_ACCESS_USERS: `${environment.endpoint}/corp/events/denied`,
  FIRMWARE_VERSION_DEVICE: `${environment.endpoint}/corp/site/door/send/read_firmware_version`,
  BATTERY_STATE_DEVICE: `${environment.endpoint}/corp/site/door/send/read_battery`,
  GENERATE_QRCODE_PASSCODE: `${environment.endpoint}/corp/user/access/generate`,
  SEND_QRCODE_PASSCODE: `${environment.endpoint}/corp/user/access/send`,
  SITE_GATEWAYS: `${environment.endpoint}/corp/site/gateways`,
  DOORS_GATEWAY: `${environment.endpoint}/corp/site/gateway/doors`,
  GATEWAY_DOORS: `${environment.endpoint}/corp/site/door/gateways`,
  GET_REPORTS: `${environment.endpoint}/corp/site/report/list`,
  REPORT_QUERY: `${environment.endpoint}/corp/site/report/query`,
  CREATE_REPORT: `${environment.endpoint}/corp/site/report/create`,
  DELETE_REPORT: `${environment.endpoint}/corp/site/report/delete`,
  DETAIL_REPORT: `${environment.endpoint}/corp/site/report/details`,
  SITE_PERMISSIONS: `${environment.endpoint}/corp/user/site/permissions`,
  PERSONAL_INFO: `${environment.endpoint}/corp/user/getPersonalInfo`,
  GET_BOOKINGS: `${environment.endpoint}/corp/site/bookings`,
  CREATE_BOOKING: `${environment.endpoint}/corp/site/booking/new`,
  UPDATE_BOOKING: `${environment.endpoint}/corp/site/booking/update`,
  DELETE_BOOKING: `${environment.endpoint}/corp/site/booking/delete`,
  FIND_BOOKING: `${environment.endpoint}/corp/site/booking/find`,
  GET_GROUPS_AND_USERS: `${environment.endpoint}/corp/site/door/groupsAndUsers`,
  GET_BILLINGS: `${environment.endpoint}/corp/billing`,
  GET_EXTERNAL_ROLE_INFO: `${environment.endpoint}/corp/site/role/share/info`,
  GET_CONNECT_ROLE_LINK: `${environment.endpoint}/corp/site/role/share`,
  DISCONNECT_ROLE: `${environment.endpoint}/corp/site/role/share/disconnect`,
  GET_ACCESS: `${environment.endpoint}/access`,
  REMOVE_DEVICE: `${environment.endpoint}/corp/site/door/remove`,
  REMOVE_SITE: `${environment.endpoint}/corp/site/remove`,
  GET_LISTINGS: `${environment.endpoint}/corp/site/listings`,
  GET_CORP_INTEGRATIONS: `${environment.endpoint}/corp/integrations`,
  GET_LISTING_BY_ID: `${environment.endpoint}/corp/site/listing`,
  UPSERT_DOORS_ON_LISTING: `${environment.endpoint}/corp/site/listing/upsertDoors`,
  SEND_COMMAND: `${environment.endpoint}/corp/site/gateway/command`,
  GENERATE_OFFLINE_PASSCODE: `${environment.endpoint}/corp/site/booking/otp`,
};
