import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
})
export class SearchButtonComponent implements OnInit {
  @Output() filterApplied = new EventEmitter<string>();
  @Output() clearEvent = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() filterValue = '';
  @Input() color: string;
  public isEmpty: boolean = true;
  public faSearch = faSearch;
  public faTimes = faTimes;

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.filterApplied.emit(filterValue);
    this.isEmpty = filterValue === '';
  }

  clearFilter() {
    this.clearEvent.emit();
    this.applyFilter('');
    this.isEmpty = true;
  }
}
