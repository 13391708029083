import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-stepper-controller',
  templateUrl: './stepper-controller.component.html',
  styleUrls: ['./stepper-controller.component.scss'],
})
export class StepperControllerComponent implements OnInit {
  @Output() onFormCleared = new EventEmitter<boolean>();
  @Output() onFormSubmitted = new EventEmitter<boolean>();
  @Input() isEditing: string;
  @Input() isInfo: string = 'false';
  @Input() actionText: string;
  @Input() blockButton: boolean = false;
  public sizeWidthSuitable: boolean = false;

  constructor() {
    this.sizeWidthSuitable = window.innerWidth <= 500;
  }

  ngOnInit() {}
}
