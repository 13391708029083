<div class="role-invitation">
  <div class="text-center">
    <h2 mat-dialog-title translate>
      Corp Connect Invitation
    </h2>
    <span class="gray-label" translate>You have received an invite from an external Corporation</span>
  </div>
  <div mat-dialog-content>
    <mat-card appearance="outlined" class="role-invitation__card-content">
      <h5 class="mrg-btm-20" translate>Corp Connect Info</h5>
      <h6 class="gray-label">Corp</h6>
      <span>{{role.corp}}</span>
      <h6 class="gray-label">Site</h6>
      <span>{{role.site}}</span>
      <h6 class="gray-label">Role Name</h6>
      <span>{{role.name}}</span>
    </mat-card>
    <mat-list class="no-pdd" [formGroup]="roleForm">
      <span translate>Choose Corporation to connect to</span>
      <mat-form-field class="width-100" appearance="outline">
        <mat-select formControlName="corp" placeholder="{{ 'Select Corporation' | translate }}">
          <mat-option *ngFor="let option of corps" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span translate>Choose Site to connect to</span>
      <mat-form-field class="width-100" appearance="outline">
        <mat-select formControlName="site" placeholder="{{ 'Select Site' | translate }}">
          <mat-option *ngFor="let option of sites" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list>
  </div>
    <div *ngIf="!widthSuitableSize" class="role-invitation__btn-actions">
      <div class="col-auto mr-auto">
        <button mat-dialog-close class="btn-cancel">
          <span class="btn-label" translate>Cancel</span>
        </button>
      </div>
      <div class="col-auto">
        <button
          aria-label="deny"
          mat-raised-button
          class="main-btn modal-button mrg-right-20"
          matDialogClose
        >
          <span class="btn-stop-label" translate>Deny</span>
        </button>
        <button
          aria-label="accept"
          mat-raised-button
          class="delete-button modal-button accent"
          (click)="connectRole()"
          matDialogClose
        >
          <span class="btn-label" translate>Accept</span>
        </button>
      </div>
    </div>
    <div *ngIf="widthSuitableSize" class="role-invitation__btn-actions">
      <button mat-dialog-close class="btn-general btn-cancel-float">
        <span class="btn-label" translate>Cancel</span>
      </button>

      <button
          aria-label="accept"
          mat-raised-button
          class="btn-general btn-accept-float"
          (click)="connectRole()"
          matDialogClose
        >
          <span class="btn-label" translate>Accept</span>
        </button>
    </div>
  </div>