<div class="result-modal__result text-center">
  <div class="centered" *ngIf="!data">
    <mat-spinner diameter="40" color="accent"></mat-spinner>
  </div>
  <img
    *ngIf="data.status && !data.warning"
    alt="result-icon"
    class="result-modal-icon"
    src="../../../../assets/icons/black_success.svg"
    width="80px"
  />
  <img
    *ngIf="!data.status && !data.warning"
    alt="result-icon"
    class="result-modal-icon"
    src="../../../../assets/icons/black_error.svg"
    width="80px"
  />
  <fa-icon alt="angle-right" *ngIf="data.warning" class="icon" [icon]="faExclamationTriangle"></fa-icon>
  <span class="result-modal__label-warning" translate>{{ data.text }}</span>
  <span class="subtitle" translate>{{ data.subtitle }}</span>
  <button
    aria-label="refresh"
    (click)="close()"
    mat-raised-button
    mat-dialog-close
    class="refresh-button right mrg-right-5"
  >
    <span translate>Close</span>
  </button>
</div>
