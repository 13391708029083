import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { CookiesManagerService } from '@services/cookies.manager.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { LoggingService } from '@services/logging.service';
import { ResultModalComponent } from '@shared/components/result-modal/result-modal.component';
import { GlobalsService } from '@services/globals.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FullStoryService } from '@services/fullstory-service.service';
import { FeedbackService } from '../feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  public feedbackForm: UntypedFormGroup;
  public currentUsername: string = '';
  public imageChangedEvent: any = '';
  public printscreens = [];
  public printPicture = [];
  public newPrintPicture: string = '';
  public mimeType: string = '';
  public loadingData: boolean = true;
  public pendingLoad: Promise<boolean>;
  public whatsappIcon;
  public sizeWidthSuitable = false;
  public showFeedback = false;
  public showSupport = true;
  public faQuestionCircle = faQuestionCircle;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private cookiesManagerService: CookiesManagerService,
    private dialog: MatDialogRef<FeedbackComponent>,
    private feedbackService: FeedbackService,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private dialogResult: MatDialog,
    private globalsService: GlobalsService,
    private fullStoryService: FullStoryService,
    private titleService: Title
  ) {
    this.feedbackForm = new UntypedFormGroup({
      category: new UntypedFormControl(data.category, [
        Validators.required,
        Validators.minLength(3),
      ]),
      feature: new UntypedFormControl(data.feature, [
        Validators.required,
        Validators.minLength(3),
      ]),
      feedbackDescription: new UntypedFormControl(data.feedbackDescription, []),
      corpId: new UntypedFormControl(
        this.globalsService.getCorporationId(),
        []
      ),
    });
  }

  ngOnInit() {
    this.sizeWidthSuitable = window.innerWidth <= 1200;
    this.whatsappIcon = faWhatsapp;
    this.loadInfoSession();

    this.titleService.setTitle('Feedback | LoopKey');

    this.fullStoryService.setUserVars({
      displayName: localStorage.getItem('userName'),
      corpId: this.globalsService.getCorporationId(),
      siteId: this.globalsService.getSiteId(),
    });
  }

  viewFeedback() {
    this.showFeedback = true;
    this.showSupport = false;
  }

  viewSupport() {
    this.showFeedback = false;
    this.showSupport = true;
  }

  loadInfoSession(): void {
    const session = this.cookiesManagerService.getSession();
    this.pendingLoad = this.getPromise();

    setTimeout(() => {
      if (session && session.user) {
        this.loadingData = false;
        this.currentUsername = `${session.user.name}`;
      }
    }, 3000);
  }

  getPromise(thereIsPrintPicture: boolean = false): Promise<boolean> {
    return new Promise((resolve) => {
      if (thereIsPrintPicture) {
        setTimeout(() => {
          if (this.loadingData) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, 60000);
      } else {
        setTimeout(() => {
          if (this.loadingData) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, 15000);
      }
    });
  }

  get f() {
    return this.feedbackForm.controls;
  }

  fileChangeEvent(event: any): void {
    this.getBase64(event);
    if (event.target.files.length > 0) {
      this.printscreens.push(event.target.files[0].name);
    }
  }

  getBase64(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = this.handleReaderLoaded.bind(this);
  }

  handleReaderLoaded(e) {
    this.printPicture.push(btoa(e.target.result));
  }

  remove(option: any) {
    const index = this.printscreens.indexOf(option);

    if (index >= 0) {
      this.printscreens.splice(index, 1);
    }
  }

  onSubmit() {
    this.loadingData = true;
    if (this.feedbackForm.valid) {
      Object.keys(this.feedbackForm.value).map((value) => {
        if (
          this.feedbackForm.value[value] == null ||
          this.feedbackForm.value[value] === ''
        )
          delete this.feedbackForm.value[value];
        return this.feedbackForm.value[value];
      });
      if (this.printPicture.length > 0) {
        this.pendingLoad = this.getPromise(true);
        this.newPrintPicture = atob(this.printPicture.toString()).replace(
          /^data:image\/[a-z]+;base64,/,
          ''
        );
        this.feedbackService
          .sendFeedbackPicture(this.feedbackForm.value, this.newPrintPicture)
          .subscribe(
            () => {
              this.successMessage();
              this.feedbackForm.reset();
              this.printscreens.length = 0;
            },
            () => {
              this.errorMessage();
            }
          );
      } else {
        this.pendingLoad = this.getPromise();
        this.feedbackService.sendFeedback(this.feedbackForm.value).subscribe(
          () => {
            this.successMessage();
            this.feedbackForm.reset();
            this.printscreens.length = 0;
          },
          () => {
            this.errorMessage();
          }
        );
      }
    }
  }

  successMessage() {
    this.loadingData = false;
    const title = this.translateService.instant('Feedback successfully sent!');
    const config = {
      panelClass: 'dialog-container',
      width: '450px',
      height: '250px',
      maxHeight: '100%',
      maxWidth: '100%',
      data: { text: title, status: true },
    };
    this.dialogResult.open(ResultModalComponent, config);
  }

  errorMessage() {
    this.loadingData = false;
    const title = this.translateService.instant('Error sending feedback!');
    const config = {
      panelClass: 'dialog-container',
      width: '450px',
      height: '250px',
      maxHeight: '100%',
      maxWidth: '100%',
      data: { text: title, status: false },
    };
    this.dialogResult.open(ResultModalComponent, config);
  }
}
