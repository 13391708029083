import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GroupsService } from '@modules/groups/groups.service';
import { ILKGroupRestrictions } from '@models/LKGroupRestrictions';
import { HelperService } from '@services/helper.manager.service';
import { GlobalsService } from '@services/globals.service';

@Component({
  selector: 'app-group-summary',
  templateUrl: './group-summary.component.html',
  styleUrls: ['./group-summary.component.scss'],
})
export class GroupSummaryComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() submitAccessGroup = new EventEmitter<{
    response: string;
    error: Boolean;
  }>();
  @Input() accessGroup: {
    name: '';
    description: '';
    doorNameList: [];
    doorList: '';
    userNameList: [];
    userList: '';
    roleList: '';
    siteId: '';
    restrictions: ILKGroupRestrictions;
  };
  public sizeWidthSuitable = false;

  constructor(
    private groupsService: GroupsService,
    private helperService: HelperService,
    private globalsService: GlobalsService
  ) {}

  ngOnInit() {
    this.sizeWidthSuitable = window.innerWidth <= 500;
  }

  onSubmit() {
    // eslint-disable-next-line prefer-const
    let groupInfo: any = JSON.parse(JSON.stringify(this.accessGroup));

    const { startDateTime } = groupInfo.restrictions;
    const { endDateTime } = groupInfo.restrictions;
    const { startTimeOfDay } = groupInfo.restrictions;
    const { endTimeOfDay } = groupInfo.restrictions;
    const { daysOfTheWeek } = groupInfo.restrictions;

    delete groupInfo.restrictions.startDateTime;
    delete groupInfo.restrictions.endDateTime;
    delete groupInfo.restrictions.startTimeOfDay;
    delete groupInfo.restrictions.endTimeOfDay;
    delete groupInfo.restrictions.daysOfTheWeek;
    delete groupInfo.restrictions.daysList;

    groupInfo.startDateTime = startDateTime
      ? this.helperService.getDateTimeString(new Date(startDateTime))
      : null;
    groupInfo.endDateTime = endDateTime
      ? this.helperService.getDateTimeString(new Date(endDateTime))
      : null;

    groupInfo.startTimeOfDay = startTimeOfDay;
    groupInfo.endTimeOfDay = endTimeOfDay;
    groupInfo.daysOfTheWeek = daysOfTheWeek;

    // eslint-disable-next-line prefer-const
    let finalGroupInfo: any = {
      name: groupInfo.name,
      description: groupInfo.description ? groupInfo.description : '',
      siteId: this.globalsService.getSiteId(),
      roleList: groupInfo.roleList,
      doorList: groupInfo.doorList,
      userList: groupInfo.userList,
      daysOfTheWeek: groupInfo.daysOfTheWeek,
      'restrictions.remoteAllowed': groupInfo.restrictions.remoteAllowed,
      'restrictions.smartphoneAllowed':
        groupInfo.restrictions.smartphoneAllowed,
      'restrictions.qrcodeAllowed': groupInfo.restrictions.qrcodeAllowed,
      'restrictions.cardAllowed': groupInfo.restrictions.cardAllowed,
      'restrictions.offlineAllowed': groupInfo.restrictions.offlineAllowed,
      'restrictions.passcodeAllowed': groupInfo.restrictions.passcodeAllowed,
    };

    if (
      groupInfo.startDateTime &&
      groupInfo.endDateTime &&
      !groupInfo.restrictions.offlineAllowed
    ) {
      finalGroupInfo.startDateTime = groupInfo.startDateTime;
      finalGroupInfo.endDateTime = groupInfo.endDateTime;
    }

    if (groupInfo.startTimeOfDay && !groupInfo.restrictions.offlineAllowed) {
      finalGroupInfo.startTimeOfDay = groupInfo.startTimeOfDay;
    }

    if (groupInfo.endTimeOfDay && !groupInfo.restrictions.offlineAllowed) {
      finalGroupInfo.endTimeOfDay = groupInfo.endTimeOfDay;
    }
    this.groupsService.createNewGroup(finalGroupInfo).subscribe(
      (response) => {
        this.submitAccessGroup.emit({ response, error: false });
      },
      (err) => {
        if (err.error.errorCode === 102) {
          this.submitAccessGroup.emit({
            response: 'Already exist a group with this name.',
            error: true,
          });
        } else {
          this.submitAccessGroup.emit({
            response: 'Action failed. Please, try again.',
            error: true,
          });
        }
      }
    );
  }

  changeStyleONListOfDays(selectDay: string): any {
    if (this.accessGroup.restrictions.daysList === undefined) {
      return {
        backgroundColor: '#979797',
      };
    }

    const hasDay = this.accessGroup.restrictions.daysList.find(
      (day) => day === selectDay
    );

    return {
      backgroundColor: hasDay ? '#1382AB' : '#979797',
    };
  }
}
