import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserSessionService } from '@services/user.session.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private sessionManager: UserSessionService,
    private router: Router
  ) {}

  private readonly AUTH_HEADER = 'Authorization';

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: new HttpHeaders().append(
          'Content-Type',
          'application/x-www-form-urlencoded'
        ),
      });
    }
    req = this.addAuthToken(req);

    return next.handle(req).pipe(
      timeout(60000),
      catchError((error: HttpErrorResponse) => {
        if (
          error &&
          error.status === 401 &&
          !error.url.includes('changePassword')
        ) {
          this.router.navigate(['/authentication']);
          /* TODO: Implement refresh token on backend */
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          // if (this.refreshTokenInProgress) {
          // 	return this.refreshTokenSubject.pipe(
          // 		filter(result => result !== null),
          // 		take(1),
          // 		switchMap(() => next.handle(this.addAuthToken(req)))
          // 	);
          // } else {
          // 	this.refreshTokenInProgress = true;

          // 	this.refreshTokenSubject.next(null);

          // 	return this.refreshAccessToken().pipe(
          // 		switchMap((success: boolean) => {
          // 			this.refreshTokenSubject.next(success);
          // 			return next.handle(this.addAuthToken(req));
          // 		})
          // 	),
          // 	finalize(() => this.refreshTokenInProgress = false);
          // }
        } else if (error instanceof TimeoutError) {
          const errorObj = {
            error: {
              errorDescription: error.message,
              name: error.name,
            },
          };
          return throwError(errorObj);
        }

        return throwError(error);
      })
    );
  }

  private addAuthToken(req: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a session yet then we should not set the header.
    if (!this.sessionManager.getSession()) {
      return req;
    }

    // If we do are requesting an outside domain then we should not set the header.
    if (!req.url.includes(environment.endpoint)) {
      return req;
    }

    // None of the above, set the auth header
    return req.clone({
      headers: req.headers.set(
        this.AUTH_HEADER,
        this.sessionManager.getSession().authorization
      ),
    });
  }
}
