import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { CorporationGuard } from '@core/guards/corporation.guard';
import { SitesGuard } from '@core/guards/sites.guard';

const routes: Routes = [
  {
    path: 'authentication',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'devices',
    loadChildren: () =>
      import('./modules/devices/devices.module').then((m) => m.DevicesModule),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'permissions',
    loadChildren: () =>
      import('./modules/permissions/permissions.module').then(
        (m) => m.PermissionsModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./modules/billing/billing.module').then((m) => m.BillingModule),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('./modules/integrations/integrations.module').then(
        (m) => m.IntegrationsModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'general-dashboard',
    loadChildren: () =>
      import('./modules/general-dashboard/general-dashboard.module').then(
        (m) => m.GeneralDashboardModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./modules/general-reports/general-reports.module').then(
        (m) => m.GeneralReportsModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./modules/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'corporations',
    loadChildren: () =>
      import('./modules/corporation/corporation.module').then(
        (m) => m.CorporationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sites',
    loadChildren: () =>
      import('./modules/sites/sites.module').then((m) => m.SitesModule),
    canActivate: [AuthGuard, CorporationGuard],
  },
  {
    path: 'groups',
    loadChildren: () =>
      import('./modules/groups/groups.module').then((m) => m.GroupsModule),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'roles',
    loadChildren: () =>
      import('./modules/roles/roles.module').then((m) => m.RolesModule),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./modules/feedback/feedback.module').then(
        (m) => m.FeedbackModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('./modules/bookings/bookings.module').then(
        (m) => m.BookingsModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  {
    path: 'listings',
    loadChildren: () =>
      import('./modules/listings/listings.module').then(
        (m) => m.ListingsModule
      ),
    canActivate: [AuthGuard, CorporationGuard, SitesGuard],
  },
  { path: '**', redirectTo: '/general-dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
