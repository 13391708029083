<div class="bookings-roles-rooms">
  <h4 class="bookings-roles-rooms__title" translate><fa-icon *ngIf="widthSizeSuitable" (click)="changeStep(1)" class="mrg-right-5" icon="arrow-left"></fa-icon>Select accommodation</h4>
  <span class="bookings-roles-rooms__subtitle" translate>You can search for an existing Room or choose to select it
    manually on the list below.</span>
  <div mat-dialog-content>
    <mat-list class="no-pdd header">
      <span class="label-search-name" translate>Search by Room name</span>
      <mat-form-field class="width-100" appearance="outline">
        <span matPrefix class="pdd-right-10">
          <fa-icon [icon]="faSearch" class="font-size-14"></fa-icon>
        </span>
        <input matInput type="text" [formControl]="roomsControl" [matAutocomplete]="auto" class="primary-color"
          placeholder="{{ 'Must have at least 3 characters.' | translate }}" />
        <mat-error *ngIf="roomsControl.errors" translate>
          Must have at least 3 characters.
        </mat-error>
        <mat-autocomplete #auto="matAutocomplete">
        </mat-autocomplete>
      </mat-form-field>
    </mat-list>

    <div class="row">
      <div class="col-md-6">
        <span class="bookings-roles-rooms__title" translate>Rooms (your devices)</span>
        <mat-card appearance="outlined" class="bookings-roles-rooms__users">
          <mat-card-content>
            <mat-list *ngIf="filteredOptions.length === 0">
              <mat-list-item *ngFor="let option of devices" style="cursor: pointer;" (click)="addOption(option)"
                class="bookings-roles-rooms__selected-option">
                <span class="option-name">
                  {{ option.name }}
                </span>
              </mat-list-item>
            </mat-list>
            <mat-list *ngIf="filteredOptions.length !== 0">
              <mat-list-item *ngFor="let option of filteredOptions" style="cursor: pointer;" (click)="addOption(option)"
                class="bookings-roles-rooms__selected-option">
                <span class="option-name">
                  {{ option.name }}
                </span>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
        <mat-error class="mrg-top-10" *ngIf="selectedOptions.length === 0" translate>
          Select at least one room
        </mat-error>
      </div>

      <div class="col-md-6 bookings-roles-rooms__selected-labels">
        <span class="bookings-roles-rooms__title" translate>Selected Rooms</span>
        <mat-card appearance="outlined" class="bookings-roles-rooms__users">
          <mat-card-content>
            <mat-list>
              <mat-list-item *ngFor="let option of selectedOptions" style="cursor: pointer;"
                (click)="removeOption(option)" class="bookings-roles-rooms__selected-option">

                <span class="option-name">
                  {{ option.name }}
                </span>
                <fa-icon *ngIf="option.hasExternalSite" class="mrg-right-10 option-name" icon="link"></fa-icon>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="steppers">
    <app-stepper-controller isEditing="false" [actionText]="'Next'"
      [blockButton]="selectedOptions.length === 0 ? true : false"
      (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>