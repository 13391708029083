<span class="load-wrapper">
  <div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
    <mat-spinner diameter="40" color="accent"></mat-spinner>
  </div>
</span>
<div *ngIf="!loadingData && !(pendingLoad | async)" class="firmware-version">
  <span>
    <div class="firmware-version__icon">
      <fa-icon icon="microchip"></fa-icon>
    </div>
    <div class="firmware-version__title">
      <span class="label" translate>Firmware Current Version:</span>
      <span class="version">{{ firmwareVersion }}</span>
    </div>
  </span>
  <button
    *ngIf="!sizeSuitableWidth"
    aria-label="close"
    (click)="close()"
    mat-raised-button
    class="firmware-version__close-button right mrg-top-35 mrg-btm-10"
  >
    <span translate>Close</span>
  </button>
  <button
    *ngIf="sizeSuitableWidth"
    aria-label="close"
    (click)="close()"
    mat-raised-button
    class="firmware-version__close-button-absolute right mrg-top-35 mrg-btm-10"
  >
    <span translate>Close</span>
  </button>
</div>
