import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { MaterialModule } from 'app/material.module';
import { SharedModule } from '@shared/shared.module';
import { BillingRoutingModule } from './billing-routing.module';
import { BillingComponent } from './pages/billing/billing.component';
import { InvoiceDetailsComponent } from './components/invoice-details/invoice-details.component';

@NgModule({
  declarations: [BillingComponent, InvoiceDetailsComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BillingRoutingModule,
  ],
})
export class BillingModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faDownload);
  }
}
