import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BookingsService } from '@modules/bookings/bookings.service';
import { TranslateService } from '@ngx-translate/core';
import { ResultModalComponent } from '../result-modal/result-modal.component';

@Component({
  selector: 'app-delete-booking',
  templateUrl: './delete-booking.component.html',
  styleUrls: ['./delete-booking.component.scss'],
})
export class DeleteBookingComponent {
  @Output() bookingDeleted = new EventEmitter<boolean>();
  private results: { hasError: boolean; booking }[] = [];
  public sizeWidthSuitable: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private bookingsService: BookingsService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {
    this.sizeWidthSuitable = window.innerWidth <= 500;
  }

  deleteBooking() {
    this.bookingsService
      .deleteBooking(this.data.booking.id)
      .then(() => {
        const { booking } = this.data;
        this.bookingDeleted.emit(true);
        this.results.push({ hasError: false, booking });
        let title = '';
        title = this.translateService.instant(`Booking Successfully Deleted!`);
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '270px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: true },
        };
        this.dialog.open(ResultModalComponent, config);
      })
      .catch(() => {
        const { booking } = this.data;
        this.results.push({ hasError: true, booking });
        let title = '';
        title = this.translateService.instant(`Failed to Delete Booking!`);
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '270px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: false },
        };
        this.dialog.open(ResultModalComponent, config);
      });
  }
}
