<div class="create-guest">
  <h4 class="create-guest__title" translate>Guest Info</h4>
  <span class="create-guest__subtitle" translate>You can search for an existing User inside your Corporation or choose
    to add one manually.</span>
  <div mat-dialog-content>
    <mat-list class="no-pdd header">
      <span translate>
        Add an existing user by name
      </span>
      <mat-form-field class="width-100" appearance="outline">
        <span matPrefix class="pdd-right-10">
          <fa-icon [icon]="faSearch" class="font-size-14"></fa-icon>
        </span>
        <!-- <span matSuffix *ngIf="guestControl.value" (click)="clearFilter()">
          <fa-icon icon="times" class="font-size-15 time"></fa-icon>
        </span> -->
        <input matInput type="text" [formControl]="guestControl" [matAutocomplete]="auto" class="primary-color"
          placeholder="{{ 'Must have at least 3 characters.' | translate }}" />
        <mat-error *ngIf="guestControl.errors" translate>
          Must have at least 3 characters.
        </mat-error>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngIf="filteredOptions.length === 0 && guestControl.value.length > 2"><span translate>Loading...</span></mat-option>
          <mat-option (onSelectionChange)="addGuest($event)" *ngFor="let option of filteredOptions"
            class="create-guest__options" [value]="option">
            {{ verifyOptions(option) }}
            {{ option.createGuest || option.name }}
            {{ option.surname === undefined ? '' : option.surname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-list>
    <div class="create-guest__divider"></div>
    <div>
      <form [formGroup]="guestForm">
        <mat-list class="no-pdd">
          <div class="row">
            <div class="col-md-6">
              <span>{{ 'Name' | translate }} *</span>
              <mat-form-field class="width-100" appearance="outline" required>
                <input id="name" formControlName="userName" matInput type="text" class="third-color" />
                <mat-error *ngIf="f.userName.errors" translate>
                  Required. Must have at least 3 characters.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <span>{{ 'Surname' | translate }} *</span>
              <mat-form-field class="width-100" appearance="outline">
                <input id="surname" formControlName="userSurname" matInput type="text" class="third-color" />
                <mat-error *ngIf="f.userSurname.errors" translate>
                  Required. Must have at least 3 characters.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mrg-top-10">
            <div class="col-md-6">
              <span>Email</span>
              <mat-form-field class="width-100" appearance="outline">
                <input id="email" formControlName="userEmail" matInput type="email" class="third-color" />
                <span matSuffix (click)="enableInput('userEmail')">
                  <fa-icon [icon]="faEdit" *ngIf="data.action == 'Edit' && data.corpAccessLevel == 'guest'"
                    class="font-size-14 edit"></fa-icon>
                </span>
                <mat-error *ngIf="f.userEmail.errors" translate>
                  {{ getEmailErrorMessage() }}
                </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="updatedPhone" class="col-md-6">
              <span>{{ 'Phone' | translate }} </span>
              <div style="margin-top: 2px !important;">  
                <ngx-intl-tel-input formControlName="userPhone" [separateDialCode]="true"
                [preferredCountries]="preferredCountries" [enablePlaceholder]="false" [phoneValidation]="true">
              </ngx-intl-tel-input>
              <span>
                <mat-error *ngIf="f.userPhone.invalid" translate class="phone-error pdd-left-10 pdd-top-5">
                  {{ getPhoneErrorMessage() }}
                </mat-error>
              </span>
            </div>
            </div>
            <div *ngIf="!updatedPhone" class="col-md-6">
              <span>{{ 'Phone' | translate }}</span>
              <mat-form-field class="width-100" appearance="outline">
                <input id="phone" formControlName="userPhone" matInput type="phone" class="third-color" />
                <span matSuffix (click)="enableInput('userPhone')">
                  <fa-icon [icon]="faEdit" *ngIf="data.action == 'Edit' && data.corpAccessLevel == 'guest'"
                    class="font-size-12 edit"></fa-icon>
                </span>
              </mat-form-field>
            </div>
          </div>
          <div class="row mrg-top-10">
            <div class="col-md-6" *ngIf="!updatedCpf">
              <span>CPF</span>
              <mat-form-field class="width-100" appearance="outline">
                <input id="cpf" formControlName="userCpf" matInput type="cpf" class="third-color" />
              </mat-form-field>
            </div>
            <div class="col-md-6" *ngIf="updatedCpf">
              <span>CPF</span>
              <mat-form-field class="width-100" appearance="outline">
                <input id="cpf" formControlName="userCpf" matInput type="cpf" class="third-color"
                  mask="000.000.000-00" />
                <span matSuffix (click)="enableInput('userCpf')">
                  <fa-icon [icon]="faEdit" *ngIf="data.action == 'Edit' && data.corpAccessLevel == 'guest'"
                    class="font-size-14 edit"></fa-icon>
                </span>
                <mat-error *ngIf="f.userCpf.errors" translate>
                  {{ getCPFErrorMessage() }}
                </mat-error> 
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <span translate>Card number</span>
              <mat-form-field class="width-100" appearance="outline">
                <input id="userCard" formControlName="userCard" matInput type="text" class="third-color" />
                <span matSuffix (click)="enableInput('userCard')">
                  <fa-icon [icon]="faEdit" *ngIf="data.action == 'Edit' && data.corpAccessLevel == 'guest'"
                    class="font-size-14 edit"></fa-icon>
                </span>
                <mat-error *ngIf="f.userCard.errors" translate>
                  {{ getCardErrorMessage() }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mrg-top-10">
            <div *ngIf="data.corpAccessLevel !== 'owner' && data.corpAccessLevel !== 'admin'" class="col-md-6">
              <span translate>Passcode</span>
              <mat-form-field class="width-100" appearance="outline">
                <input id="userPasscode" formControlName="userPasscode" matInput type="text" class="third-color" />
                <span matSuffix (click)="enableInput('userPasscode')">
                  <fa-icon [icon]="faEdit" *ngIf="data.action == 'Edit'" class="font-size-14 edit"></fa-icon>
                </span>
                <mat-error *ngIf="f.userPasscode.errors" translate>
                  {{ getPasscodeErrorMessage() }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-list>
      </form>
    </div>
  </div>
  <div class="steppers">
    <app-stepper-controller class="stepper-controller" isEditing="true" [blockButton]="guestForm.valid ? false : true"
      [actionText]="'Next'" (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>