<div class="search-button">
  <mat-form-field [ngClass]="color" appearance="outline">
    <input
      type="text"
      [value]="filterValue"
      matInput
      class="ng-pristine ng-invalid ng-invalid-required ng-touched mrg-right-2"
      (keyup)="applyFilter($event.target.value)"
      [placeholder]="placeholder | translate"
    />
    <span matPrefix class="search-icon">
      <fa-icon [icon]="faSearch" class="font-size-14"></fa-icon> &nbsp;
    </span>
    <span matSuffix *ngIf="!isEmpty" (click)="clearFilter()">
      <fa-icon [icon]="faTimes" class="font-size-14 time"></fa-icon>
    </span>
  </mat-form-field>
</div>
