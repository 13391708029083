import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { MaterialModule } from 'app/material.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  faHome,
  faWarehouse,
  faTh,
  faUser,
  faSpinner,
  faTimes,
  faCalendarAlt,
  faCalendar,
  faClock,
  faExclamation,
  faExclamationTriangle,
  faEdit,
  faTimesCircle,
  faEllipsisV,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { SharedModule } from '../../shared/shared.module';
import { BookingsRoutingModule } from './bookings-routing.module';
import { BookingsComponent } from './pages/bookings/bookings.component';
import { CreateBookingComponent } from './pages/create-booking/create-booking.component';
import { BookingSummaryComponent } from './pages/booking-summary/booking-summary.component';
import { BookingResultComponent } from './pages/booking-result/booking-result.component';
import { DateBookingComponent } from './pages/date-booking/date-booking.component';
import { OfflinePasscodeComponent } from './components/offline-passcode/offline-passcode.component';

@NgModule({
  declarations: [
    BookingsComponent,
    CreateBookingComponent,
    BookingSummaryComponent,
    BookingResultComponent,
    DateBookingComponent,
    OfflinePasscodeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BookingsRoutingModule,
    SharedModule,
    MaterialModule,
    FontAwesomeModule,
    NgxMaterialTimepickerModule,
  ],
})
export class BookingsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faHome,
      faWarehouse,
      faTh,
      faUser,
      faSpinner,
      faTimes,
      faCalendarAlt,
      faCalendar,
      faClock,
      faExclamation,
      faExclamationTriangle,
      faEdit,
      faTimesCircle,
      faEllipsisV,
      faArrowLeft
    );
  }
}
