export interface ILKEventUser {
  id: string;
  cpUserId: string;
  picture: string;
  name: string;
  surname: string;
  card: string;
  passcode: string;
  qrCode: string;
  email: string;
  phone: string;
}

export class LKEventUser {
  id: string;
  cpUserId: string;
  picture: string;
  name: string;
  surname: string;
  card: string;
  passcode: string;
  qrCode: string;
  email: string;
  phone: string;

  constructor(serverObject: Partial<ILKEventUser>) {
    Object.assign(this, serverObject);
  }
}
