import * as qs from 'querystring';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/http/api.service';
import { Observable } from 'rxjs';
import { GlobalsService } from '@services/globals.service';

@Injectable({
  providedIn: 'root',
})
export class ListingsService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getListings = (skip: number, limit: number): Observable<any> => {
    return this.apiService.getListings(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        skip,
        limit,
      })
    );
  };

  verifyListings = (corpId: number): Observable<any> => {
    return this.apiService.getCorpIntegrations(
      qs.stringify({
        corpId,
      })
    );
  };

  getListingById = (listingId: number): Observable<any> => {
    return this.apiService.getListingById(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        listingId,
      })
    );
  };

  upsertDoors(doorIds: any[], listingId: number): Observable<any> {
    const deviceIds = doorIds.length > 0 ? doorIds : '';
    return this.apiService.upsertDoorsOnListing(
      qs.stringify({
        doorIds: deviceIds,
        listingId,
        siteId: this.globalsService.getSiteId(),
      })
    );
  }
}
