// ANGULAR IMPORTS
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgxMaskModule, IConfig } from 'ngx-mask';
// COMPONENTS AND THIRD PARTY
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faSearch,
  faExclamationTriangle,
  faBan,
  faRedo,
  faPowerOff,
  faGlobe,
  faSync,
  faDoorOpen,
  faClock,
  faMicrochip,
  faBatteryFull,
  faEllipsisV,
  faKey,
  faPaperPlane,
  faQrcode,
  faIdCard,
  faSortDown,
  faChevronRight,
  faCalendarAlt,
  faShareSquare,
  faTrashAlt,
  faLock,
  faLink,
  faUserPlus,
  faShapes,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { MaterialModule } from 'app/material.module';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { DeviceConfigComponent } from '@shared/components/lk-config/device-config/device-config.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from '@services/custom-mat-paginator-intl.service';
import { CreateRoleComponent } from '@modules/roles/pages/create-role/create-role.component';
import { RoleInfoComponent } from '@modules/roles/components/role-info/role-info.component';
import { ModalResultComponent } from '@modules/roles/components/modal-result/modal-result.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileService } from '@modules/profile/profile.service';
import { CreateGroupComponent } from '@modules/groups/pages/create-group/create-group.component';
import { GroupInfoComponent } from '@modules/groups/components/group-info/group-info.component';
import { GroupDoorsComponent } from '@modules/groups/components/group-doors/group-doors.component';
import { GroupSummaryComponent } from '@modules/groups/components/group-summary/group-summary.component';
import { GroupRestrictionsComponent } from '@modules/groups/components/group-restrictions/group-restrictions.component';
import { GroupDetailsComponent } from '@modules/groups/pages/group-details/group-details.component';
import { GroupResultComponent } from '@modules/groups/components/group-result/group-result.component';
import { RemoveDeviceComponent } from '@modules/devices/components/remove-device/remove-device.component';
import { DashboardCardComponent } from '@modules/general-dashboard/components/dashboard-card/dashboard-card.component';
import { ExtendedDashboardCardComponent } from '@modules/general-dashboard/components/extended-dashboard-card/extended-dashboard-card.component';
import { DataTablePagerComponent } from '@utils/pager.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AccessesComponent } from './components/accesses/accesses.component';
import { SearchButtonComponent } from './components/search-button/search-button.component';
import { SearchToggleComponent } from './components/search-toggle/search-toggle.component';
import { InfiniteScrollerDirective } from './directives/infinite-scroller.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { LockersComponent } from './components/modals/lockers/lockers.component';
import { DeviceSettingsComponent } from './components/modals/device-settings/device-settings.component';
import { StepperControllerComponent } from './components/stepper-controller/stepper-controller.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { CreateGuestComponent } from './components/create-guest/create-guest.component';
import { SearchMembersComponent } from './components/search-members/search-members.component';
import { NoConnectionComponent } from './components/no-connection/no-connection.component';
import { TopLoadingComponent } from './components/top-loading/top-loading.component';
import { ResultModalComponent } from './components/result-modal/result-modal.component';
import { SnackMessageComponent } from './components/snack-message/snack-message.component';
import { SnackAccessComponent } from './components/snack-access/snack-access.component';
import { ChangeImageUserComponent } from './components/change-image-user/change-image-user.component';
import { GatewayConfigComponent } from './components/lk-config/gateway-config/gateway-config.component';
import { DeleteBookingComponent } from './components/delete-booking/delete-booking.component';
import { DeviceGroupsUsersComponent } from './components/device-groups-users/device-groups-users.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { BookingsRolesRoomsComponent } from './components/bookings-roles-rooms/bookings-roles-rooms.component';
import { GuestyIntegrationComponent } from './components/guesty-integration/guesty-integration.component';
import { SelectDeviceComponent } from './components/select-device/select-device.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
  declarations: [
    SearchButtonComponent,
    SearchToggleComponent,
    LockersComponent,
    InfiniteScrollerDirective,
    DeviceSettingsComponent,
    DeviceConfigComponent,
    GatewayConfigComponent,
    AutofocusDirective,
    StepperControllerComponent,
    CreateUserComponent,
    CreateGuestComponent,
    DeleteBookingComponent,
    SearchMembersComponent,
    NoConnectionComponent,
    AccessesComponent,
    CreateRoleComponent,
    RoleInfoComponent,
    ModalResultComponent,
    TopLoadingComponent,
    ResultModalComponent,
    SnackMessageComponent,
    SnackAccessComponent,
    ChangeImageUserComponent,
    CreateGroupComponent,
    GroupInfoComponent,
    GroupDoorsComponent,
    GroupSummaryComponent,
    GroupRestrictionsComponent,
    GroupDetailsComponent,
    GroupResultComponent,
    DeviceGroupsUsersComponent,
    DeleteModalComponent,
    BookingsRolesRoomsComponent,
    RemoveDeviceComponent,
    GuestyIntegrationComponent,
    SelectDeviceComponent,
    DashboardCardComponent,
    ExtendedDashboardCardComponent,
    DataTablePagerComponent,
  ],
  imports: [
    NgxMaterialTimepickerModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FontAwesomeModule,
    MaterialModule,
    TranslateModule,
    RouterModule,
    NgxIntlTelInputModule,
    MatPaginatorModule,
    NgxMaskModule.forRoot(options),
    ImageCropperModule,
    NgxDatatableModule,
  ],
  exports: [
    SearchButtonComponent,
    SearchToggleComponent,
    CommonModule,
    FormsModule,
    LockersComponent,
    InfiniteScrollerDirective,
    AutofocusDirective,
    TranslateModule,
    DeviceConfigComponent,
    GatewayConfigComponent,
    StepperControllerComponent,
    NgxIntlTelInputModule,
    CreateUserComponent,
    CreateGuestComponent,
    DeleteBookingComponent,
    SearchMembersComponent,
    NoConnectionComponent,
    AccessesComponent,
    CreateRoleComponent,
    RoleInfoComponent,
    ModalResultComponent,
    TopLoadingComponent,
    CreateGroupComponent,
    GroupInfoComponent,
    GroupDoorsComponent,
    GroupSummaryComponent,
    GroupRestrictionsComponent,
    GroupDetailsComponent,
    GroupResultComponent,
    DeviceGroupsUsersComponent,
    BookingsRolesRoomsComponent,
    RemoveDeviceComponent,
    SelectDeviceComponent,
    DashboardCardComponent,
    ExtendedDashboardCardComponent,
    DataTablePagerComponent,
    NgxDatatableModule,
  ],
  providers: [
    MaintenanceService,
    ProfileService,
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService },
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSearch,
      faExclamationTriangle,
      faBan,
      faRedo,
      faPowerOff,
      faGlobe,
      faSync,
      faDoorOpen,
      faClock,
      faMicrochip,
      faBatteryFull,
      faEllipsisV,
      faKey,
      faPaperPlane,
      faQrcode,
      faIdCard,
      faSortDown,
      faChevronRight,
      faCalendarAlt,
      faShareSquare,
      faTrashAlt,
      faLock,
      faLink,
      faUserPlus,
      faShapes,
      faList
    );
  }
}
