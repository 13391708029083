import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'app-firmware-version',
  templateUrl: './firmware-version.component.html',
  styleUrls: ['./firmware-version.component.scss'],
})
export class FirmwareVersionComponent implements OnInit {
  public firmwareVersion: string = '';
  public sizeSuitableWidth: boolean = false;
  public pendingLoad: Promise<boolean>;
  public loadingData: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialogRef<FirmwareVersionComponent>,
    private maintenanceService: MaintenanceService,
    private loggingService: LoggingService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.sizeSuitableWidth = window.innerWidth <= 500;
    this.firmwareVersion = this.data.firmwareVersion;
    this.loadFirmwareVersion();
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
          this.dialog.close({ successful: false });
          this.loggingService.customAlert(
            {
              position: 'center',
              title: this.translateService.instant(
                'It was not possible to read firmware version.'
              ),
            },
            'error'
          );
        } else {
          resolve(false);
        }
      }, 60000);
    });
  }

  loadFirmwareVersion() {
    this.loadingData = true;
    this.pendingLoad = this.getPromise();

    if (this.data.type === 'Door') {
      this.maintenanceService
        .getFirmwareVersion({ id: this.data.id })
        .subscribe(
          (response) => {
            setTimeout(() => {
              this.loadingData = false;
            }, 1000);
            if (response) {
              this.firmwareVersion = response.version;
            } else {
              this.dialog.close({ successful: false });
              this.loggingService.customAlert(
                {
                  position: 'center',
                  title: this.translateService.instant(
                    'It was not possible to read firmware version.'
                  ),
                },
                'error'
              );
            }
          },
          (err) => {
            setTimeout(() => {
              this.loadingData = false;
            }, 1000);
            this.dialog.close({ successful: false });
            const { errorDescription } = err.error;
            this.loggingService.customAlert(
              {
                position: 'center',
                title: this.translateService.instant(errorDescription),
              },
              'error'
            );
          }
        );
    } else if (this.data.type === 'Gateway') {
      this.maintenanceService
        .sendGatewayCommand(this.data.id, 'version')
        .subscribe(
          (response) => {
            setTimeout(() => {
              this.loadingData = false;
            }, 1000);
            if (response) {
              this.firmwareVersion = response.response.version;
            } else {
              this.dialog.close({ successful: false });
              this.loggingService.customAlert(
                {
                  position: 'center',
                  title: this.translateService.instant(
                    'It was not possible to read firmware version.'
                  ),
                },
                'error'
              );
            }
          },
          (err) => {
            setTimeout(() => {
              this.loadingData = false;
            }, 1000);
            this.dialog.close({ successful: false });
            const { errorDescription } = err.error;
            this.loggingService.customAlert(
              {
                position: 'center',
                title: this.translateService.instant(errorDescription),
              },
              'error'
            );
          }
        );
    }
  }

  close() {
    this.dialog.close({ successful: true });
  }
}
