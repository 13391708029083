import { Injectable } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import { environment } from 'environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class FullStoryService {
  public orgId = environment.orgId;

  constructor() {
    const fsScript = document.createElement('script');
    fsScript.src = 'https://www.fullstory.com/s/fs.js';
    fsScript.async = true;
    document.head.appendChild(fsScript);
  }

  init(): void {
    FullStory.init({
      orgId: this.orgId,
    });
  }

  identify(userId: string, userVars?: any): void {
    FullStory.identify(userId, userVars);
  }

  setUserVars(userVars: any): void {
    FullStory.setUserVars(userVars);
  }

  setPageVars(pageVars: any): void {
    FullStory.setVars('page', pageVars);
  }
}
