<div class="role-info">
  <form [formGroup]="roleForm">
    <div>
      <span translate>Name</span>
      <mat-form-field class="width-100" appearance="outline">
        <input matInput type="text" formControlName="name" placeholder="{{ 'Managers' | translate }}"
          class="secondary-color" />
        <mat-error *ngIf="f.name.errors" translate>
          Required. Must have at least 3 characters.
        </mat-error>
      </mat-form-field>
      <span translate>Description</span>
      <mat-form-field class="width-100" appearance="outline">
        <input matInput type="text" formControlName="description" placeholder="{{ 'Group of managers' | translate }}"
          class="secondary-color" />
        <mat-error *ngIf="f.description.errors" translate>
          Must have at least 3 characters.
        </mat-error>
      </mat-form-field>
      <div *ngIf="isEditing && !role.isGlobal || !isEditing">
        <span translate>Global Role</span>
        <div>
          <mat-slide-toggle formControlName="isGlobal">
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="isEditing && role.isGlobal">
        <fa-icon class="font-size-20 mrg-right-10" icon="info-circle"></fa-icon>
        <span translate>This is a Global Role</span>
      </div>
    </div>
    <div class="steppers">
      <app-stepper-controller class="stepper-controller" isEditing="true" [actionText]="isEditing ? 'Save' : 'Next'"
        (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()"></app-stepper-controller>
    </div>
  </form>
</div>