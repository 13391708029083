<div class="device-groups-users-wrapper">
  <div class="device-groups-users">
    <div mat-dialog-content>
      <mat-list class="no-pdd">
        <mat-form-field class="width-100" appearance="outline">
          <span matPrefix class="pdd-right-10">
            <fa-icon [icon]="faSearch" class="font-size-12"></fa-icon>
          </span>
          <input matInput type="text" [formControl]="myControl" [matAutocomplete]="auto" class="primary-color"
            placeholder="{{ 'Must have at least 3 characters.' | translate }}" />
          <mat-error *ngIf="myControl.errors" translate>
            Must have at least 3 characters.
          </mat-error>
          <mat-autocomplete #auto="matAutocomplete">
          </mat-autocomplete>
        </mat-form-field>
      </mat-list>

      <mat-card appearance="outlined" class="device-groups-users__users centered">
        <mat-card-content>
          <mat-list *ngIf="this.view === 'users' && filteredOptions.length === 0">
            <mat-list-item *ngFor="let option of deviceUsers" class="device-groups-users__selected-user">
              <div>
                <img class="device-groups-users__user-img" [src]="option.picture"
                  *ngIf="option.picture && isImageLoading" />
                <img alt="user default image" class="device-groups-users__user-img"
                  src="../../assets/images/avatars/default-users.png" *ngIf="
                  (!isImageLoading || !option.picture) && !option.surname
                " />
                <img alt="users default image" class="device-groups-users__user-img"
                  src="../../assets/images/avatars/default-user.png" *ngIf="
                  (!isImageLoading || !option.picture) && option.surname
                " />
                <span class="user-name">
                  {{ option.name }}
                  {{ option.surname === undefined ? '' : option.surname }}
                </span>
              </div>
            </mat-list-item>
          </mat-list>
          <mat-list *ngIf="this.view === 'users' && filteredOptions.length !== 0">
            <mat-list-item *ngFor="let option of filteredOptions" class="device-groups-users__selected-user">
              <div>
                <img class="device-groups-users__user-img" [src]="option.picture"
                  *ngIf="option.picture && isImageLoading" />
                <img alt="user default image" class="device-groups-users__user-img"
                  src="../../assets/images/avatars/default-users.png" *ngIf="
                  (!isImageLoading || !option.picture) && !option.surname
                " />
                <img alt="users default image" class="device-groups-users__user-img"
                  src="../../assets/images/avatars/default-user.png" *ngIf="
                  (!isImageLoading || !option.picture) && option.surname
                " />
                <span class="user-name">
                  {{ option.name }}
                  {{ option.surname === undefined ? '' : option.surname }}
                </span>
              </div>
            </mat-list-item>
          </mat-list>
          <mat-list *ngIf="this.view === 'groups' && filteredOptions.length === 0">
            <mat-list-item *ngFor="let option of deviceGroups" class="device-groups-users__selected-user">
              <div>
                <span class="user-name">
                  {{ option.name }}
                </span>
              </div>
            </mat-list-item>
          </mat-list>
          <mat-list *ngIf="this.view === 'groups' && filteredOptions.length !== 0">
            <mat-list-item *ngFor="let option of filteredOptions" class="device-groups-users__selected-user">
              <div>
                <span class="user-name">
                  {{ option.name }}
                </span>
              </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="steppers">
    <app-stepper-controller isEditing="{{ this.view === 'users' ? 'false' : 'true' }}"
      [actionText]="this.view === 'users' ? 'Finish' : 'Next'" (onFormCleared)="previousItem.emit()"
      (onFormSubmitted)="this.view === 'groups' ? this.nextItem.emit(true) : this.finishEvent.emit(true)">
    </app-stepper-controller>
  </div>
</div>