<div class="search-members">
  <div mat-dialog-content>
    <mat-list class="no-pdd">
      <div>
        <span class="label-search-member" *ngIf="this.roleSearch === false" translate>
          Search for a user or role
        </span>
        <span class="label-search-member" *ngIf="this.roleSearch === true" translate>
          Search for an user
        </span>
      </div>
      <div class="row g-0" style="flex-wrap: inherit;" *ngIf="(this.roleSearch === true && !widthSizeSuitable) || widthSizeSuitable">
        <div class="width-100" style="flex: inherit;">
          <mat-form-field [ngClass]="widthSizeSuitable ? 'width-100' : 'width-100 pdd-right-40'" appearance="outline">
            <span matPrefix class="pdd-right-10">
              <fa-icon [icon]="faSearch" class="font-size-14"></fa-icon>
            </span>
            <input matInput type="text" [formControl]="myControl" [matAutocomplete]="auto" class="primary-color"
              placeholder="{{ 'Must have at least 3 characters.' | translate }}" />
            <mat-error *ngIf="myControl.errors" translate>
              Must have at least 3 characters.
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete">
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col" *ngIf="!widthSizeSuitable">
          <button class="right btn-new-user" mat-raised-button (click)="createUser()">
            <span class="bold" translate>New User</span>
          </button>
        </div>
      </div>
      <div class="row g-0" style="flex-wrap: inherit;" *ngIf="this.roleSearch === false && !widthSizeSuitable">
        <div class="col-8" style="width: 63%;">
          <mat-form-field class="width-100" appearance="outline">
            <span matPrefix class="pdd-right-10">
              <fa-icon [icon]="faSearch" class="font-size-14"></fa-icon>
            </span>
            <input matInput type="text" [formControl]="myControl" [matAutocomplete]="auto" class="primary-color"
              placeholder="{{ 'Must have at least 3 characters.' | translate }}" />
            <mat-error *ngIf="myControl.errors" translate>
              Must have at least 3 characters.
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete">
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col mrg-left-16">
          <button class="btn-new-user" mat-raised-button (click)="createRole()">
            <span class="bold" translate>New Role</span>
          </button>
        </div>
        <div class="col mrg-left-16">
          <button class="btn-new-user" mat-raised-button (click)="createUser()">
            <span class="bold" translate>New User</span>
          </button>
        </div>
      </div>
    </mat-list>

    <div class="row">
      <div class="col-md-6">
        <span class="users-roles-title" *ngIf="this.roleSearch === false" translate>
          Users and Roles
        </span>
        <span class="users-roles-title" *ngIf="this.roleSearch === true" translate>Users</span>
        <mat-card appearance="outlined" class="search-members__users centered">
          <mat-card-content>
            <mat-list *ngIf="filteredOptions.length === 0">
              <mat-list-item *ngFor="let option of siteUsers" style="cursor: pointer;" (click)="addOption(option)"
                class="search-members__selected-user">
                <img class="search-members__user-img" [src]="option.picture" *ngIf="option.picture && isImageLoading" />
                <img alt="user default image" class="search-members__user-img"
                  src="../../assets/images/avatars/default-users.png" *ngIf="
                  (!isImageLoading || !option.picture) && !option.surname
                " />
                <img alt="users default image" class="search-members__user-img"
                  src="../../assets/images/avatars/default-user.png" *ngIf="
                  (!isImageLoading || !option.picture) && option.surname
                " />
                <span class="user-name">
                  {{ option.name }}
                  {{ option.surname === undefined ? '' : option.surname }}
                </span>
              </mat-list-item>
            </mat-list>
            <mat-list *ngIf="filteredOptions.length !== 0">
              <mat-list-item *ngFor="let option of filteredOptions" style="cursor: pointer;" (click)="addOption(option)"
                class="search-members__selected-user">
                <img class="search-members__user-img" [src]="option.picture" *ngIf="option.picture && isImageLoading" />
                <img alt="user default image" class="search-members__user-img"
                  src="../../assets/images/avatars/default-users.png" *ngIf="
                  (!isImageLoading || !option.picture) && !option.surname
                " />
                <img alt="users default image" class="search-members__user-img"
                  src="../../assets/images/avatars/default-user.png" *ngIf="
                  (!isImageLoading || !option.picture) && option.surname
                " />
                <span class="user-name">
                  {{ option.name }}
                  {{ option.surname === undefined ? '' : option.surname }}
                </span>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-md-6">
        <span class="users-roles-title" *ngIf="this.roleSearch === false" translate>
          Selected users and roles
        </span>
        <span class="users-roles-title" *ngIf="this.roleSearch === true" translate>Selected users</span>
        <mat-card appearance="outlined" class="search-members__users centered">
          <mat-card-content>
            <mat-list>
              <mat-list-item *ngFor="let option of selectedOptions" style="cursor: pointer;"
                (click)="removeOption(option)" class="search-members__selected-user">
                <img class="search-members__user-img" [src]="option.picture" *ngIf="option.picture && isImageLoading" />
                <img alt="user default image" class="search-members__user-img"
                  src="../../assets/images/avatars/default-user.png" *ngIf="
                (!isImageLoading || !option.picture) && option.surname
                " />

                <img alt="user default image" class="search-members__user-img"
                  src="../../assets/images/avatars/default-users.png" *ngIf="
                  (!isImageLoading || !option.picture) && !option.surname
                " />

                <span class="user-name">
                  {{ option.name }}
                  {{ option.surname === undefined ? '' : option.surname }}
                </span>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="steppers">
    <app-stepper-controller isEditing="{{ editUsers }}" [actionText]="editUsers ? 'Save' : 'Next'"
      (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()"></app-stepper-controller>
  </div>
</div>