import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SitesService } from '@modules/sites/sites.service';
import { Observable } from 'rxjs';
import { LKDevice } from '@models/LKDevice';
import { DevicesService } from '@modules/devices/devices.service';
import { map, startWith } from 'rxjs/operators';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bookings-roles-rooms',
  templateUrl: './bookings-roles-rooms.component.html',
  styleUrls: ['./bookings-roles-rooms.component.scss'],
})
export class BookingsRolesRoomsComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() roomsSet = new EventEmitter<{}>();
  @Output() stepIndex = new EventEmitter<any>();
  @Input() doorId: number;
  @Input() doors;
  public deviceGroups: any[] = [];
  public deviceUsers: any[] = [];
  public roomsControl = new UntypedFormControl();
  public selectedOptions: any[] = [];
  public isImageLoading = false;
  public filteredOptionsObservable: Observable<any[]>;
  public filteredOptions: any[] = [];
  public devices: LKDevice[] = [];
  public devicesId = [];
  public widthSizeSuitable: boolean = false;
  public faSearch = faSearch;

  constructor(
    public dialog: MatDialog,
    public translateService: TranslateService,
    public sitesService: SitesService,
    private devicesService: DevicesService
  ) {
    this.roomsControl = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
  }

  ngOnInit() {
    this.widthSizeSuitable = window.innerWidth <= 500;
    this.loadRooms();
    this.filteredOptionsObservable = this.roomsControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );
    this.filteredOptionsObservable.subscribe((options) => {
      this.filteredOptions = options;
    });
  }

  loadRooms() {
    if (this.doors) {
      this.selectedOptions = this.doors;
    }
    setTimeout(async () => {
      this.sitesService.getSiteDevices().subscribe((totalOfDevices) => {
        this.devices = totalOfDevices;
        if (this.devices && this.doors) {
          this.doors.forEach((door) => {
            this.selectDevicesAndRoles(door);
          });
        }
      });
    }, 200);
  }

  private errorMessage() {
    this.roomsControl.markAllAsTouched();
  }

  private filter(value: string): any[] {
    if (value && value.length > 2) {
      const filterValue = value.toLowerCase();
      const filterOptions = [];
      setTimeout(async () => {
        this.devicesService
          .findDoor(filterValue)
          .subscribe(async (response) => {
            response.forEach((door) => {
              const hasDoor = this.selectedOptions.filter(
                (doorSelected) =>
                  door instanceof LKDevice && doorSelected.id === door.id
              );
              if (hasDoor.length === 0) {
                filterOptions.push(door);
              }
            });
          });
      }, 200);

      return filterOptions;
    }
    if (value && value.length < 2) {
      this.errorMessage();
    }
    return [];
  }

  selectDevicesAndRoles(option) {
    /* eslint-disable consistent-return */
    /* eslint-disable array-callback-return */
    this.devices = this.devices.filter((device) => {
      if (device.id !== option.id) {
        return device !== option;
      }
    });
  }

  changeStep(stepIndex: number) {
    this.stepIndex.emit(stepIndex);
  }

  addOption(option) {
    this.selectedOptions.push(option);
    this.selectDevicesAndRoles(option);
    this.filteredOptions = [];
  }

  removeOption(option) {
    this.devices.push(option);
    this.selectedOptions = this.selectedOptions.filter((selectOption) => {
      return selectOption !== option;
    });
  }

  onSubmit() {
    if (this.selectedOptions) {
      this.roomsSet.emit({ doors: this.selectedOptions });
    }
  }
}
