import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesManagerService } from '@services/cookies.manager.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() path: string[];
  public corpToolTip: string = 'Change Corporation';
  public siteToolTip: string = 'Change Site';

  constructor(
    private router: Router,
    private cookiesManagerService: CookiesManagerService
  ) {}

  ngOnInit() {
    const language = this.cookiesManagerService.getLanguage();
    if (language === 'pt-BR') {
      this.corpToolTip = 'Mudar Corporação';
      this.siteToolTip = 'Mudar Site';
    }
  }

  navigate = (path: string, index: string) => {
    if (this.isNavigatePath(path)) {
      switch (parseInt(index, 10)) {
        case 0:
          this.router.navigate(['/corporations']);
          break;
        case 2:
          this.router.navigate(['/sites']);
          break;
        case 4:
          this.router.navigate([`/${path.toLowerCase()}`]);
          break;
        case 6:
          if (this.path[8] === 'Permissions') {
            this.router.navigate([`/dashboard/${path}`]);
          } else if (this.path[4] === 'Groups') {
            this.router.navigate([`/groups/${path}`]);
          }
          break;
        default:
          break;
      }
    }
  };

  isNavigatePath = (path: string): boolean => {
    return path !== '/' && path !== 'Dashboard' && path !== 'Permissions';
  };
}
