import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent {
  @Input() numberColor: string;
  @Input() isLoading: boolean;
  @Input() numberInfo: string;
  @Input() totalNumber: string;
  @Input() label: string;

  constructor(private router: Router) {}

  navigate(description: string) {
    if (description === 'Today’s Bookings') {
      this.router.navigate(['/bookings', { filter: 'active' }]);
    } else if (description === 'Bookings waiting to sync') {
      this.router.navigate(['/bookings', { filter: 'activeSyncing' }]);
    }
  }
}
