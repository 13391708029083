<div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>
<div *ngIf="!loadingData && !(pendingLoad | async)" class="battery-state">
  <div class="battery-state__power-icon">
    <div class="title">
      <span translate>Battery Status</span>
    </div>
    <span class="battery-state__battery-wrapper-icon">
      <span
        class="battery-state__power-battery-icon"
        [ngClass]="
          batteryEnergy >= 25
            ? 'battery-state__charge-battery'
            : 'battery-state__discharge-battery'
        "
      >
        <span
          class="battery-state__progress-block"
          [ngStyle]="{ 'width.%': batteryEnergy + 20 }"
          [ngClass]="
            batteryEnergy >= 25
              ? 'battery-state__charge-progress-battery'
              : 'battery-state__discharge-progress-battery'
          "
        >
        </span>
      </span>
      <span
        class="battery-state__battery-front-icon"
        [ngClass]="
          batteryEnergy >= 25
            ? 'battery-state__charge-battery battery-state__charge-progress-battery'
            : 'battery-state__discharge-battery battery-state__discharge-progress-battery'
        "
      >
      </span>
    </span>
    <div class="subtitle">
      <span class="label-subtitle" translate>Device Battery:</span>
      <span>{{ batteryEnergy }}%</span>
    </div>
    <button
      *ngIf="!sizeSuitableWidth"
      aria-label="close"
      (click)="close()"
      mat-raised-button
      class="battery-state__close-button right mrg-top-35 mrg-btm-10"
    >
      <span translate>Close</span>
    </button>
    <button
      *ngIf="sizeSuitableWidth"
      aria-label="close"
      (click)="close()"
      mat-raised-button
      class="battery-state__close-button-absolute right mrg-top-35 mrg-btm-10"
    >
      <span translate>Close</span>
    </button>
  </div>
</div>
