<span class="load-wrapper">
  <div class="centered" *ngIf="loadingData">
    <mat-spinner diameter="40" color="accent"></mat-spinner>
  </div>
</span>
<div class="offline-passcode" *ngIf="!loadingData">
  <div class="offline-passcode__title">
    <span translate>Backup Passcode Warning</span>
  </div>
  <div class="offline-passcode__text">
    <span translate>This is an emergency measure. Make sure you always check that the original password has been
      synchronized on the device. The Backup Passcode will be available an cannot be erased until its expiration
      date.</span>
    <span translate class="offline-passcode__confirm-label">Are you sure you want to create an backup password for this booking?</span>
  </div>
  <div class="width-100 stepper-controller">
    <div class="no-pdd">
      <button class="mrg-right-5 btn-cancel" aria-label="cancel generate" matDialogClose>
        <span class="label-color">{{ 'Cancel' | translate }}</span>
      </button>
    </div>
    <div class="no-pdd-right">
      <button aria-label="generate passcode" class="btn-accent" (click)="generatePasscode()">
        <span class="label-color">{{ 'Generate Passcode' | translate }}</span>
      </button>
    </div>
  </div>
</div>