import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserSessionService } from 'app/core/services/user.session.service';

@Injectable()
export class AuthGuard implements CanActivate {
  public state: any;
  constructor(
    private userSessionService: UserSessionService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userSessionService.getSession() != null) {
      return true;
    }
    this.router.navigate(['/authentication'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
