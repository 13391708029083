import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LKUser } from '@models/LKUser';
import { DevicesService } from '@modules/devices/devices.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LKGroup } from '@models/LKGroup';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-device-groups-users',
  templateUrl: './device-groups-users.component.html',
  styleUrls: ['./device-groups-users.component.scss'],
})
export class DeviceGroupsUsersComponent implements OnInit {
  @Output() previousItem = new EventEmitter<{}>();
  @Output() nextItem = new EventEmitter<{}>();
  @Output() finishEvent = new EventEmitter<{}>();
  @Input() doorId: number;
  @Input() view;
  public deviceGroups: any[] = [];
  public deviceUsers: any[] = [];
  public myControl = new UntypedFormControl();
  public selectedOptions: any[] = [];
  public isImageLoading = false;
  public filteredOptionsObservable: Observable<any[]>;
  public filteredOptions: any[] = [];
  public faSearch = faSearch;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private router: Router,
    private devicesService: DevicesService,
    public translateService: TranslateService
  ) {
    this.myControl = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
  }

  ngOnInit() {
    this.loadGroupsAndUsers();
    this.filteredOptionsObservable = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );
    this.filteredOptionsObservable.subscribe((options) => {
      this.filteredOptions = options;
    });
  }

  loadGroupsAndUsers() {
    setTimeout(async () => {
      this.devicesService
        .getGroupsAndUsers(this.doorId)
        .subscribe(async (response) => {
          response.groups.forEach((group) => {
            if (response.groups) {
              this.deviceGroups.push(group);
            }
          });
          response.users.forEach((user) => {
            if (response.users) {
              this.deviceUsers.push(user);
            }
          });
        });
    }, 200);
  }

  private errorMessage() {
    this.myControl.markAllAsTouched();
  }

  private filter(value: string): any[] {
    if (value && value.length > 2) {
      const filterValue = value.toLowerCase();
      const filterOptions = [];
      setTimeout(() => {
        this.devicesService
          .findGroupsAndUsers(this.doorId, filterValue)
          .subscribe((response) => {
            response.users.forEach((user) => {
              const hasUser = this.selectedOptions.filter(
                (userSelected) =>
                  user instanceof LKUser && userSelected.id === user.id
              );
              if (hasUser.length === 0 && this.view === 'users') {
                filterOptions.push(user);
              }
            });
            response.groups.forEach((group) => {
              const hasGroup = this.selectedOptions.filter(
                (groupSelected) =>
                  group instanceof LKGroup && groupSelected.id === group.id
              );
              if (hasGroup.length === 0 && this.view === 'groups') {
                filterOptions.push(group);
              }
            });
          });
      }, 200);
      return filterOptions;
    }
    if (value && value.length < 2) {
      this.errorMessage();
    }
    return [];
  }
}
