<app-top-loading [loadingProp]="loadingTableData && !(pendingLoad | async)">
</app-top-loading>

<app-no-connection *ngIf="pendingLoad | async" (refreshPage)="loadBookings()">
</app-no-connection>

<div class="centered" *ngIf="loadingTableData && !(pendingLoad | async)">
    <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>

<mat-sidenav-container class="bookings" (backdropClick)="snav.close()"
    *ngIf="!(pendingLoad | async) && !loadingTableData">

    <mat-sidenav-content class="bookings__content">
        <div class="bookings__table-content">
            <div *ngIf="sizeWidthSuitable && !loadingTableData">
                <h2 class="bookings__page-name" translate>Bookings</h2>
            </div>
            <div *ngIf="!loadingTableData">
                <div *ngIf="bookingsSize > 0" class="bookings__filter">
                    <div class="row g-0 filters">
                        <app-search-toggle class="bookings__toggle" *ngIf="!sizeWidthSuitable" [filters]="filtersStatus"
                            (filterApplied)="applyFilter($event, 'state')">
                        </app-search-toggle>

                        <app-search-button class="bookings__search" [placeholder]="'Search' | translate"
                            [filterValue]="filterName" (filterApplied)="applyFilter($event, 'name')"
                            (clearEvent)="clearFilter()">
                        </app-search-button>
                    </div>
                    <button *ngIf="!sizeWidthSuitable" class="btn-create right accent" mat-raised-button
                        (click)="searchGuest()">
                        <span class="create-label" translate>New Booking</span>
                    </button>
                </div>

                <div *ngIf="bookingsSize === 0; else showBookings" class="no-booking centered">
                    <fa-icon icon="exclamation" class="no-booking icon"></fa-icon>
                    <span class="no-booking-warning" translate>There's no Booking available to display</span>
                    <button mat-raised-button (click)="searchGuest()">
                        <span translate>New Booking</span>
                    </button>
                </div>

                <ng-template #showBookings>
                    <div class="bookings__table-container">
                        <ngx-datatable #table class="material" [limit]="numberBookingsShow" [rows]="rows"
                            [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            (sort)="sortData($event)" [loadingIndicator]="loadingFilterTable"
                            [messages]="language === 'pt-BR' ? portugueseMessage : englishMessage"
                            [scrollbarH]="true">
                            <ngx-datatable-column prop="guestName" name="{{ 'Guest Name' | translate}}" [flexGrow]="1"
                                [resizeable]="false" [minWidth]="200">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{ row?.users[0].name + ' ' + row?.users[0].surname }}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{ 'Device' | translate}}" prop="doorName" [flexGrow]="1"
                                [resizeable]="false" [minWidth]="100">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row?.doors[0].name}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{ 'Status' | translate }}" prop="state" [flexGrow]="1"
                                [resizeable]="false" [minWidth]="150">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span *ngIf="!row.checkInTime && row.permissionSetTimestamp" translate>Waiting
                                        Checkin</span>
                                    <span class="status-waiting-sync"
                                        *ngIf="!row.checkInTime && !row.permissionSetTimestamp" translate>Waiting
                                        Sync</span>
                                    <mat-chip-listbox *ngIf="row.checkInTime">
                                        <mat-chip-option class="checked-in" [selectable]="false">
                                            <fa-icon [icon]="faCheckCircle" class="mrg-right-10 font-size-15">
                                            </fa-icon>
                                            <span translate>Checked in</span>
                                            <span>&nbsp;- {{
                                                getHourAsString(row.checkInTime)
                                                }}</span>
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{ 'Checkin' | translate}}" [flexGrow]="1" [resizeable]="false" [minWidth]="100">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.accessRule.type === 'AlwaysAccessRule'">
                                        <span translate>Unlimited restriction</span>
                                    </div>
                                    <div *ngIf="!sizeWidthSuitable && row.accessRule.type === 'StartEndAccessRule'">
                                        <div>
                                            <fa-icon [icon]="faRightToBracket"
                                                class="mrg-right-10 gray-label font-size-15">
                                            </fa-icon>
                                            <span>{{
                                                getDateAsString(row.accessRule.startDateTime)
                                                }}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!sizeWidthSuitable && row.accessRule.type === 'StartEndAccessRule'">
                                        <fa-icon [icon]="faClock" class="mrg-right-10 gray-label font-size-15">
                                        </fa-icon>
                                        <span>{{
                                            getHourAsString(row.accessRule.startDateTime)
                                            }}</span>
                                    </div>
                                    <div *ngIf="sizeWidthSuitable">
                                        <div>
                                            <fa-icon [icon]="faRightToBracket"
                                                class="mrg-right-10 gray-label font-size-15">
                                            </fa-icon>
                                            <span>{{
                                                getDateAsString(row.accessRule.startDateTime)
                                                }}</span>
                                        </div>
                                        <div>
                                            <fa-icon [icon]="faClock" class="mrg-right-10 gray-label font-size-15">
                                            </fa-icon>
                                            <span>{{
                                                getHourAsString(row.accessRule.startDateTime)
                                                }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{ 'Checkout' | translate}}" [flexGrow]="1"
                                [resizeable]="false" [minWidth]="100">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.accessRule.type === 'AlwaysAccessRule'">
                                        <span translate>Unlimited restriction</span>
                                    </div>
                                    <div *ngIf="!sizeWidthSuitable && row.accessRule.type === 'StartEndAccessRule'">
                                        <div>
                                            <fa-icon [icon]="faRightFromBracket"
                                                class="mrg-right-10 gray-label font-size-15">
                                            </fa-icon>
                                            <span>{{
                                                getDateAsString(row.accessRule.endDateTime)
                                                }}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!sizeWidthSuitable && row.accessRule.type === 'StartEndAccessRule'">
                                        <fa-icon [icon]="faClock" class="mrg-right-10 gray-label font-size-15">
                                        </fa-icon>
                                        <span>{{
                                            getHourAsString(row.accessRule.endDateTime)
                                            }}</span>
                                    </div>
                                    <div *ngIf="sizeWidthSuitable">
                                        <div>
                                            <fa-icon [icon]="faRightFromBracket"
                                                class="mrg-right-10 gray-label font-size-15">
                                            </fa-icon>
                                            <span>{{
                                                getDateAsString(row.accessRule.endDateTime)
                                                }}</span>
                                        </div>
                                        <div>
                                            <fa-icon [icon]="faClock" class="mrg-right-10 gray-label font-size-15">
                                            </fa-icon>
                                            <span>{{
                                                getHourAsString(row.accessRule.endDateTime)
                                                }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{ 'Passcode' | translate}}" [flexGrow]="1" [sortable]='false'
                                [resizeable]="false" [minWidth]="100">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="row g-0 passcode-cel">
                                        <div style="flex: 0.5; margin-right: 5px;">
                                            <fa-icon *ngIf="row.users[0].passcode" class="pointer font-size-16"
                                                (click)="!row.showPasscode ? changePasscodeStatus(row, 'show') : changePasscodeStatus(row, 'hide')"
                                                icon="{{ !row.showPasscode ? 'eye' : 'eye-slash' }}">
                                            </fa-icon>
                                        </div>
                                        <div *ngIf="!row.showPasscode && row.users[0].passcode">
                                            <span>******</span>
                                        </div>
                                        <div *ngIf="!row.users[0].passcode" 
                                            [matTooltip]="'This user has an Owner or Admin permission in the Corporation, therefore, it is not possible to view the passcode.' | translate">
                                            <span>******</span>
                                        </div>
                                        <div
                                            *ngIf="row.users.length != 0 && row.users[0].passcode && row.showPasscode">
                                            <span class="bookings__btn-edit">{{
                                                row.users[0].passcode
                                                }}</span>
                                            <span
                                                *ngIf="row.users.length != 0 && !row.users[0].passcode && row.showPasscode"
                                                class="bookings__btn-edit">
                                                -
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Backup Passcode' | translate}}" [flexGrow]="1"
                                [sortable]='false' [resizeable]="false" [minWidth]="100">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="row g-0 offline-passcode-cel" *ngIf="row.backupCodes.length > 0">
                                        <div class="col passcode-field" style="flex: 0.4">
                                            <fa-icon *ngIf="!sizeWidthSuitable" icon="info-circle" class="mrg-right-10"
                                                [matTooltip]="!row.checkInTime && !row.permissionSetTimestamp ? getOfflinePasscodeMessage(row, 'not sync') : getOfflinePasscodeMessage(row, 'sync')"></fa-icon>
                                            <fa-icon class="pointer font-size-16"
                                                (click)="!row.showOfflinePasscode ? changeOfflinePasscodeStatus(row, 'show') : changeOfflinePasscodeStatus(row, 'hide')"
                                                icon="{{ !row.showOfflinePasscode ? 'eye' : 'eye-slash' }}">
                                            </fa-icon>
                                        </div>
                                        <div class="col passcode-field" style="flex: 0.5">
                                            <div *ngIf="!row.showOfflinePasscode && row.backupCodes.length > 0">
                                                <span class="bookings__btn-edit">{{row.backupCodes[0].passcode}}</span>
                                            </div>
                                            <span class="bookings__btn-edit" *ngIf="row.showOfflinePasscode">{{
                                                row.backupCodes[0].passcode
                                                }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Actions' | translate}}" [flexGrow]="1" [sortable]='false'
                                [resizeable]="false" [minWidth]="100">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="bookings__actions">
                                        <button mat-icon-button class="bookings__btn-action" [matMenuTriggerFor]="menu">
                                            <fa-icon icon="ellipsis-vertical"></fa-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button (click)="editBooking(row)" mat-menu-item>
                                                <span translate>Edit</span>
                                            </button>
                                            <button (click)="generatePasscode(row)" mat-menu-item>
                                                <span translate>Generate Backup Passcode</span>
                                            </button>
                                            <button (click)="deleteBooking(row)" data-toggle="modal"
                                                data-target="#blockedModal" mat-menu-item>
                                                <span translate>Delete</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template let-rowCount="rowCount" let-pageSize="pageSize"
                                    let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                                    ngx-datatable-footer-template>
                                    <span *ngIf="language === 'en'">
                                        Results: {{calcFirstElem(curPage)}} - {{calcLastElem(curPage, pageSize,
                                        rowCount)}} of
                                        {{numberRows}}
                                    </span>
                                    <span *ngIf="language === 'pt-BR'">
                                        Resultados: {{calcFirstElem(curPage)}} - {{calcLastElem(curPage, pageSize,
                                        rowCount)}} de
                                        {{numberRows}}
                                    </span>

                                    <app-datatable-pager [page]="curPage" [visiblePagesCount]="3" [size]="pageSize"
                                        [count]="rowCount" [hidden]="false"
                                        (change)="table.onFooterPage($event); changePage($event)">
                                    </app-datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </ng-template>

                <ng-template #emptyMessage>
                    <div class="centered">
                        <span class="bookings__warning inline-block pdd-left-15" translate>
                            <fa-icon class="pdd-left-5 font-size-15" icon="exclamation-triangle"></fa-icon>
                            No booking was found with the selected filter(s)
                        </span>
                    </div>
                </ng-template>

                <ng-template #loadingMessage>
                    <div class="centered">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </ng-template>
            </div>
            <div class="bookings__create-booking" *ngIf="sizeWidthSuitable">
                <button class="btn-create right accent" mat-raised-button (click)="searchGuest()">
                    <span translate>New Booking</span>
                </button>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>