import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoleParams, LKRole } from '@models/LKRole';
import { MatStepper } from '@angular/material/stepper';
import { RolesService } from '@modules/roles/roles.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss'],
})
export class CreateRoleComponent implements OnInit {
  @Output() roleCreated = new EventEmitter<boolean>();

  editing: boolean = true;
  result: {
    hasError: boolean;
    error: string;
  };
  roleParams = {
    name: '',
    isGlobal: false,
    siteId: null,
    corpId: null,
    description: '',
    userList: [],
  };
  public currentStepIndex: number = 1;
  public labelStepSelected: string = '';
  public currentRole: LKRole = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private rolesService: RolesService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<CreateRoleComponent>
  ) {}

  ngOnInit() {
    this.labelStepSelected = this.translateService.instant('Information');
  }

  setInfo = (roleInfo: RoleParams, stepper: MatStepper) => {
    this.roleParams.siteId = roleInfo.siteId;
    this.roleParams.corpId = roleInfo.corpId;
    this.roleParams.name = roleInfo.name;
    this.roleParams.isGlobal = roleInfo.isGlobal;
    this.roleParams.description = roleInfo.description;
    this.goForward(stepper);
  };

  setUsers = (
    roleUsers: { users: { info: []; ids: [] } },
    stepper: MatStepper
  ) => {
    const hasUsers = roleUsers.users.ids.length > 0;
    this.roleParams.userList = hasUsers ? roleUsers.users.ids : [' '];
    this.createRole(stepper);
  };

  createRole = (stepper: MatStepper) => {
    this.rolesService.createNewRole(
      this.roleParams,
      (role) => {
        this.saveResult();
        this.result = {
          error: null,
          hasError: false,
        };
        this.currentRole = new LKRole(role);
      },
      (e) => {
        if (e.error.errorCode === 102) {
          this.result = {
            error: 'Already exist a role with this name.',
            hasError: true,
          };
        } else {
          this.result = {
            error: e.error.errorDescription,
            hasError: true,
          };
        }
      }
    );
    this.goForward(stepper);
  };

  closeDialog() {
    this.dialogRef.close(this.currentRole);
  }

  saveResult = () => {
    this.editing = false;
    this.roleCreated.emit(true);
  };

  goForward = (stepper: MatStepper) => {
    stepper.next();
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  backStep = (stepper) => {
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  previousStep(stepper) {
    stepper.previous();
    this.backStep(stepper);
  }
}
