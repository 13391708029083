<app-top-loading [loadingProp]="loadingData && !(pendingLoad | async)">
</app-top-loading>
<app-no-connection *ngIf="pendingLoad | async"></app-no-connection>
<div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>
<div *ngIf="!loadingData && !(pendingLoad | async)" class="integrations">
  <div class="integrations__wrapper">
    <div class="integrations__title" translate>
      LoopKey Integrations
    </div>
    <div class="integrations__descriptions" translate>
      Below you can configure integrations between our partners and your LoopKey
      Account, so you can automate as much process as possible.
    </div>
    <div class="integrations-content">
      <div class="integrations-content__type">
        <div>
          <fa-icon [icon]="faCheckCircle" class="icon-size" [ngClass]="clients.length > 0 ? 'icon-green' : 'icon-gray'">
          </fa-icon>
          <img class="integrations-light-icon"
            src="../../../../../assets/images/logo/guesty-logo.svg" alt="Guesty logo" width="90px" />
        </div>
        <div>
          <div>
            <span (click)="openGuestyIntegrationDialog()" class="link-keys" *ngIf="!sizeWidthSuitable && clients.length === 0">
              <span translate>Configure</span> Guesty
            </span>
            <span *ngIf="!sizeWidthSuitable && clients.length > 0" (click)="removeIntegration(clients[0].id)" class="link-keys mrg-left-15"
              translate>
              Remove
            </span>
          </div>
          <button *ngIf="sizeWidthSuitable" mat-icon-button [matMenuTriggerFor]="menuStays" class="dropdown">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuStays="matMenu">
            <button *ngIf="clients.length > 0" mat-menu-item (click)="removeIntegration(clients[0].id)" translate>
              Remove
            </button>
            <button mat-menu-item (click)="openGuestyIntegrationDialog()" *ngIf="clients.length === 0">
              <span class="link-keys">
                <span translate>Configure</span> Guesty
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>