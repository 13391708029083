<div class="group-result">
  <mat-dialog-content>
    <div class="mrg-top-20 group-result__icon-div">
      <div *ngIf="
          responseAccessGroup && !responseAccessGroup.error;
          else responseError
        ">
        <div class="group-result__icon">
          <img alt="result-icon" class="sucess"
            src="../../../../../assets/icons/black_success.svg" width="120" height="120" />
        </div>
        <br />
        <h5 translate>Created successfully!</h5>
      </div>
      <ng-template #responseError>
        <div class="group-result__icon">
          <img alt="result-icon" class="error"
            src="../../../../../assets/icons/black_error.svg" width="120" height="120" />
        </div>
        <div>
          <h5 *ngIf="responseAccessGroup" translate>
            {{ responseAccessGroup.response }}
          </h5>
        </div>
      </ng-template>
    </div>
  </mat-dialog-content>

  <div *ngIf="!sizeWidthSuitable" class="no-mrg mrg-top-15 width-100" [ngClass]="
  responseAccessGroup && responseAccessGroup.error
        ? 'group-result__btn-action-error'
        : 'group-result__btn-action'
">
    <div *ngIf="responseAccessGroup && responseAccessGroup.error">
      <button aria-label="back" class="back-btn" mat-raised-button (click)="backAction()">
        <span>{{ 'Back' | translate }}</span>
      </button>
    </div>
    <div>
      <button aria-label="finish" matDialogClose mat-raised-button class="accent" (click)="finish()">
        <span class="btn-color-label">{{ 'Finish' | translate }}</span>
      </button>
    </div>
  </div>
  <div *ngIf="sizeWidthSuitable" class="mrg-top-15 width-100" [ngClass]="
  responseAccessGroup && responseAccessGroup.error
    ? 'group-result__btn-action-error steppers'
    : 'group-result__btn-action steppers'
">
    <div>
      <button aria-label="finish" matDialogClose mat-raised-button class="accent" (click)="finish()">
        <span class="btn-color-label">{{ 'Finish' | translate }}</span>
      </button>
    </div>
    <div *ngIf="responseAccessGroup && responseAccessGroup.error">
      <button aria-label="back" class="back-btn" mat-raised-button (click)="backAction()">
        <span>{{ 'Back' | translate }}</span>
      </button>
    </div>
  </div>
</div>