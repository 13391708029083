import { LKEventUser } from './LKEventUser';
import { LKSharingRestrictions } from './LKSharingRestrictions';

export interface ILKEvent {
  id: string;
  operation: string;
  user: LKEventUser;
  when: Date;
  commandMethod: string;
  activated: boolean;
  targetUser: LKEventUser;
  timerStart: Date;
  timerEnd: Date;
  restrictions: LKSharingRestrictions;
  permissionLevel: string;
  doorName: string;
}

export class LKEvent {
  id: string;
  operation: string;
  user: LKEventUser;
  when: Date;
  commandMethod: string;
  activated: boolean;
  targetUser: LKEventUser;
  timerStart: Date;
  timerEnd: Date;
  restrictions: LKSharingRestrictions;
  permissionLevel: string;
  doorName: string;

  constructor(serverEventObject: Partial<ILKEvent>) {
    this.id = serverEventObject.id;
    this.operation = serverEventObject.operation;
    this.user =
      Object.keys(serverEventObject.user).length !== 0
        ? new LKEventUser(serverEventObject.user)
        : null;
    this.when = new Date(serverEventObject.when);
    this.commandMethod = serverEventObject.commandMethod;
    this.activated = serverEventObject.activated;
    this.targetUser = serverEventObject.targetUser
      ? new LKEventUser(serverEventObject.targetUser)
      : null;
    this.timerStart = new Date(serverEventObject.timerStart);
    this.timerEnd = new Date(serverEventObject.timerEnd);
    this.restrictions = serverEventObject.restrictions;
    this.permissionLevel = serverEventObject.permissionLevel;
    this.doorName = serverEventObject.doorName;
  }

  getUserNameOrContact(): String {
    if (this.user) {
      if (this.user.name && this.user.surname) {
        return `${this.user.name} ${this.user.surname}`;
      }
      if (this.user.name) {
        return this.user.name;
      }
      return 'A LoopKey user';
    }
    if (this.operation === 'privateModeChange') {
      return 'relating to private mode';
    }
    return 'A LoopKey user';
  }

  getTargetNameOrContact(): String {
    if (this.targetUser) {
      if (this.targetUser.name && this.targetUser.surname) {
        return `${this.targetUser.name} ${this.targetUser.surname}`;
      }
      if (this.targetUser.name) {
        return this.targetUser.name;
      }
      if (this.targetUser.email) {
        return this.targetUser.email;
      }
      return this.targetUser.phone;
    }
    if (this.operation === 'timerSet') {
      return this.getTimerLogAsString();
    }
    return '';
  }

  getContact(): String {
    switch (this.commandMethod) {
      case 'card':
        return this.user.card;
      default:
        if (this.user) {
          return this.user.phone ? this.user.phone : this.user.email;
        }
        return 'A LoopKey user';
    }
  }

  getIcon(): String {
    if (this.commandMethod === 'card') {
      return 'address-card';
    }
    if (this.user) {
      if (this.commandMethod) {
        return 'mobile-alt';
      }
    }
    return 'key';
  }

  getUnlockUser() {
    if (this.commandMethod === 'card') {
      return this.user.card;
    }
    if (this.user) {
      if (this.user.phone) {
        return this.user.phone;
      }
      return this.user.email;
    }
    return '';
  }

  getDescription(): String {
    switch (this.operation) {
      case 'open':
        return 'The door has been opened.';
      case 'closed':
        return 'The door has been closed.';
      case 'unlockExitButton':
        return 'Door unlocked through exit button.';
      case 'unlockCommand':
        return 'unlocked the door';
      case 'unlockPhysicalKey':
        return 'The door has been unlocked with a physical key.';
      case 'timerSet':
        return 'set a timer until ';
      case 'timerCancel':
        return 'disabled the timer ';
      case 'timerCancelButton':
        return 'The timer was physically canceled.';
      case 'timerStart':
        return 'The door was unlocked because a timer started.';
      case 'privateModeChange':
        return 'Private mode has been changed to ';
      case 'alarmDoorHalfOpen':
        return 'Door ajar.';
      case 'alarmOutOfRestrictionsGuestCommand':
        return 'Had access denied due to restrictions.';
      case 'alarmUnlockDenied':
        return 'Unlock attempt was denied.';
      case 'create':
        return 'Created the door.';
      case 'update':
        return 'updated door.';
      case 'permissionCreate':
        return 'Shared access with ';
      case 'permissionUpdate':
        return 'Edited permission of ';
      case 'permissionRemove':
        return 'Removed permission of ';
      case 'timerEnd':
        return 'Removed permission of ';
      default:
        return 'Timer ended.';
    }
  }

  getBackColor(): String {
    switch (this.operation) {
      case 'timerSet':
      case 'timerCancel':
        return 'red';
      case 'timerCancelButton':
      case 'timerStart':
      case 'alarmDoorHalfOpen':
      case 'timerEnd':
        return 'green';
      case 'privateModeChange':
      case 'permissionCreate':
        return 'blue';
      case 'permissionUpdate':
        return 'shadowBlack56';
      case 'permissionRemove':
        return 'red';
      case 'alarmOutOfRestrictionsGuestCommand':
      case 'alarmUnlockDenied':
        return 'red';
      case 'unlockExitButton':
        return 'shadowBlack56';
      case 'unlockCommand':
        return 'shadowBlack56';
      case 'unlockPhysicalKey':
        return 'shadowBlack56';
      case 'create':
        return 'blue';
      case 'update':
        return 'shadowBlack56';
      default:
        return 'blue';
    }
  }

  getCreateIcon(): string {
    if (this.targetUser) {
      if (this.targetUser.card) {
        return 'address-card';
      }
      if (this.targetUser.phone) {
        return 'mobile-alt';
      }
      return 'envelope';
    }
    return '';
  }

  getDateLogAsString(language: string): string {
    const year = this.when.getFullYear();
    const month = this.capitalizeFirstLetter(
      this.when.toLocaleString(language, { month: 'short' })
    );
    const day = this.when.getDate();
    const weekDay = this.capitalizeFirstLetter(
      this.when.toLocaleString(language, { weekday: 'short' })
    );
    const hour = this.when.getHours();
    const minute = this.when.getMinutes();
    return `${weekDay} - ${month} ${this.toTwoDigits(
      day
    )}, ${year} ${this.toTwoDigits(hour)}:${this.toTwoDigits(minute)}`;
  }

  getDateEvent(): string {
    const year = this.when.getFullYear();
    const month = this.when.getMonth();
    const day = this.when.getDate();
    const hour = this.when.getHours();
    const minute = this.when.getMinutes();
    return `${day}/${`0${String(month + 1)}`.slice(
      -2
    )}/${year} - ${this.toTwoDigits(hour)}:${this.toTwoDigits(minute)}`;
  }

  getTimerLogAsString(): string {
    if (this.timerEnd) {
      const year = this.timerEnd.getFullYear();
      const month = this.timerEnd.getMonth() + 1;
      const day = this.timerEnd.getDate();
      const hour = this.timerEnd.getHours();
      const minute = this.timerEnd.getMinutes();
      return `${this.toTwoDigits(day)}/${this.toTwoDigits(
        month
      )}/${year} ${this.toTwoDigits(hour)}:${this.toTwoDigits(minute)}`;
    }
    return '';
  }

  toTwoDigits(num: number) {
    return num <= 9 ? `0${num}` : num;
  }

  private capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
}
