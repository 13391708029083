import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RestrictionsParams } from '@models/LKShare';
import { HelperService } from '@services/helper.manager.service';
import { LoggingService } from '@services/logging.service';
import { LKGroupRestrictions } from '@models/LKGroupRestrictions';
import { GroupsService } from '@modules/groups/groups.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-group-restrictions',
  templateUrl: './group-restrictions.component.html',
  styleUrls: ['./group-restrictions.component.scss'],
})
export class GroupRestrictionsComponent implements OnInit {
  @Output() restrictionsSet = new EventEmitter<any>();
  @Output() previousItem = new EventEmitter<any>();
  @Input() userRestrictions: RestrictionsParams;
  @Input() isEditing: boolean;
  @Input() editPermission: boolean = false;
  @Input() actionText: string;
  @Input() group: any;
  @Input() restrictionsEdit: LKGroupRestrictions;

  public restrictions: LKGroupRestrictions;
  public offlineTooltip =
    "Users don't have restrictions and can access the device anytime via bluetooth";
  public guestTooltip =
    'Add date and time restrictions if you wish. Guests must be online to open';
  public adminTooltip =
    'Unrestricted access to your device, ability to share it with others and edit permissions';
  public daysList: Array<boolean> = [true, true, true, true, true, true, true];
  public allDates: Boolean = true;
  public allTimes: Boolean = true;
  public accessType: number = 1;
  public startDate = new Date();
  public endDate = new Date(
    this.startDate.getTime() + 1000 * 60 * 60 * 24 * 30
  );
  public sizeWidthSuit: boolean = false;

  constructor(
    private helperService: HelperService,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private groupsService: GroupsService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('pt-BR');
  }

  ngOnInit() {
    this.sizeWidthSuit = window.innerWidth <= 500;
    if (this.restrictionsEdit === undefined) {
      this.restrictions = new LKGroupRestrictions();
      this.restrictions.startDateTime = this.startDate;
      this.restrictions.endDateTime = this.endDate;
      this.restrictions.startTimeOfDay = null;
      this.restrictions.endTimeOfDay = null;
      this.restrictions.offlineAllowed = false;
      this.restrictions.passcodeAllowed = true;
      this.restrictions.remoteAllowed = true;
      this.restrictions.cardAllowed = true;
      this.restrictions.smartphoneAllowed = true;
      this.restrictions.qrcodeAllowed = true;
    } else {
      this.restrictions = { ...this.restrictionsEdit };
      if (this.restrictions.endDateTime || this.restrictions.startDateTime) {
        this.allDates = false;
      } else {
        this.restrictions.startDateTime = this.startDate;
        this.restrictions.endDateTime = this.endDate;
      }

      if (this.restrictions.endTimeOfDay || this.restrictions.startTimeOfDay) {
        this.allDates = false;
        this.allTimes = false;
        const guide = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const newDaysList = guide.map(
          (item) => this.restrictions.daysList.indexOf(item) !== -1
        );
        this.daysList = newDaysList;
      } else {
        this.restrictions.startDateTime = this.startDate;
        this.restrictions.endDateTime = this.endDate;
        this.restrictions.startTimeOfDay = '';
        this.restrictions.endTimeOfDay = '';
      }
      this.restrictions.offlineAllowed = this.restrictionsEdit.offlineAllowed;
    }
  }

  clearStartDate() {
    this.restrictions.startTimeOfDay = null;
  }

  clearEndDate() {
    this.restrictions.endTimeOfDay = null;
  }

  allTimesToggleChanged() {
    if (!this.allTimes) {
      this.allDates = false;
    }
  }

  onSubmit() {
    if (
      !this.helperService.verifyDateRestrictions(
        this.allDates,
        new Date(this.restrictions.startDateTime).setHours(0, 0, 0, 0),
        new Date(this.restrictions.endDateTime).setHours(0, 0, 0, 0)
      )
    ) {
      this.loggingService.defaultToast(
        'error',
        this.translateService.instant(
          'Invalid date restriction. The final date should be greater than the inicial one.'
        ),
        'center'
      );
    } else {
      const restriction = {
        startDateTime:
          this.allDates || this.restrictions.offlineAllowed
            ? null
            : this.restrictions.startDateTime,
        endDateTime:
          this.allDates || this.restrictions.offlineAllowed
            ? null
            : this.restrictions.endDateTime,
        startTimeOfDay:
          this.allTimes || this.restrictions.offlineAllowed
            ? null
            : this.restrictions.startTimeOfDay,
        endTimeOfDay:
          this.allTimes || this.restrictions.offlineAllowed
            ? null
            : this.restrictions.endTimeOfDay,
        daysOfTheWeek: LKGroupRestrictions.formatDaysToDateNumber(
          this.allTimes || this.restrictions.offlineAllowed
            ? [true, true, true, true, true, true, true]
            : this.daysList
        ),
        cardAllowed: this.restrictions.cardAllowed,
        daysList:
          this.allTimes || this.restrictions.offlineAllowed
            ? LKGroupRestrictions.formatDaysToStringArray([
                true,
                true,
                true,
                true,
                true,
                true,
                true,
              ])
            : LKGroupRestrictions.formatDaysToStringArray(this.daysList),
        offlineAllowed: this.restrictions.offlineAllowed,
        passcodeAllowed: this.restrictions.passcodeAllowed,
        remoteAllowed: this.restrictions.remoteAllowed,
        smartphoneAllowed: this.restrictions.smartphoneAllowed,
        qrcodeAllowed: this.restrictions.qrcodeAllowed,
      };

      if (this.editPermission) {
        const restrictionData: any = {
          daysOfTheWeek: [true, true, true, true, true, true, true],
        };

        if (!this.restrictions.offlineAllowed) {
          if (!this.allDates) {
            restrictionData.startDateTime =
              this.helperService.getDateTimeString(
                this.restrictions.startDateTime
              );
            restrictionData.endDateTime = this.helperService.getDateTimeString(
              this.restrictions.endDateTime
            );
          }

          if (!this.allTimes) {
            if (this.restrictions.startTimeOfDay) {
              restrictionData.startTimeOfDay = this.restrictions.startTimeOfDay;
            }

            if (this.restrictions.endTimeOfDay) {
              restrictionData.endTimeOfDay = this.restrictions.endTimeOfDay;
            }
          }
        }

        restrictionData.daysOfTheWeek =
          LKGroupRestrictions.formatDaysToDateNumber(
            this.allTimes || this.restrictions.offlineAllowed
              ? [true, true, true, true, true, true, true]
              : this.daysList
          );

        this.groupsService
          .editGroup({
            id: this.group.id,
            ...restrictionData,
            restrictions: {
              offlineAllowed: this.restrictions.offlineAllowed,
              passcodeAllowed: this.restrictions.passcodeAllowed,
              remoteAllowed: this.restrictions.remoteAllowed,
              smartphoneAllowed: this.restrictions.smartphoneAllowed,
              qrcodeAllowed: this.restrictions.qrcodeAllowed,
              cardAllowed: this.restrictions.cardAllowed,
            },
          })
          .subscribe(
            () => {
              let restrictions: any = {};
              if (!this.restrictions.offlineAllowed) {
                if (!this.allDates) {
                  restrictions = {
                    ...restrictions,
                    startDateTime: this.restrictions.startDateTime,
                    endDateTime: this.restrictions.endDateTime,
                  };
                }
                if (!this.allTimes) {
                  restrictions = {
                    ...restrictions,
                  };

                  if (this.restrictions.startTimeOfDay) {
                    restrictions.startTimeOfDay =
                      this.restrictions.startTimeOfDay;
                    restrictions.startTime = this.restrictions.startTimeOfDay;
                  }

                  if (this.restrictions.endTimeOfDay) {
                    restrictions.endTimeOfDay = this.restrictions.endTimeOfDay;
                    restrictions.endTime = this.restrictions.endTimeOfDay;
                  }
                }

                restrictions = {
                  ...restrictions,
                  daysList: restriction.daysList,
                };
              }

              this.restrictionsSet.emit({
                daysList: restriction.daysList,
                restrictions,
                ...restrictions,
                endTimeOfDay: this.restrictions.endTimeOfDay,
                startTimeOfDay: this.restrictions.startTimeOfDay,
                offlineAllowed: this.restrictions.offlineAllowed,
                passcodeAllowed: this.restrictions.passcodeAllowed,
                remoteAllowed: this.restrictions.remoteAllowed,
                smartphoneAllowed: this.restrictions.smartphoneAllowed,
                qrcodeAllowed: this.restrictions.qrcodeAllowed,
                cardAllowed: this.restrictions.cardAllowed,
              });

              this.loggingService.defaultToast(
                'success',
                this.translateService.instant(
                  'Group restrictions updated successfully!'
                ),
                'center'
              );
            },
            () => {
              this.loggingService.defaultToast(
                'error',
                this.translateService.instant(
                  'Action failed. Please, try again.'
                ),
                'center'
              );
            }
          );
      } else {
        this.restrictionsSet.emit(restriction);
      }
    }
  }
}
