import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  LKCmdResponseType,
  feedbackLKCmdResponse,
} from '@models/LKCmdResponse';
import { Router } from '@angular/router';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { LoggingService } from '@services/logging.service';
import { LKCmdScheduleCheckResponse } from '@models/LKCmdScheduleCheckResponse';
import { TranslateService } from '@ngx-translate/core';
import { LKPermission } from '@models/LKPermission';
import { MatDialog } from '@angular/material/dialog';
import { LKDevice } from '@models/LKDevice';
import { FirmwareVersionComponent } from '@modules/maintenance/components/firmware-version/firmware-version.component';
import { BateryStateComponent } from '@modules/maintenance/components/batery-state/batery-state.component';
import { RemoveDeviceComponent } from '@modules/devices/components/remove-device/remove-device.component';
import { faSync, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FullStoryService } from '@services/fullstory-service.service';
import { LockersComponent } from '../../modals/lockers/lockers.component';
import { TimerComponent } from '../../modals/timer/timer.component';
import { DeviceSettingsComponent } from '../../modals/device-settings/device-settings.component';

@Component({
  selector: 'app-device-config',
  templateUrl: './device-config.component.html',
  styleUrls: ['./device-config.component.scss'],
})
export class DeviceConfigComponent implements OnInit, OnChanges {
  @Input() selectedDevice: any;
  @Input() snav: MatSidenav;
  @Output() deviceUpdated = new EventEmitter<boolean>();
  public sendingCommand: boolean = false;
  public updatedDevice: any;
  public hasTimer: boolean = true;
  public startDate: Date;
  public endDate: Date;
  public activeTimer = false;
  public timeSpent = 0;
  public minutes = 0;
  public hours = 0;
  public timerResponded: boolean = false;
  public checkingTimer = true;
  public timerOn: boolean = false;
  public command: string;
  public invalidTime = false;
  public endTimer: string = '23:59';
  public selectedLocker: any;
  public permissionList: LKPermission[] = [];
  public loadingTableData: boolean = true;
  public pendingLoad: Promise<boolean>;
  public deviceList: LKDevice[] = [];
  public gatewayList: any = [];
  public devicesSize;
  public disableButtons: boolean = false;
  public faSync = faSync;
  public faTrashAlt = faTrashAlt;
  public strongSignal = false;

  constructor(
    private maintenanceService: MaintenanceService,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private fullStoryService: FullStoryService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.snav.openedStart.subscribe(() => {
      this.getGatewaysByDoor(this.selectedDevice.id);
    });
    this.snav.closedStart.subscribe(() => {
      this.updatedDevice = JSON.parse(JSON.stringify(this.selectedDevice));
      this.strongSignal = false;
    });
    this.fullStoryService.setPageVars({
      pageName: 'Device Configuration',
      deviceId: this.selectedDevice.id,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updatedDevice = JSON.parse(
      JSON.stringify(changes.selectedDevice.currentValue)
    );
    if (
      !this.selectedDevice.serial.startsWith('TLK') &&
      this.selectedDevice.status === 'ONLINE'
    ) {
      this.checkTimer();
    } else {
      this.hasTimer = false;
    }
  }

  async getGatewaysByDoor(deviceId) {
    this.gatewayList = await this.maintenanceService
      .getGatewaysByDoor(deviceId)
      .toPromise();
    // eslint-disable-next-line array-callback-return
    this.gatewayList.map((gateway) => {
      if (gateway && gateway.rssi >= -70) {
        this.strongSignal = true;
      }
    });
    if (this.gatewayList.length === 0) this.strongSignal = true;
  }

  verifyAddressUpdate() {
    return (
      this.selectedDevice.address !== this.updatedDevice.address ||
      this.selectedDevice.name !== this.updatedDevice.name
    );
  }

  updateDevice() {
    this.sendingCommand = true;
    this.disableButtons = true;
    this.maintenanceService.updateDevice(this.updatedDevice).subscribe(
      (response) => {
        this.selectedDevice.name = response.name;
        this.selectedDevice.address = response.address;
        this.deviceUpdated.emit(true);
        this.sendingCommand = false;
        this.loggingService.gatewayResponse(
          'success',
          `${this.selectedDevice.name} ${this.translateService.instant(
            'successfully updated!'
          )}`
        );
        this.disableButtons = false;
      },
      () => {
        this.disableButtons = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.selectedDevice.name} ${this.translateService.instant(
            'failed updating. Try again.'
          )}`
        );
        this.sendingCommand = false;
      }
    );
  }

  restart() {
    this.disableButtons = true;
    this.maintenanceService
      .restart(this.selectedDevice.id)
      .then((response) => {
        if (response.successful) {
          this.disableButtons = false;
          this.loggingService.gatewayResponse(
            'success',
            `${this.selectedDevice.name} ${this.translateService.instant(
              'successfully restarted!'
            )}`
          );
        } else {
          this.disableButtons = false;
          this.loggingService.gatewayResponse(
            'error',
            `${this.selectedDevice.name} ${this.translateService.instant(
              'restarted failed:'
            )} ${this.translateService.instant(
              feedbackLKCmdResponse[LKCmdResponseType[response.value]]
            )}`
          );
        }
      })
      .catch(() => {
        this.disableButtons = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Failed to connect with')} ${
            this.selectedDevice.name
          }`
        );
      });
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingTableData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 5000);
    });
  }

  async getSiteDeviceList() {
    this.loadingTableData = true;
    this.pendingLoad = this.getPromise();

    this.maintenanceService.getSiteDevices().subscribe(
      (deviceList) => {
        this.deviceList = deviceList;
        this.devicesSize = deviceList.length;
      },
      () => {}
    );
  }

  openDialog(element: any, dialog: string) {
    const config = {
      panelClass: 'dialog-container',
      width: '700px',
      maxWidth: '100%',
      data: {},
    };
    config.data = { selectedDevice: element };
    switch (dialog) {
      case 'lockers':
        this.selectedLocker = element;
        config.data = {
          selectedLocker: this.selectedLocker,
          permissionList: this.permissionList,
          sharings: false,
          modalType: dialog,
        };
        this.dialog.open(LockersComponent, config);
        break;
      case 'timer':
        this.dialog.open(TimerComponent, config);
        break;
      default: {
        const dialogRef = this.dialog.open(DeviceSettingsComponent, config);
        dialogRef.componentInstance.deviceUpdated.subscribe(
          () => {
            this.loggingService.customAlert(
              {
                position: 'center',
                title: this.translateService.instant(
                  'Device successfully updated!'
                ),
              },
              'success'
            );
            this.getSiteDeviceList();
            dialogRef.close();
          },
          () => {
            this.loggingService.customAlert(
              {
                position: 'center',
                title: this.translateService.instant(
                  'Error updating device. Try again!'
                ),
              },
              'error'
            );
          }
        );
        break;
      }
    }
  }

  unlockDevices(element: any) {
    if (element.type === 'Lockers') {
      this.openDialog(element, 'lockers');
    } else if (element.type === 'Gates') {
      this.maintenanceGate(element, 'unlock', 'Unlock');
    } else {
      this.unlockDoor(element);
    }
  }

  maintenanceGate(
    gate: any,
    actionType: string,
    actionDescription: string
  ): void {
    gate.unlock = true;
    this.disableButtons = true;
    this.maintenanceService
      .custom(gate.id, actionType)
      .then((response) => {
        if (response.successful) {
          this.loggingService.gatewayResponse(
            'success',
            gate.name +
              this.translateService.instant(' successfully did action: ') +
              this.translateService.instant(actionDescription)
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            this.translateService.instant('Action failed. Please, try again.')
          );
        }

        this.disableButtons = false;
        gate.unlock = false;
      })
      .catch(() => {
        this.loggingService.gatewayResponse(
          'error',
          this.translateService.instant('Failed to communicate with gate: ') +
            gate.name
        );

        this.disableButtons = false;
        gate.unlock = false;
      });
  }

  unlockDoor(device: any): void {
    this.disableButtons = true;
    device.unlock = true;

    this.maintenanceService
      .unlock(device.id)
      .then((response) => {
        device.unlock = false;
        if (response.successful === true) {
          this.loggingService.gatewayResponse(
            'success',
            `${device.name} ${this.translateService.instant(
              'successfully unlocked!'
            )}`
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            `${this.translateService.instant('Error unlocking')} ${
              device.name
            }: ${this.translateService.instant(
              feedbackLKCmdResponse[LKCmdResponseType[response.value]]
            )}`
          );
        }

        this.disableButtons = false;
      })
      .catch(() => {
        device.unlock = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Error unlocking')}
          ${device.name}: ${this.translateService.instant(
            'device is offline'
          )}!`
        );

        this.disableButtons = false;
      });
  }

  updateTime() {
    this.updateTimezone();
    this.disableButtons = true;

    this.maintenanceService
      .setTime(this.selectedDevice.id)
      .then((response) => {
        if (response.successful) {
          this.loggingService.gatewayResponse(
            'success',
            `${this.selectedDevice.name} ${this.translateService.instant(
              'updated time successfully!'
            )}`
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            `${this.selectedDevice.name} ${this.translateService.instant(
              'update time failed:'
            )} ${this.translateService.instant(
              feedbackLKCmdResponse[LKCmdResponseType[response.value]]
            )}`
          );
        }

        this.disableButtons = false;
      })
      .catch(() => {
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Failed to connect with')} ${
            this.selectedDevice.name
          }`
        );

        this.disableButtons = false;
      });
  }

  updateTimezone() {
    this.disableButtons = true;

    this.maintenanceService
      .setTimeZone(this.selectedDevice.id)
      .then((response) => {
        if (response.successful) {
          this.loggingService.gatewayResponse(
            'success',
            `${this.selectedDevice.name} ${this.translateService.instant(
              'updated timezone successfully!'
            )}`
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            `${this.selectedDevice.name} ${this.translateService.instant(
              'update timezone failed:'
            )} ${feedbackLKCmdResponse[LKCmdResponseType[response.value]]}`
          );
        }

        this.disableButtons = false;
      })
      .catch(() => {
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Failed to connect with')} ${
            this.selectedDevice.name
          }`
        );

        this.disableButtons = false;
      });
  }

  sendGatewayCommand(gateway: any, command: string) {
    if (command === 'reboot') {
      this.maintenanceService.sendGatewayCommand(gateway.id, command).subscribe(
        (response) => {
          if (response.response.includes('okok')) {
            this.loggingService.gatewayResponse(
              'success',
              `Gateway ${this.translateService.instant(
                'restarted successfully!'
              )}`
            );
          } else {
            this.loggingService.gatewayResponse(
              'error',
              `${this.translateService.instant(
                'Error while restarted the gateway'
              )}`
            );
          }
        },
        () => {
          this.loggingService.gatewayResponse(
            'error',
            `${this.translateService.instant(
              'Error while restarted the gateway'
            )}: ${this.translateService.instant('gateway is offline')}!`
          );
        }
      );
    } else if (command === 'version') {
      const config = {
        panelClass: 'dialog-container',
        width: '700px',
        maxWidth: '100%',
        data: {
          id: gateway.id,
          type: 'Gateway',
        },
      };

      this.dialog.open(FirmwareVersionComponent, config);
    }
  }

  async controllerTime() {
    if (!this.timerOn) {
      this.calcTimeSpent();
      await this.setTimer();
    } else {
      await this.stopTimer();
    }
  }

  async checkTimer(): Promise<void> {
    this.timerResponded = false;
    this.checkingTimer = true;
    this.timerOn = false;
    this.startDate = new Date();

    try {
      const response = await this.maintenanceService.scheduleCheck(
        this.selectedDevice.id
      );
      switch (response.value) {
        case LKCmdResponseType.ERROR_SCHED_STOP:
          this.timerResponded = true;
          this.timerOn = false;
          this.checkingTimer = false;
          this.calcTimeSpent();
          break;
        case LKCmdResponseType.SCHEDULE_REQUEST_RESULT:
          this.timerResponded = true;
          this.timerOn = true;
          this.checkingTimer = false;
          this.endDate = new Date();
          this.setTimerResponse(response);
          break;
        default:
          this.timerResponded = false;
          this.checkingTimer = false;
          break;
      }
    } catch (e) {
      this.timerResponded = false;
      this.checkingTimer = false;
    }
  }

  forceSync(event, device: any) {
    event.stopPropagation();
    this.disableButtons = true;

    this.maintenanceService
      .forceSync(device.id)
      .then(() => {
        this.loggingService.gatewayResponse(
          'success',
          this.translateService.instant(`Started synchronization successfully!`)
        );
        this.disableButtons = false;
      })
      .catch(() => {
        device.unlock = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Error while try started sync')}
            ${device.name}: ${this.translateService.instant(
            'device is offline'
          )}!`
        );

        this.disableButtons = false;
      });
  }

  maintenanceTimer() {
    this.checkingTimer = true;
    this.startDate = new Date();
    if (this.timerOn) {
      this.stopTimer();
    } else {
      this.setTimer();
    }
  }

  async setTimer(): Promise<void> {
    this.disableButtons = true;

    return this.maintenanceService
      .scheduleInit(this.selectedDevice.id, 0, this.timeSpent)
      .then(async (res) => {
        this.checkingTimer = false;
        if (res.successful) {
          this.loggingService.gatewayResponse(
            'success',
            this.translateService.instant('Timer successfully set!')
          );

          this.disableButtons = false;
          this.timerResponded = true;
          this.timerOn = true;
        } else {
          await this.checkTimer();
          if (this.timerOn === false) {
            this.disableButtons = false;
            this.loggingService.gatewayResponse(
              'error',
              `${this.translateService.instant('Failed to set timer:')} ${
                feedbackLKCmdResponse[LKCmdResponseType[res.value]]
              }`
            );
          }
        }
      })
      .catch(() => {
        this.loggingService.gatewayResponse(
          'error',
          this.translateService.instant('Operation timed out.')
        );

        this.disableButtons = false;
        this.checkingTimer = false;
      });
  }

  stopTimer(): void {
    this.disableButtons = true;

    this.maintenanceService
      .scheduleStop(this.selectedDevice.id)
      .then((response) => {
        if (
          response.successful ||
          response.value === LKCmdResponseType.ERROR_SCHED_STOP
        ) {
          this.loggingService.gatewayResponse(
            'success',
            this.translateService.instant('Timer successfully cancelled!')
          );
          this.timerResponded = true;
          this.timerOn = false;
          this.endTimer = '23:59';
          this.calcTimeSpent();
        } else {
          this.timerResponded = false;
          this.loggingService.gatewayResponse(
            'error',
            this.translateService.instant('Failed to cancel timer!')
          );
        }

        this.disableButtons = false;
        this.checkingTimer = false;
      })
      .catch(() => {
        this.timerResponded = false;
        this.loggingService.gatewayResponse(
          'error',
          this.translateService.instant('Failed to cancel timer!')
        );
        this.disableButtons = false;
        this.checkingTimer = false;
      });
  }

  calcTimeSpent(): void {
    this.startDate = new Date();
    const [hoursString, minutesString] = this.endTimer.split(':');
    const hours = Number.parseInt(hoursString, 10);
    const minutes = Number.parseInt(minutesString, 10);
    const timerDate = new Date(
      this.startDate.getFullYear(),
      this.startDate.getMonth(),
      this.startDate.getDate(),
      hours,
      minutes
    );
    const nowDailyMinutes =
      this.startDate.getHours() * 60 + this.startDate.getMinutes();
    const timerEndDailyMinutes = hours * 60 + minutes;
    if (timerEndDailyMinutes < nowDailyMinutes) {
      timerDate.setDate(timerDate.getDate() + 1);
    }
    this.endDate = timerDate;
    this.timeSpent = timerDate.getTime() - this.startDate.getTime();
    this.hours = Math.floor(this.timeSpent / (60 * 60 * 1000));
    this.minutes = Math.floor(
      (this.timeSpent - this.hours * 60 * 60 * 1000) / (60 * 1000)
    );
  }

  setTimerResponse(response: LKCmdScheduleCheckResponse): void {
    this.endDate.setTime(this.startDate.getTime() + response.duration);
    this.endTimer = `${String(this.endDate.getHours())}:${String(
      this.endDate.getMinutes()
    )}`;
    this.calcTimeSpent();
  }

  readFirmwareVersion(element: any): void {
    const config = {
      panelClass: 'dialog-container',
      width: '700px',
      maxWidth: '100%',
      data: {
        id: element.id,
        type: 'Door',
      },
    };

    this.dialog.open(FirmwareVersionComponent, config);
  }

  readBatteryState(element: any): void {
    const config = {
      panelClass: 'dialog-container',
      width: '700px',
      maxWidth: '100%',
      data: {
        battery: element.battery,
        idDoor: element.id,
      },
    };

    this.dialog.open(BateryStateComponent, config);
  }

  openRemoveDeviceDialog(element: any): void {
    const config = {
      panelClass: 'dialog-container',
      width: '500px',
      maxWidth: '100%',
      data: {
        deviceId: element.id,
      },
    };

    const dialogRef = this.dialog.open(RemoveDeviceComponent, config);
    dialogRef.componentInstance.deviceRemoved.subscribe(() => {
      const currentUrl = this.router.url;
      if (currentUrl.includes('dashboard')) this.router.navigate(['/devices']);
      else
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([currentUrl]);
          });
    });
  }
}
