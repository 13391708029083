import { Injectable } from '@angular/core';
import { ApiService } from '@core/http/api.service';
import * as qs from 'querystring';
import { GlobalsService } from '@services/globals.service';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StatusService {
  public onlineDevices: any = { doorStatus: [], gatewayStatus: [] };
  public devicesStatus = new Subject<string[]>();
  public interval: any;

  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getOnlineDevices() {
    this.apiService
      .getOnlineDevicesList(
        qs.stringify({ siteId: this.globalsService.getSiteId() })
      )
      .subscribe(
        (response) => {
          if (
            JSON.stringify(this.onlineDevices.doorStatus.sort(this.compare)) !==
              JSON.stringify(response.doorStatus.sort(this.compare)) ||
            JSON.stringify(
              this.onlineDevices.gatewayStatus.sort(this.compare)
            ) !== JSON.stringify(response.gatewayStatus.sort(this.compare))
          ) {
            this.onlineDevices.doorStatus = response.doorStatus;
            this.onlineDevices.gatewayStatus = response.gatewayStatus;
            this.devicesStatus.next(this.onlineDevices.doorStatus);
          }
        },
        () => {}
      );
  }

  compare(deviceA, deviceB) {
    if (deviceA.id < deviceB.id) {
      return -1;
    }
    if (deviceA.id > deviceB.id) {
      return 1;
    }
    return 0;
  }

  startScan() {
    this.getOnlineDevices();
    this.interval = setInterval(() => {
      this.getOnlineDevices();
    }, 30000);
  }

  watchOnlineDevices(): Observable<any> {
    return this.devicesStatus.asObservable();
  }

  returnOnlineDevices(): any {
    return this.onlineDevices;
  }

  stopScan() {
    // this.onlineDevices = [];
    clearInterval(this.interval);
  }
}
