<div class="create-booking">
  <div class="text-center">
    <h5 mat-dialog-title translate>New Booking</h5>
  </div>
  <div class="create-booking__messageError" *ngIf="messageError">
    <fa-icon class="icon-warning" icon="exclamation-triangle"></fa-icon>
    <p translate>{{messageError}}</p>
  </div>
  <mat-horizontal-stepper class="create-booking__stepper" linear="true"
    (selectionChange)="onChangeHeader($event, stepper)" (click)="triggerClick($event, stepper)" #stepper>
    <mat-step [label]="'1. Guest' | translate" [completed]="currentStepIndex > 0">
      <app-create-guest [data]="data" class="create-booking__user" (createInfoSet)="setInfo($event, stepper)"
        (editInfoSet)="setInfo($event, stepper)">
      </app-create-guest>
    </mat-step>

    <mat-step [label]="'2. Date' | translate" [completed]="false" hasError="true">
      <app-date-booking [data]="data" class="create-booking__user" (stepIndex)="changeStep($event)"
        (bookingInfo)="setDateInfo($event, stepper)" (editInfoSet)="setInfo($event, stepper)">
      </app-date-booking>
    </mat-step>

    <mat-step [label]="'3. Room' | translate" [completed]="false">
      <app-bookings-roles-rooms class="create-booking__user" (stepIndex)="changeStep($event)" [doors]="data.doors"
         (roomsSet)="setRooms($event, stepper)">
      </app-bookings-roles-rooms>
    </mat-step>

    <mat-step [label]="'4. Summary' | translate" [completed]="false">
      <app-booking-summary actionText="Submit" (submitBooking)="submitBooking($event, stepper)"
        (stepIndex)="changeStep($event)" [createBooking]="createBookingParams" [editBooking]="editBookingParams"
        [action]="action" (previousItem)="backStep(stepper)"></app-booking-summary>
    </mat-step>

    <mat-step [label]="'5. Result' | translate">
      <app-booking-result actionText="Finish" [action]="action" [responseBooking]="responseBooking" [stepper]="stepper"
        (previousItem)="backStep(stepper)"></app-booking-result>
    </mat-step>

  </mat-horizontal-stepper>
  <mat-dialog-content></mat-dialog-content>
</div>