import { LKUser } from './LKUser';

export interface ILKSession {
  authorization: string;
  user: LKUser;
}

export class LKSession {
  authorization: string;
  user: LKUser;

  constructor(serverSessionObject: ILKSession) {
    this.authorization = serverSessionObject.authorization;
    this.user = new LKUser(serverSessionObject.user);
  }
}
