import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faCheck,
  faEye,
  faEyeSlash,
  faCopy,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { IntegrationsRoutingModule } from './integrations-routing.module';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { AddKeyComponent } from './pages/add-key/add-key.component';
import { ConfirmationRemoveComponent } from './components/confirmation-remove/confirmation-remove.component';

@NgModule({
  declarations: [
    IntegrationsComponent,
    AddKeyComponent,
    ConfirmationRemoveComponent,
  ],
  imports: [
    CommonModule,
    IntegrationsRoutingModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
})
export class IntegrationsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCheck, faEyeSlash, faEye, faCopy, faSpinner);
  }
}
