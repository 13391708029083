import { Injectable, EventEmitter } from '@angular/core';
import * as qs from 'querystring';
import { LKSession } from '@models/LKSession';
import { ApiService } from '@core/http/api.service';
import { CookiesManagerService } from './cookies.manager.service';

/**
 * User Active Session Manager
 *
 * @export
 * @class UserSessionService
 */
@Injectable({ providedIn: 'root' })
export class UserSessionService {
  private activeSession: LKSession;

  userDidLoginEmitter = new EventEmitter<LKSession>();
  userSessionChangedEmitter = new EventEmitter<LKSession>();
  userDidLogoutEmitter = new EventEmitter<LKSession>();

  constructor(
    private cookiesManager: CookiesManagerService,
    private apiService: ApiService
  ) {
    this.activeSession = cookiesManager.getSession();
  }

  /**
   *
   * Save actual LKSession on cookies
   *
   * @param {LKSession} session
   * @memberof UserSessionService
   */
  saveSession(session: LKSession) {
    this.activeSession = session;
    this.cookiesManager.saveSession(session);
    this.userSessionChangedEmitter.emit(this.activeSession);
  }

  /**
   * Remove actual session from the cookies
   *
   * @memberof UserSessionService
   */
  removeSession(): void {
    this.activeSession = this.cookiesManager.getSession();
    this.activeSession = null;
    localStorage.clear();
    this.cookiesManager.removeSession();
    this.userSessionChangedEmitter.emit(this.activeSession);
  }

  /**
   * Get actual session from the cookies
   *
   * @returns {LKSession}
   * @memberof UserSessionService
   */
  getSession(): LKSession {
    return this.activeSession;
  }

  /**
   * Remove sessions
   *
   * @param {string} id
   * @param {((session: LKSession) => void)} success
   * @param {(() => void)} failed
   * @memberof UserSessionService
   */
  invalidateSession(id: string) {
    const body = qs.stringify({ id });
    // eslint-disable-next-line prettier/prettier
    this.apiService.invalidateSession(body).subscribe(() => {}, () => {});
  }
}
