import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { GroupsService } from '@modules/groups/groups.service';
import { LoggingService } from '@services/logging.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss'],
})
export class GroupInfoComponent implements OnInit {
  @Output() infoSet = new EventEmitter<{
    name: string;
    description: string;
  }>();
  @Input() info: any;
  @Input() editInfo: boolean = false;

  public groupForm: UntypedFormGroup;
  public sizeWidthSuit: boolean = false;

  constructor(
    private groupsService: GroupsService,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<GroupInfoComponent>
  ) {}

  ngOnInit() {
    this.sizeWidthSuit = window.innerWidth <= 500;
    this.groupForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.info !== undefined ? this.info.name : '',
        [Validators.required, Validators.minLength(3)]
      ),
      description: new UntypedFormControl(
        this.info !== undefined ? this.info.description : '',
        [Validators.minLength(3)]
      ),
    });
  }

  get f() {
    return this.groupForm.controls;
  }

  getNameError(): string {
    if (this.f.name.errors.nameError) {
      return 'Already exist a group with this name.';
    }
    return 'Required. Must have at least 3 characters.';
  }

  onSubmit() {
    if (this.groupForm.valid) {
      if (this.editInfo) {
        const paramGroup = {
          id: this.info.id,
          name: this.f.name.value,
          description: this.f.description.value,
        };
        this.groupsService.editGroup(paramGroup).subscribe(
          () => {
            this.infoSet.emit({
              name: this.f.name.value,
              description: this.f.description.value,
            });

            this.dialogRef.close();
            this.loggingService.defaultToast(
              'success',
              this.translateService.instant('Group updated successfully!'),
              'center'
            );
          },
          (e) => {
            if (e.error.errorCode === 102) {
              this.f.name.setErrors({ nameError: true });
            } else {
              this.loggingService.defaultToast(
                'error',
                this.translateService.instant(
                  'Action failed. Please, try again.'
                ),
                'center'
              );
            }
          }
        );
      } else {
        this.infoSet.emit({
          name: this.f.name.value,
          description: this.f.description.value,
        });
      }
    }
  }
}
