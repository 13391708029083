import { Component, OnInit } from '@angular/core';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { GlobalsService } from '@services/globals.service';
import { CorporationService } from '@modules/corporation/corporation.service';
import { SitesService } from '@modules/sites/sites.service';
import heatData from '@utils/heatmap-mock.js';
import { LKEvent } from '@models/LKEvent';
import { TranslateService } from '@ngx-translate/core';
import { GeneralDashboardService } from '@modules/general-dashboard/general-dashboard.service';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable, Subscription } from 'rxjs';
import { LKDeviceEvents } from '@models/LKDeviceEvents';
import { LKUserEvent } from '@models/LKUserEvent';
import { Title } from '@angular/platform-browser';
import {
  faBuilding,
  faExclamationTriangle,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FullStoryService } from '@services/fullstory-service.service';

@Component({
  selector: 'app-general-dashboard',
  templateUrl: './general-dashboard.component.html',
  styleUrls: ['./general-dashboard.component.scss'],
})
export class GeneralDashboardComponent implements OnInit {
  public currentUsername: string = '';
  public images = [];
  public imagesID = [];
  public imgRef = '../../../assets/images/avatars/default-user.png';
  public corpName: string = '';
  public siteName: string = '';
  public weekDayUsage: any = [];
  public hourlyUsage = [];
  public currentMonth: string;
  public barPaddingWeek = 35;
  public barPaddingHourly = 15;
  public viewGraphBar = [];
  public viewGraphLine = [];
  public filterData = [];
  public language = 'default';
  public colorSchemeWhite = {
    domain: ['#C4C4C4', '#077AA9', '#3FB0D9', '#179ED0', '#36BCEE', '#313C4E'],
  };
  public heatmapData = [];
  public mapsLoading = true;
  public valuePercentagem = {
    activateUsers: 0,
    countEveryUsers: 0,
    userGrowth: 0,
    onlineDevices: 0,
    totalDevices: 0,
    accessDenied: 0,
  };
  public bookingsStats = {
    passcodesNotSettled: 0,
    passcodesSettled: 0,
    alreadyMadeCheckins: 0,
    totalCount: 0,
    occupancyPercentage: 0,
  };
  public devicesStats = {
    totalLocks: 0,
    totalGateways: 0,
    onlineLocks: 0,
    onlineGateways: 0,
    offlineGateways: 0,
    locksWithLowBattery: 0,
    locksWithMediumBattery: 0,
    lockWihHighBattery: 0,
    doorsWithLowBattery: [],
  };
  public loadingData: boolean = false;
  public pendingLoad: Promise<boolean>;
  public scrollCallback;
  public reachedTheEnd: boolean = false;
  public currentPage: number = 0;
  public showWeekGraph: boolean = true;
  public loadWeekGraph: boolean = true;
  public showHourGraph: boolean = true;
  public loadHourGraph: boolean = true;
  public loadHeatmapData: boolean = true;
  public widthSizeSuitable: boolean = false;
  public sizeWidthSuitable: boolean = false;
  public hasLogs: boolean = true;
  public subscription: Subscription;
  public events: LKDeviceEvents | LKUserEvent = { events: [], count: 0 };
  public filtersStatus = ['Daily', 'Hourly'];
  public weekView: boolean = true;
  public faBuilding = faBuilding;
  public faLocationDot = faLocationDot;
  public faExclamationTriangle = faExclamationTriangle;
  public hasBooking = false;

  constructor(
    private cookiesManagerService: CookiesManagerService,
    private globalsServices: GlobalsService,
    private corporationService: CorporationService,
    private sitesService: SitesService,
    private translateServices: TranslateService,
    private generalDashboardService: GeneralDashboardService,
    private fullStoryService: FullStoryService,
    private titleService: Title
  ) {
    this.scrollCallback = this.getEvents.bind(this);
    this.language = this.cookiesManagerService.getLanguage();
  }

  async ngOnInit() {
    if (localStorage.getItem('corporation') !== null) {
      this.hasBooking = JSON.parse(
        localStorage.getItem('corporation')
      ).hasBooking;
    }
    this.widthSizeSuitable = window.innerWidth <= 500;
    this.sizeWidthSuitable = window.innerWidth <= 800;
    this.currentMonth = moment().format('MM');

    this.fullStoryService.setUserVars({
      displayName: localStorage.getItem('userName'),
      corpId: this.globalsServices.getCorporationId(),
      siteId: this.globalsServices.getSiteId(),
    });

    this.heatmapData = this.widthSizeSuitable
      ? heatData.heatmap_mobile
      : heatData.heatmap_normal;

    const session = this.cookiesManagerService.getSession();
    const corpResp = await this.corporationService
      .getUserCorporations()
      .toPromise();

    const [currentCorp] = corpResp.filter(
      (corp) => corp.id === this.globalsServices.getCorporationId()
    );

    this.corpName = currentCorp.name;

    const siteResp = await this.sitesService
      .getSitesFromCorp(this.globalsServices.getCorporationId())
      .toPromise();

    const [currentSite] = siteResp.filter(
      (site) => site.id === this.globalsServices.getSiteId()
    );

    this.siteName = currentSite.name;

    this.currentUsername = `${session.user.name} ${session.user.surname}`;

    this.verifyPaddingGraphBar(window);
    this.language = this.cookiesManagerService.getLanguage();

    this.loadingContent();

    this.titleService.setTitle(
      this.translateServices.instant('Dashboard | LoopKey')
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  readLocalStorageValue(key) {
    return localStorage.getItem(key);
  }

  async loadingContent() {
    this.showHourGraph = true;
    this.pendingLoad = this.getPromise();

    try {
      if (this.hasBooking) {
        const bookingsGeneralContent = await this.generalDashboardService
          .getBookingsStats()
          .toPromise();

        if (bookingsGeneralContent) {
          this.bookingsStats.totalCount = bookingsGeneralContent.totalCount;
          this.bookingsStats.passcodesNotSettled =
            bookingsGeneralContent.passcodesNotSettled;
          this.bookingsStats.alreadyMadeCheckins =
            bookingsGeneralContent.alreadyMadeCheckins;
          this.bookingsStats.occupancyPercentage =
            bookingsGeneralContent.occupancyPercentage;
        }

        const devicesGeneralContent = await this.generalDashboardService
          .getDevicesStats()
          .toPromise();

        if (devicesGeneralContent) {
          this.devicesStats.totalLocks = devicesGeneralContent.totalLocks;
          this.devicesStats.totalGateways = devicesGeneralContent.totalGateways;
          this.devicesStats.onlineLocks = devicesGeneralContent.onlineLocks;
          this.devicesStats.onlineGateways =
            devicesGeneralContent.onlineGateways;
          this.devicesStats.offlineGateways =
            devicesGeneralContent.totalGateways -
            devicesGeneralContent.onlineGateways;
          this.devicesStats.locksWithLowBattery =
            devicesGeneralContent.locksWithLowBattery;
          this.devicesStats.locksWithMediumBattery =
            devicesGeneralContent.locksWithMediumBattery;
          this.devicesStats.lockWihHighBattery =
            devicesGeneralContent.lockWihHighBattery;
          this.devicesStats.doorsWithLowBattery =
            devicesGeneralContent.doorsWithLowBattery;
        }
      } else {
        const dashboardGeneralContent = await this.generalDashboardService
          .getGeneralEventsDashboard()
          .toPromise();
        if (dashboardGeneralContent) {
          this.valuePercentagem.activateUsers =
            dashboardGeneralContent.countUsers.countActiveUsers;
          this.valuePercentagem.countEveryUsers =
            dashboardGeneralContent.countUsers.countEveryUsers;
          this.valuePercentagem.accessDenied =
            dashboardGeneralContent.deniedAccess;
          this.valuePercentagem.totalDevices =
            dashboardGeneralContent.siteDevices.totalDevices;
          this.valuePercentagem.onlineDevices =
            dashboardGeneralContent.siteDevices.onlineDevices;
          if (
            dashboardGeneralContent.growthOfActiveUsers
              .countUsersFromLastPeriod === 0 ||
            Number.isNaN(
              Number(
                dashboardGeneralContent.growthOfActiveUsers
                  .countUsersFromLastPeriod
              )
            )
          ) {
            this.valuePercentagem.userGrowth =
              dashboardGeneralContent.growthOfActiveUsers
                .countUsersFromCurrentPeriod * 100;
          } else {
            this.valuePercentagem.userGrowth = Math.floor(
              ((dashboardGeneralContent.growthOfActiveUsers
                .countUsersFromCurrentPeriod -
                dashboardGeneralContent.growthOfActiveUsers
                  .countUsersFromLastPeriod) /
                dashboardGeneralContent.growthOfActiveUsers
                  .countUsersFromLastPeriod) *
                100
            );
          }
        }
      }

      // this.showNumbersDinamically();
      this.mapsLoading = false;

      const weekDay = await this.generalDashboardService
        .getWeekUsage()
        .toPromise();

      if (weekDay) {
        const sumWeek = [];
        this.weekDayUsage = Object.entries(weekDay).map((week) => {
          sumWeek.push(week[1]);
          return {
            name: moment(week[0]).format('DD/MM'),
            value: week[1],
          };
        });
        this.showWeekGraph = sumWeek.reduce((total, num) => total + num) !== 0;
      }
      this.loadWeekGraph = false;

      const hourly = await this.generalDashboardService
        .getHourlyUsage()
        .toPromise();

      if (hourly) {
        const sumHours = [];
        const hourlyDataFiltered = Object.entries(hourly).map((hour) => {
          sumHours.push(hour[1]);
          return {
            name: hour[0].split('-')[1],
            value: hour[1],
          };
        });
        this.hourlyUsage = [
          { name: '0', value: hourly['0-2'] },
          ...hourlyDataFiltered,
        ];
        this.showHourGraph = sumHours.reduce((total, num) => total + num) !== 0;
      }
      this.loadHourGraph = false;

      const monthUsage = await this.generalDashboardService
        .getMonthlyUsage()
        .toPromise();
      if (monthUsage) {
        this.loadHeatmapData = false;
        const mothlyContent = [];
        if (!this.widthSizeSuitable) {
          for (let i = 0; i < 23; i++) {
            mothlyContent.push({
              name: String(i),
              series: [
                {
                  name: this.translateServices.instant('Sun'),
                  value: monthUsage.SUNDAY[`${i}-${i + 1}`],
                },
                {
                  name: this.translateServices.instant('Sat'),
                  value: monthUsage.SATURDAY[`${i}-${i + 1}`],
                },
                {
                  name: this.translateServices.instant('Fri'),
                  value: monthUsage.FRIDAY[`${i}-${i + 1}`],
                },
                {
                  name: this.translateServices.instant('Thu'),
                  value: monthUsage.THURSDAY[`${i}-${i + 1}`],
                },
                {
                  name: this.translateServices.instant('Wed'),
                  value: monthUsage.WEDNESDAY[`${i}-${i + 1}`],
                },
                {
                  name: this.translateServices.instant('Tue'),
                  value: monthUsage.TUESDAY[`${i}-${i + 1}`],
                },
                {
                  name: this.translateServices.instant('Mon'),
                  value: monthUsage.MONDAY[`${i}-${i + 1}`],
                },
              ],
            });
          }

          mothlyContent.push({
            name: 23,
            series: [
              {
                name: this.translateServices.instant('Sun'),
                value: monthUsage.SUNDAY[`22-23`],
              },
              {
                name: this.translateServices.instant('Sat'),
                value: monthUsage.SATURDAY[`22-23`],
              },
              {
                name: this.translateServices.instant('Fri'),
                value: monthUsage.FRIDAY[`22-23`],
              },
              {
                name: this.translateServices.instant('Thu'),
                value: monthUsage.THURSDAY[`22-23`],
              },
              {
                name: this.translateServices.instant('Wed'),
                value: monthUsage.WEDNESDAY[`22-23`],
              },
              {
                name: this.translateServices.instant('Tue'),
                value: monthUsage.TUESDAY[`22-23`],
              },
              {
                name: this.translateServices.instant('Mon'),
                value: monthUsage.MONDAY[`22-23`],
              },
            ],
          });
        } else {
          for (let i = 0; i < 6; i++) {
            mothlyContent.push({
              name: `${String(3 * i + i)}-${String(4 * i + 3)}`,
              series: [
                {
                  name: this.translateServices.instant('Sun'),
                  value:
                    monthUsage.SUNDAY[`${3 * i + i}-${3 * i + i + 1}`] +
                    monthUsage.SUNDAY[`${3 * i + i + 1}-${3 * i + i + 2}`] +
                    monthUsage.SUNDAY[`${3 * i + i + 2}-${3 * i + i + 3}`],
                },
                {
                  name: this.translateServices.instant('Sat'),
                  value:
                    monthUsage.SATURDAY[`${3 * i + i}-${3 * i + i + 1}`] +
                    monthUsage.SATURDAY[`${3 * i + i + 1}-${3 * i + i + 2}`] +
                    monthUsage.SATURDAY[`${3 * i + i + 2}-${3 * i + i + 3}`],
                },
                {
                  name: this.translateServices.instant('Fri'),
                  value:
                    monthUsage.FRIDAY[`${3 * i + i}-${3 * i + i + 1}`] +
                    monthUsage.FRIDAY[`${3 * i + i + 1}-${3 * i + i + 2}`] +
                    monthUsage.FRIDAY[`${3 * i + i + 2}-${3 * i + i + 3}`],
                },
                {
                  name: this.translateServices.instant('Thu'),
                  value:
                    monthUsage.THURSDAY[`${3 * i + i}-${3 * i + i + 1}`] +
                    monthUsage.THURSDAY[`${3 * i + i + 1}-${3 * i + i + 2}`] +
                    monthUsage.THURSDAY[`${3 * i + i + 2}-${3 * i + i + 3}`],
                },
                {
                  name: this.translateServices.instant('Wed'),
                  value:
                    monthUsage.WEDNESDAY[`${3 * i + i}-${3 * i + i + 1}`] +
                    monthUsage.WEDNESDAY[`${3 * i + i + 1}-${3 * i + i + 2}`] +
                    monthUsage.WEDNESDAY[`${3 * i + i + 2}-${3 * i + i + 3}`],
                },
                {
                  name: this.translateServices.instant('Tue'),
                  value:
                    monthUsage.TUESDAY[`${3 * i + i}-${3 * i + i + 1}`] +
                    monthUsage.TUESDAY[`${3 * i + i + 1}-${3 * i + i + 2}`] +
                    monthUsage.TUESDAY[`${3 * i + i + 2}-${3 * i + i + 3}`],
                },
                {
                  name: this.translateServices.instant('Mon'),
                  value:
                    monthUsage.MONDAY[`${3 * i + i}-${3 * i + i + 1}`] +
                    monthUsage.MONDAY[`${3 * i + i + 1}-${3 * i + i + 2}`] +
                    monthUsage.MONDAY[`${3 * i + i + 2}-${3 * i + i + 3}`],
                },
              ],
            });
          }
        }

        this.heatmapData = mothlyContent;
      }
    } catch {
      this.showHourGraph = false;
      this.showWeekGraph = false;
      this.loadingData = false;
      this.mapsLoading = false;
      this.loadWeekGraph = false;
      this.loadHourGraph = false;
      this.loadHeatmapData = false;
    }
  }

  getHourFormat(date: string) {
    return String(moment(date).format('HH:mm'));
  }

  getDateFormat(date: string) {
    const getDate = new Date(date);
    const year = getDate.getFullYear();
    const month = this.capitalizeFirstLetter(
      getDate.toLocaleString(this.language, { month: 'short' })
    );
    const day = getDate.getDate();
    const weekDay = this.capitalizeFirstLetter(
      getDate.toLocaleString(this.language, { weekday: 'short' })
    );
    return `${weekDay} - ${month} ${this.toTwoDigits(day)}, ${year} `;
  }

  toTwoDigits(num: number) {
    return num <= 9 ? `0${num}` : num;
  }

  private capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  getEventMessage(event: LKEvent): String {
    switch (event.operation) {
      case 'timerSet':
        return this.translateServices.instant('Timer Set');
      case 'timerCancel':
        return this.translateServices.instant('Timer Canceled');
      case 'timerCancelButton':
        return this.translateServices.instant('Timer Canceled Button');
      case 'timerStart':
        return this.translateServices.instant('Timer Started');
      case 'alarmDoorHalfOpen':
        return this.translateServices.instant('Timer Canceled');
      case 'timerEnd':
        return this.translateServices.instant('Timer End');
      case 'privateModeChange':
        return this.translateServices.instant('Private Mode Changed');
      case 'permissionCreate':
        return this.translateServices.instant('Shared');
      case 'permissionUpdate':
        return this.translateServices.instant('Permission Updated');
      case 'permissionRemove':
        return this.translateServices.instant('Timer Removed');
      case 'alarmOutOfRestrictionsGuestCommand':
        return this.translateServices.instant('Alarm out of restriction');
      case 'alarmUnlockDenied':
        return this.translateServices.instant('Denied');
      case 'unlockExitButton':
        return this.translateServices.instant('Unlock Exit Button');
      case 'unlockCommand':
        if (event.commandMethod === 'otp') {
          return this.translateServices.instant('Unlocked (Backup Passcode)');
        }
        return this.translateServices.instant('Unlocked');

      case 'unlockPhysicalKey':
        return this.translateServices.instant('Unlock using physical key');
      case 'create':
        return this.translateServices.instant('Created');
      case 'update':
        return this.translateServices.instant('Updated');
      default:
        return '';
    }
  }

  getEvents(): Observable<any> {
    return this.generalDashboardService
      .getGeneralDashboardEventsSite(30 * this.currentPage, 30)
      .pipe(
        map((response) => this.processData(response)),
        catchError((error: any) => this.errorHandler(error))
      );
  }

  errorHandler(error) {
    if (error.error.errorDescription === 'This site does not contain doors') {
      this.hasLogs = false;
    }
    const errorCode = error.code;
    return throwError(errorCode);
  }

  private processData(response) {
    const eventsResponse = response;
    this.currentPage++;
    if (eventsResponse.events.length > 0) {
      this.events.events = this.events.events.concat(eventsResponse.events);
      this.filterData = this.events.events;
      this.events.count = eventsResponse.count;
      if (this.events.events.length < 30) {
        this.reachedTheEnd = true;
      }
    } else {
      this.reachedTheEnd = true;
    }
    if (this.events.events.length === 0) {
      this.hasLogs = false;
    }
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 5000);
    });
  }

  verifyPaddingGraphBar(windowStat) {
    this.barPaddingWeek = windowStat.innerWidth * 0.025;
    this.barPaddingHourly = windowStat.innerWidth * 0.01;

    if (windowStat.innerHeight <= 800) {
      this.viewGraphLine = [
        windowStat.innerWidth * 0.48,
        windowStat.innerHeight * 0.2,
      ];
      this.viewGraphBar = [
        windowStat.innerWidth * 0.22,
        windowStat.innerHeight * 0.15,
      ];
    } else {
      this.viewGraphBar = [
        windowStat.innerWidth * 0.22,
        windowStat.innerHeight * 0.16,
      ];
      this.viewGraphLine = [
        windowStat.innerWidth * 0.48,
        windowStat.innerHeight * 0.23,
      ];
    }

    if (windowStat.innerWidth >= 800 && windowStat.innerWidth <= 1100) {
      this.viewGraphBar[0] = windowStat.innerWidth * 0.38;
      this.viewGraphLine[0] = windowStat.innerWidth * 0.78;
    }

    if (windowStat.innerWidth < 800) {
      this.barPaddingWeek = windowStat.innerWidth * 0.08;
      this.barPaddingHourly = windowStat.innerWidth * 0.03;
      this.viewGraphLine = [
        windowStat.innerWidth * 0.75,
        windowStat.innerHeight * 0.4,
      ];
      this.viewGraphBar = [
        windowStat.innerWidth * 0.75,
        windowStat.innerHeight * 0.31,
      ];
    }

    if (windowStat.innerWidth >= 1800) {
      this.barPaddingWeek = 55;
      this.barPaddingHourly = 25;
      this.viewGraphLine = [950, 230];
      this.viewGraphBar = [450, 180];
    }
  }

  onResize(event) {
    this.verifyPaddingGraphBar(event.target);
  }

  formatDateEvent(event: LKEvent): string {
    return this.getDateEvent(event).split(' - ')[0];
  }

  getDateEvent(event: any): string {
    const eventDate = new Date(event.when);
    const year = eventDate.getFullYear();
    const month = eventDate.getMonth();
    const day = eventDate.getDate();
    const hour = eventDate.getHours();
    const minute = eventDate.getMinutes();
    return `${day}/${`0${String(month + 1)}`.slice(
      -2
    )}/${year} - ${this.toTwoDigits(hour)}:${this.toTwoDigits(minute)}`;
  }

  formatDate(event: LKEvent): string {
    return event.getDateLogAsString(this.language);
  }

  changeWeekView() {
    this.weekView = !this.weekView;
  }

  formatTooltip = (value) => {
    if (value > 1) {
      return `${value} ${this.translateServices.instant('Accesses')}`;
    }
    if (value === 1) {
      return `${value} ${this.translateServices.instant('Access')}`;
    }

    return this.translateServices.instant('No Accesses');
  };

  counter = (key, start, end, duration) => {
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const step = Math.abs(Math.floor(duration / range));
    const timer = setInterval(() => {
      current += increment;
      // this.valuePercentagem[key] = current;
      if (current === end) {
        clearInterval(timer);
      }
    }, step);
  };
}
