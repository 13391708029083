import { ApiService } from '@core/http/api.service';
import { GlobalsService } from '@services/globals.service';
import { Observable } from 'rxjs';
import { LKUser, ILKUser } from '@models/LKUser';
import * as qs from 'querystring';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ILKRole, LKRole } from '@models/LKRole';
import { ILKGroup, LKGroup } from '@models/LKGroup';
import { LKUserEvent } from '@models/LKUserEvent';
import { LKEvent, ILKEvent } from '@models/LKEvent';
import { ResultModalComponent } from '@shared/components/result-modal/result-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private globalService: GlobalsService,
    private translateService: TranslateService
  ) {}

  getUserEvent(
    userId: string,
    skip: number,
    limit: number = 30,
    order: string = 'DESC'
  ): Observable<any> {
    const params = qs.stringify({
      skip,
      limit,
      userId,
      order,
    });

    return this.apiService.getUserEvents(params).pipe(
      map((response) => {
        this.processUserEvents(response);
        return this.processUserEvents(response);
      })
    );
  }

  processUserEvents(response): LKUserEvent {
    const events: LKEvent[] = [];
    response.events.forEach((event: ILKEvent) => {
      events.push(new LKEvent(event));
    });
    return new LKUserEvent({ events, count: response.count });
  }

  getUserRolesList(id) {
    return this.apiService
      .getUserRoles(
        qs.stringify({ id, siteId: this.globalService.getSiteId() })
      )
      .pipe(map((response) => this.processUserRolesList(response)));
  }

  getUserAccessGroupsList(id) {
    return this.apiService
      .getUserAccessGroups(
        qs.stringify({ id, siteId: this.globalService.getSiteId() })
      )
      .pipe(map((response) => this.processUserAccessGroupsList(response)));
  }

  getUsersFromCorp = (idCorp): Observable<any> => {
    const skip = 0;
    return this.getUserList(idCorp, skip);
  };

  getPersonalInfo = (userId, { data }): Observable<any> => {
    return this.apiService.getPersonalInfo(
      qs.stringify({
        userId,
        ...data,
      })
    );
  };

  getUsers = (
    skip,
    nameOrder?,
    corpAccessLevelOrder?,
    limit = 200
  ): Observable<any> => {
    return this.getUserList(
      this.globalService.getCorporationId(),
      skip,
      limit,
      nameOrder || 'ASC',
      corpAccessLevelOrder
    );
  };

  getUserList(
    corpId,
    skip,
    limit = 200,
    nameOrder = 'ASC',
    corpAccessLevelOrder = ''
  ) {
    return this.apiService
      .getUserOnCorp(
        qs.stringify({
          corpId,
          skip,
          limit,
          nameOrder,
          maskedValues: true,
          corpAccessLevelOrder,
        })
      )
      .pipe(map((response) => this.processUserList(response)));
  }

  changeUserStatus = (userList: string, status: boolean): Observable<any> => {
    return this.apiService.changeUserStatus(
      qs.stringify({
        corpId: this.globalService.getCorporationId(),
        userList,
        status,
      })
    );
  };

  updateUser = (user: any): Observable<any> => {
    return this.apiService.updateLKUser(
      qs.stringify({
        ...user,
      })
    );
  };

  updateUserPicture = (user: any): Observable<any> => {
    return this.apiService.updateLKUser(
      qs.stringify({
        ...user,
      })
    );
  };

  processUserList(response: { count: number; users: ILKUser[] }): {
    count: number;
    users: LKUser[];
  } {
    const users: LKUser[] = [];
    response.users.forEach((responseUser: ILKUser) => {
      const user = new LKUser(responseUser);
      users.push(user);
    });

    return { ...response, users };
  }

  processUserRolesList(response: { results: ILKRole[] }): LKRole[] {
    const roles: LKRole[] = [];
    response.results.forEach((responseRole: ILKRole) => {
      const role = new LKRole(responseRole);
      roles.push(role);
    });
    return roles;
  }

  processUserAccessGroupsList(response: { results: ILKGroup[] }): LKGroup[] {
    const groups: LKGroup[] = [];
    response.results.forEach((responseGroup: ILKGroup) => {
      const group = new LKGroup(responseGroup);
      groups.push(group);
    });
    return groups;
  }

  blockedAlert(ids: string[]) {
    let title;
    this.changeUserStatus(ids.join(','), false).subscribe(
      () => {
        if (ids.length === 1) {
          title = this.translateService.instant(`User Successfully Blocked!`);
        } else {
          title = this.translateService.instant(`Users Successfully Blocked!`);
        }
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '250px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: true },
        };
        this.dialog.open(ResultModalComponent, config);
      },
      (error) => {
        if (ids.length === 1) {
          title = this.translateService.instant(`Failed to Block User`);
        } else {
          title = this.translateService.instant(`Failed to Block Users`);
        }
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '250px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, description: error.message, status: false },
        };
        this.dialog.open(ResultModalComponent, config);
      }
    );
  }

  activateAlert(ids: string[]) {
    let title;

    this.changeUserStatus(ids.join(','), true).subscribe(
      () => {
        if (ids.length === 1) {
          title = this.translateService.instant(`User Successfully Activated!`);
        } else {
          title = this.translateService.instant(
            `Users Successfully Activated!`
          );
        }
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '250px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: true },
        };
        this.dialog.open(ResultModalComponent, config);
      },
      (error) => {
        if (ids.length === 1) {
          title = this.translateService.instant(`Failed to Activate user`);
        } else {
          title = this.translateService.instant(`Failed to Activate users`);
        }
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '250px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, description: error.message, status: false },
        };
        this.dialog.open(ResultModalComponent, config);
      }
    );
  }

  generateNewPassCodeOrQrcode(id: number, accessType: string): Observable<any> {
    return this.apiService.generatePassCodeOrQrcode(
      qs.stringify({
        id,
        accessType,
      })
    );
  }

  sendPassCodeOrQrcode(id: number, accessType: string): Observable<any> {
    return this.apiService.sendPassCodeOrQrcode(
      qs.stringify({
        id,
        accessType,
      })
    );
  }

  getPermissionsSites(userId: number): Observable<any> {
    return this.apiService.getPermissionsSites(
      qs.stringify({
        userId,
      })
    );
  }
}
