import * as qs from 'querystring';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/http/api.service';
import { GlobalsService } from '@services/globals.service';
import { LKRole, ILKRole } from '@models/LKRole';
import { Observable } from 'rxjs';
import { LKEventUser, ILKEventUser } from '@models/LKEventUser';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Output, Injectable } from '@angular/core';
import { ILKUser, LKUser } from '@models/LKUser';
import { LoggingService } from '@services/logging.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserSessionService } from '../../core/services/user.session.service';

@Injectable()
export class RolesService {
  @Output() roleUsersUpdated = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private globalsService: GlobalsService,
    private translateService: TranslateService,
    private loggingService: LoggingService,
    private sessionManager: UserSessionService
  ) {}

  getRolesList(): Observable<any[]> {
    return this.apiService
      .getSiteRoles(
        qs.stringify({
          siteId: this.globalsService.getSiteId(),
          corpId: this.globalsService.getCorporationId(),
        })
      )
      .pipe(map((response) => this.processRolesList(response)));
  }

  getExternalRoleInfo(roleId: string, siteId: string): Observable<any> {
    const body = qs.stringify({ roleId, siteId });
    return this.apiService.getExternalRoleInfo(body);
  }

  getConnectRoleLink(roleId: string): Observable<any> {
    return this.apiService.getConnectRoleLink(
      qs.stringify({
        roleId,
      })
    );
  }

  getExternalRoles(query): Observable<any[]> {
    return this.apiService
      .getExternalRoles(
        qs.stringify({
          siteId: this.globalsService.getSiteId(),
          query,
        })
      )
      .pipe(map((response) => this.processRolesList(response)));
  }

  getRoleUsers(roleId): Observable<any[]> {
    return this.apiService
      .getRoleUsers(
        qs.stringify({
          siteId: this.globalsService.getSiteId(),
          maskedValues: true,
          roleId,
        })
      )
      .pipe(map((response) => this.proccessRoleUsersList(response)));
  }

  createNewRole(
    role: any,
    success: (response: any) => void,
    failed: (error: any) => void
  ) {
    const body = qs.stringify(role);
    return this.apiService.createRole(body).subscribe(
      (response) => {
        success(response);
      },
      (error) => {
        failed(error);
      }
    );
  }

  connectRole(
    connectRoleLink: string,
    externalSiteId: string
  ): Observable<LKRole[]> {
    const session = this.sessionManager.getSession();
    const headers = new HttpHeaders().append(
      'Authorization',
      session.authorization
    );
    return this.apiService.connectRole(
      headers,
      connectRoleLink,
      qs.stringify({
        externalSiteId,
      })
    );
  }

  disconnectRole(roleId: string, siteId: string) {
    return this.apiService.disconnectRole(
      qs.stringify({
        roleId,
        siteId,
      })
    );
  }

  deleteRole(rolesIds: string): Promise<Object> {
    const body = qs.stringify({
      corpId: this.globalsService.getCorporationId(),
      rolesIds,
    });
    return this.apiService.deleteRole(body).toPromise();
  }

  findUser(
    query: string,
    corpAccessLevel?,
    nameOrder = 'ASC'
  ): Observable<any> {
    return this.apiService
      .findUserOrRoleOnSite(
        qs.stringify({
          query,
          nameOrder,
          corpId: this.globalsService.getCorporationId(),
          findRoles: false,
          corpAccessLevel,
        })
      )
      .pipe(map((response) => this.processList(response.users)));
  }

  private processList(responseUsers: ILKUser[]): { users: LKUser[] } {
    const users: LKUser[] = [];

    responseUsers.forEach((responseUser: ILKUser) => {
      const user = new LKUser(responseUser);
      users.push(user);
    });

    return { users };
  }

  updateRoleUsers(
    id: number,
    userListAdd: number[],
    userListRemove: number[]
  ): Observable<any> {
    const body = qs.stringify({ userListRemove, userListAdd, id });
    return this.apiService.updateRoleUsers(body);
  }

  editRole(role: any, success: () => void, failed: (error: any) => void) {
    this.apiService.editRole(qs.stringify(role)).subscribe(
      () => {
        success();
      },
      (error) => {
        failed(error);
      }
    );
  }

  globalRole(
    roleId: number,
    success: () => void,
    failed: (error: any) => void
  ) {
    const body = qs.stringify({ roleId });
    return this.apiService.updateGlobalRole(body).subscribe(
      () => {
        success();
      },
      (error) => {
        failed(error);
      }
    );
  }

  processRolesList(response: ILKRole[]): LKRole[] {
    const roles: LKRole[] = [];
    response.forEach((roleResponse: ILKRole) => {
      const role = new LKRole(roleResponse);
      roles.push(role);
    });

    return roles;
  }

  proccessRoleUsersList(response: ILKEventUser[]): LKEventUser[] {
    const roleUsers: LKEventUser[] = [];

    response.forEach((userResponse: ILKEventUser) => {
      const user = new LKEventUser(userResponse);
      roleUsers.push(user);
    });

    return roleUsers;
  }

  deleteUsers(roleId: number, usersIdSelected: number[]) {
    this.updateRoleUsers(roleId, [], usersIdSelected).subscribe(
      () => {
        let title;
        if (usersIdSelected.length === 1) {
          title = this.translateService.instant(`User was removed!`);
        } else {
          title = this.translateService.instant(`Users were removed!`);
        }

        this.roleUsersUpdated.emit(true);

        return this.loggingService.customAlert(
          {
            title,
          },
          'success'
        );
      },
      (error) => {
        let title;
        if (usersIdSelected.length === 1) {
          title = this.translateService.instant(`Failed to remove User`);
        } else {
          title = this.translateService.instant(`Failed to remove Users`);
        }

        return this.loggingService.customAlert(
          {
            title,
            text: error.message,
          },
          'error'
        );
      }
    );
  }
}
