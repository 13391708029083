import { Component, OnInit, ViewChild } from '@angular/core';
import { ILKRole } from '@models/LKRole';
import { Router } from '@angular/router';
import { BillingService } from '@modules/billing/billing.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { InvoiceDetailsComponent } from '@modules/billing/components/invoice-details/invoice-details.component';
import { CustomMatPaginatorIntlService } from '@services/custom-mat-paginator-intl.service';
import { HelperService } from '@services/helper.manager.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public selectedbilling = [];
  public markedIds = [];
  public dataSource: MatTableDataSource<any>;
  public numberBillingShow: number = 5;
  public currentInnerWidth: number = 0;
  public currentInnerHeight: number = 0;
  public displayedColumns: string[] = [
    'checkbox',
    'id',
    'period',
    'dueDate',
    // 'status',
    'amount',
    'actions',
  ];
  public billingList = [];
  public billingSize: number = 0;
  public loadingData: boolean = false;
  public pendingLoad: Promise<boolean>;
  public sizeWidthSuitable: boolean;
  public billingConfigured: boolean = true;

  constructor(
    private router: Router,
    private billingService: BillingService,
    private titleService: Title,
    private translateService: TranslateService,
    private helperService: HelperService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadbilling();
    this.sizeWidthSuitable = window.innerWidth <= 500;
    this.currentInnerHeight = window.innerHeight;
    this.currentInnerWidth = window.innerWidth;
    this.calculatePaginator();

    this.titleService.setTitle(
      this.translateService.instant('Billing | LoopKey')
    );
  }

  getDateString(date: string, option: string): string {
    return this.helperService.getDateFromString(date, option);
  }

  calculatePaginator() {
    const table = this.currentInnerHeight * 0.6965;
    let total = this.numberBillingShow;

    if (this.currentInnerWidth > 650) {
      total = table / 48;
    } else if (this.currentInnerWidth > 550) {
      total = table / 53;
    } else {
      total = table / 74;
    }

    const totalbilling = Math.round(total);
    this.numberBillingShow = totalbilling;
  }

  compare(a, b) {
    const roleA = a.name.toUpperCase();
    const roleB = b.name.toUpperCase();
    let comparison = 0;

    if (roleA > roleB) {
      comparison = 1;
    } else if (roleA < roleB) {
      comparison = -1;
    }

    return comparison;
  }

  loadbilling() {
    this.loadingData = true;
    this.pendingLoad = this.getPromise();
    this.billingService.getBillings().subscribe(
      (billingList) => {
        this.billingList = billingList;
        this.billingSize = this.billingList.length;
        this.dataSource = new MatTableDataSource(this.billingList);
        localStorage.setItem('bookings', JSON.stringify(this.billingList));
        localStorage.setItem('count_bookings', String(this.billingSize));
        this.dataSource.filterPredicate = this.tableFilter();
        setTimeout(() => {
          // eslint-disable-next-line no-underscore-dangle
          const intl = this.paginator._intl as CustomMatPaginatorIntlService;
          intl.length = this.billingSize;
          this.paginator.pageSizeOptions = [
            5,
            10,
            25,
            50,
            this.numberBillingShow,
          ];
          this.dataSource.paginator = this.paginator;
        });
      },
      (error) => {
        if (error.error.errorCode === 138) {
          this.billingConfigured = false;
        }
      }
    );
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 5000);
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  tableFilter(): (data: ILKRole, filter: string) => boolean {
    const filterFunction = (data, filter): boolean => {
      return data.name
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(filter.toLowerCase());
    };
    return filterFunction;
  }

  openBillingDialog(billing) {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100%',
      disableClose: true,
      data: { billing },
    };
    this.dialog.open(InvoiceDetailsComponent, config);
  }
}
