import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from 'app/material.module';
import { AgmCoreModule } from '@agm/core';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faUsers, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '@modules/users/users.service';
import { environment } from 'environments/environment';
import { SitesRoutingModule } from './sites-routing.module';
import { SitesComponent } from './pages/sites/sites.component';
import { SitesService } from './sites.service';
import { UpdateSiteInfoComponent } from './components/update-site-info/update-site-info.component';
import { ConfirmPasswordComponent } from './components/confirm-password/confirm-password.component';
import { DeleteSiteComponent } from './components/delete-site/delete-site.component';

@NgModule({
  declarations: [
    SitesComponent,
    UpdateSiteInfoComponent,
    ConfirmPasswordComponent,
    DeleteSiteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SitesRoutingModule,
    SharedModule,
    MaterialModule,
    FontAwesomeModule,
    AgmCoreModule.forRoot({
      apiKey: `${environment.mapsApiKey}`,
    }),
  ],
  providers: [SitesService, UserService],
})
export class SitesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faUsers, faDoorOpen);
  }
}
