export enum LKCmdResponseType {
  SUCCESSFUL,
  SUCCESSFUL_OPEN_WARNING,
  SUCCESSFUL_TIMER,
  ERROR_SIGNATURE,
  ERROR_SIGNATURE_SYNC,
  ERROR_PERMISSION,
  ERROR_ALREADY_CONF,
  ERROR_TIME_OUT,
  ERROR_MESSAGE_LONG,
  ERROR_MESSAGE_SIZE,
  ERROR_NOT_CONFIGURED,
  ERROR_CHECKSUM,
  ERROR_COMMAND,
  ERROR_VALID_MODE,
  ERROR_VALID_DURATION,
  ERROR_ALREADY_UNLK,
  ERROR_NO_LOG,
  ERROR_LOG_NOT_FOUND,
  ERROR_DOOR_OPEN,
  ERROR_SCHED_INVALID,
  ERROR_SCHED_RUNNING,
  ERROR_SCHED_STOP,
  ERROR_CUST_ADDR,
  ERROR_CUST_ERROR,
  ERROR_CUST_SIZE,
  ERROR_GENERIC,
  ERROR_KEY_INVALID,
  ERROR_TIME_INVALID,
  ERROR_PERM_ALREADY_SET,
  ERROR_PERM_NOT_FOUND,
  ERROR_PERM_NOT_VALID,
  ERROR_DEVICE_DISAPPEARED,
  ERROR_NO_RESPONSE_RECEIVED,
  TIMEOUT,
  UNKNOWN,
  SCHEDULE_REQUEST_RESULT,
}

export const feedbackLKCmdResponse = {
  SUCCESSFUL: 'successful',
  SUCCESSFUL_OPEN_WARNING: 'successful when open but with warnings',
  SUCCESSFUL_TIMER: 'timer successfully set',
  ERROR_SIGNATURE: 'signature error',
  ERROR_SIGNATURE_SYNC: 'signature error during synchronization',
  ERROR_PERMISSION: 'error permission',
  ERROR_ALREADY_CONF: 'error because it is already config',
  ERROR_TIME_OUT: 'time out error',
  ERROR_MESSAGE_LONG: 'message too long',
  ERROR_MESSAGE_SIZE: 'message is too long',
  ERROR_NOT_CONFIGURED: 'this is not config',
  ERROR_CHECKSUM: 'checksum error',
  ERROR_COMMAND: 'error during command',
  ERROR_VALID_MODE: 'error during valid mode',
  ERROR_VALID_DURATION: 'error during validation',
  ERROR_ALREADY_UNLK: 'it is already unlock',
  ERROR_NO_LOG: 'no logs are present',
  ERROR_LOG_NOT_FOUND: 'log was not found',
  ERROR_DOOR_OPEN: 'door is open',
  ERROR_SCHED_INVALID: 'schedule is invalid',
  ERROR_SCHED_RUNNING: 'schedule is running at moment',
  ERROR_SCHED_STOP: 'schedule is stop',
  ERROR_CUST_ADDR: 'during custom address',
  ERROR_CUST_ERROR: 'custom error',
  ERROR_CUST_SIZE: 'custom size',
  ERROR_GENERIC: 'generic error',
  ERROR_KEY_INVALID: 'key invalid',
  ERROR_TIME_INVALID: 'time is invalid',
  ERROR_PERM_ALREADY_SET: 'permission is already set',
  ERROR_PERM_NOT_FOUND: 'permission was not found',
  ERROR_PERM_NOT_VALID: 'permission is not valid',
  ERROR_DEVICE_DISAPPEARED: 'device disappeared',
  ERROR_NO_RESPONSE_RECEIVED: 'no response was received',
  TIMEOUT: 'timeout',
  UNKNOWN: 'unknown',
  SCHEDULE_REQUEST_RESULT: 'schedule request result',
};

export class LKCmdResponse {
  value: LKCmdResponseType;
  successful: Boolean;

  static fromServerObject(serverObject: any): LKCmdResponse {
    const res = new LKCmdResponse();
    this.fillServerInfo(res, serverObject);
    return res;
  }

  protected static fillServerInfo(obj: LKCmdResponse, serverObject: any) {
    obj.successful = serverObject.successful as Boolean;
    obj.value = LKCmdResponseType[serverObject.value as string];
    if (obj.value === undefined) {
      obj.value = LKCmdResponseType.UNKNOWN;
    }
  }
}
