<div class="edit-corp">
    <div class="text-center">
        <h4 mat-dialog-title translate>Edit Corporation</h4>
    </div>

    <div mat-dialog-content>
        <div class="mrg-top-20">
            <mat-list class="no-pdd" [formGroup]="corpForm">
                <div class="row">
                    <span class="mrg-right-15">{{ 'Name' | translate }}</span>
                    <mat-form-field class="width-100" appearance="outline" required>
                        <input id="name" formControlName="name" matInput type="text" class="third-color" />
                        <mat-error *ngIf="f.name.errors" translate>
                            Required. Must have at least 3 characters.
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-list>
        </div>
    </div>

    <div *ngIf="widthSuitableSize" class="buttons">
        <div class="row">
            <button mat-raised-button class="edit-corp__cancel-button-mobile" (click)="cancel()">
                <span translate>Cancel</span>
            </button>
            <button mat-raised-button class="edit-corp__submit-button-mobile accent" type="submit"
                (click)="onSubmit()" [disabled]="
          !corpForm.valid
        ">
                <span translate>Save</span>
            </button>
        </div>
    </div>
    <div *ngIf="!widthSuitableSize" class="buttons-big-size-mode">
        <div class="row">
            <button mat-raised-button class="edit-corp__cancel-button" (click)="cancel()">
                <span translate>Cancel</span>
            </button>
            <button mat-raised-button class="edit-corp__submit-button accent" type="submit" (click)="onSubmit()"
                [disabled]="
          !corpForm.valid 
        ">
                <span translate>Save</span>
            </button>
        </div>
    </div>
</div>