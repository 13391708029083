import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment.prod';
import { UserSessionService } from '@services/user.session.service';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { VersionCheckService } from '@services/version-check.service';
import { LKSession, ILKSession } from '@models/LKSession';
import { LKGroup } from '@models/LKGroup';
import { FullStoryService } from '@services/fullstory-service.service';
import { ProfileService } from '@modules/profile/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public showMenu = false;
  public showSide = false;
  public userImg: string = '';
  public username: string;
  public route: string;
  public sideBarOpened = true;
  public session: any;
  public path: string[];
  public groupPath: LKGroup;
  public showButton: boolean = false;
  public showIcon: boolean = true;
  public currentUrl: string = '';

  constructor(
    private profileService: ProfileService,
    private userSessionService: UserSessionService,
    private router: Router,
    private translateService: TranslateService,
    private cookiesManagerService: CookiesManagerService,
    private cookieService: CookieService,
    private versionCheckService: VersionCheckService,
    private fullStoryService: FullStoryService
  ) {
    this.session = this.userSessionService.getSession();
    this.translateService.use(this.cookiesManagerService.getLanguage());
    this.fullStoryService.init();
  }

  ngOnInit() {
    if (this.session) {
      localStorage.setItem(
        'userName',
        `${this.session.user.name} ${this.session.user.surname}`
      );
      localStorage.setItem('userId', this.session.user.id.toString());
    }
    this.versionCheckService.initVersionCheck(environment.versionCheckUrl);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url === '' ||
          event.url.includes('authentication') ||
          !this.userSessionService.getSession()
        ) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
          this.sideBarOpened =
            !event.url.includes('corporations') &&
            event.url !== '/sites' &&
            window.innerWidth >= 500;
          this.showSide =
            !event.url.includes('corporations') && event.url !== '/sites';
          if (window.innerWidth < 500) {
            if (event.url) {
              this.currentUrl = event.url;
            }

            if (
              !this.currentUrl.includes('authentication') &&
              this.currentUrl !== '/sites' &&
              this.currentUrl !== '/corporations'
            ) {
              this.showButton = true;
              this.showIcon = true;
            } else {
              this.showButton = false;
            }
          }
          this.path = this.getBreadcrumbPath(event.url);
        }
      }
    });
    this.userSessionService.userSessionChangedEmitter.subscribe(
      (session: ILKSession) => {
        if (session) {
          this.session = session;
          this.setUser(new LKSession(this.session));
        }
      }
    );
    if (this.session) {
      this.setUser(new LKSession(this.session));
    }
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  setUser(session: LKSession) {
    this.username = session.user.name;
    this.profileService.getUser().subscribe((response) => {
      this.userImg = response.picture ? response.picture : '';
    });
  }

  getBreadcrumbPath = (url: string): string[] => {
    let path = [];

    if (url === '/sites') {
      const { name } = JSON.parse(localStorage.getItem('corporation'));
      path = [name];
    } else if (!url.includes('corporations')) {
      const { name } = JSON.parse(localStorage.getItem('corporation'));
      const { name: siteName } = JSON.parse(localStorage.getItem('site'));
      path = [name, '/', siteName, '/'];

      if (url.includes('permissions')) {
        path = [...path, 'Devices', '/', url.split('/')[2], '/', 'Permissions'];
      } else if (url.includes('general-dashboard')) {
        return [name, '/', siteName, '/', 'Dashboard'];
      } else if (url.includes('dashboard')) {
        path = [...path, 'Devices', '/'];
        this.getPathDevices(parseInt(url.split('/')[2], 10), path);
      } else if (url.includes('maintenance')) {
        path = [...path, 'Maintenance'];
      } else if (url.includes('groups')) {
        path = [...path, 'Groups'];
        if (url.split('/')[2]) {
          path = [...path, '/'];
          this.getPathGroup(parseInt(url.split('/')[2], 10), path);
        }
      } else if (url.includes('users')) {
        path = [...path, 'Users'];
        if (url.split('/')[2]) {
          path = [...path, '/'];
          this.getPathUser(parseInt(url.split('/')[2], 10), path);
        }
      } else if (url.includes('devices')) {
        path = [...path, 'Devices'];
      } else if (url.includes('feedback')) {
        path = [...path, 'Feedback'];
      } else if (url.includes('bookings')) {
        path = [...path, 'Bookings'];
      } else if (url.includes('profile')) {
        path = [...path, 'Profile'];
      } else if (url.includes('roles')) {
        path = [...path, 'Roles'];
        if (url.split('/')[2]) {
          path = [...path, '/'];
          this.getPathRole(parseInt(url.split('/')[2], 10), path);
        }
      } else if (url.includes('integrations')) {
        path = [...path, 'Integrations'];
      } else if (url.includes('reports')) {
        path = [...path, 'Reports'];
      } else if (url.includes('billing')) {
        path = [...path, 'Billing'];
      } else if (url.includes('listings')) {
        path = [...path, 'Listings'];
      }
    }

    return path;
  };

  actionSide(drawer) {
    drawer.toggle();
    this.showIcon = !this.showIcon;
  }

  private getPathGroup(groupId: Number, path: string[]): void {
    const groupList = JSON.parse(localStorage.getItem('groups'));

    if (groupList) {
      path.push(groupList.filter((group) => group.id === groupId)[0].name);
    } else {
      path.push(String(groupId));
    }
  }

  private getPathUser(userId: Number, path: string[]): void {
    const userList = JSON.parse(localStorage.getItem('users'));

    if (userList) {
      path.push(userList.filter((user) => user.id === userId)[0].name);
    } else {
      path.push(String(userId));
    }
  }

  private getPathRole(roleId: Number, path: string[]): void {
    const rolesList = JSON.parse(localStorage.getItem('roles'));

    if (rolesList) {
      path.push(rolesList.filter((role) => role.id === roleId)[0].name);
    } else {
      path.push(String(roleId));
    }
  }

  private getPathDevices(deviceId: Number, path: string[]): void {
    const deviceList = JSON.parse(localStorage.getItem('devices'));

    if (deviceList) {
      path.push(deviceList.filter((device) => device.id === deviceId)[0].name);
    } else {
      path.push(String(deviceId));
    }
  }
}
