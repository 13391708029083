import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { HelperService } from '@services/helper.manager.service';

@Component({
  selector: 'app-date-booking',
  templateUrl: './date-booking.component.html',
  styleUrls: ['./date-booking.component.scss'],
})
export class DateBookingComponent implements OnInit {
  @Input() data: any;
  @Output() previousItem = new EventEmitter<{}>();
  @Output() bookingInfo = new EventEmitter<{
    startDateTime: Date;
    endDateTime: Date;
  }>();
  @Output() stepIndex = new EventEmitter<any>();
  public bookingForm: UntypedFormGroup;
  public widthSuitableSize = false;
  public startDateTime;
  public endDateTime;
  public faTimes = faTimes;

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.widthSuitableSize = window.innerWidth <= 500;
    this.bookingForm = new UntypedFormGroup({
      startDateTime: new UntypedFormControl(new Date(this.data.startDateTime), [
        Validators.required,
      ]),
      endDateTime: new UntypedFormControl(new Date(this.data.endDateTime), [
        Validators.required,
      ]),
      startTime: new UntypedFormControl(
        this.helperService.getTime(this.data.startDateTime),
        [Validators.required]
      ),
      endTime: new UntypedFormControl(
        this.helperService.getTime(this.data.endDateTime),
        [Validators.required]
      ),
    });
  }

  get f() {
    return this.bookingForm.controls;
  }

  changeStep(stepIndex: number) {
    this.stepIndex.emit(stepIndex);
  }

  clearCheckinDate() {
    this.f.startDateTime.setValue('');
  }

  clearCheckoutDate() {
    this.f.endDateTime.setValue('');
  }

  onSubmit() {
    if (this.bookingForm.valid) {
      if (this.f.startDateTime.value && this.f.endDateTime.value) {
        this.startDateTime = this.helperService.getDateTimeString(
          this.f.startDateTime.value,
          this.f.startTime.value
        );
        this.endDateTime = this.helperService.getDateTimeString(
          this.f.endDateTime.value,
          this.f.endTime.value
        );
      }
      this.bookingInfo.emit({
        startDateTime: this.startDateTime,
        endDateTime: this.endDateTime,
      });
    }
  }
}
