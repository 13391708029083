import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-remove',
  templateUrl: './confirmation-remove.component.html',
  styleUrls: ['./confirmation-remove.component.scss'],
})
export class ConfirmationRemoveComponent {
  @Output() remove = new EventEmitter<any>();
  public sizeWidthSuitable: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public dialogRef: MatDialogRef<ConfirmationRemoveComponent>
  ) {
    this.sizeWidthSuitable = window.innerWidth <= 500;
  }
}
