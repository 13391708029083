<div class="date-booking">
  <form [formGroup]="bookingForm">
    <div class="date-booking-header">
      <h4 class="date-booking__title" translate><fa-icon *ngIf="widthSuitableSize" (click)="changeStep(0)" class="mrg-right-5" icon="arrow-left"></fa-icon>Booking Info</h4>
      <span class="date-booking__subtitle" translate>Provide the Check-in and Check-out booking information. Make sure the
        date and hour is correct. You can always come back an change it.</span>
    </div>
    <div mat-dialog-content>
      <span>{{ 'Checkin' | translate }} *</span>
      <div class="row">
        <div class="date-booking__range-date col-md-6">
          <mat-form-field class="width-100" appearance="outline">
            <input class="pdd-left-10 primary-color" matInput formControlName="startDateTime"
              (click)="pickerCheckinDate.open()" type="text" [matDatepicker]="pickerCheckinDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="pickerCheckinDate">
            </mat-datepicker-toggle>
            <mat-datepicker #pickerCheckinDate disabled="false">
            </mat-datepicker>
            <mat-error *ngIf="f.startDateTime.errors">{{
              'Required field' | translate
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="date-booking__range-time col-md-6">
          <mat-form-field class="input-time" appearance="outline">
            <input id="checkinTime" type="time" formControlName="startTime" class="allow-input"
              [ngxTimepicker]="pickerCheckinTime" [format]="24" readonly matInput />
            <button (click)="clearCheckinDate()" class="button_clear" matSuffix>
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
            <ngx-material-timepicker #pickerCheckinTime [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
            </ngx-material-timepicker>
            <ng-template #cancelBtn>
              <button mat-raised-button class="cancel-button cancel-btn-float"><span translate
                  style="color: #fff !important;">Cancel</span></button>
            </ng-template>
            <ng-template #confirmBtn>
              <button mat-raised-button class="accent"><span translate
                  style="color: #fff !important;">Set</span></button>
            </ng-template>
            <mat-error *ngIf="f.startTime.errors">{{
              'Required field' | translate
              }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <span>{{ 'Checkout' | translate }} *</span>
      <div class="row">
        <div class="date-booking__range-date col-md-6">
          <mat-form-field class="width-100" appearance="outline">
            <input matInput class="pdd-left-10 primary-color" formControlName="endDateTime"
              (click)="pickerCheckoutDate.open()" type="text" [matDatepicker]="pickerCheckoutDate" readonly />
            <mat-datepicker-toggle matSuffix [for]="pickerCheckoutDate">
            </mat-datepicker-toggle>
            <mat-datepicker #pickerCheckoutDate disabled="false">
            </mat-datepicker>
            <mat-error *ngIf="f.endDateTime.errors">{{
              'Required field' | translate
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="date-booking__range-time col-md-6">
          <mat-form-field class="input-time" appearance="outline">
            <input id="checkoutTime" type="time" formControlName="endTime" class="allow-input"
              [ngxTimepicker]="pickerCheckoutTime" [format]="24" readonly matInput />
            <button (click)="clearCheckoutDate()" class="button_clear" matSuffix>
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
            <ngx-material-timepicker #pickerCheckoutTime [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
            </ngx-material-timepicker>
            <ng-template #cancelBtn>
              <button mat-raised-button class="cancel-button cancel-btn-float"><span translate
                  style="color: #fff !important;">Cancel</span></button>
            </ng-template>
            <ng-template #confirmBtn>
              <button mat-raised-button class="accent"><span translate
                  style="color: #fff !important;">Set</span></button>
            </ng-template>
            <mat-error *ngIf="f.endTime.errors">{{
              'Required field' | translate
              }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div class="steppers">
    <app-stepper-controller class="stepper-controller" [blockButton]="bookingForm.valid ? false : true"
      isEditing="false" [actionText]="'Next'" (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>