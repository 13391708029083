import * as qs from 'querystring';
import { map } from 'rxjs/operators';
import { ILKBooking, LKBooking } from '@models/LKBooking';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalsService } from '@services/globals.service';
import { ApiService } from '../../core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class BookingsService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getBookings = (skip, limit, order?, sortBy?): Observable<any> => {
    const body = qs.stringify({
      siteId: this.globalsService.getSiteId(),
      limit,
      skip,
      order,
      sortBy,
    });
    return this.apiService.getBookings(body);
  };

  createBooking(booking: any): Observable<any> {
    return this.apiService
      .createBooking(
        qs.stringify({
          ...booking,
        })
      )
      .pipe(map((response) => this.processBooking(response)));
  }

  processBooking(response: ILKBooking): LKBooking {
    return new LKBooking(response);
  }

  proccessBookingList(response: ILKBooking[]): LKBooking[] {
    const bookings: LKBooking[] = [];

    response.forEach((bookingResponse: ILKBooking) => {
      const booking = new LKBooking(bookingResponse);
      bookings.push(booking);
    });

    return bookings;
  }

  getBackupCode = (bookingId: number): Observable<any> => {
    const body = qs.stringify({
      siteId: this.globalsService.getSiteId(),
      bookingId,
      unmask: true,
    });
    return this.apiService.getBackupCode(body);
  };

  deleteBooking(bookingId: number): Promise<Object> {
    const body = qs.stringify({
      bookingId,
    });
    return this.apiService.deleteBooking(body).toPromise();
  }

  updateBooking(booking: any): Observable<any> {
    return this.apiService
      .updateBooking(
        qs.stringify({
          ...booking,
        })
      )
      .pipe(map((response) => this.processBooking(response)));
  }

  filterNonNull(obj) {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
      if (v === Object(v)) {
        newObj[k] = this.filterNonNull(v);
      } else if (v != null) {
        newObj[k] = obj[k];
      }
    });
    return newObj;
  }

  findBooking(
    query?: string,
    bookingState?: string,
    order?: string,
    sortBy?: string,
    limit?: number,
    skip?: number
  ): Observable<any> {
    const body = qs.stringify(
      this.filterNonNull({
        query,
        bookingState,
        sortBy,
        order,
        siteId: this.globalsService.getSiteId(),
        limit,
        skip,
      })
    );
    return this.apiService.findBooking(body);
  }

  generateOfflinePasscode(bookingId: number) {
    const body = qs.stringify({
      siteId: this.globalsService.getSiteId(),
      bookingId,
    });
    return this.apiService.generateOfflinePasscode(body);
  }
}
