<div class="sidenav">

  <div class="sidenav__content">
    <span class="sidenav__title">
      {{ selectedDevice.address.toUpperCase() }}
    </span>
    <span translate class="sidenav__subtitle">Advanced Settings</span>

    <span class="sidenav__info--section" translate>General Info</span>

    <span *ngIf="selectedDevice.status === 'ONLINEGATEWAY'"
      class="right sidenav__info--label-connection sidenav__info--status-connected" translate>
      ONLINE
    </span>
    <span *ngIf="selectedDevice.status === 'NODEVICESNEARBY'"
      class="right sidenav__info--label-connection sidenav__info--status-pending" translate>
      NO DEVICES NEARBY
    </span>
    <span *ngIf="selectedDevice.status === 'OFFLINE'"
      class="right sidenav__info--label-connection sidenav__info--status-disconnected">
      OFFLINE
    </span>

    <hr />

    <div class="sidenav__info">
      <span class="attribute"> ID </span>
      <span class="sidenav__info-content"> {{ selectedDevice.id }} </span>
    </div>

    <div class="sidenav__info">
      <span class="attribute"> Serial </span>
      <span class="sidenav__info-content">
        {{ selectedDevice.name }}
      </span>
    </div>

    <div class="sidenav__info">
      <span class="attribute" translate> Device </span>
      <span class="sidenav__info-content">
        {{ "Gateway" }}
      </span>
    </div>

    <div class="sidenav__info">
      <span class="attribute"> Firmware </span>
      <span class="sidenav__info-content">
        {{ selectedDevice.version }}
      </span>
    </div>

    <span *ngIf="deviceList.lenght > 0" class="display-block sidenav__info--section" translate>Devices List</span>

    <hr *ngIf="deviceList.lenght > 0" />

    <span class="display-block sidenav__info--section" *ngIf="selectedDevice.status !== 'OFFLINE'" translate>Available
      Devices</span>

    <hr *ngIf="selectedDevice.status !== 'OFFLINE'">

    <div *ngIf="deviceList.length === 0 && selectedDevice.status !== 'OFFLINE'" class="no-devices">
      <span translate>No devices available</span>
    </div>

    <div class="sidenav__device-list" *ngFor="let device of deviceList">
      <div class="row sidenav__device-content">
        <div class="sidenav__device-info">
          <div class="sidenav__info">
            <span class="device-attribute name">{{ device.name }}</span>
          </div>
          <div class="sidenav__info">
            <span class="device-attribute">ID {{ device.id }}</span>
          </div>
        </div>
        <div class="sidenav__device-signal">
          <div>
            <span class="bold green" translate *ngIf="device.rssi >= -70">Strong</span>
            <span class="bold yellow" translate *ngIf="-70 > device.rssi && device.rssi > -80">Medium</span>
            <span class="bold red" translate *ngIf="-80 >= device.rssi">Weak</span>
            <fa-icon icon="wifi"
              [ngClass]="{'red': -80 >= device.rssi, 'yellow': -70 > device.rssi && device.rssi > -80, 'green': device.rssi >= -70}">
            </fa-icon>
          </div>
          <div>
            <span class="device-rssi">{{ device.rssi }} dBm</span>
          </div>
        </div>
        <div class="sidenav__device-actions">
          <button mat-icon-button class="sidenav__btn-action" [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()">
            <fa-icon icon="ellipsis-vertical"></fa-icon>
          </button>
        </div>
      </div>
      <mat-menu xPosition="before" #menu="matMenu">
        <p class="title_section" translate>Actions</p>
        <button (click)="unlockDevices(device)" mat-menu-item>
          <span translate>Unlock</span>
        </button>
        <div *ngIf="device.serial.startsWith('TLK')" [matTooltip]="
            'Function not supported for this device.' | translate
          ">
          <button disabled mat-menu-item>
            <span translate>Set Timer</span>
          </button>
        </div>
        <button *ngIf="!device.serial.startsWith('TLK')" (click)="openDialog(device, 'timer')" mat-menu-item>
          <span translate>Set Timer</span>
        </button>
        <p class="title_section" translate>Management</p>
        <div *ngIf="device.serial.startsWith('TLK')" [matTooltip]="
            'Function not supported for this device.' | translate
          ">
          <button disabled mat-menu-item>
            <span translate>Restart</span>
          </button>
        </div>
        <button *ngIf="!device.serial.startsWith('TLK')" (click)="restartDevice(device)" mat-menu-item>
          <span translate>Restart</span>
        </button>
        <button mat-menu-item (click)="forceSync($event, device)">
          <span translate>Force Sync</span>
        </button>
        <div *ngIf="device.serial.startsWith('TLK')" [matTooltip]="
            'Function not supported for this device.' | translate
          ">
          <button disabled mat-menu-item>
            <span translate>Update Timezone</span>
          </button>
        </div>
        <button *ngIf="!device.serial.startsWith('TLK')" (click)="updateTimezone(device)" mat-menu-item>
          <span class="pdd-right-25" translate>Update Timezone</span>
        </button>
        <button (click)="updateTime(device)" mat-menu-item>
          <span class="pdd-right-25" translate>Update Time</span>
        </button>
        <button (click)="readFirmwareVersion(device)" mat-menu-item>
          <span translate>Firmware Version</span>
        </button>
        <button (click)="readBatteryState(device)" mat-menu-item>
          <span translate>Battery Status</span>
        </button>
      </mat-menu>
    </div>

    <span *ngIf="selectedDevice.lastOnline" class="display-block sidenav__info--section" translate>Last Time
      Online</span>

    <hr *ngIf="selectedDevice.lastOnline" />


    <div *ngIf="selectedDevice.lastOnline" class="sidenav__info">
      <span class="attribute" translate>Day</span>
      <span class="sidenav__info-content"> {{ getDateAndHour(selectedDevice.lastOnline, "date") }} </span>
    </div>

    <div *ngIf="selectedDevice.lastOnline" class="sidenav__info">
      <span class="attribute" translate>Hour</span>
      <span class="sidenav__info-content"> {{ getDateAndHour(selectedDevice.lastOnline, "hour") }} </span>
    </div>

    <span class="display-block sidenav__info--section" translate>
      Edit Information
    </span>

    <hr />

    <div>
      <span translate>Address</span>
      <mat-form-field appearance="outline" class="width-100">
        <input matInput value="updatedGateway.address" [(ngModel)]="updatedGateway.address" />
      </mat-form-field>
    </div>

    <div class="element">
      <button class="sidenav__update-btn" (click)="updateGateway()" [disabled]="!verifyAddressUpdate()">
        <span class="display-block update-label" translate>Save New Address</span>
      </button>
    </div>

    <span class="display-block sidenav__info--section mrg-btm-10" translate>
      Actions
    </span>

    <hr />

    <div class="sidenav__btn-container">

      <div class="element" *ngIf="selectedDevice.type === 'Gateways'">
        <button aria-label="updateTime" [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_gateway' : 'disable_actions'" mat-mini-fab
          (click)="sendCommand('reboot')">
          <fa-icon icon="power-off"></fa-icon>
        </button>
        <span translate>Reboot</span>
      </div>

      <div class="element" *ngIf="selectedDevice.type === 'Gateways'">
        <button aria-label="updateTime" [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_gateway' : 'disable_actions'" mat-mini-fab
          (click)="sendCommand('version')">
          <fa-icon icon="microchip"></fa-icon>
        </button>
        <span translate>Firmware</span>
      </div>

    </div>
  </div>