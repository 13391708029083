import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ProfileService } from '@modules/profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { SitesService } from '@modules/sites/sites.service';
import { ResultModalComponent } from '@shared/components/result-modal/result-modal.component';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss'],
})
export class ConfirmPasswordComponent implements OnInit {
  @Output() removedSite = new EventEmitter<any>();
  public currentPassword: string = '';
  public newPassword: string = '';
  public showCurrentPassword: boolean = false;
  public editing: boolean = true;
  public passwordColor = 'danger';
  public strenghtValue = 0;
  public feedback: string = '';
  public validPassword = false;
  public loadingData: boolean = false;
  public pendingLoad: Promise<boolean>;
  public userInfoSession: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private profileService: ProfileService,
    private translateService: TranslateService,
    private sitesService: SitesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmPasswordComponent>
  ) {}

  ngOnInit() {
    this.profileService.getUser().subscribe((response) => {
      this.userInfoSession = response;
    });
  }

  getType(): string {
    return this.showCurrentPassword ? 'text' : 'password';
  }

  removeSite(): void {
    this.getPromise();
    this.loadingData = true;
    this.sitesService
      .removeSite(this.data.site.id, this.currentPassword)
      .then(() => {
        this.removedSite.emit(true);
        let title = '';
        title = this.translateService.instant(`Site Successfully Deleted!`);
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '270px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: true },
        };
        this.dialog.open(ResultModalComponent, config);
      })
      .catch(() => {
        let title = '';
        title = this.translateService.instant(`Failed to Delete Site!`);
        const config = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '270px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: false },
        };
        this.dialog.open(ResultModalComponent, config);
      });
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 5000);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
