import { Component } from '@angular/core';

@Component({
  selector: 'app-range-daily-usage',
  templateUrl: './range-daily-usage.component.html',
  styleUrls: ['./range-daily-usage.component.scss'],
})
export class RangeDailyUsageComponent {
  public startDate = new Date(new Date().setDate(new Date().getDate() - 30));
  public endDate = new Date();
  public thirdyDaysAgo = new Date(
    new Date().setDate(new Date().getDate() - 30)
  );
  public currentDate = new Date();
  public widthSizeSuitable: boolean = false;

  constructor() {
    this.widthSizeSuitable = window.innerWidth <= 500;
  }

  changeEndDate() {
    const { endDate } = this;
    this.thirdyDaysAgo = new Date(
      new Date(endDate).setDate(new Date(endDate).getDate() - 30)
    );
  }
}
