import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CorporationModule } from '@modules/corporation/corporation.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MaterialModule } from 'app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { GeneralDashboardRoutingModule } from './general-dashboard-routing.module';
import { GeneralDashboardComponent } from './pages/general-dashboard/general-dashboard.component';

@NgModule({
  declarations: [GeneralDashboardComponent],
  imports: [
    CommonModule,
    GeneralDashboardRoutingModule,
    CorporationModule,
    NgxChartsModule,
    FontAwesomeModule,
    ScrollingModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
  ],
})
export class GeneralDashboardModule {}
