<div class="invoice-details">
  <div class="text-center">
    <h2 mat-dialog-title translate>
      Invoice
    </h2>
    <span translate>Bill of month</span><span> {{ getDateString(data.billing.initialPeriod, 'month') }}</span>
  </div>
  <div mat-dialog-content>
    <mat-card appearance="outlined" class="invoice-details__card-content">
      <h5 class="mrg-btm-20 bold" translate>Bill Details</h5>
      <div class="row invoice-details__info">
        <div>
          <h6 class="gray-label">ID</h6>
          <span>{{ data.billing.id }}</span>
        </div>
        <div>
          <h6 class="gray-label" translate>Period</h6>
          <span>{{ getDateString(data.billing.initialPeriod, 'month') }}</span>
        </div>
        <div>
          <h6 class="gray-label" translate>Due Date</h6>
          <span>{{ getDateString(data.billing.dueDate, null) }}</span>
        </div>
      </div>
      <div class="row invoice-details__info mrg-top-20">
        <!-- <div>
          <h6 class="gray-label" translate>Bill Status</h6>
          <span>{{ data.billing.status }}</span>
        </div> -->
        <div>
          <h6 class="gray-label" translate>Amount Due</h6>
          <span class="bold">R$ {{ data.billing.id }},00</span>
        </div>
      </div>
      <!-- <div class="invoice-details__download">
        <fa-icon icon="download" class="mrg-right-10"></fa-icon>
        <span class="bold" translate>Download Bill</span>
      </div> -->
    </mat-card>
  </div>
    <div *ngIf="!widthSizeSuitable" class="invoice-details__btn-actions">
      <div>
        <button mat-dialog-close class="btn-cancel">
          <span class="btn-label" translate>Cancel</span>
        </button>
      </div>

      <div class="no-pdd">
        <!-- <button
          class="mrg-right-5 btn-cancel"
          aria-label="cancel invite"
          mat-raised-button
        >
          <span class="label-color">{{ 'Download Fiscal File' | translate }}</span>
        </button> -->

        <button
          class="mrg-left-10"
          mat-raised-button
          class="accent"
          (click)="showInvoice(data.billing.pdf)"
        >
          <span class="label-color">{{ 'View Invoice' | translate }}</span>
        </button>
      </div>
    </div>
    <div *ngIf="widthSizeSuitable">
      <button mat-dialog-close class="btn-cancel-float">
        <span class="btn-label" translate>Cancel</span>
      </button>
    </div>
  </div>