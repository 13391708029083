<div class="delete-site">
  <div class="title-info pdd-horizon-15 mrg-btm-20">
    <h5 class="title" mat-dialog-title translate>Remove Site</h5>
    <span class="gray" translate>Are you sure you want to delete the following Site?</span>
    <h6 *ngIf="data.site" class="bold gray" translate>{{data.site.name}}</h6>
  </div>
  <div class="delete-site__wrapper-content" mat-dialog-content>
    <mat-card appearance="outlined" class="delete-site__info centered">
      <mat-card-content>
        <h6 class="bold" translate>Site Information</h6>
        <div class="row subtitle">
          <div>
            <span class="info" translate>Devices</span><br />
            <span *ngIf="data.site">{{data.site.numberDoors}} <span translate>devices</span></span>
          </div>
          <div class="user-info">
            <span class="info" translate>Users</span><br />
            <span *ngIf="data.site">{{data.site.numberUsers}} <span translate>active users</span></span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="delete-site__info centered">
      <mat-card-content>
          <fa-icon class="warn-text mrg-right-10" [icon]="faExclamationTriangle"></fa-icon>
          <span *ngIf="data.site" translate>By removing<span> {{data.site.name}} </span><span translate>from your Corporation you'll loose all data and settings previously created.</span></span>
      </mat-card-content>
    </mat-card>
    <div class="row buttons">
      <div class="col-auto mr-auto">
        <button aria-label="cancel" mat-raised-button class="cancel-button modal-button" matDialogClose>
          <span translate>Cancel</span>
        </button>
      </div>
      <div class="col-auto">
        <button aria-label="delete" mat-raised-button class="accent modal-button"
          (click)="openConfirmPasswordDialog()" matDialogClose>
          <span translate>Continue</span>
        </button>
      </div>
    </div>
  </div>
</div>