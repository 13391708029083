<!-- <app-top-loading [loadingProp]="loadingData && !(pendingLoad | async)">
</app-top-loading>

<div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>

<app-no-connection *ngIf="pendingLoad | async" (refreshPage)="loadbilling()">
</app-no-connection> -->

<div class="billing">
  <div class="billing__container">
    <h4 translate>Billing</h4>
    <div class="billing-details row mrg-left-5 mrg-btm-10" *ngIf="billingSize !== 0">
      <div class="billing__info">
        <span class="text-muted" translate>Current bill</span><span class="text-muted">: {{ getDateString(billingList[0].initialPeriod, 'month')}}</span>
        <br>
        <span class="text-muted" translate>Due Date</span><span class="text-muted">: {{ getDateString(billingList[0].dueDate, null)}}</span>
      </div>
      <div class="billing__info-divider">
        <div class="billing__filter-divider" *ngIf="!sizeWidthSuitable"></div>
      </div>
      <div class="billing__info">
        <span class="text-muted" translate>Amount Due</span><span class="text-muted">: R$ {{ billingList[0].totalAmount }},00</span>
        <br>
        <span class="text-muted bold" style="text-decoration: underline; cursor: pointer;" (click)="openBillingDialog(billingList[0])" translate>More Details</span>
      </div>
    </div>
  <!-- <div *ngIf="billingSize > 0" class="billing__options">
    <div class="billing__filter">
      <app-search-button
        class="billing__search"
        [placeholder]="'Search for ID, Period or Status' | translate"
        (filterApplied)="applyFilter($event)"
        class="billing__options-search"
      ></app-search-button>
    </div>
    <div class="billing__options__buttons">
      <button
        mat-raised-button
        color="accent"
        [disabled]="selectedbilling.length > 0"
        (click)="openNewRoleDialog()"
      >
        <span class="label-btn" translate>Download Fiscal Files</span>
      </button>
      <button
        mat-raised-button
        class="delete-button"
        [disabled]="selectedbilling.length === 0"
        (click)="deletebilling()"
      >
        <span class="label-btn" translate>Generate Boleto</span>
      </button>
    </div>
  </div> -->
  <div *ngIf="billingSize === 0; else showbilling" class="no-role centered">
    <fa-icon icon="exclamation" class="no-role icon"></fa-icon>
    <span class="no-role-warning" *ngIf="billingConfigured" translate>You don't have any billing yet</span>
    <span class="no-role-warning" *ngIf="!billingConfigured" translate>The Corporation doesn't have cnpj configured.Please call customer support.</span>
  </div>
  <ng-template #showbilling>
    <div class="billing__pagination">
      <div class="billing__table-container">
        <table mat-table [dataSource]="dataSource" matSort class="billing__table">
          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef class="billing-checkbox">
              <mat-checkbox (change)="selectAllbilling($event)"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" class="billing-checkbox">
              <mat-checkbox
                [checked]="element.isSelected"
                (click)="$event.stopPropagation()"
                (change)="select(element)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
              <span class="bold" translate>ID</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.id }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef>
              <span class="bold" translate>Period</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{ getDateString(element.initialPeriod, 'month') }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>
              <span class="bold" translate>Due Date</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{ getDateString(element.dueDate, null) }}
              </span>
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              <span class="bold" translate>Status</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>
                {{ element.status }}
              </span>
            </td>
          </ng-container> -->

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>
              <span class="bold" translate>Amount Due</span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span>
                R$ {{ element.totalAmount }},00
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <span class="bold" translate>Actions</span>
            </th>
            <td mat-cell *matCellDef="let element" class="billing__actions">
              <button
                class="billing__actions__button"
                *ngIf="!sizeWidthSuitable"
                mat-button
                (click)="
                  $event.stopPropagation();
                  selectedRole = element;
                  openBillingDialog(element)
                "
              >
                <span [ngClass]="{ hidden: selectedbilling.length > 0 }" translate
                  >More Details</span
                >
              </button>
            </td>
          </ng-container>
          <tr
            class="table-header"
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            (click)="navigate(element.id)"
            *matRowDef="let element; columns: displayedColumns"
            class="table-rows"
          ></tr>
        </table>
      </div>
      <mat-paginator
        *ngIf="!sizeWidthSuitable"
        [pageSize]="numberbillingShow"
        [pageSizeOptions]="[5, 10, 25, 50, 100]"
        class="primary-color"
        sticky
      ></mat-paginator>
    </div>
  </ng-template>
</div>