import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { GlobalsService } from '@services/globals.service';
import { HelperService } from '@services/helper.manager.service';
import { CountryISO } from 'ngx-intl-tel-input';
import { SitesService } from '@modules/sites/sites.service';
import { UserService } from '@modules/users/users.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LKUser } from '@models/LKUser';
import { RolesService } from '@modules/roles/roles.service';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-create-guest',
  templateUrl: './create-guest.component.html',
  styleUrls: ['./create-guest.component.scss'],
})
export class CreateGuestComponent implements OnInit {
  @Output() previousItem = new EventEmitter<{}>();
  @Output() createInfoSet = new EventEmitter<{
    userId: number;
    userName: string;
    userSurname: string;
    userEmail: string;
    userPhone: string;
    userCpf: string;
    userPasscode: string;
    forms: UntypedFormGroup;
    action: string;
  }>();
  @Output() editInfoSet = new EventEmitter<{
    userId: number;
    userName: string;
    userSurname: string;
    userEmail: string;
    userPhone: string;
    userCpf: string;
    userPasscode: string;
    bookingId: number;
    updatedEmail: boolean;
    updatedCpf: boolean;
    updatedCard: boolean;
    updatedPasscode: boolean;
    forms: UntypedFormGroup;
    action: string;
  }>();
  @Input() data: any;
  public guestForm: UntypedFormGroup;
  public generatePasscodeTooltip =
    'We generate a random passcode and send it directly to the guest';
  public widthSuitableSize = false;
  public sitesguest: any = [];
  public sitesSelects: any = [];
  public checkResolve: any = {};
  public updatedCard: boolean = false;
  public updatedCpf: boolean = false;
  public updatedEmail: boolean = false;
  public updatedPhone: boolean = false;
  public updatedPasscode: boolean = false;
  public selectedOption: any[] = [];
  public devices = [];
  public guestControl = new UntypedFormControl();
  public filteredOptionsObservable: Observable<any[]>;
  public filteredOptions: any[] = [];
  public newGuestName: string;
  public preferredCountries: CountryISO[] = [
    CountryISO.Brazil,
    CountryISO.UnitedStates,
  ];
  public faSearch = faSearch;
  public faEdit = faEdit;

  constructor(
    private globalsService: GlobalsService,
    private helperService: HelperService,
    private dialog: MatDialogRef<CreateGuestComponent>,
    private sitesService: SitesService,
    private userService: UserService,
    private rolesService: RolesService,
    private cookiesManagerService: CookiesManagerService
  ) {
    this.guestControl = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
  }

  ngOnInit() {
    this.widthSuitableSize = window.innerWidth <= 500;
    this.filteredOptionsObservable = this.guestControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );
    this.filteredOptionsObservable.subscribe((options) => {
      this.filteredOptions = options;
    });
    this.guestForm = new UntypedFormGroup({
      userId: new UntypedFormControl(this.data.userId, []),
      userName: new UntypedFormControl(this.data.userName, [
        Validators.required,
        Validators.minLength(3),
      ]),
      userSurname: new UntypedFormControl(this.data.userSurname, [
        Validators.required,
        Validators.minLength(3),
      ]),
      userEmail: new UntypedFormControl(this.data.userEmail, [
        Validators.email,
      ]),
      userPhone: new UntypedFormControl(this.data.userPhone, [
        this.helperService.phoneValidator,
      ]),
      userCpf: new UntypedFormControl(this.data.cpf, [
        this.helperService.validateCpf,
      ]),
      userPasscode: new UntypedFormControl(this.data.userPasscode, []),
      userCard: new UntypedFormControl(this.data.userCard, [
        this.helperService.validateCard,
      ]),
      corpId: new UntypedFormControl(
        this.globalsService.getCorporationId(),
        []
      ),
      siteId: new UntypedFormControl(this.globalsService.getSiteId(), []),
    });

    if (this.data.action === 'Edit') {
      this.guestControl.disable();
    }

    this.sitesSelects = [];

    this.verifyUserAccessLevel();

    this.sitesService.getUserSites().subscribe((response) => {
      this.sitesguest = response;

      this.sitesSelects.forEach((element) => {
        this.checkResolve[element.id] = false;
      });

      if (this.data && this.data.id) {
        this.userService.getPermissionsSites(this.data.id).subscribe((resp) => {
          this.sitesSelects = resp;

          this.sitesSelects.forEach((element) => {
            this.checkResolve[element.id] = true;
          });
        });
      }
    });

    this.sitesService.getSiteDevices().subscribe((totalOfDevices) => {
      this.devices = totalOfDevices;
    });
  }

  private verifyUserAccessLevel() {
    if (!this.data.userId && this.data.action === 'Create') {
      this.updatedCpf = true;
      this.updatedPhone = true;
    } else {
      this.updatedCpf = false;
      this.updatedPhone = false;
      this.guestForm.controls.userName.disable();
      this.guestForm.controls.userSurname.disable();
      this.guestForm.controls.userPhone.disable();
      this.guestForm.controls.userEmail.disable();
      this.guestForm.controls.userCpf.disable();
      this.guestForm.controls.userCard.disable();
      this.guestForm.controls.userPasscode.disable();
    }
  }

  private errorMessage() {
    this.guestControl.markAllAsTouched();
  }

  private filter(value: string): any[] {
    if (value && value.length > 2) {
      const filterValue = value.toLowerCase();
      const filterOptions = [];
      setTimeout(() => {
        this.rolesService.findUser(filterValue).subscribe((response) => {
          response.users.forEach((user) => {
            const hasUser = this.selectedOption.filter(
              (userSelected) =>
                user instanceof LKUser && userSelected.id === user.id
            );
            if (hasUser.length === 0) {
              filterOptions.push(user);
            }
          });

          let createGuest = {};
          this.newGuestName = value;
          if (this.cookiesManagerService.getLanguage() === 'en') {
            createGuest = `Create new guest "${value}"`;
          } else {
            createGuest = `Criar novo hóspede "${value}"`;
          }

          filterOptions.push({ createGuest });
        });
      }, 200);
      return filterOptions;
    }
    if (value && value.length < 2) {
      this.errorMessage();
    }
    return [];
  }

  private createGuest() {
    this.data.userId =
      this.selectedOption.length > 0 ? this.selectedOption[0].id : null;
    this.data.userName =
      this.selectedOption.length > 0
        ? this.selectedOption[0].name
        : this.newGuestName;
    this.data.userSurname =
      this.selectedOption.length > 0 ? this.selectedOption[0].surname : null;
    this.data.userCpf =
      this.selectedOption.length > 0 ? this.selectedOption[0].cpf : null;
    this.data.userEmail =
      this.selectedOption.length > 0 ? this.selectedOption[0].email : null;
    this.data.userPhone =
      this.selectedOption.length > 0 ? this.selectedOption[0].phone : null;
    this.data.userCard =
      this.selectedOption.length > 0 ? this.selectedOption[0].card : null;
    this.data.corpAccessLevel =
      this.selectedOption.length > 0
        ? this.selectedOption[0].corpAccessLevel
        : null;
    this.data.action = 'Create';
    this.ngOnInit();
  }

  verifyOptions(option: any) {
    const language = this.cookiesManagerService.getLanguage();
    if (option.corpAccessLevel === 'guest') {
      if (language === 'en') {
        return 'Guest:';
      }
      return 'Hóspede:';
    }
    if (language === 'en') {
      return 'User:';
    }
    return 'Usuário:';
  }

  clearForm() {
    this.guestForm.reset();
    Object.keys(this.guestForm.controls).map((value) => {
      this.guestForm.controls[value].enable();
      return this.guestForm.controls[value];
    });
  }

  addGuest(event) {
    if (
      event.source.value.createGuest &&
      (event.source.value.createGuest.includes('Create') ||
        event.source.value.createGuest.includes('Criar'))
    ) {
      this.createGuest();
    } else {
      this.selectedOption.push(event.source.value);
      this.createGuest();
    }
    this.selectedOption = [];
    this.guestControl.reset();
  }

  optionEvent(event) {
    event.stopPropagation();
  }

  enableInput(inputName) {
    this.guestForm.controls[inputName].enable();
    if (inputName === 'userEmail') {
      this.updatedEmail = true;
      this.f.userEmail.setValue('');
    }
    if (inputName === 'userCpf') {
      this.updatedCpf = true;
      this.f.userCpf.setValue('');
    }
    if (inputName === 'userCard') {
      this.updatedCard = true;
      this.f.userCard.setValue('');
    }
    if (inputName === 'userPasscode') {
      this.updatedPasscode = true;
      this.f.userPasscode.setValue('');
    }
    if (inputName === 'userPhone') {
      this.updatedPhone = true;
      this.f.userPhone.setValue('');
      this.f.userPhone.markAsTouched();
    }
  }

  handleSiteCheck(event, site) {
    event.stopPropagation();

    if (this.checkResolve[site.id] === true) {
      this.checkResolve[site.id] = false;
      this.sitesSelects = this.sitesSelects.filter(
        (currentSite) => currentSite.id !== site.id
      );
    } else {
      this.sitesSelects.push(site);
      this.checkResolve[site.id] = true;
    }
  }

  get f() {
    return this.guestForm.controls;
  }

  cancel() {
    this.dialog.close();
  }

  getPhoneErrorMessage = (): string => {
    if (this.f.userPhone.errors.phoneInvalid) {
      return 'Already exist an user with this phone.';
    }

    if (this.guestForm.controls.userPhone.invalid) {
      if (this.guestForm.controls.userPhone.touched) {
        return this.guestForm.controls.userPhone.hasError('required')
          ? 'Required field'
          : 'Not a valid phone';
      }
    }
    return '';
  };

  getPasscodeErrorMessage(): string {
    if (this.guestForm.controls.passcode.invalid) {
      if (this.guestForm.controls.passcode.touched) {
        return this.guestForm.controls.passcode.hasError('required')
          ? 'Required field'
          : 'Not a valid passcode';
      }
    }
    return '';
  }

  getCardErrorMessage(): string {
    if (this.f.userCard.errors.cardInvalid) {
      return 'Already exist an user with this card.';
    }
    if (this.f.userCard.status === 'INVALID') {
      return 'Card number is invalid.';
    }
    return '';
  }

  getEmailErrorMessage(): string {
    if (this.f.userEmail.errors.emailInvalid) {
      return 'Already exist an user with this email.';
    }
    if (this.f.userEmail.status === 'INVALID') {
      return 'A valid e-mail must be provided';
    }
    return '';
  }

  getCPFErrorMessage(): string {
    if (this.f.userCpf.errors.cpfInvalid) {
      return 'Already exist an user with this CPF.';
    }
    if (this.f.userCpf.status === 'INVALID') {
      return 'CPF is invalid.';
    }
    return '';
  }

  async validateIdentities() {
    const phone = this.f.userPhone.value
      ? new Promise((resolve) => {
          this.rolesService
            .findUser(this.f.userPhone.value.number)
            .subscribe((response) => {
              if (response.users && response.users.length > 0) {
                this.f.userPhone.setErrors({ phoneInvalid: true });
              }
              resolve(true);
            });
        })
      : null;

    const email = this.f.userEmail.value
      ? new Promise((resolve) => {
          this.rolesService
            .findUser(this.f.userEmail.value)
            .subscribe((response) => {
              if (response.users && response.users.length > 0) {
                this.f.userEmail.setErrors({ emailInvalid: true });
              }
              resolve(true);
            });
        })
      : null;

    const cpf = this.f.userCpf.value
      ? new Promise((resolve) => {
          this.rolesService
            .findUser(this.f.userCpf.value)
            .subscribe((response) => {
              if (response.users && response.users.length > 0) {
                this.f.userCpf.setErrors({ cpfInvalid: true });
              }
              resolve(true);
            });
        })
      : null;

    const card = this.f.userCard.value
      ? new Promise((resolve) => {
          this.rolesService
            .findUser(this.f.userCard.value)
            .subscribe((response) => {
              if (response.users && response.users.length > 0) {
                this.f.userCard.setErrors({ cardInvalid: true });
              }
              resolve(true);
            });
        })
      : null;

    await Promise.all([phone, email, cpf, card]);
  }

  async onSubmit() {
    await this.validateIdentities().then(() => {
      if (this.guestForm.valid) {
        if (this.f.userCard.value) {
          this.f.userCard.setValue(
            this.helperService.convertWCardToRegular(this.f.userCard.value)
          );
        }
        Object.keys(this.guestForm.value).map((value) => {
          if (
            this.guestForm.value[value] == null ||
            this.guestForm.value[value] === ''
          )
            delete this.guestForm.value[value];
          return this.guestForm.value[value];
        });
        if (this.data.action === 'Create') {
          this.createInfoSet.emit({
            userId: this.f.userId.value ? this.f.userId.value : null,
            userName: this.f.userName.value,
            userSurname: this.f.userSurname.value,
            userEmail: this.f.userEmail.value ? this.f.userEmail.value : null,
            userPhone: this.f.userPhone.value ? this.f.userPhone.value : null,
            userCpf: this.f.userCpf.value,
            userPasscode: this.f.userPasscode.value
              ? this.f.userPasscode.value
              : null,
            forms: this.guestForm.value,
            action: 'Create',
          });
        } else {
          this.editInfoSet.emit({
            userId: this.f.userId.value ? this.f.userId.value : null,
            userName: this.f.userName.value,
            userSurname: this.f.userSurname.value,
            userEmail: this.f.userEmail.value ? this.f.userEmail.value : null,
            userPhone: this.f.userPhone.value,
            userCpf: this.f.userCpf.value,
            userPasscode: this.f.userPasscode.value
              ? this.f.userPasscode.value
              : null,
            bookingId: this.data.bookingId,
            updatedEmail: this.updatedEmail,
            updatedCpf: this.updatedCpf,
            updatedCard: this.updatedCard,
            updatedPasscode: this.updatedPasscode,
            forms: this.guestForm.value,
            action: 'Edit',
          });
        }
      }
    });
  }
}
