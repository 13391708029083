<app-top-loading [loadingProp]="loading && !(pendingLoad | async)">
</app-top-loading>

<div class="centered" *ngIf="loading && !(pendingLoad | async)">
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>

<app-no-connection *ngIf="pendingLoad | async" [loadingData]="loading" (refreshPage)="loadGroups(0)">
</app-no-connection>

<div class="group-details" *ngIf="!loading && !(pendingLoad | async)">
  <div class="group-details__cards">
    <div class="infos">
      <mat-card appearance="outlined" class="info">
        <div class="title">
          <h5 class="group-title">{{ group.name }}</h5>
          <div *ngIf="!sizeWidthSuitable" class="group-details__edit">
            <span class="edit-btn-info btn-struct" (click)="editInfo()" translate>Edit Group Info</span>
          </div>
          <div class="group-details__menu" *ngIf="sizeWidthSuitable">
            <button mat-button [matMenuTriggerFor]="menu">
              <fa-icon icon="ellipsis-vertical"></fa-icon>
            </button>
            <mat-menu class="teste" #menu="matMenu">
              <button mat-menu-item (click)="editInfo()" translate>
                Edit Group Info
              </button>
              <button (click)="deleteGroup()" mat-menu-item translate>
                Delete Group
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="title_desc">
          <mat-card appearance="outlined" class="device-info">
            <span class="group-description" translate>{{ group.description }}</span>
          </mat-card>
          <span *ngIf="!sizeWidthSuitable" (click)="deleteGroup()" class="delete-btn-info btn-struct" translate>Delete
            Group</span>
        </div>
      </mat-card>
    </div>
    <div class="restrictions">
      <mat-card appearance="outlined" class="restriction">
        <div class="title marg-btm-20">
          <h5 translate>Restrictions</h5>
          <div class="group-details__edit">
            <span class="edit-group-info" (click)="editPermission()" translate>Edit</span>
          </div>
        </div>

        <div class="group-details__components">
          <div class="group-details__component methods">
            <span class="group-details__restrictions-title" translate>Access Methods</span>
            <div class="group-details__components access-methods">
              <div class="group-details__divider"></div>
              <div class="group-details__component">
                <div class="group-details__access-methods">
                  <div [ngStyle]="{
                      'padding-left': group.restrictions.smartphoneAllowed
                        ? '0px'
                        : '17px'
                    }">
                    <fa-icon class="pdd-right-5 font-size-13" [icon]="faCheckCircle"
                      *ngIf="group.restrictions.smartphoneAllowed"></fa-icon>
                    <span class="method font-size-12" [ngStyle]="{
                        opacity: group.restrictions.smartphoneAllowed ? 1 : 0.2
                      }" translate>Smartphone</span>
                  </div>
                  <div [ngStyle]="{
                      'padding-left': group.restrictions.cardAllowed
                        ? '0px'
                        : '17px'
                    }">
                    <fa-icon class="pdd-right-5 font-size-13" [icon]="faCheckCircle"
                      *ngIf="group.restrictions.cardAllowed"></fa-icon>
                    <span class="method font-size-12" [ngStyle]="{
                        opacity: group.restrictions.cardAllowed ? 1 : 0.2
                      }" translate>Access Card</span>
                  </div>
                  <div [ngStyle]="{
                      'padding-left': group.restrictions.passcodeAllowed
                        ? '0px'
                        : '17px'
                    }">
                    <fa-icon class="pdd-right-5 font-size-13" [icon]="faCheckCircle"
                      *ngIf="group.restrictions.passcodeAllowed"></fa-icon>
                    <span class="method font-size-12" [ngStyle]="{
                        opacity: group.restrictions.passcodeAllowed ? 1 : 0.2
                      }" translate>Passcode</span>
                  </div>
                </div>
              </div>
              <div class="group-details__component">
                <div class="group-details__access-methods">
                  <div [ngStyle]="{
                      'padding-left': group.restrictions.remoteAllowed
                        ? '0px'
                        : '17px'
                    }">
                    <fa-icon class="pdd-right-5 font-size-13" [icon]="faCheckCircle"
                      *ngIf="group.restrictions.remoteAllowed"></fa-icon>
                    <span class="method font-size-12" [ngStyle]="{
                        opacity: group.restrictions.remoteAllowed ? 1 : 0.2
                      }" translate>Remote Unlock</span>
                  </div>
                  <div [ngStyle]="{
                      'padding-left': group.restrictions.qrcodeAllowed
                        ? '0px'
                        : '17px'
                    }">
                    <fa-icon class="pdd-right-5 font-size-13" [icon]="faCheckCircle"
                      *ngIf="group.restrictions.qrcodeAllowed"></fa-icon>
                    <span class="method font-size-12" [ngStyle]="{
                        opacity: group.restrictions.qrcodeAllowed ? 1 : 0.2
                      }" translate>QRCode</span>
                  </div>
                  <div [ngStyle]="{
                      'padding-left': group.restrictions.offlineAllowed
                        ? '0px'
                        : '17px'
                    }">
                    <fa-icon class="pdd-right-5 font-size-10 warning" icon="exclamation-triangle"
                      *ngIf="group.restrictions.offlineAllowed"></fa-icon>
                    <span class="method font-size-12" [ngStyle]="{
                        opacity: group.restrictions.offlineAllowed ? 1 : 0.2
                      }" translate>Offline Access</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="group-details__component">
            <span class="group-details__restrictions-title" translate>
              Access Restrictions
            </span>
            <div class="group-details__components access-restrictions">
              <div class="group-details__divider"></div>
              <div class="group-details__component">
                <div class="group-details__access-restrictions">
                  <div class="group-details__day-restrictions">
                    <fa-icon class="pdd-right-5 font-size-12 color-green" icon="calendar"></fa-icon>
                    <div [ngClass]="
                    group.restrictions.daysList.indexOf('sun') !== -1
                      ? 'restriction-day'
                      : 'no-restriction-day'
                  "><span *ngIf="language === 'en'">
                        S
                      </span>
                      <span *ngIf="language === 'pt-BR'">
                        D
                      </span>
                    </div>
                    <div [ngClass]="
                    group.restrictions.daysList.indexOf('mon') !== -1
                      ? 'restriction-day'
                      : 'no-restriction-day'
                  "><span *ngIf="language === 'en'">
                        M
                      </span>
                      <span *ngIf="language === 'pt-BR'">
                        S
                      </span>
                    </div>
                    <div [ngClass]="
                    group.restrictions.daysList.indexOf('tue') !== -1
                      ? 'restriction-day'
                      : 'no-restriction-day'
                  "><span *ngIf="language === 'en'">
                        T
                      </span>
                      <span *ngIf="language === 'pt-BR'">
                        T
                      </span>
                    </div>
                    <div [ngClass]="
                    group.restrictions.daysList.indexOf('wed') !== -1
                      ? 'restriction-day'
                      : 'no-restriction-day'
                  "><span *ngIf="language === 'en'">
                        W
                      </span>
                      <span *ngIf="language === 'pt-BR'">
                        Q
                      </span>
                    </div>
                    <div [ngClass]="
                    group.restrictions.daysList.indexOf('thu') !== -1
                      ? 'restriction-day'
                      : 'no-restriction-day'
                  "><span *ngIf="language === 'en'">
                        T
                      </span>
                      <span *ngIf="language === 'pt-BR'">
                        Q
                      </span>
                    </div>
                    <div [ngClass]="
                    group.restrictions.daysList.indexOf('fri') !== -1
                      ? 'restriction-day'
                      : 'no-restriction-day'
                  "><span *ngIf="language === 'en'">
                        F
                      </span>
                      <span *ngIf="language === 'pt-BR'">
                        S
                      </span>
                    </div>
                    <div [ngClass]="
                    group.restrictions.daysList.indexOf('sat') !== -1
                      ? 'restriction-day'
                      : 'no-restriction-day'
                  "><span *ngIf="language === 'en'">
                        S
                      </span>
                      <span *ngIf="language === 'pt-BR'">
                        S
                      </span>
                    </div>
                  </div>
                  <div>
                    <div *ngIf="
                        group.restrictions.startDateTime;
                        else noDateRestriction
                      ">
                      <fa-icon class="pdd-right-5 font-size-12 color-green" [icon]="faCalendarAlt"></fa-icon>
                      <span class="method font-size-12" translate>{{
                        formatDate(group.restrictions.startDateTime, 'month')
                        }}</span>
                      <span class="method font-size-12">
                        {{ formatDate(group.restrictions.startDateTime, 'day') }}</span>
                      <span class="method font-size-12">,
                        {{
                        formatDate(group.restrictions.startDateTime, 'year')
                        }}</span>
                      <span class="method font-size-12"> - </span>
                      <span class="method font-size-12" translate>{{
                        formatDate(group.restrictions.endDateTime, 'month')
                        }}</span>
                      <span class="method font-size-12">
                        {{ formatDate(group.restrictions.endDateTime, 'day') }}
                      </span>
                      <span class="method font-size-12">,
                        {{ formatDate(group.restrictions.endDateTime, 'year') }}
                      </span>
                    </div>
                    <ng-template #noDateRestriction>
                      <fa-icon class="pdd-right-5 font-size-12 color-green" [icon]="faCalendarAlt"></fa-icon>
                      <span class="method font-size-12" translate>No date restrictions</span>
                    </ng-template>
                  </div>
                  <div>
                    <div *ngIf="
                        group.restrictions.startTimeOfDay;
                        else noTimeRestriction
                      ">
                      <fa-icon class="pdd-right-5 font-size-12 color-green" icon="clock"></fa-icon>
                      <span class="method font-size-12">
                        {{ group.restrictions.startTimeOfDay ? group.restrictions.startTimeOfDay + 'h' : '' }}
                        <span> {{ group.restrictions.endTimeOfDay && group.restrictions.endTimeOfDay ? '-' : '' }} </span>
                        {{ group.restrictions.endTimeOfDay ? group.restrictions.endTimeOfDay + 'h' : '' }}
                      </span>
                    </div>
                    <ng-template #noTimeRestriction>
                      <fa-icon class="pdd-right-5 font-size-12 color-green" icon="clock"></fa-icon>
                      <span class="method font-size-12" translate>No time restrictions</span>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="group-details__cards">
    <div class="devices">
      <mat-card appearance="outlined">
        <div class="title">
          <h5 translate>Devices</h5>
          <div class="group-details__edit">
            <span class="edit-group-info" (click)="editDevices()" translate>Edit</span>
          </div>
        </div>

        <div class="group-details__info-devices-users">
          <div>
            <div class="group-details__data">
              <span class="font-size-40 accent-color">{{
                group.doors.length
                }}</span>
              <span class="font-size-20 accent-color">/{{ totalDevices }}</span>
            </div>
            <div class="group-details__info-divider">
              <div class="group-details__filter-divider"></div>
            </div>
            <div class="description" translate>
              Devices
            </div>
          </div>
          <div>
            <div class="group-details__data">
              <span class="font-size-40 online">{{ onlineDevices.length }}</span>
              <span class="font-size-20 online">/{{ group.doors.length }}</span>
            </div>
            <div class="group-details__info-divider">
              <div class="group-details__filter-divider"></div>
            </div>
            <div class="description" translate>
              Online
            </div>
          </div>
          <div>
            <div class="group-details__data">
              <span class="font-size-40 need-attention">{{ coolOfDevices.length }}</span>
              <span class="font-size-20 need-attention">/{{ group.doors.length }}</span>
            </div>
            <div class="group-details__info-divider">
              <div class="group-details__filter-divider"></div>
            </div>
            <div class="description" translate>
              Need Attention
            </div>
          </div>
        </div>

        <mat-card appearance="outlined" class="device-list">
          <mat-chip-listbox #chipList>
            <mat-chip-option *ngFor="let door of group.doors" class="group-details__bubble-devices">
              {{ door.name }}
            </mat-chip-option>
          </mat-chip-listbox>
        </mat-card>
      </mat-card>
    </div>
    <div class="users">
      <mat-card appearance="outlined">
        <div class="title">
          <h5 translate>Users and Roles</h5>
          <div class="group-details__edit">
            <span class="edit-group-info" (click)="editUserOrRoles()" translate>Edit</span>
          </div>
        </div>

        <div class="group-details__info-devices-users">
          <div>
            <div class="group-details__data">
              <span class="font-size-40 accent-color">{{
                group.users.length
                }}</span>
              <span class="font-size-20 accent-color">/{{ totalUsers }}</span>
            </div>
            <div class="group-details__info-divider">
              <div class="group-details__filter-divider"></div>
            </div>
            <div class="description" translate>
              Users
            </div>
          </div>
          <div>
            <div class="group-details__data">
              <span class="font-size-40 accent-color">{{
                activeUsers()
                }}</span>
              <span class="font-size-20 accent-color">/{{ group.users.length }}</span>
            </div>
            <div class="group-details__info-divider">
              <div class="group-details__filter-divider"></div>
            </div>
            <div class="description" translate>
              Active Users
            </div>
          </div>
          <div>
            <div class="group-details__data">
              <span class="font-size-40 accent-color">{{
                group.roles.length
                }}</span>
              <span class="font-size-20 accent-color">/{{ totalRoles }}</span>
            </div>
            <div class="group-details__info-divider">
              <div class="group-details__filter-divider"></div>
            </div>
            <div class="description" translate>
              Roles
            </div>
          </div>
        </div>

        <mat-card appearance="outlined" class="user-list">
          <mat-chip-listbox #chipList>
            <mat-chip-option *ngFor="let userOrRole of usersAndRoles" class="group-details__bubble-users">
              <fa-icon *ngIf="userOrRole.surname === undefined" icon="users" class="pdd-right-10"></fa-icon>
              {{ userOrRole.name }}
              {{ userOrRole.surname === undefined ? '' : userOrRole.surname }}
            </mat-chip-option>
          </mat-chip-listbox>
        </mat-card>
      </mat-card>
    </div>
  </div>
</div>