<div class="text-center margin-10">
  <h4 mat-dialog-title>{{ 'UPDATE ' + data.modalType | translate }}</h4>
</div>

<div mat-dialog-content class="pdd-btm-25">
  <div class="mrg-top-30 mrg-btm-50" *ngIf="loading">
    <div>
      <mat-spinner
        diameter="40"
        color="accent"
        class="center-spinner"
      ></mat-spinner>
    </div>
  </div>

  <div *ngIf="!loading">
    <span>{{ 'Device ' + data.modalType.toLowerCase() | translate }}</span>
    <div class="width-100">
      <mat-form-field class="width-100" appearance="outline">
        <input matInput [formControl]="updateField" required />
      </mat-form-field>
      <mat-error *ngIf="updateField.invalid"
        ><span>{{ 'Required' | translate }}</span></mat-error
      >
    </div>

    <div class="row">
      <div class="col-md-6">
        <button mat-button matDialogClose>
          <span>{{ 'Cancel' | translate }}</span>
        </button>
      </div>
      <div class="col-md-6 text-right">
        <button mat-raised-button class="accent" (click)="updateDevice()">
          <span>{{ 'Update' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
