import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-toggle',
  templateUrl: './search-toggle.component.html',
  styleUrls: ['./search-toggle.component.scss'],
})
export class SearchToggleComponent implements OnInit {
  @Input() filters: any[];
  @Input() isDisabled: boolean;
  @Output() filterApplied = new EventEmitter<string>();

  ngOnInit() {}

  applyFilter(filterValue: string) {
    this.filterApplied.emit(filterValue);
  }
}
