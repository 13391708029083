import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.scss'],
})
export class NoConnectionComponent implements OnInit {
  @Output() refreshPage = new EventEmitter<any>();
  @Input() loadingData: boolean;

  ngOnInit() {}

  refresh() {
    this.refreshPage.emit();
  }
}
