import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DevicesService } from '@modules/devices/devices.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'app-remove-device',
  templateUrl: './remove-device.component.html',
  styleUrls: ['./remove-device.component.scss'],
})
export class RemoveDeviceComponent {
  @Output() deviceRemoved = new EventEmitter<any>();
  public sizeWidthSuitable: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private devicesService: DevicesService,
    public dialogRef: MatDialogRef<RemoveDeviceComponent>,
    private translateService: TranslateService,
    private loggingService: LoggingService
  ) {
    this.sizeWidthSuitable = window.innerWidth <= 500;
  }

  removeDevice() {
    this.devicesService.removeDevice(this.data.deviceId).subscribe(
      (deviceResponse) => {
        this.dialogRef.close(deviceResponse);
        this.deviceRemoved.emit(true);
        this.loggingService.customAlert(
          {
            title: this.translateService.instant(
              'Device successfully removed!'
            ),
            text: '',
          },
          'success'
        );
      },
      (error) => {
        this.deviceRemoved.emit(true);
        this.loggingService.customAlert(
          {
            title: this.translateService.instant('Error removing device!'),
            text:
              this.translateService.instant(error.error.errorDescription) || '',
          },
          'error'
        );
      }
    );
  }
}
