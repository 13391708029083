import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { LKRole } from './LKRole';
// eslint-disable-next-line import/no-cycle
import { LKGroup } from './LKGroup';
import { ILKDevice } from './LKDevice';

export interface ILKUser {
  name: string;
  surname: string;
  email: string;
  phone: string;
  active: boolean;
  phoneConfirmed: boolean;
  id: number;
  picture: string;
  fbUserID: string;
  passcodeIsActive: boolean;
  qrcodeIsActive: boolean;
  fbUserToken: string;
  authenticationMethods: string[];
  emailConfirmed: boolean;
  email_phone: string;
  identities: any;
  cpf: string;
  card: string;
  corpAccessLevel: string;
  doors: ILKDevice[];
}

export class LKUser {
  name: string;
  surname: string;
  email: string;
  phone: string;
  active: boolean;
  phoneConfirmed: boolean;
  id: number;
  picture: string;
  fbUserID: string;
  passcodeIsActive: boolean;
  qrcodeIsActive: boolean;
  fbUserToken: string;
  authenticationMethods: string[];
  emailConfirmed: boolean;
  corpAccessLevel: string;
  email_phone: string;
  identities: any;
  cpf: string;
  card: string;
  roles: LKRole[] = [];
  groups: LKGroup[] = [];

  constructor(serverObject: Partial<ILKUser>) {
    Object.assign(this, serverObject);
  }

  getPhoneFormated = () => {
    if (this.phone !== null) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(this.phone, 'BR');
      return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
    }
    return this.phone;
  };

  getMaskPhoneFormated = () => {
    if (this.phone !== null) {
      return this.phone.replace(/^(.{3})(.{2})(.*)$/, '$1 $2 $3');
    }
    return this.phone;
  };
}
