import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';
import { ILKGroupRestrictions } from '@models/LKGroupRestrictions';
import { LKDevice } from '@models/LKDevice';
import { LKUser } from '@models/LKUser';
import { LKRole } from '@models/LKRole';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit {
  @Output() groupCreated = new EventEmitter<boolean>();
  @Output() previousItem = new EventEmitter<any>();
  public groupParams = {
    name: '',
    description: '',
    doorNameList: [],
    doorList: '',
    userNameList: [],
    userList: '',
    roleNameList: [],
    roleList: '',
    siteId: '',
    restrictions: {},
  };
  public responseAccessGroup = {
    response: {},
    error: false,
  };
  public messageError = '';
  public currentStepIndex: number = 1;
  public labelStepSelected: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.labelStepSelected = this.translateService.instant('Information');
  }

  goForward = (stepper: MatStepper) => {
    stepper.selected.completed = true;
    stepper.next();
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  backStep = (stepper) => {
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  previousStep(stepper) {
    stepper.previous();
    this.backStep(stepper);
  }

  setInfo = (
    groupInfo: { name: string; description: string },
    stepper: MatStepper
  ) => {
    this.groupParams.name = groupInfo.name;
    this.groupParams.description = groupInfo.description;
    this.goForward(stepper);
  };

  setDevices = (
    devices: { info: LKDevice[]; ids: string[] },
    stepper: MatStepper
  ) => {
    this.groupParams.doorNameList = devices.info;
    this.groupParams.doorList = devices.ids.join(',');
    this.goForward(stepper);
  };

  setRestrictions = (
    restrictions: ILKGroupRestrictions,
    stepper: MatStepper
  ) => {
    this.groupParams.restrictions = restrictions;
    this.goForward(stepper);
  };

  setUsers = (
    usersAndRoles: {
      users: { info: LKUser[]; ids: string[] };
      roles: { info: LKRole[]; ids: string[] };
    },
    stepper: MatStepper
  ) => {
    this.groupParams.userNameList = usersAndRoles.users.info;
    this.groupParams.userList = usersAndRoles.users.ids.join(',');
    this.groupParams.roleNameList = usersAndRoles.roles.info;
    this.groupParams.roleList = usersAndRoles.roles.ids.join(',');
    this.goForward(stepper);
  };

  submitAccessGroup = (
    response: { response: {}; error: boolean },
    stepper: MatStepper
  ) => {
    stepper.steps.forEach((step, index) => {
      if (!response.error) {
        if (index < 5) {
          step.editable = false;
        }
      }
    });

    this.responseAccessGroup = response;
    this.goForward(stepper);
  };

  finishEvent = () => {
    this.groupCreated.emit(true);
  };

  onChangeHeader = (event, stepper) => {
    if (event.selectedIndex === 0) {
      stepper.steps.forEach((step) => {
        step.editable = true;
      });
    }
    event.selectedStep.completed = false;
  };

  triggerClick = (event, stepper) => {
    let clickedStep: number;
    stepper.steps.forEach((step, index) => {
      if (step.label === event.target.textContent) {
        clickedStep = index;
      }
    });

    if (clickedStep > stepper.selectedIndex) {
      this.messageError = "Click on the 'Next' button to continue!";

      setTimeout(() => {
        this.messageError = '';
      }, 5000);
    }
  };
}
