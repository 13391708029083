<app-top-loading [loadingProp]="loadingData && !(pendingLoad | async)">
</app-top-loading>
<div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>
<app-no-connection *ngIf="pendingLoad | async" [loadingData]="loadingData" (refreshPage)="loadSites()">
</app-no-connection>
<div class="site" *ngIf="!loadingData && !(pendingLoad | async)">
  <div class="migrated-corp" *ngIf="corpIsDisabled">
    <fa-icon icon="triangle-exclamation" class="mrg-right-10"></fa-icon>
    <span translate class="bold">Attention:</span>&nbsp;<span translate>This site has been migrated to Vingcard Athos. All management should be made though</span>&nbsp;<a href="https://app.athos.vingcard.com" target="_blank">https://app.athos.vingcard.com</a>
  </div>

  <div class="site__label-saluation">
    <div>
      <span class="bold"> {{ userName }}, </span>
      <span translate class="bold">you are in Corporation</span>
      <span class="bold"> {{ corpName }} </span>
    </div>
    <span class="site__change-corp" *ngIf="backButton" (click)="return()" translate>Change Corporation</span>
  </div>
  <div class="site__header">
    <div class="site__description">
      <h3 translate>Choose your Site</h3>
      <span translate>Please select the site do you want to manage.</span>
    </div>
    <div class="row g-0 mrg-right-30" style="flex-wrap: initial;">
      <app-search-button [filterValue]="filterName" class="site__search mrg-right-20" [placeholder]="'Search by site name' | translate"
        (clearEvent)="clearFilter()" (filterApplied)="filterChanged($event)">
      </app-search-button>
      <div *ngIf="!sizeWidthSuitable">
        <button class="btn-guest mrg-right-10" *ngIf="!hasIntegration" mat-raised-button (click)="openGuestyIntegrationDialog()">
          <span translate>Guesty Integration</span>
        </button>
        <button class="btn-guest mrg-right-10" *ngIf="hasIntegration" mat-raised-button (click)="openUpdateGuestyIntegrationDialog()">
          <span translate>Update Guesty Listings</span>
        </button>
        <button class="right btn-site" mat-raised-button class="accent" (click)="createSite()">
          <fa-icon class="btn-label site__icon" icon="location-dot"></fa-icon>
          <span class="btn-label" translate>New Site</span>
        </button>
      </div>
    </div>
  </div>
  <div class="site__content">
    <div class="site__list">
      <div class="site__list">
        <mat-grid-list *ngIf="sites.length > 0; else noSites" [cols]="cols" [rowHeight]="rowSize + 'px'"
          (window:resize)="onResize($event)">
          <mat-grid-tile *ngFor="let site of (filteredSites.length > 0 ? filteredSites : sites); let i = index">
            <mat-card appearance="outlined" *ngIf="sites.length > i || !editModalOpen" class="site__list-item mat-elevation-z8"
              [ngClass]="corpIsDisabled ? 'site__list-item mat-elevation-z8 corp-disabled' : 'site__list-item mat-elevation-z8'"
              (click)="!corpIsDisabled && selectSite(site)">
              <mat-card-header>
                <mat-card-title mat-card-avatar>
                  <h5 class="bold">{{ site.name }}</h5>
                  <span class="site__address-name">
                    {{ site.address }}
                  </span>
                </mat-card-title>
                <div class="site-info">
                  <span class="edit-site-info" (click)="editInfo(site); openAndCloseDialog()" translate>Edit</span>
                </div>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <span class="site__info-wrapper">
                    <fa-icon class="site__icon" icon="users"></fa-icon> {{ site.numberUsers }}
                  </span>
                  <span class="mrg-left-15 site__info-wrapper">
                    <fa-icon class="site__icon" icon="door-open"></fa-icon> {{ site.numberDoors }}
                  </span>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
        <ng-template #noSites>
          <div class="site__no-sites">
            <span translate>There is no sites</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>