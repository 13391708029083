import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CustomMatPaginatorIntlService } from '@services/custom-mat-paginator-intl.service';
import { ListingsService } from '@modules/listings/listings.service';
import { LKListing } from '@models/LKListing';
import { SelectDeviceComponent } from '@shared/components/select-device/select-device.component';
import { ListingIntegrationComponent } from '@modules/listings/components/listings-integration/listings-integration';
import { DashboardService } from '@modules/dashboard/dashboard.service';
import { LoggingService } from '@services/logging.service';
import { IntegrationsService } from '@modules/integrations/integrations.service';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
})
export class ListingsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public sizeWidthSuitable: boolean = false;
  public loadingTableData: boolean = true;
  public dataSource: MatTableDataSource<any>;
  public currentInnerWidth: number = 0;
  public currentInnerHeight: number = 0;
  public numberListingsShow: number = 5;
  public displayedColumns: string[] = [
    'name',
    'idPMS',
    'devices',
    // 'integration',
    'actions',
  ];
  public language = 'default';
  public listingList: LKListing[] = [];
  public listingsSize;
  public pendingLoad: Promise<boolean>;
  public limit: number;
  public deviceName: string;
  SelectDe;

  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private loggingService: LoggingService,
    private listingService: ListingsService,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private integrationsService: IntegrationsService
  ) {}

  ngOnInit() {
    this.sizeWidthSuitable = window.innerWidth <= 500;
    this.limit = 200;
    this.loadListings();
    this.titleService.setTitle(
      this.translateService.instant('Listings | LoopKey')
    );
    this.currentInnerHeight = window.innerHeight;
    this.currentInnerWidth = window.innerWidth;
    this.calculatePaginator();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentInnerWidth = event.target.innerWidth;
    this.currentInnerHeight = event.target.innerHeight;
    this.calculatePaginator();
  }

  calculatePaginator() {
    const table = this.currentInnerHeight * 0.6965;
    let total = this.numberListingsShow;

    if (this.currentInnerWidth > 650) {
      total = table / 44;
    } else if (this.currentInnerWidth > 550) {
      total = table / 53;
    } else {
      total = table / 74;
    }

    const totalListings = Math.round(total);
    this.numberListingsShow = totalListings;
  }

  validResults(): Boolean {
    return true;
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingTableData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 30000);
    });
  }

  tableFilter(): (data: any, filter: string) => boolean {
    const filterFunction = (data, filter): boolean => {
      return (
        data.name
          .concat(' ')
          .concat(data.surname)
          .toLowerCase()
          .indexOf(filter) !== -1 ||
        (data.email !== null &&
          data.email.toLowerCase().indexOf(filter) !== -1) ||
        (data.phone !== null &&
          data.phone.toLowerCase().indexOf(filter) !== -1) ||
        (data.cpf !== null && data.cpf.toLowerCase().indexOf(filter) !== -1)
      );
    };
    this.loadingTableData = false;
    return filterFunction;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();

    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(
            a.externalConnections[0].externalListingName,
            b.externalConnections[0].externalListingName,
            isAsc
          );
        case 'id':
          return this.compare(
            a.externalConnections[0].id,
            b.externalConnections[0].id,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  showDoorName = (doorId: string): string => {
    this.dashboardService.getDeviceInfo(doorId).subscribe((device) => {
      this.deviceName = device.name;
    });
    return this.deviceName;
  };

  openDevicesDialog(element) {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      data: {
        screen: 'Listings',
        listingId: element.id,
      },
    };
    const dialogRef = this.dialog.open(SelectDeviceComponent, config);
    dialogRef.componentInstance.devicesSet.subscribe(() => {
      this.listingService
        .upsertDoors(dialogRef.componentInstance.deviceInfos.ids, element.id)
        .subscribe(
          () => {
            this.loadListings();
            dialogRef.close();
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  'Devices successfully updated!'
                ),
                text: '',
              },
              'success'
            );
          },
          (error) => {
            this.loggingService.customAlert(
              {
                title: this.translateService.instant('Error editing devices!'),
                text:
                  this.translateService.instant(error.error.errorDescription) ||
                  '',
              },
              'error'
            );
          }
        );
    });
  }

  openIntegrationDialog() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      data: {
        screen: 'Listings',
      },
    };
    const dialogRef = this.dialog.open(ListingIntegrationComponent, config);
    dialogRef.componentInstance.devicesSet.subscribe(() => {
      this.loadListings();
    });
  }

  openUpdateGuestyIntegrationDialog() {
    this.integrationsService.importListings().subscribe(() => {
      this.loggingService.defaultToast(
        'warning',
        'The process can take up to 1 minute to import all listings. All imported listings will be available after it finishes.',
        'center'
      );
    });
  }

  async loadListings() {
    this.loadingTableData = true;
    this.pendingLoad = this.getPromise();
    this.listingService.getListings(0, this.limit).subscribe((listingList) => {
      this.listingList = listingList.bookings;
      this.listingsSize = listingList.length;
      this.dataSource = new MatTableDataSource(listingList);
      localStorage.setItem('listings', JSON.stringify(this.listingList));
      localStorage.setItem('count_listings', String(this.listingsSize));
      this.dataSource.filterPredicate = this.tableFilter();
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        const intl = this.paginator._intl as CustomMatPaginatorIntlService;
        intl.length = this.listingsSize;
        this.paginator.pageSizeOptions = [
          5,
          10,
          25,
          50,
          this.numberListingsShow,
        ];
        this.dataSource.paginator = this.paginator;
      });
    });
  }
}
