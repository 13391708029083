import * as qs from 'querystring';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/http/api.service';
import { LKDevice, ILKDevice } from '@models/LKDevice';
import { GlobalsService } from '@services/globals.service';
import { HelperService } from '@services/helper.manager.service';
import { Injectable } from '@angular/core';
import { ILKGroup, LKGroup } from '@models/LKGroup';
import { ILKUser, LKUser } from '@models/LKUser';

@Injectable()
export class DevicesService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService,
    private helperService: HelperService
  ) {}

  getDevicePermissionList(id: number): Observable<any> {
    return this.apiService.getDevicePermissionList(qs.stringify({ id }));
  }

  getSiteDevices = (): Observable<LKDevice[]> => {
    return this.apiService
      .getSiteDoors(qs.stringify({ siteId: this.globalsService.getSiteId() }))
      .pipe(
        map((response: ILKDevice[]) => {
          return this.processSiteDevices(response);
        })
      );
  };

  getDevicesAccess = (): Observable<any> => {
    return this.apiService.getAccess();
  };

  findDoor(query: string): Observable<any> {
    return this.apiService
      .getSiteDoors(
        qs.stringify({
          query,
          siteId: this.globalsService.getSiteId(),
        })
      )
      .pipe(
        map((response: ILKDevice[]) => {
          return this.processSiteDevices(response);
        })
      );
  }

  addDoorToSite(doorIds: []): Observable<any> {
    return this.apiService.addDoorToSite(
      qs.stringify({
        doorIds,
        siteId: this.globalsService.getSiteId(),
      })
    );
  }

  getGroupsAndUsers(doorId: number): Observable<any> {
    return this.apiService.getGroupsAndUsers(qs.stringify({ doorId }));
  }

  findGroupsAndUsers(doorId: number, query: string): Observable<any> {
    return this.apiService
      .findUserOrRoleOnSite(
        qs.stringify({
          doorId,
          query,
        })
      )
      .pipe(
        map((response) => this.processList(response.users, response.groups))
      );
  }

  removeDevice(doorId: number) {
    return this.apiService.removeDevice(
      qs.stringify({
        doorId,
      })
    );
  }

  private processList(
    responseGroups: ILKGroup[],
    responseUsers: ILKUser[]
  ): { users: LKUser[]; groups: LKGroup[] } {
    const users: LKUser[] = [];
    const groups: LKGroup[] = [];

    responseUsers.forEach((responseUser: ILKUser) => {
      const user = new LKUser(responseUser);
      users.push(user);
    });

    responseGroups.forEach((responseGroup: ILKGroup) => {
      const group = new LKGroup(responseGroup);
      groups.push(group);
    });

    return { users, groups };
  }

  processSiteDevices = (response: ILKDevice[]): LKDevice[] => {
    const devices = [];
    response.forEach((element: ILKDevice) => {
      devices.push(new LKDevice(element));
    });
    devices.sort((a, b) => this.helperService.compare(a.name, b.name, true));

    return devices;
  };
}
