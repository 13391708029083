import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LKUser } from '@models/LKUser';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { GroupsService } from '@modules/groups/groups.service';
import { LKRole } from '@models/LKRole';
import { Router } from '@angular/router';
import { RolesService } from '@modules/roles/roles.service';
import { LKGroup } from '@models/LKGroup';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { CreateRoleComponent } from '@modules/roles/pages/create-role/create-role.component';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CreateUserComponent } from '../create-user/create-user.component';

@Component({
  selector: 'app-search-members',
  templateUrl: './search-members.component.html',
  styleUrls: ['./search-members.component.scss'],
})
export class SearchMembersComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() usersSet = new EventEmitter<{}>();
  @Input() selectedUsers: LKUser[] = [];
  @Input() editUsers: boolean = false;
  @Input() users: LKUser[] = [];
  @Input() roles: LKRole[] = [];
  @Input() group: LKGroup;
  @Input() roleSearch: boolean;
  public widthSizeSuitable: boolean = false;
  public siteUsers: any[] = [];
  public myControl = new UntypedFormControl();
  public selectedOptions: any[] = [];
  public filteredOptionsObservable: Observable<any[]>;
  public filteredOptions: any[] = [];
  public isImageLoading = false;
  public userInfos = {
    info: [],
    ids: [],
  };
  public roleInfos = {
    info: [],
    ids: [],
  };
  public faSearch = faSearch;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    public dialog: MatDialog,
    private rolesService: RolesService,
    private groupsService: GroupsService,
    private cookiesManagerService: CookiesManagerService,
    private loggingService: LoggingService,
    private translateService: TranslateService
  ) {
    this.myControl = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
  }

  ngOnInit() {
    this.widthSizeSuitable = window.innerWidth <= 500;
    this.loadUsers();
    this.filteredOptionsObservable = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );
    this.filteredOptionsObservable.subscribe((options) => {
      this.filteredOptions = options;
    });
    this.injectUsers();
    this.injectRoles();
  }

  loadUsers() {
    if (this.selectedUsers) {
      this.selectedOptions = this.selectedUsers;
    }
    setTimeout(async () => {
      this.groupsService.findUserOrRole('').subscribe(async (response) => {
        if (!this.roleSearch) {
          response.roles.forEach((role) => {
            const hasRole = this.selectedOptions.filter(
              (roleSelected) =>
                role instanceof LKRole && roleSelected.id === role.id
            );
            if (hasRole.length === 0) {
              this.siteUsers.push(role);
            }
          });
        }
        response.users.forEach((user) => {
          const hasUser = this.selectedOptions.filter(
            (userSelected) =>
              user instanceof LKUser && userSelected.id === user.id
          );
          if (hasUser.length === 0 && user.active) {
            this.siteUsers.push(user);
          }
        });
      });
    }, 200);
    if (this.siteUsers) {
      this.siteUsers = this.siteUsers.map((user) => new LKUser(user));
      this.selectedOptions.forEach((selectedOption) => {
        /* eslint-disable consistent-return */
        /* eslint-disable array-callback-return */
        this.siteUsers = this.siteUsers.filter((user) => {
          if (user.id !== selectedOption.id) {
            return user;
          }
        });
      });
    }
  }

  injectUsers() {
    this.users.map((user) => this.selectedOptions.push(new LKUser(user)));
  }

  injectRoles() {
    this.roles.map((role) => this.selectedOptions.push(new LKRole(role)));
  }

  verifyOptions(option: any) {
    const language = this.cookiesManagerService.getLanguage();

    if (option instanceof LKRole) {
      if (language === 'en') {
        if (option.isGlobal) {
          return 'Global Role:';
        }
        return 'Role:';
      }
      if (option.isGlobal) {
        return 'Papel Global:';
      }
      return 'Papel:';
    }
    if (option instanceof LKUser) {
      if (language === 'en') {
        return 'User:';
      }
      return 'Usuário:';
    }

    return '';
  }

  removeOption(option: LKUser) {
    this.siteUsers.push(option);
    this.selectedOptions = this.selectedOptions.filter((selectOption) => {
      return selectOption !== option;
    });
  }

  private clearInfos() {
    this.roleInfos.ids = [];
    this.roleInfos.info = [];
    this.userInfos.ids = [];
    this.userInfos.info = [];
  }

  onSubmit() {
    const addOrRemove = {
      id: this.group === undefined ? 0 : this.group.id,
      userListAdd: '',
      userListRemove: '',
      roleListAdd: '',
      roleListRemove: '',
    };
    const addUser = [];
    const removeUser = [];
    const addRole = [];
    const removeRole = [];

    this.clearInfos();
    this.users.forEach((user) => {
      const userSelected = this.selectedOptions.filter((u) => u.id === user.id);
      if (userSelected.length === 0) {
        removeUser.push(user.id.toString());
      }
    });
    this.roles.forEach((role) => {
      const roleSelected = this.selectedOptions.filter((r) => r.id === role.id);
      if (roleSelected.length === 0) {
        removeRole.push(role.id.toString());
      }
    });
    this.selectedOptions.forEach((userOrRole) => {
      if (userOrRole instanceof LKUser) {
        const userSelected = this.users.filter((u) => u.id === userOrRole.id);
        if (userSelected.length === 0) {
          addUser.push(userOrRole.id.toString());
        }
        this.userInfos.ids.push(userOrRole.id);
        this.userInfos.info.push(userOrRole);
      }
      if (userOrRole instanceof LKRole) {
        const roleSelected = this.roles.filter((r) => r.id === userOrRole.id);
        if (roleSelected.length === 0) {
          addRole.push(userOrRole.id.toString());
        }
        this.roleInfos.ids.push(userOrRole.id);
        this.roleInfos.info.push(userOrRole);
      }
    });

    if (this.editUsers) {
      addOrRemove.userListAdd = addUser.join(',');
      addOrRemove.userListRemove = removeUser.join(',');
      addOrRemove.roleListAdd = addRole.join(',');
      addOrRemove.roleListRemove = removeRole.join(',');
      this.groupsService.editUsersAndRoles(addOrRemove).subscribe(
        () => {
          this.usersSet.emit({
            users: this.userInfos.info,
            roles: this.roleInfos.info,
          });
          this.loggingService.defaultToast(
            'success',
            this.translateService.instant(
              'Users and Roles updated successfully!'
            ),
            'center'
          );
        },
        () => {
          this.loggingService.defaultToast(
            'error',
            this.translateService.instant('Action failed. Please, try again.'),
            'center'
          );
        }
      );
    } else if (this.router.url.includes('roles')) {
      this.usersSet.emit({ users: this.userInfos });
    } else {
      this.usersSet.emit({ users: this.userInfos, roles: this.roleInfos });
    }
  }

  createUser() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      data: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        passcode: '',
        qrcode: '',
        action: 'Create',
      },
    };
    this.dialog
      .open(CreateUserComponent, config)
      .afterClosed()
      .subscribe((response: LKUser) => {
        if (response !== undefined) {
          this.selectedOptions.push(response);
        }
      });
  }

  createRole() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      data: {
        name: '',
        action: 'Create',
      },
    };

    this.dialog
      .open(CreateRoleComponent, config)
      .afterClosed()
      .subscribe((response) => {
        if (
          response !== '' &&
          response !== undefined &&
          response instanceof LKRole
        ) {
          this.selectedOptions.push(response);
        }
      });
  }

  private errorMessage() {
    this.myControl.markAllAsTouched();
  }

  private filter(value: string): any[] {
    if (value && value.length > 2) {
      const filterValue = value.toLowerCase();
      const filterOptions = [];
      if (this.roleSearch) {
        setTimeout(() => {
          this.rolesService.findUser(filterValue).subscribe((response) => {
            response.users.forEach((user) => {
              const hasUser = this.selectedOptions.filter(
                (userSelected) =>
                  user instanceof LKUser && userSelected.id === user.id
              );
              if (hasUser.length === 0) {
                filterOptions.push(user);
              }
            });
          });
        }, 200);
      } else {
        setTimeout(async () => {
          this.groupsService
            .findUserOrRole(filterValue)
            .subscribe(async (response) => {
              response.users.forEach((user) => {
                const hasUser = this.selectedOptions.filter(
                  (userSelected) =>
                    user instanceof LKUser && userSelected.id === user.id
                );
                if (hasUser.length === 0) {
                  filterOptions.push(user);
                }
              });
              response.roles.forEach((role) => {
                const hasRole = this.selectedOptions.filter(
                  (roleSelected) =>
                    role instanceof LKRole && roleSelected.id === role.id
                );
                if (hasRole.length === 0) {
                  filterOptions.push(role);
                }
              });
            });
        }, 200);
      }

      return filterOptions;
    }
    if (value && value.length < 2) {
      this.errorMessage();
    }
    return [];
  }

  addOption(option: LKUser) {
    this.selectedOptions.push(option);
    /* eslint-disable consistent-return */
    /* eslint-disable array-callback-return */
    this.siteUsers = this.siteUsers.filter((user) => {
      if (user.id !== option.id) {
        return user !== option;
      }
    });
    this.filteredOptions = [];
  }

  addRoleOrUser(event) {
    this.selectedOptions.push(event.source.value);
    this.myControl.reset();
  }
}
