import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { LKUser } from '@models/LKUser';
import { ProfileService } from '@modules/profile/profile.service';
import { UserService } from '@modules/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';
import { UserSessionService } from '@services/user.session.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ResultModalComponent } from '../result-modal/result-modal.component';

@Component({
  selector: 'app-change-image-user',
  templateUrl: './change-image-user.component.html',
  styleUrls: ['./change-image-user.component.scss'],
})
export class ChangeImageUserComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public rotationLevel: any = [0, 1, 2, 3];
  public newPicture = '';
  public user: LKUser;
  public url = '';
  public pendingLoad: Promise<boolean>;
  public loadingData: boolean = false;
  public isImageLoading = false;
  public userImg: any;
  public session: any;
  public editing = true;
  public userEdit: LKUser;
  public faUndo = faUndo;

  constructor(
    private userService: UserService,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private userSessionService: UserSessionService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public dialogRef: MatDialogRef<ChangeImageUserComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.dialogData.page === 'profile') {
      this.getUserInfo();
    } else {
      this.user = this.dialogData.user;
    }

    this.userImg = this.dialogData.userImg;
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 15000);
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  rotateImage(rotate: string) {
    if (rotate === 'right') {
      const [firstChild, ...rest] = this.rotationLevel;
      this.rotationLevel = [...rest, firstChild];
    } else {
      this.rotationLevel = [
        this.rotationLevel[this.rotationLevel.length - 1],
        ...this.rotationLevel.slice(0, this.rotationLevel.length - 1),
      ];
    }
  }

  imageUpload() {
    this.newPicture = this.croppedImage.replace(
      /^data:image\/[a-z]+;base64,/,
      ''
    );

    this.url = this.croppedImage;

    if (this.dialogData.page === 'profile') {
      this.updateLKUserPhoto();
    } else {
      this.updateCPUserPhoto();
    }
  }

  imageCancel() {
    this.newPicture = '';
    this.croppedImage = '';
    this.rotationLevel = [0, 1, 2, 3];
    this.dialogRef.close({ success: false });
  }

  updateCPUserPhoto(): void {
    this.pendingLoad = this.getPromise();
    this.loadingData = true;

    if (
      this.user &&
      this.newPicture !== this.user.picture &&
      this.newPicture !== ''
    ) {
      this.user.picture = this.newPicture;

      const userInfo: any = {
        id: this.user.id,
        name: this.user.name,
        surname: this.user.surname,
        picture: this.user.picture,
        corpAccessLevel: this.user.corpAccessLevel,
      };

      this.userService.updateUserPicture(userInfo).subscribe(
        (resp) => {
          this.url = '';
          this.loggingService.customAlert(
            {
              title: this.translateService.instant(
                'User successfully updated!'
              ),
              text: '',
            },
            'success'
          );
          this.dialogRef.close({
            success: true,
            url: resp.lkUser.picture,
          });
        },
        (error) => {
          this.imageCancel();
          this.loggingService.customAlert(
            {
              title: this.translateService.instant('Error updating user!'),
              text: error.error.errorDescription,
            },
            'error'
          );
          this.loadingData = false;
          this.dialogRef.close({
            success: false,
          });
        }
      );
    }
  }

  getUserInfo(): void {
    this.session = this.userSessionService.getSession();
    this.user = JSON.parse(JSON.stringify(this.session.user));
    this.userEdit = JSON.parse(JSON.stringify(this.session.user));
  }

  updateLKUserPhoto(): void {
    this.pendingLoad = this.getPromise();
    this.loadingData = true;

    if (this.newPicture !== this.user.picture && this.newPicture !== '') {
      this.user.picture = this.newPicture;
      this.profileService.updateUser(
        this.user,
        (user) => {
          this.user.picture = user.picture;
          this.userEdit.picture = user.picture;
          this.session.user = user;
          this.userSessionService.saveSession(this.session);
          this.getUserInfo();
          this.editing = true;

          const title = this.translateService.instant(
            'Photo updated successfully'
          );
          const config = {
            panelClass: 'dialog-container',
            width: '450px',
            height: '270px',
            maxHeight: '100%',
            maxWidth: '100%',
            data: { text: title, status: true },
          };
          this.dialog.open(ResultModalComponent, config);
          this.loadingData = false;
          this.rotationLevel = [0, 1, 2, 3];
          this.dialogRef.close({
            success: true,
            url: user.picture,
          });
        },
        () => {
          const title = this.translateService.instant('Error updating user!');
          const config = {
            panelClass: 'dialog-container',
            width: '450px',
            height: '270px',
            maxHeight: '100%',
            maxWidth: '100%',
            data: { text: title, status: false },
          };
          this.dialog.open(ResultModalComponent, config);
          this.loadingData = false;
          this.imageCancel();
        }
      );
    }
  }
}
