import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SitesService } from '@modules/sites/sites.service';
import { LKDevice } from '@models/LKDevice';
import { LKGroup } from '@models/LKGroup';
import { GroupsService } from '@modules/groups/groups.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-group-doors',
  templateUrl: './group-doors.component.html',
  styleUrls: ['./group-doors.component.scss'],
})
export class GroupDoorsComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() devicesSet = new EventEmitter<{}>();
  @Input() editDoors: boolean = false;
  @Input() doors: LKDevice[];
  @Input() group: LKGroup;
  public devices: {
    device: LKDevice;
    selected: boolean;
    filtered: boolean;
  }[] = [];
  public filterName: string = '';
  public deviceInfos = {
    info: [],
    ids: [],
  };
  public sizeWidthSuit: boolean = false;

  constructor(
    private sitesService: SitesService,
    private groupsService: GroupsService,
    private loggingService: LoggingService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.sizeWidthSuit = window.innerWidth <= 500;
    this.sitesService.getSiteDevices().subscribe(
      (devices) => {
        devices.forEach((device) => {
          let findDoor;
          if (this.doors !== undefined) {
            findDoor = this.doors.find((door) => door.id === device.id);
          }
          this.devices.push({
            device: new LKDevice(device),
            selected: findDoor !== undefined,
            filtered: true,
          });
        });
      },
      () => {}
    );
  }

  private clearInfos() {
    this.deviceInfos.ids = [];
    this.deviceInfos.info = [];
  }

  onSubmit() {
    const addOrRemove = {
      id: this.group === undefined ? 0 : this.group.id,
      doorListAdd: '',
      doorListRemove: '',
    };
    const add = [];
    const remove = [];

    this.clearInfos();
    this.devices.forEach((dev) => {
      if (this.editDoors) {
        const door = this.doors.filter((device) => device.id === dev.device.id);
        if (door.length === 0) {
          if (dev.selected) {
            add.push(dev.device.id.toString());
          }
        } else if (!dev.selected) {
          remove.push(dev.device.id.toString());
        }
      }
      if (dev.selected) {
        this.deviceInfos.info.push(dev.device);
        this.deviceInfos.ids.push(dev.device.id.toString());
      }
    });

    if (this.editDoors) {
      addOrRemove.doorListAdd = add.join(',');
      addOrRemove.doorListRemove = remove.join(',');
      this.groupsService.editDevices(addOrRemove).subscribe(
        () => {
          this.devicesSet.emit(this.deviceInfos);
          this.loggingService.defaultToast(
            'success',
            this.translateService.instant('Devices updated successfully.'),
            'center'
          );
        },
        () => {
          this.loggingService.defaultToast(
            'error',
            this.translateService.instant('Action failed. Please, try again.'),
            'center'
          );
        }
      );
    } else {
      this.devicesSet.emit(this.deviceInfos);
    }
  }

  filterChanged(filterValue: string) {
    this.filterName = filterValue;
    this.devices = this.devices.map((el) => {
      if (el.device.name.toUpperCase().includes(filterValue.toUpperCase())) {
        el.filtered = true;
      } else {
        el.filtered = false;
      }
      return el;
    });
  }

  clearFilter() {
    this.filterName = '';
  }

  verifyDevicesSelect() {
    const selectedDevices = this.devices.find((device) => device.selected);

    return selectedDevices !== undefined;
  }

  verifyDevicesFilter() {
    const filteredDevices = this.devices.find((device) => device.filtered);

    return filteredDevices !== undefined;
  }
}
