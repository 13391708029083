import libphonenumber from 'google-libphonenumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Injectable } from '@angular/core';
import * as base32 from 'hi-base32';
import { ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class HelperService {
  phoneValidator(control: AbstractControl): ValidatorFn {
    const phone = control.value;
    if (phone !== null) {
      return (): { [key: string]: any } | null => {
        return !this.validatePhone(control.value)
          ? { phone: { value: control.value } }
          : null;
      };
    }
    return null;
  }

  validateEmail(email) {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  validateCard(control: AbstractControl) {
    const isNumber = (card) => !Number.isNaN(Number(card));
    let cardValue = control.value;
    let valid: boolean = true;

    if (cardValue !== null) {
      if (cardValue[0] === 'W' || cardValue[0] === 'w') {
        cardValue = cardValue.slice(1);
      }

      if (isNumber(cardValue) === true) {
        valid = true;
      } else {
        valid = false;
      }
    }

    if (valid) return null;

    return { InvalidCard: true };
  }

  validateCpf(control: AbstractControl) {
    const cpf = control.value;

    let sum: number = 0;
    let rest: number;
    let valid: boolean = true;

    if (cpf == null || cpf.length === 0) {
      valid = true;
    } else if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      valid = false;
    else {
      for (let i = 1; i <= 9; i++)
        // eslint-disable-next-line radix
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) rest = 0;
      // eslint-disable-next-line radix
      if (rest !== parseInt(cpf.substring(9, 10))) valid = false;

      sum = 0;
      for (let i = 1; i <= 10; i++)
        // eslint-disable-next-line radix
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) rest = 0;
      // eslint-disable-next-line radix
      if (rest !== parseInt(cpf.substring(10, 11))) valid = false;
    }
    if (valid) return null;

    return { cpfInvalid: true };
  }

  validatePhone(phone) {
    if (phone) {
      if (phone.number) {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        const parsedPhone = phoneUtil.parse(phone.number, phone.countryCode);
        if (phoneUtil.isValidNumber(parsedPhone)) {
          return true;
        }
        return false;
      }
      phone = phone[0] !== '0' ? phone : phone.substr(1);
      if (phone[0] + phone[1] !== '61') {
        phone = phone[0] === '+' ? phone : `+${phone}`;
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber) {
          if (phoneNumber.country) {
            return true;
          }
          return false;
        }
        return false;
      }
      phone = `+55${phone}`;
      const phoneNumber = parsePhoneNumberFromString(phone);
      if (phoneNumber.country) {
        return true;
      }
      return false;
    }
    return false;
  }

  /**
   * Reverses a string
   *
   * @memberof HelperService
   */
  reverseString(str) {
    return str.split('').reverse().join('');
  }

  /**
   * Converts the actual day Date object to string format  dd/mm/yyyy
   *
   * @returns {string}
   * @memberof HelperService
   */
  getTodayString(): string {
    const today: Date = new Date();
    const year = String(today.getFullYear());
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());

    if (month.length === 1) {
      month = `0${month}`;
    }
    if (day.length === 1) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  }

  getDayString(date: Date): string {
    if (date == null) {
      return null;
    }
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  getDateFromString(date: string, option: string): string {
    if (date == null) {
      return null;
    }
    const year = String(date.substring(0, 4));
    const month = String(date.substring(7, 5));
    const day = String(date.substring(11, 8));

    if (option === 'month') {
      return `${month}/${year}`;
    }
    return `${day}/${month}/${year}`;
  }

  getDaysDifference(
    startDateTime: Date,
    endDateTime: Date,
    returnType: string
  ): string {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    startDateTime.setHours(0, 0, 0, 0);
    endDateTime.setHours(0, 0, 0, 0);

    const difference = Math.abs(
      startDateTime.getTime() - endDateTime.getTime()
    );

    const daysDifference = Math.floor(difference / millisecondsPerDay);

    if (returnType === 'text') {
      if (daysDifference === 1) return 'last-hour';
      if (daysDifference === 7) return 'last-week';
      if (daysDifference === 30) return 'last-month';
      return 'custom';
    }
    return daysDifference.toString();
  }

  stringDateToDate(date: string): Date {
    const [year, month, day] = date.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  hexEncode(string) {
    let hex;
    let result = '';
    for (let i = 0; i < string.length; i++) {
      hex = string.charCodeAt(i).toString(16);
      if (string.charCodeAt(i).toString(16).length !== 2) {
        hex = `0${hex}`;
      }
      result += hex;
    }
    if (result.length % 2 !== 0) {
      result = `0${result}`;
    }
    const bytes = [];
    for (let i = 0; i < result.length; i += 2) {
      bytes.push(parseInt(result.slice(i, i + 2), 16));
    }
    return bytes;
  }

  formatSerial(serial64): string {
    return base32.encode(this.hexEncode(atob(serial64)));
  }

  base64toHEX(base64: string): string {
    const raw = atob(base64);

    let HEX = '';

    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);

      HEX += hex.length === 2 ? hex : `0${hex}`;
    }
    return HEX.toUpperCase();
  }

  hex2ascii(hexString): string {
    let str = '';

    for (
      let i = 0;
      i < hexString.length && hexString.substr(i, 2) !== '00';
      i += 2
    ) {
      str += String.fromCharCode(parseInt(hexString.substr(i, 2), 16));
    }

    return str;
  }

  hexToInt(hex): number {
    if (hex.length % 2 !== 0) {
      hex = `0${hex}`;
    }
    let num = parseInt(hex, 16);
    const maxVal = ((hex.length / 2) * 8) ** 2;
    if (num > maxVal / 2 - 1) {
      num -= maxVal;
    }
    return num;
  }

  replaceAt(string, index, replace): string {
    return string.substring(0, index) + replace + string.substring(index + 1);
  }

  getMonth(month: string): string {
    switch (month) {
      case 'Dec':
        return '12';
      case 'Nov':
        return '11';
      case 'Oct':
        return '10';
      case 'Sep':
        return '09';
      case 'Aug':
        return '08';
      case 'Jul':
        return '07';
      case 'Jun':
        return '06';
      case 'May':
        return '05';
      case 'Apr':
        return '04';
      case 'Mar':
        return '03';
      case 'Fev':
        return '02';
      default:
        return '01';
    }
  }

  contains(arr, valueOrObject): number {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].referenceValue === valueOrObject) {
        return i;
      }
    }
    return -1;
  }

  getExportInfo(period: any, startDate: Date, endDate: Date): Date[] {
    if (period === 'Custom') {
      return [startDate, endDate];
    }
    endDate = new Date();
    startDate = new Date();
    switch (period) {
      case 'Today':
        startDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          0,
          0,
          0,
          0
        );
        break;
      case 'Yesterday':
        startDate.setDate(startDate.getDate() - 1);
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          0,
          0,
          0,
          0
        );
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          23,
          59,
          59,
          999
        );
        break;
      case 'Last 24 hours':
        startDate.setDate(startDate.getDate() - 1);
        break;
      case 'Last Week':
        endDate.setDate(endDate.getDate() - endDate.getDay() - 1); // Goes back to saturday.
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59,
          999
        );
        startDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          0,
          0,
          0,
          0
        );
        startDate.setDate(startDate.getDate() - 6);
        break;
      case 'Last 7 days':
        endDate.setDate(endDate.getDate() - 1);
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59,
          999
        );
        startDate.setDate(startDate.getDate() - 7);
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          0,
          0,
          0,
          0
        );
        break;
      case 'Last Month':
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59,
          999
        );
        endDate.setDate(0);
        startDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          1,
          0,
          0,
          0,
          0
        );
        break;
      default:
        startDate = null;
        endDate = null;
        break;
    }
    return [startDate, endDate];
  }

  getDateTimeString(date: Date, time?: string): string {
    if (date == null) {
      return '';
    }
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = time
      ? time.substring(0, 2)
      : String(date.getHours()).padStart(2, '0');
    const minute = time
      ? time.substring(3, 5)
      : String(date.getMinutes()).padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}T${hour}:${minute}`;
  }

  getTime(date: Date): string {
    if (date == null) {
      return '';
    }

    date = new Date(date);
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    return `${hour}:${minute}`;
  }

  getDateString(date: Date, language: string): string {
    if (date == null) {
      return '';
    }
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if (language === 'pt-BR') {
      return `${day}/${month}/${date.getFullYear()}`;
    }

    return `${month}/${day}/${date.getFullYear()}`;
  }

  getTimeString(date: Date, language: string): string {
    if (date == null) {
      return '';
    }

    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    if (language === 'pt-BR') {
      return `${hour}:${minute}`;
    }

    const ampm = parseInt(hour, 10) >= 12 ? 'pm' : 'am';
    let hourParsed = parseInt(hour, 10) % 12;
    hourParsed = hourParsed || 12;
    const strTime = `${hourParsed}:${minute} ${ampm}`;
    return strTime;
  }

  verifyDateRestrictions(allDate: Boolean, begin: any, end: any): Boolean {
    if (!allDate) {
      if (begin && end) {
        return begin <= end;
      }
      return false;
    }
    return true;
  }

  verifyTimeRestrictions(
    allTimes: Boolean,
    beginTime: any,
    endTime: any,
    beginDate: any,
    endDate: any
  ): Boolean {
    if (!allTimes) {
      if (beginTime && endTime) {
        return (
          (beginDate === endDate && beginTime < endTime) ||
          beginDate !== endDate
        );
      }
      return false;
    }
    return true;
  }

  prettifySerial(serial: string): string {
    const first = serial[0];
    const second = serial.substr(1, 3);
    const third = serial.substr(4, 3);
    const fourth = serial.substr(7, 7);
    const fifth = serial.substr(14);
    return `${first}-${second}-${third}
      -${fourth}-${fifth}`;
  }

  compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getTodayDateFormat(language: string = 'pt-BR') {
    const today = new Date();
    const year = String(today.getFullYear());
    const month = String(today.getMonth() + 1);
    const day = String(today.getDate());

    if (language === 'pt-BR') {
      return `${parseInt(day, 10) < 10 ? `0${String(day)}` : day}/${
        parseInt(month, 10) < 10 ? `0${String(month)}` : day
      }/${year}`;
    }

    return `${parseInt(month, 10) < 10 ? `0${String(month)}` : day}/${
      parseInt(day, 10) < 10 ? `0${String(day)}` : day
    }/${year}`;
  }

  convertWCardToRegular(cardValue): string {
    if (cardValue[0] === 'W' || cardValue[0] === 'w') {
      cardValue = cardValue.replace(/\D/g, '');
      const begin = parseInt(
        cardValue.substr(0, cardValue.length - 5),
        10
      ).toString(16);
      const end = parseInt(cardValue.substr(-5), 10).toString(16);
      const final = begin.toString() + end.toString();
      return parseInt(final, 16).toString(10);
    }
    return cardValue;
  }

  getDateAsString(date) {
    date = new Date(date);
    const year = date.getFullYear();
    const month = this.capitalizeFirstLetter(
      date.toLocaleString('default', { month: 'short' })
    );
    const day = date.getDate();
    return `${month} ${day <= 9 ? `0${day}` : day}, ${year}`;
  }

  private capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
}
