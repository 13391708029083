import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupsService } from '@modules/groups/groups.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { GlobalsService } from '@services/globals.service';
import { HelperService } from '@services/helper.manager.service';
import { CountryISO } from 'ngx-intl-tel-input';
import { UserService } from '@modules/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';
import { SitesService } from '@modules/sites/sites.service';
import { faEdit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FullStoryService } from '@services/fullstory-service.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  public userForm: UntypedFormGroup;
  public generatePasscodeTooltip =
    'We generate a random passcode and send it directly to the user';
  public widthSuitableSize = false;
  public sitesUser: any = [];
  public sitesSelects: any = [];
  public checkResolve: any = {};
  public updatedCard: boolean = false;
  public updatedCpf: boolean = false;
  public updatedEmail: boolean = false;
  public updatedPhone: boolean = false;
  public faEdit = faEdit;
  public faQuestionCircle = faQuestionCircle;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private groupsService: GroupsService,
    private globalsService: GlobalsService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private loggingService: LoggingService,
    private userService: UserService,
    private dialog: MatDialogRef<CreateUserComponent>,
    private sitesService: SitesService,
    private fullStoryService: FullStoryService
  ) {
    this.userForm = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surname: new UntypedFormControl(data.surname, [
        Validators.required,
        Validators.minLength(3),
      ]),
      email: new UntypedFormControl(data.email, [Validators.email]),
      phone: new UntypedFormControl(data.phone, [
        Validators.required,
        this.helperService.phoneValidator,
      ]),
      cpf: new UntypedFormControl(data.cpf, [this.helperService.validateCpf]),
      card: new UntypedFormControl(data.cardNumber, [
        this.helperService.validateCard,
      ]),
      passcodeIsActive: new UntypedFormControl(data.passcodeIsActive, []),
      qrcodeIsActive: new UntypedFormControl(data.qrcodeIsActive, []),
      corpAccessLevel: new UntypedFormControl(data.corpAccessLevel, []),
      corpId: new UntypedFormControl(
        this.globalsService.getCorporationId(),
        []
      ),
      siteIds: new UntypedFormControl(this.globalsService.getSiteId(), []),
      allowedPersonalData: new UntypedFormControl(false, []),
      managingSiteIds: new UntypedFormControl([], []),
    });
  }
  public preferredCountries: CountryISO[] = [
    CountryISO.Brazil,
    CountryISO.UnitedStates,
  ];

  ngOnInit() {
    this.widthSuitableSize = window.innerWidth <= 500;

    if (this.data.action === 'Edit') {
      this.userForm.controls.email.disable();
      this.userForm.controls.cpf.disable();
      this.userForm.controls.card.disable();
      this.userForm.controls.phone.disable();
      this.fullStoryService.setPageVars({
        pageName: 'Edit User',
        userId: this.data.id,
      });
    } else {
      this.updatedCard = true;
      this.updatedCpf = true;
      this.updatedEmail = true;
      this.updatedPhone = true;
    }

    this.sitesService.getUserSites().subscribe((response) => {
      this.sitesUser = response;

      this.sitesUser.forEach((element) => {
        this.checkResolve[element.id] = false;
      });

      if (this.data && this.data.id) {
        this.userService.getPermissionsSites(this.data.id).subscribe((resp) => {
          this.sitesSelects = resp;
          this.sitesSelects.forEach((element) => {
            this.checkResolve[element.id] = true;
          });
        });
      }
    });
  }

  optionEvent(event) {
    this.f.corpAccessLevel.setValue('siteAdmin');
    event.stopPropagation();
  }

  enableInput(inputName) {
    this.userForm.controls[inputName].enable();
    if (inputName === 'email') {
      this.updatedEmail = true;
      this.f.email.setValue('');
    }
    if (inputName === 'cpf') {
      this.updatedCpf = true;
      this.f.cpf.setValue('');
    }
    if (inputName === 'card') {
      this.updatedCard = true;
      this.f.card.setValue('');
    }
    if (inputName === 'phone') {
      this.updatedPhone = true;
      this.f.phone.setValue('');
      this.f.phone.markAsTouched();
    }
  }

  handleSiteCheck(event, site) {
    event.stopPropagation();

    if (this.checkResolve[site.id] === true) {
      this.sitesSelects.push(site);
    } else {
      this.sitesSelects = this.sitesSelects.filter(
        (currentSite) => currentSite.id !== site.id
      );
    }
  }

  get f() {
    return this.userForm.controls;
  }

  cancel() {
    this.dialog.close();
  }

  getPhoneErrorMessage = (): string => {
    if (this.f.phone.errors.phoneError) {
      return 'Already exist a user with this phone.';
    }

    if (this.userForm.controls.phone.invalid) {
      if (this.userForm.controls.phone.touched) {
        return this.userForm.controls.phone.hasError('required')
          ? 'Required field'
          : 'Not a valid phone';
      }
    }
    return '';
  };

  getEmailErrorMessage(): string {
    if (this.f.email.errors.emailError) {
      return 'Already exist a user with this email.';
    }
    if (this.f.email.status === 'INVALID') {
      return 'A valid e-mail must be provided';
    }
    return '';
  }

  getCPFErrorMessage(): string {
    if (this.f.cpf.errors.cpfError) {
      return 'Already exist a user with this CPF.';
    }
    if (this.f.cpf.status === 'INVALID') {
      return 'CPF is invalid.';
    }
    return '';
  }

  getCardErrorMessage(): string {
    if (this.f.card.errors.cardError) {
      return 'Already exist a user with this card.';
    }
    if (this.f.card.status === 'INVALID') {
      return 'Card number is invalid.';
    }
    return '';
  }

  setError(err: any) {
    if (err.error.errorDescription.includes('Phone')) {
      this.f.phone.setErrors({ phoneError: true });
    }
    if (err.error.errorDescription.includes('Email')) {
      this.f.email.setErrors({ emailError: true });
    }
    if (err.error.errorDescription.includes('CPF')) {
      this.f.cpf.setErrors({ cpfError: true });
    }
    if (err.error.errorDescription.includes('Card')) {
      this.f.card.setErrors({ cardError: true });
    }
  }

  verifyReturnError(err: any) {
    if (err.error.errorDescription.toLowerCase().includes('cpf')) {
      return 'CPF is already in use by another user.';
    }
    if (err.error.errorDescription.toLowerCase().includes('email')) {
      return 'Email is already in use by another user.';
    }
    if (err.error.errorDescription.toLowerCase().includes('phone')) {
      return 'Phone is already in use by another user.';
    }
    if (err.error.errorDescription.toLowerCase().includes('card')) {
      return 'Card is already in use by another user.';
    }
    if (
      err.error.errorDescription ===
      'Privileges missing for managing this corporation.'
    ) {
      return 'User can only update other users of lower access level.';
    }

    return err.error.errorDescription;
  }

  onSubmit() {
    if (this.userForm.valid) {
      if (this.f.card.value) {
        this.f.card.setValue(
          this.helperService.convertWCardToRegular(this.f.card.value)
        );
      }
      if (this.userForm.value.phone) {
        this.f.phone.setValue(this.userForm.controls.phone.value.e164Number);
      }
      if (this.userForm.value.corpAccessLevel === 'siteAdmin') {
        if (this.sitesSelects.length !== 0) {
          let finalQuerySite = '';

          this.sitesSelects.forEach((element) => {
            finalQuerySite += `${String(element.id)},`;
          });

          this.f.managingSiteIds.setValue(finalQuerySite.slice(0, -1));
        }
      }
      Object.keys(this.userForm.value).map((value) => {
        if (
          this.userForm.value[value] == null ||
          this.userForm.value[value] === ''
        )
          delete this.userForm.value[value];
        return this.userForm.value[value];
      });
      if (this.data.action === 'Create') {
        const dataSend: any = {
          ...this.userForm.value,
        };

        this.groupsService.createUser(dataSend).subscribe(
          (userResponse) => {
            this.dialog.close(userResponse);
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  'User successfully created!'
                ),
                text: '',
              },
              'success'
            );
          },
          (error) => {
            this.setError(error);
            this.loggingService.customAlert(
              {
                title: this.translateService.instant('Error creating user!'),
                text:
                  this.translateService.instant(error.error.errorDescription) ||
                  '',
              },
              'error'
            );
          }
        );
      } else {
        const dataSend: any = {
          ...this.userForm.value,
          id: this.data.id,
          updatedEmail: this.updatedEmail,
          updatedCpf: this.updatedCpf,
          updatedCard: this.updatedCard,
        };

        this.userService.updateUser(dataSend).subscribe(
          (userResponse) => {
            this.dialog.close(userResponse);
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  'User successfully updated!'
                ),
                text: '',
              },
              'success'
            );
          },
          (error) => {
            this.setError(error);
            this.loggingService.customAlert(
              {
                title: this.translateService.instant('Error updating user!'),
                text: this.translateService.instant(
                  this.verifyReturnError(error)
                ),
              },
              'error'
            );
          }
        );
      }
    }
  }

  generateQrCodeOrPasscode(accessType: string) {
    this.userService
      .generateNewPassCodeOrQrcode(this.data.id, accessType.toLowerCase())
      .subscribe(
        (resp) => {
          if (resp === null) {
            this.dialog.close();
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  `${accessType} generated successfully!`
                ),
                text: '',
              },
              'success'
            );
          } else {
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  `Error while tried generate new ${accessType}!`
                ),
                text: this.translateService.instant('Try again later'),
              },
              'error'
            );
          }
        },
        (error) => {
          this.setError(error);
          this.loggingService.customAlert(
            {
              title: this.translateService.instant(
                `Error while tried generate new ${accessType}!`
              ),
              text: this.translateService.instant('Try again later'),
            },
            'error'
          );
        }
      );
  }

  sendQrCodeOrPasscode(accessType: string) {
    this.userService
      .sendPassCodeOrQrcode(this.data.id, accessType.toLowerCase())
      .subscribe(
        (resp) => {
          if (resp === null) {
            this.dialog.close();
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  `${accessType} sent successfully!`
                ),
                text: '',
              },
              'success'
            );
          } else {
            this.loggingService.customAlert(
              {
                title: this.translateService.instant(
                  `Error while tried send new ${accessType}!`
                ),
                text: this.translateService.instant('Try again later'),
              },
              'error'
            );
          }
        },
        (error) => {
          this.setError(error);
          this.loggingService.customAlert(
            {
              title: this.translateService.instant(
                `Error while tried send new ${accessType}!`
              ),
              text: this.translateService.instant('Try again later'),
            },
            'error'
          );
        }
      );
  }
}
