import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { IntegrationsService } from '@modules/integrations/integrations.service';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'app-guesty-integration',
  templateUrl: './guesty-integration.component.html',
  styleUrls: ['./guesty-integration.component.scss'],
})
export class GuestyIntegrationComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @Output() integrationAdded = new EventEmitter<boolean>();
  @Output() previousItem = new EventEmitter<any>();
  public currentStepIndex: number = 1;
  public labelStepSelected: string = '';
  public guestyControl = new UntypedFormControl();
  public loadingData: boolean = false;
  public pendingLoad: Promise<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private translateService: TranslateService,
    private integrationsService: IntegrationsService,
    private dialogRef: MatDialogRef<GuestyIntegrationComponent>,
    private loggingService: LoggingService
  ) {
    this.guestyControl = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(500),
      Validators.pattern('[^\r\n]+'),
    ]);
  }

  ngOnInit() {
    this.labelStepSelected = this.translateService.instant('Information');
  }

  goForward = (stepper: MatStepper) => {
    stepper.selected.completed = true;
    stepper.next();
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  backStep = (stepper) => {
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  previousStep(stepper) {
    stepper.previous();
    this.backStep(stepper);
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 40000);
    });
  }

  onSubmit() {
    this.loadingData = true;
    this.pendingLoad = this.getPromise();
    this.integrationsService
      .createIntegration(this.guestyControl.value)
      .subscribe(
        () => {
          this.integrationsService.importListings().subscribe(
            () => {
              this.loadingData = false;
              this.integrationAdded.emit();
              this.dialogRef.close();
              this.loggingService.customAlert(
                {
                  title: this.translateService.instant(
                    'Integration successfully started!'
                  ),
                  text: '',
                },
                'success'
              );
            },
            (error) => {
              this.loggingService.customAlert(
                {
                  title: this.translateService.instant(
                    'Error starting integration!'
                  ),
                  text:
                    this.translateService.instant(
                      error.error.errorDescription
                    ) || '',
                },
                'error'
              );
            }
          );
        },
        (error) => {
          this.loggingService.customAlert(
            {
              title: this.translateService.instant(
                'Error creating integration!'
              ),
              text:
                this.translateService.instant(error.error.errorDescription) ||
                '',
            },
            'error'
          );
        }
      );
  }
}
