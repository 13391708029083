<div *ngIf="!showMenu; else userAuthenticated">
  <router-outlet #routerOutlet="outlet"></router-outlet>
</div>

<ng-template #userAuthenticated>
  <div class="app">
    <mat-drawer-container
      autosize
    >
      <mat-drawer
        #drawer
        [disableClose]="true"
        mode="side"
        [ngClass]="showSide ? 'app__drawer' : 'app__drawer_hidden'"
        [opened]="sideBarOpened"
      >
        <app-sidebar
          (closeSide)="actionSide(drawer, hideButton)"
          [showIcon]="showIcon"
        >
        </app-sidebar>
        <footer class="mt-auto"></footer>
      </mat-drawer>

      <mat-toolbar class="app__toolbar">
        <button
          type="button"
          (click)="actionSide(drawer, hideButton)"
          class="app__menu-btn"
          *ngIf="showButton"
        >
          <fa-icon icon="bars" *ngIf="showIcon"></fa-icon>
        </button>
        <app-header
          class="app__header"
          [path]="path"
          [username]="username"
          [userImg]="userImg"
        >
        </app-header>
      </mat-toolbar>

      <div class="app__router">
        <router-outlet #routerOutlet="outlet"></router-outlet>
      </div>
    </mat-drawer-container>
  </div>
</ng-template>
