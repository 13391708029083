<div class="modal-result">
  <mat-dialog-content>
    <div *ngIf="result; else loading" class="mrg-top-20 modal-result__icon-div">
      <div *ngIf="!result.hasError; else responseError">
        <div class="modal-result__icon">
          <img alt="result-icon" class="sucess"
            src="../../../../../assets/icons/black_success.svg" width="120" height="120" />
        </div>
        <br />
        <h5 translate>{{ successMessage | translate }}</h5>
      </div>
      <ng-template #responseError>
        <div class="modal-result__icon">
          <img alt="result-icon" class="error"
            src="../../../../../assets/icons/black_error.svg" width="120" height="120" />
        </div>
        <div>
          <h5 translate>{{ result.error }}</h5>
        </div>
      </ng-template>
    </div>
    <ng-template #loading>
      <div class="centered" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"></mat-spinner>
      </div>
    </ng-template>
  </mat-dialog-content>

  <div *ngIf="!widthSizeSuitable" class="no-mrg mrg-top-15 width-100" [ngClass]="
  result && result.hasError
        ? 'modal-result__btn-action-error'
        : 'modal-result__btn-action'
">
    <div *ngIf="result && result.hasError">
      <button aria-label="back" class="back-btn" mat-raised-button (click)="backAction()">
        <span class="btn-color-label">{{ 'Back' | translate }}</span>
      </button>
    </div>
    <div>
      <button aria-label="finish" matDialogClose mat-raised-button class="accent" (click)="finishAll.emit()">
        <span class="btn-color-label">{{ 'Finish' | translate }}</span>
      </button>
    </div>
  </div>
  <div *ngIf="widthSizeSuitable" class="mrg-top-15 width-100" [ngClass]="
  result && result.hasError
    ? 'modal-result__btn-action-error steppers'
    : 'modal-result__btn-action steppers'
">
    <div>
      <button aria-label="finish" matDialogClose mat-raised-button class="accent" (click)="finishAll.emit()">
        <span class="btn-color-label">{{ 'Finish' | translate }}</span>
      </button>
    </div>
    <div *ngIf="result && result.hasError">
      <button aria-label="back" class="back-btn" mat-raised-button (click)="backAction()">
        <span class="btn-color-label">{{ 'Back' | translate }}</span>
      </button>
    </div>
  </div>
</div>