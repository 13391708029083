import { Injectable } from '@angular/core';
import { ApiService } from '@core/http/api.service';
import * as qs from 'querystring';
import * as moment from 'moment';
import { GlobalsService } from '@services/globals.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralDashboardService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getWeekUsage(): Observable<any> {
    return this.apiService.getWeekUsageContent(
      qs.stringify({ siteId: this.globalsService.getSiteId() })
    );
  }

  getHourlyUsage(): Observable<any> {
    return this.apiService.getHourlyUsageContent(
      qs.stringify({ siteId: this.globalsService.getSiteId() })
    );
  }

  getMonthlyUsage(): Observable<any> {
    return this.apiService.getMonthlyUsageContent(
      qs.stringify({ siteId: this.globalsService.getSiteId() })
    );
  }

  getBookingsStats(): Observable<any> {
    return this.apiService.getBookingsStats(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      })
    );
  }

  getDevicesStats(): Observable<any> {
    return this.apiService.getDevicesStats(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
      })
    );
  }

  getGeneralEventsDashboard(): Observable<any> {
    return this.apiService.getGeneralEventsDashboard(
      qs.stringify({ siteId: this.globalsService.getSiteId() })
    );
  }

  getGeneralDashboardEventsSite(
    skip: number,
    limit: number = 30,
    order: string = 'DESC'
  ): Observable<any> {
    return this.apiService.getGeneralDashboardEventsSite(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        skip,
        limit,
        order,
      })
    );
  }
}
