import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-loading',
  templateUrl: './top-loading.component.html',
  styleUrls: ['./top-loading.component.scss'],
})
export class TopLoadingComponent {
  @Input() loadingProp;
}
