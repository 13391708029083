<div class="listings-integration-wrapper">
  <div class="listings-integration">
    <div mat-dialog-content>
      <mat-list class="no-pdd">
        <div class="text-center">
          <h4 class="listings-integration__title" translate>
            Add Integrations
          </h4>
          <span class="listings-integration__subtitle" translate>Select all available devices you want to add to your
            listing.</span>
        </div>
        <div class="row mrg-top-30">
          <div class="col-12">
            <span translate>Select Integration</span>
            <mat-form-field class="width-100 mrg-top-5" appearance="outline">
              <mat-select placeholder="{{ 'Please choose one option' | translate }}" [formControl]="integration"
                matInput type="text" class="secondary-color">
                <mat-option value="Integration 1" style="background-color: #181B1E; color: white;">{{ 'Integration 1' |
                  translate }}
                </mat-option>
                <mat-option value="Integration 2" style="background-color: #181B1E; color: white">
                  {{ 'Integration 2' | translate }}</mat-option>
                <mat-option value="Integration 3" style="background-color: #181B1E; color: white">
                  {{ 'Integration 3' | translate }}</mat-option>
              </mat-select>
              <mat-error *ngIf="integration.errors" translate>
                Required. Please choose one option.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-list>

      <mat-list *ngIf="fullModal" class="no-pdd">
        <span class="label-search-listing" translate>
          Search Listings by name
        </span>
        <div>
          <mat-form-field [ngClass]="widthSizeSuitable ? 'width-100' : 'width-100 pdd-right-40'" appearance="outline">
            <span matPrefix class="pdd-right-10">
              <fa-icon [icon]="faSearch" class="font-size-15"></fa-icon>
            </span>
            <input matInput type="text" [formControl]="listing" [matAutocomplete]="auto" class="primary-color"
              placeholder="{{ 'Must have at least 3 characters.' | translate }}" />
            <mat-error *ngIf="listing.errors" translate>
              Must have at least 3 characters.
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete">
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div>
          <span translate>
            Available Listings
          </span>
          <mat-card appearance="outlined" class="listings-integration__listings centered">
            <mat-card-content>
              <div class="no-listings" *ngIf="siteDevices.length === 0">
                <fa-icon class="font-size-25" icon="door-closed"></fa-icon>
                <span translate>No listings available</span>
              </div>
              <mat-list>
                <mat-list-item *ngFor="let option of siteDevices" style="cursor: pointer;" (click)="addOption(option)"
                  class="listings-integration__selected-listing">
                  <mat-checkbox (click)="$event.stopPropagation()"
                    >
                  </mat-checkbox>
                  <span class="listing-name">
                    {{ option.name }}
                  </span>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-list>

    </div>
  </div>
  <div [ngClass]="data.screen && data.screen === 'Listings' ? 'steppers buttons' : 'steppers'">
    <app-stepper-controller [actionText]="fullModal ? 'Add Integrations' : 'Next'" [blockButton]="!integration.valid"
      (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>