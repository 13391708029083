<div class="accesses">
  <div *ngIf="isNotInUserPage">
    <div class="accesses__divide"></div>

    <div class="accesses__date">
      <span class="accesses__date-value">
        Today's Access
      </span>
    </div>

    <div class="accesses__access-content mrg-right-10" [ngClass]="{
      shadowBlack56: event.operation === 'unlockCommand' && event.commandMethod !== 'otp',
      shadowRed56: event.operation === 'alarmUnlockDenied',
      shadowBlue65: event.operation === 'permissionCreate',
      shadowYellow65: event.operation === 'unlockCommand' && event.commandMethod === 'otp'
    }" *ngIf="event; else noDayAccess">
      <div class="row access-information">
        <div class="col">
          <span class="hour-message">
            {{ formatDateEvent(event, true) }}
          </span>
        </div>
        <div class="col end">
          <span class="hour-message">
            {{ getMessageEvent(event) }}
          </span>
          <fa-icon class="hour-message" icon="door-ope  n"></fa-icon>
        </div>
      </div>
      <div class="row access-information">
        <div>
          <img alt="user image" class="access-img" *ngIf="event.user && event.user.picture && selectedScreen === 'device'"
            [src]="event.user.picture" />
          <img alt="user image" class="access-img" *ngIf="(!event.user || !event.user.picture) && selectedScreen === 'device'"
            [src]="imgRef" />
          <span class="user-message" *ngIf="event.user && event.user.name && selectedScreen === 'device'" translate>
            {{ event.user.name }} {{ event.user.surname }} {{ event.user.name && event.user.surname ? '' :
            'Unknown
            User' }}
          </span>
          <span class="user-message" *ngIf="event.user && !event.user.name && event.user.passcode && selectedScreen === 'device'"
            translate>
            <fa-icon class="padding-5" icon="key"></fa-icon>
            {{ event.user.passcode }}
          </span>
          <span class="user-message" *ngIf="event.user && !event.user.name && event.user.card && selectedScreen === 'device'"
            translate>
            <fa-icon class="padding-5" icon="id-card"></fa-icon>
            {{ event.user.card }}
          </span>
          <span class="user-message" *ngIf="event.user && !event.user.name && event.user.qrCode && selectedScreen === 'device'"
            translate>
            <fa-icon class="padding-5" icon="qrcode"></fa-icon>
            {{ event.user.qrCode }}
          </span>
          <span class="event-message"
            *ngIf="(!event.user || !event.user.name && !event.user.card && !event.user.passcode && !event.user.qrCode) && selectedScreen === 'device'"
            translate>
            Unknown User
          </span>
          <span class="user-message" *ngIf="event.doorName && selectedScreen === 'user'" translate>
            {{ event.doorName }}
          </span>
        </div>
      </div>
    </div>

    <ng-template #noDayAccess>
      <div class="accesses__no-access">
        <div>
          <span translate>
            <fa-icon [icon]="faExclamationTriangle"></fa-icon>
            No access registered
          </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="accesses__divide"></div>

  <cdk-virtual-scroll-viewport *ngIf="hasLogs && events; else emptyAccessMessage" appInfiniteScroller scrollPercent="75"
    immediateCallback="true" minBufferPx="999999" maxBufferPx="999999" [scrollCallback]="scrollCallback"
    class="accesses__scroller" [itemSize]="30">
    <div *cdkVirtualFor="let event of filterData; let i = index">
      <span *ngIf="event && event.operation">
        <div class="accesses__date" *ngIf="i == 0">
          <span class="accesses__date-value">
            {{ formatDate(event).substr(0, 20) }}
          </span>
        </div>

        <div class="accesses__date" *ngIf="
              i > 0 &&
              formatDateEvent(filterData[i]).substr(0, 10) !==
                formatDateEvent(filterData[i - 1]).substr(0, 10)
            ">
          <span class="accesses__date-value">
            {{ formatDate(event).substr(0, 20) }}
          </span>
        </div>

        <div class="accesses__access-content" *ngIf="event; else noDayAccess" [ngClass]="{
            shadowBlack56: event.operation === 'unlockCommand' && event.commandMethod !== 'otp',
            shadowRed56: event.operation === 'alarmUnlockDenied',
            shadowBlue65: event.operation === 'permissionCreate',
            shadowYellow65: event.operation === 'unlockCommand' && event.commandMethod === 'otp'
          }">
          <div class="row access-information">
            <div class="col">
              <span class="hour-message">
                {{ formatDateEvent(event, true) }}
              </span>
            </div>
            <div class="col end">
              <span class="hour-message">
                {{ getMessageEvent(event) }}
              </span>
              <fa-icon class="hour-message" *ngIf="event.operation === 'unlockCommand'" icon="door-open"></fa-icon>
              <fa-icon class="hour-message" *ngIf="event.operation === 'alarmUnlockDenied'"
                [icon]="faExclamationTriangle">
              </fa-icon>
              <fa-icon class="hour-message" *ngIf="event.operation === 'permissionCreate'" icon="share-square">
              </fa-icon>
            </div>
          </div>
          <div class="row access-information">
            <div>
              <img alt="user image" class="access-img" *ngIf="event.user && event.user.picture && selectedScreen === 'device'"
                [src]="event.user.picture" />
              <img alt="user image" class="access-img" *ngIf="(!event.user || !event.user.picture) && selectedScreen === 'device'"
                [src]="imgRef" />
              <span class="user-message" *ngIf="event.user && event.user.name && selectedScreen === 'device'" translate>
                {{ event.user.name }} {{ event.user.surname }} {{ event.user.name && event.user.surname ? '' :
                'Unknown
                User' }}
              </span>
              <span class="user-message" *ngIf="event.user && !event.user.name && event.user.passcode && selectedScreen === 'device'"
                translate>
                <fa-icon class="padding-5" icon="key"></fa-icon>
                {{ event.user.passcode }}
              </span>
              <span class="user-message" *ngIf="event.user && !event.user.name && event.user.card && selectedScreen === 'device'"
                translate>
                <fa-icon class="padding-5" icon="id-card"></fa-icon>
                {{ event.user.card }}
              </span>
              <span class="user-message" *ngIf="event.user && !event.user.name && event.user.qrCode && selectedScreen === 'device'"
                translate>
                <fa-icon class="padding-5" icon="qrcode"></fa-icon>
                {{ event.user.qrCode }}
              </span>
              <span class="event-message"
                *ngIf="(!event.user || !event.user.name && !event.user.card && !event.user.passcode && !event.user.qrCode) && selectedScreen === 'device'"
                translate>
                Unknown User
              </span>
              <span class="user-message" *ngIf="event.doorName && selectedScreen === 'user'" translate>
                {{ event.doorName }}
              </span>
            </div>
          </div>
        </div>
      </span>
    </div>

    <div class="accesses__end" *ngIf="reachedTheEnd; else spinner">
      <span translate>You have reached the end!</span>
    </div>
    <ng-template *ngIf="isNotInUserPage" #spinner>
      <div class="accesses__loading">
        <mat-spinner diameter="20" color="accent"></mat-spinner>
      </div>
    </ng-template>
  </cdk-virtual-scroll-viewport>

  <ng-template #emptyAccessMessage>
    <h6 class="centered warning" translate>
      <fa-icon class="padding-15" [icon]="faExclamationTriangle"></fa-icon>
      <span translate>
        There are no accesses registered
      </span>
    </h6>
  </ng-template>
</div>