import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from 'app/material.module';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faHome,
  faWarehouse,
  faTh,
  faUser,
  faSpinner,
  faTimes,
  faCalendarAlt,
  faCalendar,
  faClock,
  faExclamation,
  faExclamationTriangle,
  faEdit,
  faTimesCircle,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ListingsComponent } from './pages/listings/listings.component';
import { ListingsRoutingModule } from './listings-routing.module';
import { ListingIntegrationComponent } from './components/listings-integration/listings-integration';

@NgModule({
  declarations: [ListingsComponent, ListingIntegrationComponent],
  imports: [
    CommonModule,
    ListingsRoutingModule,
    SharedModule,
    MaterialModule,
    FontAwesomeModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class ListingsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faHome,
      faWarehouse,
      faTh,
      faUser,
      faSpinner,
      faTimes,
      faCalendarAlt,
      faCalendar,
      faClock,
      faExclamation,
      faExclamationTriangle,
      faEdit,
      faTimesCircle,
      faEllipsisV
    );
  }
}
