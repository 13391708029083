<div class="breadcrumb">
  <div *ngFor="let part of path; let i = index" class="breadcrumb__queue">
    <button
      translate
      *ngIf="part !== '/'"
      (click)="navigate(part, i)"
      class="breadcrumb__nav"
      [matTooltip]="i === 0 ? corpToolTip : (i === 2 ? siteToolTip : null)"
      >{{ part }}
  </button>
    <span *ngIf="part === '/'">
      <fa-icon icon="angle-right"></fa-icon>
    </span>
  </div>
</div>
