<div class="create-user">
  <div class="text-center">
    <h4 *ngIf="data.action == 'Create'" mat-dialog-title translate>New User</h4>
    <h4 *ngIf="data.action == 'Edit'" mat-dialog-title translate>Edit User</h4>
  </div>

  <div class="edit-message text-center mrg-top-10">
    <span *ngIf="data.action == 'Edit'" translate
      >Please change the desired fields</span
    >
  </div>

  <div mat-dialog-content>
    <div class="mrg-top-20">
      <mat-list class="no-pdd" [formGroup]="userForm">
        <div class="row">
          <div class="col-md-6">
            <span>{{ 'Name' | translate }} *</span>
            <mat-form-field class="width-100" appearance="outline" required>
              <input
                id="name"
                formControlName="name"
                matInput
                type="text"
                class="third-color"
              />
              <mat-error *ngIf="f.name.errors" translate>
                Required. Must have at least 3 characters.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <span>{{ 'Surname' | translate }} *</span>
            <mat-form-field class="width-100" appearance="outline">
              <input
                id="surname"
                formControlName="surname"
                matInput
                type="text"
                class="third-color"
              />
              <mat-error *ngIf="f.surname.errors" translate>
                Required. Must have at least 3 characters.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mrg-top-10">
          <div class="col-md-6">
            <span>Email</span>
            <mat-form-field class="width-100" appearance="outline">
              <input
                id="email"
                formControlName="email"
                matInput
                type="email"
                class="third-color"
              />
              <span
                matSuffix
                *ngIf="!updatedEmail"
                (click)="enableInput('email')"
              >
                <fa-icon [icon]="faEdit" class="font-size-12 edit"></fa-icon>
              </span>
              <mat-error *ngIf="f.email.errors" translate>
                {{ getEmailErrorMessage() }}
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="updatedPhone" class="col-md-6">
            <span>{{ 'Phone' | translate }} *</span>
            <div style="margin-top: -3px !important;">
              <ngx-intl-tel-input
                formControlName="phone"
                [separateDialCode]="true"
                [preferredCountries]="preferredCountries"
                [enablePlaceholder]="false"
                [phoneValidation]="true"
              >
              </ngx-intl-tel-input>
              <span>
                <mat-error
                  *ngIf="f.phone.invalid"
                  translate
                  class="phone-error pdd-left-10 pdd-top-5"
                >
                  {{ getPhoneErrorMessage() }}
                </mat-error>
              </span>
            </div>
          </div>
          <div *ngIf="!updatedPhone" class="col-md-6">
            <span>{{ 'Phone' | translate }} *</span>
            <mat-form-field class="width-100" appearance="outline">
              <input
                id="phone"
                formControlName="phone"
                matInput
                type="phone"
                class="third-color"
              />
              <span
                matSuffix
                *ngIf="!updatedPhone"
                (click)="enableInput('phone')"
              >
                <fa-icon [icon]="faEdit" class="font-size-12 edit"></fa-icon>
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="row mrg-top-10">
          <div class="col-md-6">
            <span>CPF</span>
            <mat-form-field class="width-100" appearance="outline">
              <input
                id="cpf"
                *ngIf="!updatedCpf"
                formControlName="cpf"
                matInput
                type="cpf"
                class="third-color"
              />
              <input
                id="cpf"
                *ngIf="updatedCpf"
                formControlName="cpf"
                matInput
                type="cpf"
                class="third-color"
                mask="000.000.000-00"
              />
              <span matSuffix *ngIf="!updatedCpf" (click)="enableInput('cpf')">
                <fa-icon [icon]="faEdit" class="font-size-12 edit"></fa-icon>
              </span>
              <mat-error *ngIf="f.cpf.errors" translate>
                {{ getCPFErrorMessage() }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <span translate>Associated Card number</span>
            <mat-form-field class="width-100" appearance="outline">
              <input
                id="cardId"
                formControlName="card"
                matInput
                type="text"
                class="third-color"
              />
              <span
                matSuffix
                *ngIf="!updatedCard"
                (click)="enableInput('card')"
              >
                <fa-icon [icon]="faEdit" class="font-size-12 edit"></fa-icon>
              </span>
              <mat-error *ngIf="f.card.errors" translate>
                {{ getCardErrorMessage() }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mrg-top-10" *ngIf="data.corpAccessLevel !== 'owner'">
          <div class="col-md-6">
            <span class="mrg-right-10" translate>User Level</span>
            <mat-form-field class="width-100" appearance="outline">
              <mat-select formControlName="corpAccessLevel">
                <mat-option
                  *ngIf="
                    data.corpAccessLevel === 'guest' || !data.corpAccessLevel
                  "
                  value="guest"
                  ><span translate>Guest</span></mat-option
                >
                <mat-option value="member"
                  ><span translate>Member</span></mat-option
                >
                <mat-option value="admin"
                  ><span translate>Admin's Corporation</span></mat-option
                >
                <mat-option value="owner"
                  ><span translate>Owner</span></mat-option
                >
                <mat-option value="siteAdmin">
                  <button
                    (click)="optionEvent($event)"
                    class="admin-choose"
                    mat-menu-item
                    [matMenuTriggerFor]="corps"
                  >
                    <span class="mrg-right-5" translate>Admin's Sites</span>
                    <fa-icon
                      [ngStyle]="{ 'padding-right': '15px' }"
                      icon="chevron-right"
                    ></fa-icon>
                  </button>
                  <mat-menu class="corps-menu" #corps="matMenu">
                    <mat-checkbox
                      *ngFor="let site of sitesUser"
                      (click)="
                        checkResolve[site.id] = !checkResolve[site.id];
                        handleSiteCheck($event, site)
                      "
                      [ngStyle]="{
                        display: 'flex',
                        'justify-content': 'flex-start'
                      }"
                    >
                      {{ site.name || '' }}
                    </mat-checkbox>
                  </mat-menu>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="options-user mrg-top-10">
          <div class="passcode-wrapper">
            <span class="pdd-right-0 pdd-left-5">
              <span translate>Generate passcode</span>
              <fa-icon
                [icon]="faQuestionCircle"
                class="mrg-left-5"
                [matTooltip]="generatePasscodeTooltip | translate"
              >
              </fa-icon>
              <div>
                <mat-slide-toggle formControlName="passcodeIsActive">
                </mat-slide-toggle>
                <span *ngIf="!f.passcodeIsActive.value" translate
                  >Inactive</span
                >
                <span *ngIf="f.passcodeIsActive.value" translate>Active</span>
              </div>
            </span>
            <span class="advanced-option pdd-left-0">
              <span
                style="font-size: 8px; color: transparent !important;"
                translate
                >Generate Passcode</span
              >
              <button
                class="passcode-config"
                mat-icon-button
                [matMenuTriggerFor]="menuPasscode"
                *ngIf="data.passcodeIsActive && data.action === 'Edit'"
              >
                <fa-icon icon="ellipsis-vertical"></fa-icon>
              </button>
              <mat-menu #menuPasscode="matMenu">
                <button
                  class="options-recover"
                  (click)="generateQrCodeOrPasscode('Passcode')"
                  mat-menu-item
                >
                  <fa-icon
                    class="mrg-right-5"
                    icon="key"
                    style="color: $neutral-800 !important;"
                  >
                  </fa-icon>
                  <span
                    style="color: $neutral-800 !important;"
                    translate
                    >Generate New Passcode</span
                  >
                </button>
                <button
                  class="options-recover"
                  style="color: $neutral-800 !important;"
                  (click)="sendQrCodeOrPasscode('Passcode')"
                  mat-menu-item
                >
                  <fa-icon
                    class="mrg-right-5"
                    style="color: $neutral-800 !important;"
                    icon="paper-plane"
                  >
                  </fa-icon>
                  <span
                    style="color: $neutral-800 !important;"
                    translate
                    >Send Current Passcode</span
                  >
                </button>
              </mat-menu>
            </span>
          </div>
          <div class="passcode-wrapper last-wrapper">
            <span class="pdd-right-5 pdd-left-0 pdd-right-0">
              <span translate>Generate QRCode</span>
              <fa-icon
                [icon]="faQuestionCircle"
                class="mrg-left-5"
                [matTooltip]="generatePasscodeTooltip | translate"
              >
              </fa-icon>
              <div>
                <mat-slide-toggle formControlName="qrcodeIsActive">
                </mat-slide-toggle>
                <span *ngIf="!f.qrcodeIsActive.value" translate>Inactive</span>
                <span *ngIf="f.qrcodeIsActive.value" translate>Active</span>
              </div>
            </span>
            <span class="advanced-option pdd-left-0">
              <span
                style="font-size: 8px; color: transparent !important;"
                translate
                >Generate Passcode</span
              >
              <button
                mat-icon-button
                [matMenuTriggerFor]="qrCodeMenu"
                *ngIf="data.qrcodeIsActive && data.action === 'Edit'"
              >
                <fa-icon icon="ellipsis-vertical"></fa-icon>
              </button>
              <mat-menu #qrCodeMenu="matMenu">
                <button
                  class="options-recover"
                  (click)="generateQrCodeOrPasscode('Qrcode')"
                  mat-menu-item
                >
                  <fa-icon
                    class="mrg-right-5"
                    style="color: $neutral-800 !important;"
                    icon="qrcode"
                  >
                  </fa-icon>
                  <span
                    style="color: $neutral-800 !important;"
                    translate
                    >Generate New QRcode</span
                  >
                </button>
                <button
                  class="options-recover"
                  (click)="sendQrCodeOrPasscode('Qrcode')"
                  mat-menu-item
                >
                  <fa-icon
                    style="color: $neutral-800 !important;"
                    class="mrg-right-5"
                    icon="paper-plane"
                  >
                  </fa-icon>
                  <span
                    style="color: $neutral-800 !important;"
                    translate
                    >Send Current QRcode</span
                  >
                </button>
              </mat-menu>
            </span>
          </div>
        </div>
        <div class="row mrg-top-20">
          <div class="col-md-12 mrg-top-20 mrg-btm-20">
            <div class="action-lgpd">
              <mat-checkbox formControlName="allowedPersonalData" translate>
                <span class="lgpd-info">
                  <span translate
                    >I claim, assuming responsibility for any damages caused,
                    that the holder's personal data was collected in accordance
                    with the General Data Protection Law, having been informed
                    to the holder about its sharing with LoopKey, as well as
                    providing access to the privacy policy of the company
                    through the link:</span
                  >
                  <a href="www.loopkey.com.br/privacidade">
                    www.loopkey.com.br/privacidade </a
                  >.
                </span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </mat-list>
    </div>
  </div>
  <div *ngIf="widthSuitableSize" class="buttons steppers">
    <button
      mat-raised-button
      class="create-user__submit-button-mobile accent"
      type="submit"
      (click)="onSubmit()"
      [disabled]="
        !userForm.valid || userForm.value.allowedPersonalData === false
      "
    >
      <span *ngIf="data.action === 'Create'" translate>Create</span>
      <span *ngIf="data.action === 'Edit'" translate>Save</span>
    </button>
    <button
      mat-raised-button
      class="create-user__cancel-button-mobile"
      (click)="cancel()"
    >
      <span translate>Cancel</span>
    </button>
  </div>
  <div *ngIf="!widthSuitableSize" class="buttons-big-size-mode">
    <button
      mat-raised-button
      class="create-user__cancel-button"
      (click)="cancel()"
    >
      <span translate>Cancel</span>
    </button>

    <button
      mat-raised-button
      class="create-user__submit-button accent"
      type="submit"
      (click)="onSubmit()"
      [disabled]="
        !userForm.valid || userForm.value.allowedPersonalData === false
      "
    >
      <span *ngIf="data.action === 'Create'" translate>Create</span>
      <span *ngIf="data.action === 'Edit'" translate>Save</span>
    </button>
  </div>
</div>
