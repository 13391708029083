export interface ILKSite {
  id: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  timezone: string;
}

export class LKSite {
  id: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  timezone: string;
  numberDoors: number;
  numberUsers: number;

  constructor(serverObject: Partial<ILKSite>) {
    Object.assign(this, serverObject);
  }
}
