import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { LKSession } from '@models/LKSession';

@Injectable({ providedIn: 'root' })
export class CookiesManagerService {
  constructor(private cookieService: CookieService) {}

  /**
   * Save actual session
   */
  saveSession(session: LKSession) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 10); // Session expires in 10 days
    this.cookieService.putObject('actual_session', session, {
      expires: expirationDate,
    });
  }

  /**
   * Get actual session saved
   */
  getSession(): LKSession {
    return <LKSession>this.cookieService.getObject('actual_session');
  }

  /**
   * Remove actual session
   */
  removeSession() {
    this.cookieService.remove('actual_session');
  }

  /**
   * Set Actual Language
   */
  setLanguage(language: Object) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 10); // Session expires in 10 days
    this.cookieService.putObject('actual_language', language, {
      expires: expirationDate,
    });
  }

  /**
   * Get Actual Language
   */
  getLanguage() {
    if (!this.cookieService.getObject('actual_language')) {
      this.setLanguage('pt-BR');
    }
    return this.cookieService.getObject('actual_language').toString();
  }
}
