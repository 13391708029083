<div class="sidebar">
  <div class="sidebar__container-icon" *ngIf="!showIcon">
    <button
      type="button"
      mat-button
      (click)="actionSide()"
      class="sidebar__menu-btn"
    >
      <fa-icon icon="bars"></fa-icon>
    </button>
  </div>
  <div class="sidebar__logo">
    <img
      alt="loopkey logo"
      class="pointer"
      src="../assets/images/logo/logo-horizontal-white.svg"
      width="110"
      height="45"
      [routerLink]="['general-dashboard']"
    />
  </div>

  <mat-nav-list class="sidebar__list">
    <mat-list-item
      [routerLink]="['general-dashboard']"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 0 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="desktop"></fa-icon>
        <span matListItemLine translate>Dashboard</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="desktop"></fa-icon>
        <span translate>Dashboard</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['devices']"
      *ngIf="hasDeviceList"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 1 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="door-closed"></fa-icon>
        <span matListItemLine translate>Devices</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="door-closed"></fa-icon>
        <span translate>Devices</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['users']"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 3 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="user"></fa-icon>
        <span matListItemLine translate>Users</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="user"></fa-icon>
        <span translate>Users</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['roles']"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 6 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="users"></fa-icon>
        <span matListItemLine translate>Roles</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="users"></fa-icon>
        <span translate>Roles</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['groups']"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 2 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="shapes"></fa-icon>
        <span matListItemLine translate>Groups</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="shapes"></fa-icon>
        <span translate>Groups</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['listings']"
      *ngIf="hasListings"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 10 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="list"></fa-icon>
        <span matListItemLine translate>Listings</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="list"></fa-icon>
        <span translate>Listings</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['reports']"
      *ngIf="false"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 8 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="chart-bar"></fa-icon>
        <span matListItemLine translate>Reports</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="chart-bar"></fa-icon>
        <span translate>Reports</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['maintenance']"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 4 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="wrench"></fa-icon>
        <span matListItemLine translate>Maintenance</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="wrench"></fa-icon>
        <span translate>Maintenance</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['bookings']"
      *ngIf="hasBooking"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 9 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon [icon]="faCalendarDays"></fa-icon>
        <span matListItemLine translate>Bookings</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" [icon]="faCalendarDays"></fa-icon>
        <span translate>Bookings</span>
      </div>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['feedback']"
      (click)="actionSide()"
      [ngClass]="
        activeUrl === 7 ? 'sidebar__list-item--active' : 'sidebar__list-item'
      "
    >
      <div *ngIf="!largeScreen" matListItemTitle>
        <fa-icon icon="comment"></fa-icon>
        <span matListItemLine translate>Support</span>
      </div>
      <div *ngIf="largeScreen" matListItemTitle>
        <fa-icon class="mrg-right-10" icon="comment"></fa-icon>
        <span translate>Support</span>
      </div>
    </mat-list-item>
  </mat-nav-list>

  <div class="sidebar__footer">
    <span>{{ version }}</span>
  </div>
</div>
