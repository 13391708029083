<app-top-loading [loadingProp]="loadingData && !(pendingLoad | async)">
</app-top-loading>

<app-no-connection *ngIf="pendingLoad | async" (refreshPage)="loadingStart()">
</app-no-connection>

<div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>

<div *ngIf="!loadingData && !(pendingLoad | async)" class="general-dashboard">
  <div class="general-dashboard__info">
    <div class="statistics__info">
      <div>
        <span class="statistics__title">
          <span class="statistics__title" translate>Welcome to</span> {{ corpName }},
          {{ currentUsername }}!</span>
      </div>
      <div class="statistics__corp-site-info">
        <div class="site-info">
          <span class="info-wrapper gray-label">
            <fa-icon class="mrg-right-5 gray-label" [icon]="faBuilding"></fa-icon>
            {{ siteName }}
          </span>
        </div>
        <div class="corp-info mrg-top-5">
          <span class="info-wrapper gray-label">
            <fa-icon class="mrg-right-5 gray-label" [icon]="faLocationDot"></fa-icon>
            {{ corpName }}
          </span>
        </div>
      </div>
    </div>
    <div class="informations">
      <div class="statistics">
        <div [ngClass]="hasBooking ? 'statistics__booking-info' : 'statistics__user-info'" class="statistics__number-info">
          <div class="numbers-info-wrapper space-card">
            <div class="number-card space-card" *ngIf="hasBooking">
              <div *ngIf="!mapsLoading" class="card-title" translate>
                <fa-icon class="font-size-20 info-circle" icon="circle-info"
                  [matTooltip]="'This is the total of bookings starting today. Use it to check that everything is working as planned.' | translate"></fa-icon>
              </div>
              <app-dashboard-card [numberColor]="'accent'" [isLoading]="mapsLoading"
                [numberInfo]="bookingsStats.totalCount" [label]="'Today\'s Bookings'"></app-dashboard-card>
            </div>
            <div class="number-card space-card" *ngIf="!hasBooking">
              <div class="loading-div-count" *ngIf="mapsLoading">
                <mat-spinner diameter="50" color="accent"></mat-spinner>
              </div>
              <span *ngIf="!mapsLoading" class="accent-label bold">
                {{ valuePercentagem.activateUsers }}
                <span class="guide-number-proportions accent-label">/{{ valuePercentagem.countEveryUsers }}</span>
                <div class="general-dashboard__info-divider">
                  <div class="general-dashboard__filter-divider"></div>
                </div>
              </span>
              <span *ngIf="!mapsLoading" class="number-card-support">
                <span translate>Active Users last</span> 7
                <span translate>Days</span></span>
            </div>
            <div class="number-card" *ngIf="hasBooking">
              <div *ngIf="!mapsLoading" class="card-title" translate>
                <fa-icon class="font-size-20 info-circle" icon="circle-info"
                  [matTooltip]="'Some bookings with a check-in date of today have not synchronized with the devices due to a problem. Please check that your devices are working correctly.' | translate"></fa-icon>
              </div>
              <app-dashboard-card [numberColor]="bookingsStats.passcodesNotSettled > 0 ? 'yellow' : 'accent'"
                [isLoading]="mapsLoading" [numberInfo]="bookingsStats.passcodesNotSettled"
                [totalNumber]="bookingsStats.totalCount" [label]="'Bookings waiting to sync'"></app-dashboard-card>
            </div>
            <div class="number-card" *ngIf="!hasBooking">
              <div class="loading-div-count" *ngIf="mapsLoading">
                <mat-spinner diameter="50" color="accent"></mat-spinner>
              </div>
              <span *ngIf="!mapsLoading" class="accent-label bold">
                {{ valuePercentagem.userGrowth > 0 ? '+' : ''
                }}{{
                valuePercentagem.userGrowth > 0
                ? valuePercentagem.userGrowth
                : 0
                }}%
                <div class="general-dashboard__info-divider">
                  <div class="general-dashboard__filter-divider"></div>
                </div>
              </span>
              <span *ngIf="!mapsLoading" translate>Active Users Growth</span>
            </div>
          </div>
          <div class="numbers-info-wrapper">
            <div class="number-card space-card" *ngIf="hasBooking">
              <div *ngIf="!mapsLoading" class="card-title" translate>
                <fa-icon class="font-size-20 info-circle" icon="circle-info"
                  [matTooltip]="'Online Devices mean that your smart lock is connected to a gateway and syncing all permissions and passcodes.' | translate"></fa-icon>
              </div>
              <app-dashboard-card [numberColor]="'green'" [isLoading]="mapsLoading"
                [numberInfo]="devicesStats.onlineLocks" [totalNumber]="devicesStats.totalLocks"
                [label]="'Online Devices'"></app-dashboard-card>
            </div>
            <div class="number-card space-card" *ngIf="!hasBooking">
              <div class="loading-div-count" *ngIf="mapsLoading">
                <mat-spinner diameter="50" color="accent"></mat-spinner>
              </div>
              <span *ngIf="!mapsLoading" class="green-label bold">
                {{ valuePercentagem.onlineDevices }}
                <span class="guide-number-proportions green-label bold">/{{ valuePercentagem.totalDevices }}</span>
                <div class="general-dashboard__info-divider">
                  <div class="general-dashboard__filter-divider"></div>
                </div>
              </span>
              <span *ngIf="!mapsLoading" translate>Online Devices</span>
            </div>
            <div class="number-card" *ngIf="hasBooking">
              <div *ngIf="!mapsLoading" class="card-title" translate>
                <fa-icon class="font-size-20 info-circle" icon="circle-info"
                  [matTooltip]="'Offline Gateways will prevent permissions and passcodes to be synced to your smartlocks.' | translate"></fa-icon>
              </div>
              <app-dashboard-card [numberColor]="devicesStats.offlineGateways > 0 ? 'red' : 'green'"
                [isLoading]="mapsLoading" [numberInfo]="devicesStats.totalGateways > 0 ? devicesStats.offlineGateways : 0"
                [totalNumber]="devicesStats.totalGateways" [label]="'Offline Gateways'"></app-dashboard-card>
            </div>
            <div class="number-card" *ngIf="!hasBooking">
              <div class="loading-div-count" *ngIf="mapsLoading">
                <mat-spinner diameter="50" color="accent"></mat-spinner>
              </div>
              <span *ngIf="!mapsLoading" class="red-label bold">
                {{ valuePercentagem.accessDenied }}
                <div class="general-dashboard__info-divider">
                  <div class="general-dashboard__filter-divider"></div>
                </div>
              </span>
              <span *ngIf="!mapsLoading" translate>Access Denied</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="graph-bars col-md-6 first-column">
            <mat-card class="graph-bar-card" *ngIf="hasBooking">
              <div class="graph-title" translate>Booking Updates</div>
              <app-extended-dashboard-card [numberColor]="'accent'" [isLoading]="mapsLoading"
                [numberInfoOne]="bookingsStats.occupancyPercentage" [labelOne]="'Today\'s Occupancy'"
                [numberInfoTwo]="bookingsStats.alreadyMadeCheckins" [totalNumberTwo]="bookingsStats.totalCount"
                [labelTwo]="'# of Check-in'" [action]="'See bookings list'"></app-extended-dashboard-card>
            </mat-card>
            <mat-card class="graph-bar-card space-card" *ngIf="!hasBooking">
              <div class="graph-title" translate>
                <span class="graph-title" *ngIf="!sizeWidthSuitable">Usage on last 7 days</span>
                <span *ngIf="sizeWidthSuitable">Last 7 days</span>
                <mat-button-toggle-group
                  *ngIf="sizeWidthSuitable"
                  #group="matButtonToggleGroup"
                  class="right"
                >
                  <mat-button-toggle
                    aria-label="toggle option"
                    class="width-100"
                    *ngFor="let element of filtersStatus; let i = index"
                    value="element.value"
                    [checked]="i == 0"
                    (click)="changeWeekView()"
                    [disabled]="isDisabled"
                  >
                    <span>
                      {{ element | translate }}
                    </span>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="loading-div" *ngIf="loadWeekGraph">
                <mat-spinner diameter="80" color="accent"></mat-spinner>
              </div>
              <div
                class="chart"
                *ngIf="
                  showWeekGraph &&
                  !loadWeekGraph &&
                  (!sizeWidthSuitable || (sizeWidthSuitable && weekView))
                "
              >
                <ngx-charts-bar-vertical
                  (window:resize)="onResize($event)"
                  [results]="weekDayUsage"
                  [xAxis]="true"
                  [barPadding]="barPaddingWeek"
                >
                  <ng-template
                    #seriesTooltipTemplate
                    #tooltipTemplate
                    let-model="model"
                  >
                    <h6 style="color: #FFFFFF !important;">{{ model.name }}</h6>
                    <h5 style="color: #FFFFFF !important;">
                      {{ model.value }}
                    </h5>
                  </ng-template>
                </ngx-charts-bar-vertical>
              </div>
              <div
                class="warning-graph"
                *ngIf="
                  !loadWeekGraph &&
                  !showWeekGraph &&
                  sizeWidthSuitable
                "
                translate
              >
                There is no data available yet
              </div>
              <div
                class="chart"
                *ngIf="
                  !loadHourGraph &&
                  showHourGraph &&
                  sizeWidthSuitable && !weekView
                "
              >
                <ngx-charts-bar-vertical
                  [results]="hourlyUsage"
                  [xAxis]="true"
                  [barPadding]="barPaddingHourly"
                >
                  <ng-template
                    #seriesTooltipTemplate
                    #tooltipTemplate
                    let-model="model"
                  >
                    <h6 style="color: #FFFFFF !important;">
                      {{ model.name + 'h' }}
                    </h6>
                    <h5 style="color: #FFFFFF !important;">
                      {{ model.value }}
                    </h5>
                  </ng-template>
                </ngx-charts-bar-vertical>
              </div>
              <div
                class="warning-graph"
                *ngIf="
                  !loadHourGraph &&
                  !showHourGraph &&
                  (!sizeWidthSuitable || (sizeWidthSuitable && !weekView))
                "
                translate
              >
                There is no data available yet
              </div>
            </mat-card>
          </div>
          <div class="graph-bars col-md-6 second-column">
            <mat-card class="graph-bar-card" *ngIf="!sizeWidthSuitable && hasBooking">
              <div class="graph-title" translate>Battery Status</div>
              <app-extended-dashboard-card [numberColor]="devicesStats.locksWithLowBattery > 0 ? 'red' : 'green'"
                [isLoading]="mapsLoading" [numberInfoOne]="devicesStats.locksWithLowBattery" [labelOne]="'Low Battery'"
                [totalNumberOne]="devicesStats.totalLocks" [list]="devicesStats.doorsWithLowBattery"
                [action]="'See low battery devices'"></app-extended-dashboard-card>
            </mat-card>
            <mat-card class="graph-bar-card" *ngIf="!sizeWidthSuitable && !hasBooking">
              <div class="graph-title" translate>Hourly use on last 7 days</div>
              <div class="loading-div" *ngIf="loadHourGraph">
                <mat-spinner diameter="80" color="accent"></mat-spinner>
              </div>
              <div class="chart" *ngIf="!loadHourGraph && showHourGraph">
                <ngx-charts-bar-vertical
                  (window:resize)="onResize($event)"
                  [results]="hourlyUsage"
                  [xAxis]="true"
                  [barPadding]="barPaddingHourly"
                >
                  <ng-template
                    #seriesTooltipTemplate
                    #tooltipTemplate
                    let-model="model"
                  >
                    <h6 style="color: #FFFFFF !important;">
                      {{ model.name + 'h' }}
                    </h6>
                    <h5 style="color: #FFFFFF !important;">
                      {{ model.value }}
                    </h5>
                  </ng-template>
                </ngx-charts-bar-vertical>
              </div>
              <div
                class="warning-graph"
                *ngIf="!showHourGraph && !loadHourGraph"
                translate
              >
                There is no data available yet
              </div>
            </mat-card>
          </div>
        </div>
        <div class="row">
          <div class="graph-heatmap col-md-12">
            <mat-card class="graph-bar-heatmap" *ngIf="!sizeWidthSuitable">
              <div class="graph-title" translate>
                Access Heat Map - Last 30 Days
              </div>
              <div class="loading-div" *ngIf="loadHeatmapData">
                <mat-spinner diameter="80" color="accent"></mat-spinner>
              </div>
              <div class="chart" *ngIf="!loadHeatmapData">
                <ngx-charts-heat-map [scheme]="
                    colorSchemeWhite
                  " [results]="heatmapData" [legend]="false" [gradient]="false" [xAxis]="true" [yAxis]="true"
                  [showXAxisLabel]="true" [xAxisLabel]="translateServices.instant('Time of the day')"
                  (select)="onSelect()" (window:resize)="onResize($event)">
                  <ng-template #seriesTooltipTemplate #tooltipTemplate let-model="model">
                    <h5 style="color: #FFFFFF !important; font-size: 14px;">
                      {{ formatTooltip(model.value) }}
                    </h5>
                  </ng-template>
                </ngx-charts-heat-map>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="access-info">
        <div class="last-accesses-log">
          <div class="top-info">
            <span class="graph-title" translate>Access Logs</span>
            <span>
              <span translate>
                Update: every
              </span>
              1 <span translate>minute</span>
            </span>
          </div>
          <cdk-virtual-scroll-viewport *ngIf="hasLogs && events; else emptyAccessMessage" appInfiniteScroller
            scrollPercent="70" class="general-dashboard__scroller" minBufferPx="999999" maxBufferPx="999999"
            immediateCallback="true" [scrollCallback]="scrollCallback" [itemSize]="30">
            <div *cdkVirtualFor="let event of filterData; let i = index">
              <span *ngIf="event && event.user && event.operation">
                <div class="general-dashboard__date" *ngIf="i == 0">
                  <span class="general-dashboard__date-value">
                    {{ getDateFormat(event.when) }}</span>
                </div>
                <div class="general-dashboard__date" *ngIf="
                    i > 0 &&
                    formatDateEvent(events.events[i]).substr(0, 10) !==
                      formatDateEvent(events.events[i - 1]).substr(0, 10)
                  ">
                  <span class="general-dashboard__date-value">{{
                    getDateFormat(event.when)
                    }}</span>
                </div>
                <div
                  class="general-dashboard__access-content"
                  [ngClass]="{
                    shadowBlack56: event.operation === 'unlockCommand' && event.commandMethod !== 'otp',
                    shadowRed56: event.operation === 'alarmUnlockDenied',
                    shadowBlue65: event.operation === 'permissionCreate',
                    shadowYellow65: event.operation === 'unlockCommand' && event.commandMethod === 'otp'
                  }"
                >
                  <div class="row access-information">
                    <div class="col-md-2">
                      <span class="hour-message">
                        {{ getHourFormat(event.when) }}
                      </span>
                    </div>
                    <div class="col-md-10 end">
                      <span class="hour-message" translate>
                        {{ getEventMessage(event) }}
                      </span>
                      <fa-icon class="hour-message" *ngIf="event.operation === 'unlockCommand'"
                        icon="door-open"></fa-icon>
                      <fa-icon class="hour-message" *ngIf="event.operation === 'alarmUnlockDenied'"
                        [icon]="faExclamationTriangle"></fa-icon>
                      <fa-icon class="hour-message" *ngIf="event.operation === 'permissionCreate'"
                        icon="share-square"></fa-icon>
                    </div>
                  </div>
                  <div class="row access-information">
                    <div class="col">
                      <img alt="user image" class="access-img" *ngIf="!event.user.picture" [src]="imgRef" />
                      <img alt="user image" class="access-img" *ngIf="event.user.picture" [src]="event.user.picture" />
                      <span class="user-message" *ngIf="event.user.name" translate>
                        {{ event.user.name }} {{ event.user.surname }} {{ event.user.name && event.user.surname ? ''
                        :
                        'Unknown
                        User' }}
                      </span>
                      <span class="user-message" *ngIf="!event.user.name && event.user.passcode" translate>
                        <fa-icon class="padding-5" icon="key"></fa-icon>
                        {{ event.user.passcode }}
                      </span>
                      <span class="user-message" *ngIf="!event.user.name && event.user.card" translate>
                        <fa-icon class="padding-5" icon="id-card"></fa-icon>
                        {{ event.user.card }}
                      </span>
                      <span class="user-message" *ngIf="!event.user.name && event.user.qrCode" translate>
                        <fa-icon class="padding-5" icon="qrcode"></fa-icon>
                        {{ event.user.qrCode }}
                      </span>
                      <span class="event-message" *ngIf="
                          !event.user.name &&
                          !event.user.card &&
                          !event.user.passcode &&
                          !event.user.qrCode
                        " translate>
                        Unknown User
                      </span>
                    </div>
                    <div class="col end">
                      <span class="user-message">
                        {{ event.doorName }}
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            </div>

            <div class="general-dashboard__end" *ngIf="reachedTheEnd; else spinner">
              <span translate>You have reached the end!</span>
            </div>
            <ng-template #spinner>
              <div class="general-dashboard__loading">
                <mat-spinner diameter="20" color="accent"></mat-spinner>
              </div>
            </ng-template>
          </cdk-virtual-scroll-viewport>

          <ng-template #emptyAccessMessage>
            <h6 class="warning-accesses" translate>
              <fa-icon class="padding-15" [icon]="faExclamationTriangle"></fa-icon>
              <span translate>
                There are no accesses registered
              </span>
            </h6>
          </ng-template>
        </div>
      </div>
    </div>
  </div>