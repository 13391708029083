import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IntegrationsService } from '@modules/integrations/integrations.service';
import * as moment from 'moment';
import { ConfirmationRemoveComponent } from '@modules/integrations/components/confirmation-remove/confirmation-remove.component';
import { Subscription } from 'rxjs';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { GuestyIntegrationComponent } from '@shared/components/guesty-integration/guesty-integration.component';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { AddKeyComponent } from '../add-key/add-key.component';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  @ViewChild('childMenu', { static: true }) public childMenu: any;
  public loadingData: boolean = true;
  public pendingLoad: Promise<boolean>;
  public sizeWidthSuitable: boolean = false;
  public staySessionContent: any = {};
  public conexaSessionContent: any = {};
  public genericSessionContent: any = {};
  public thereIsStaysSession: boolean = false;
  public thereIsConexaSession: boolean = false;
  public thereIsGenericSession: boolean = false;
  public clients: any = [];
  public sessions: any = [];
  public creationIntegration: string = '';
  public creationConexa: string = '';
  public creationStays: string = '';
  public creationGeneric: string = '';
  public subscription: Subscription;
  public faCheckCircle = faCheckCircle;

  constructor(
    private dialog: MatDialog,
    private integrationsService: IntegrationsService,
    private loggingService: LoggingService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.sizeWidthSuitable = window.innerWidth <= 500;
    this.loadingSessionAndClients();
  }

  readLocalStorageValue(key) {
    return localStorage.getItem(key);
  }

  verifySession(integrationName: string) {
    const sessionFiltered = this.sessions.filter(
      (session) => session.client.name === integrationName
    );
    if (sessionFiltered.length !== 0) {
      this.creationIntegration = moment
        .unix(sessionFiltered[0].creation)
        .format('DD/MM/YYYY HH:mm');
      return true;
    }
    return false;
  }

  loadingSessionAndClients() {
    this.getPromise();
    this.integrationsService.getIntegrations().subscribe((resp) => {
      this.clients = resp;
      this.loadingData = false;
    });
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 5000);
    });
  }

  configureIntegration(typeIntegration: string, showKey: boolean) {
    const [sessionFiltered] = this.sessions.filter(
      (session) => session.client.name === typeIntegration
    );

    const [clientFiltered] = this.clients.filter(
      (client) => client.name === typeIntegration
    );

    const clientId = clientFiltered.id;

    const data = {
      showKey,
      sessionFiltered,
      clientId,
      typeIntegration,
    };

    const dialogRef = this.dialog.open(AddKeyComponent, {
      panelClass: 'dialog-container',
      width: '400px',
      maxWidth: '100%',
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      if (!showKey) {
        this.loadingData = true;
        this.loadingSessionAndClients();
      }
    });
  }

  openGuestyIntegrationDialog() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
    };
    const dialogRef = this.dialog.open(GuestyIntegrationComponent, config);
    dialogRef.componentInstance.integrationAdded.subscribe(() => {
      this.loadingSessionAndClients();
    });
  }

  removeIntegration(integrationId: number) {
    const dialogRef = this.dialog.open(ConfirmationRemoveComponent, {
      panelClass: 'dialog-container',
      width: '400px',
      maxWidth: '100%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data.remove) {
          this.loadingData = true;
          this.integrationsService
            .removeIntegration(integrationId)
            .subscribe(() => {
              this.loadingSessionAndClients();
            });
          this.loggingService.defaultToast(
            'success',
            this.translateService.instant('Integration removed successfully.'),
            'center'
          );
        }
      },
      () => {
        this.loggingService.defaultToast(
          'error',
          this.translateService.instant('Action failed. Please, try again.'),
          'center'
        );
      }
    );
  }
}
