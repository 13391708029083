import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { GlobalsService } from '@services/globals.service';
import { ILKRole } from '@models/LKRole';

@Component({
  selector: 'app-role-info',
  templateUrl: './role-info.component.html',
  styleUrls: ['./role-info.component.scss'],
})
export class RoleInfoComponent implements OnInit {
  @Input() isEditing: boolean;
  @Input() role: ILKRole;
  @Input() data: any;
  @Output() infoSet = new EventEmitter<{
    name: string;
    description: string;
    isGlobal: boolean;
    id: number;
    siteId: number;
  }>();
  @Output() previousItem = new EventEmitter<any>();
  public roleForm: UntypedFormGroup;

  constructor(public globalsService: GlobalsService) {}

  ngOnInit() {
    this.roleForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        // eslint-disable-next-line no-nested-ternary
        this.isEditing
          ? this.role.name
          : this.data && this.data.name
          ? this.data.name
          : '',
        [Validators.required, Validators.minLength(3)]
      ),
      description: new UntypedFormControl(
        this.isEditing ? this.role.description : '',
        [Validators.minLength(3)]
      ),
      isGlobal: new UntypedFormControl(
        this.isEditing ? this.role.isGlobal : false,
        []
      ),
    });
  }

  onSubmit() {
    if (this.roleForm.valid) {
      this.infoSet.emit({
        name: this.f.name.value,
        description: this.f.description.value,
        isGlobal: this.f.isGlobal.value,
        id: this.isEditing ? this.role.id : null,
        siteId: parseInt(this.globalsService.getSiteId(), 10),
      });
    }
  }

  get f() {
    return this.roleForm.controls;
  }
}
