import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ILKBooking, LKBooking } from '@models/LKBooking';
import { CreateBookingComponent } from '@modules/bookings/pages/create-booking/create-booking.component';
import { DeleteBookingComponent } from '@shared/components/delete-booking/delete-booking.component';
import {
  faRightFromBracket,
  faRightToBracket,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import * as FullStory from '@fullstory/browser';
import { GlobalsService } from '@services/globals.service';
import { environment } from '@environments/environment';
import { FullStoryService } from '@services/fullstory-service.service';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { OfflinePasscodeComponent } from '@modules/bookings/components/offline-passcode/offline-passcode.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '@services/helper.manager.service';
import { BookingsService } from '../../bookings.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public sizeWidthSuitable: boolean = false;
  public loadingTableData: boolean = true;
  public loadingFilterTable: boolean = false;
  public dataSource: MatTableDataSource<any>;
  public currentInnerWidth: number = 0;
  public currentInnerHeight: number = 0;
  public numberBookingsShow: number = 5;
  public tableFiltered: boolean = false;
  public language = 'default';
  public bookingList: LKBooking[] = [];
  public rows: LKBooking[] = [];
  public skipNumber: number = 0;
  public bookingsSize: number;
  public numberRows: number;
  public pendingLoad: Promise<boolean>;
  public filterName: string = '';
  public limit: number;
  public filterType = [];
  public filtersStatus = [
    {
      value: 'All',
    },
    {
      value: 'Active',
    },
    {
      value: 'Future',
    },
    {
      value: 'Past',
    },
  ];
  public faCheckCircle = faCheckCircle;
  public faClock = faClock;
  public filterValues = {
    state: '',
    name: '',
  };
  public faRightToBracket = faRightToBracket;
  public faRightFromBracket = faRightFromBracket;
  public englishMessage = {
    emptyMessage: `
      <div>
        <span>There's no Booking available to display</span>
      </div>
    `,
  };
  public portugueseMessage = {
    emptyMessage: `
      <div>
        <span>Não há reservas para exibir</span>
      </div>
    `,
  };

  constructor(
    private bookingService: BookingsService,
    private dialog: MatDialog,
    public createDialog: MatDialog,
    private translateService: TranslateService,
    private helperService: HelperService,
    private globalsServices: GlobalsService,
    private cookiesManagerService: CookiesManagerService,
    private titleService: Title,
    private fullStoryService: FullStoryService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.sizeWidthSuitable = window.innerWidth <= 500;
    this.limit = 100;
    this.language = this.cookiesManagerService.getLanguage();
    this.loadBookings();
    this.currentInnerHeight = window.innerHeight;
    this.currentInnerWidth = window.innerWidth;
    this.calculatePaginator();

    this.titleService.setTitle(
      this.translateService.instant('Bookings | LoopKey')
    );

    FullStory.init({
      orgId: 'NBNEG',
      devMode: !environment.production,
    });

    this.fullStoryService.setUserVars({
      displayName: localStorage.getItem('userName'),
      corpId: this.globalsServices.getCorporationId(),
      siteId: this.globalsServices.getSiteId(),
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentInnerWidth = event.target.innerWidth;
    this.currentInnerHeight = event.target.innerHeight;
    this.calculatePaginator();
  }

  calcLastElem(firstNumber: number, secondNumber: number, totalRows: number) {
    const lastNumber = firstNumber * secondNumber;
    if (lastNumber > totalRows) return totalRows;
    return lastNumber;
  }

  calcFirstElem(currentPage: number) {
    return this.numberRows > 0
      ? (currentPage - 1) * this.numberBookingsShow + 1
      : 0;
  }

  calculatePaginator() {
    const availableTableHeight = window.innerHeight * 0.6965;

    let totalRowsPerPage = 0;

    if (this.currentInnerWidth > 900) {
      totalRowsPerPage = Math.floor(availableTableHeight / 75);
    } else if (this.currentInnerWidth >= 600 && this.currentInnerWidth <= 900) {
      totalRowsPerPage = Math.floor(availableTableHeight / 95);
    } else {
      totalRowsPerPage = Math.floor(availableTableHeight / 118);
    }

    this.numberBookingsShow = totalRowsPerPage;
  }

  validResults(): Boolean {
    return true;
  }

  async loadBookings() {
    this.loadingTableData = true;
    this.pendingLoad = this.getPromise();
    this.bookingService
      .getBookings(this.skipNumber, this.limit, 'DESC', 'checkin')
      .subscribe((bookings) => {
        const bookingList = bookings.bookings;
        this.bookingList = bookingList;
        this.bookingsSize = bookings.count;
        this.rows = this.bookingList;
        this.numberRows = bookings.count;
        localStorage.setItem('bookings', JSON.stringify(this.bookingList));
        localStorage.setItem('count_bookings', String(this.bookingsSize));
        this.loadingTableData = false;
      });
  }

  async changePage(event) {
    const lastElem = event.page * this.numberBookingsShow;
    if (lastElem > event.count && event.count < this.numberRows) {
      this.loadingFilterTable = true;
      this.skipNumber = event.count;
      if (this.filterValues.name !== '' && this.filterValues.state === '') {
        this.applyBookingFilter(this.filterValues.name, true);
      } else if (this.filterValues.name === '') {
        this.resetFilter(true);
      } else if (
        this.filterValues.state !== '' &&
        this.filterValues.name !== ''
      ) {
        this.applyBookingFilter(this.filterValues.name, true);
      }
    }
  }

  sortData(sort) {
    this.loadingFilterTable = true;
    if (this.filterValues.state === '' && this.filterValues.name === '') {
      this.bookingService
        .getBookings(
          this.skipNumber,
          this.limit,
          sort.newValue.toUpperCase(),
          sort.column.prop
        )
        .subscribe((bookings) => {
          const bookingList = bookings.bookings;
          this.bookingList = bookingList;
          this.rows = bookingList;
          this.loadingFilterTable = false;
        });
    } else {
      this.bookingService
        .findBooking(
          this.filterValues.name !== '' ? this.filterValues.name : null,
          this.filterValues.state !== '' && this.filterValues.state !== 'All'
            ? this.filterValues.state
            : null,
          sort.newValue.toUpperCase(),
          sort.column.prop
        )
        .subscribe((bookings) => {
          const bookingList = bookings.bookings;
          this.bookingList = bookingList;
          this.rows = bookingList;
          this.loadingFilterTable = false;
        });
    }
  }

  compare(a, b, isAsc) {
    if (!a || a === undefined) {
      return -1;
    }

    if (!b || b === undefined) {
      return +1;
    }

    return a.localeCompare(b, 'pt-BR', { numeric: true }) * (isAsc ? 1 : -1);
  }

  clearFilter() {
    this.filterName = '';
  }

  async applyFilter(filterValue: string, filterType: string) {
    this.loadingFilterTable = true;
    this.skipNumber = 0;
    this.tableFiltered = true;
    this.filterValues[filterType] = filterValue.trim().toLowerCase();

    if (filterType === 'name') {
      this.filterName = filterValue;
    }

    if (filterType === 'name' && this.filterValues.state === '') {
      this.applyBookingFilter(filterValue, false);
    } else if (
      (filterType === 'name' && filterValue === '') ||
      this.filterValues.name === ''
    ) {
      this.resetFilter(false);
    } else if (
      this.filterValues.state !== '' &&
      this.filterValues.name !== ''
    ) {
      this.applyBookingFilter(this.filterValues.name, false);
    }
  }

  private applyBookingFilter(name: string, concatenate: boolean) {
    const stateFilter =
      this.filterValues.state === 'all' ? null : this.filterValues.state;

    this.bookingService
      .findBooking(
        name,
        stateFilter,
        'DESC',
        'checkin',
        this.limit,
        this.skipNumber
      )
      .subscribe((bookings) => {
        this.updateRows(bookings, concatenate);
      });
  }

  private resetFilter(concatenate: boolean) {
    if (this.filterValues.state === 'all') {
      this.bookingService
        .getBookings(this.skipNumber, this.limit, 'DESC', 'checkin')
        .subscribe((bookings) => {
          this.updateRows(bookings, concatenate);
        });
    } else {
      this.bookingService
        .findBooking(
          null,
          this.filterValues.state,
          'DESC',
          'checkin',
          this.limit,
          this.skipNumber
        )
        .subscribe((bookings) => {
          this.updateRows(bookings, concatenate);
        });
    }
  }

  private updateRows(response: any, concatenate: boolean) {
    if (concatenate) {
      this.rows = this.rows.concat(response.bookings);
    } else {
      this.rows = response.bookings;
      this.numberRows = response.count;
    }
    this.loadingFilterTable = false;
  }

  tableFilter(): (data: ILKBooking, filter: string) => boolean {
    const filterFunction = (data, filter): boolean => {
      const searchTerms = JSON.parse(filter);
      return data.state
        .trim()
        .toLowerCase()
        .includes(searchTerms.state === 'all' ? '' : searchTerms.state);
    };
    this.loadingTableData = false;
    return filterFunction;
  }

  editBooking(element) {
    const config = {
      panelClass: 'dialog-container',
      width: '900px',
      maxWidth: '100vw',
      data: {
        bookingId: element.id,
        userId: element.users[0].id,
        userName: element.users[0].name,
        userSurname: element.users[0].surname,
        userCpf: element.users[0].cpf,
        userEmail: element.users[0].email,
        userPhone: element.users[0].phone,
        userCardNumber: element.users[0].card,
        corpAccessLevel: element.users[0].corpAccessLevel,
        userPasscode: element.users[0].passcode,
        startDateTime: element.accessRule.startDateTime,
        endDateTime: element.accessRule.endDateTime,
        startTimeOfDay: element.accessRule.startTime,
        endTimeOfDay: element.accessRule.endTime,
        doors: element.doors,
        action: 'Edit',
      },
    };

    this.createDialog
      .open(CreateBookingComponent, config)
      .afterClosed()
      .subscribe(() => {
        this.loadBookings();
      });
  }

  generatePasscode(element: LKBooking) {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      data: {
        booking: element,
      },
    };

    const dialogRef = this.dialog.open(OfflinePasscodeComponent, config);
    dialogRef.componentInstance.passcodeGenerated.subscribe((results) => {
      if (results) {
        this.loadBookings();
      }
    });
  }

  deleteBooking(element) {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      data: {
        booking: element,
      },
    };

    const dialogRef = this.dialog.open(DeleteBookingComponent, config);
    dialogRef.componentInstance.bookingDeleted.subscribe((results) => {
      if (results) {
        this.loadBookings();
      }
    });
  }

  changePasscodeStatus(element?, action?: string) {
    if (!element && !action) {
      this.bookingList.forEach((booking) => {
        booking.showPasscode = false;
        booking.showOfflinePasscode = false;
      });
    }
    if (element && action === 'show') {
      element.showPasscode = true;
    }
    if (element && action === 'hide') {
      element.showPasscode = false;
    }
  }

  changeOfflinePasscodeStatus(element, action: string) {
    if (element && action === 'show') {
      element.showOfflinePasscode = true;
      this.bookingService.getBackupCode(element.id).subscribe((booking) => {
        element.backupCodes[0].passcode = booking.backupCodes[0].passcode;
      });
    }
    if (element && action === 'hide') {
      element.showOfflinePasscode = false;
      element.backupCodes[0].passcode = '********';
    }
  }

  getDateAsString(date) {
    date = new Date(date);
    const year = String(date.getFullYear()).slice(-2);
    const month = this.capitalizeFirstLetter(
      date.toLocaleString(this.language, { month: 'short' })
    );
    const day = date.getDate();
    return `${month} ${day <= 9 ? `0${day}` : day}.${year}`;
  }

  getOfflinePasscodeMessage(booking, status: string) {
    let message = '';
    if (status === 'not sync') {
      if (this.language === 'pt-BR') {
        message = `Senha Online não sincronizada!\n\nDias Restantes: ${this.helperService.getDaysDifference(
          new Date(),
          new Date(booking.accessRule.endDateTime),
          'days'
        )}
        Senha válida até: ${this.helperService.getDateString(
          new Date(booking.accessRule.endDateTime),
          'pt-BR'
        )}\n\nAtenção!
        As senhas offline são uma medida de emergência para garantir que hóspedes possam acessar seus apartamentos em caso de problemas no sistema.\n
        Use esta senha com cuidado e sempre se certifique de que ela será substituída por uma senha gerada automaticamente pelo Corp.`;
      } else {
        message = `Online password not synchronized!\n\nDays Left: ${this.helperService.getDaysDifference(
          new Date(),
          new Date(booking.accessRule.endDateTime),
          'days'
        )}
        Valid until: ${this.helperService.getDateString(
          new Date(booking.accessRule.endDateTime),
          'en'
        )}\n\nAttention!
        Offline passwords are an emergency measure to ensure that guests can access their apartments in case of system problems.\n
        Use this password carefully and always make sure that it is replaced by a password automatically generated by Corp.`;
      }
    } else if (status === 'sync') {
      if (this.language === 'pt-BR') {
        message = `Senha Online sincronizada!\nSenha válida até: ${this.helperService.getDateString(
          new Date(booking.accessRule.endDateTime),
          'pt-BR'
        )}\n\nAtenção!
        As senhas offline são uma medida de emergência para garantir que hóspedes possam acessar seus apartamentos em caso de problemas no sistema.\n
        Use esta senha com cuidado e sempre se certifique de que ela será substituída por uma senha gerada automaticamente pelo Corp.`;
      } else {
        message = `Online password synchronized!\nValid until: ${this.helperService.getDateString(
          new Date(booking.accessRule.endDateTime),
          'en'
        )}\n\nAttention!
        Offline passwords are an emergency measure to ensure that guests can access their apartments in case of system problems.\n
        Use this password carefully and always make sure that it is replaced by a password automatically generated by Corp.`;
      }
    }
    return message;
  }

  getHourAsString(date) {
    date = new Date(date);
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    return `${hour}:${minute}`;
  }

  private capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingTableData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 30000);
    });
  }

  searchGuest = () => {
    const config = {
      panelClass: 'dialog-container',
      width: '900px',
      maxWidth: '100vw',
      data: {
        action: 'Create',
      },
    };

    this.dialog
      .open(CreateBookingComponent, config)
      .afterClosed()
      .subscribe(() => {
        this.loadBookings();
      });
  };
}
