export interface ILKGroupRestrictions {
  startDateTime: Date;
  endDateTime: Date;
  startTimeOfDay: string;
  endTimeOfDay: string;
  endTime: string;
  startTime: string;
  daysOfTheWeek: number;
  offlineAllowed: boolean;
  remoteAllowed: boolean;
  qrcodeAllowed: boolean;
  smartphoneAllowed: boolean;
  cardAllowed: boolean;
  passcodeAllowed: boolean;
  daysList: string[];
}

export class LKGroupRestrictions {
  public startDateTime: Date = null;
  public endDateTime: Date = null;
  public startTimeOfDay: string = null;
  public endTimeOfDay: string = null;
  endTime: string = null;
  startTime: string = null;
  public daysOfTheWeek: number = 62;
  public offlineAllowed: boolean = false;
  public remoteAllowed: boolean = false;
  public qrcodeAllowed: boolean = false;
  public smartphoneAllowed: boolean = false;
  public cardAllowed: boolean = false;
  public passcodeAllowed: boolean = false;
  public daysList: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  constructor(serverObject?: ILKGroupRestrictions, accessRule?: any) {
    Object.assign(this, serverObject);
    if (accessRule && accessRule.startDateTime) {
      this.startDateTime = new Date(accessRule.startDateTime);
    }
    if (accessRule && accessRule.endDateTime) {
      this.endDateTime = new Date(accessRule.endDateTime);
    }
    if (accessRule && accessRule.daysList) {
      this.daysList = accessRule.daysList;
    }
    if (accessRule && accessRule.startTime) {
      this.startTimeOfDay = accessRule.startTime;
    }
    if (accessRule && accessRule.endTime) {
      this.endTimeOfDay = accessRule.endTime;
    }
  }

  static convertDaysNumberToArray(days: number): boolean[] {
    const daysArray = [false, false, false, false, false, false, false];

    if (days & 0x40) {
      daysArray[0] = true;
    }
    if (days & 0x20) {
      daysArray[1] = true;
    }
    if (days & 0x10) {
      daysArray[2] = true;
    }
    if (days & 0x08) {
      daysArray[3] = true;
    }
    if (days & 0x04) {
      daysArray[4] = true;
    }
    if (days & 0x02) {
      daysArray[5] = true;
    }
    if (days & 0x01) {
      daysArray[6] = true;
    }

    return daysArray;
  }

  /**
   * Format days to number:
   * 1-127 number, representing a byte. Bitmask: 1 - mon -tue - wed - thu - fry - sat - sun.
   *
   * @static
   * @param {boolean[]} days
   * @returns {number}
   * @memberof LKGroupRestrictions
   */
  static formatDaysToDateNumber(days: boolean[]): number {
    let number = 0;

    if (days[1]) {
      // Mon
      number += 0x20;
    }
    if (days[2]) {
      // Tue
      number += 0x10;
    }
    if (days[3]) {
      // Wed
      number += 0x08;
    }
    if (days[4]) {
      // Thu
      number += 0x04;
    }
    if (days[5]) {
      // Fry
      number += 0x02;
    }
    if (days[6]) {
      // Sat
      number += 0x01;
    }
    if (days[0]) {
      // Sun
      number += 0x40;
    }

    return number;
  }

  static formatDaysToStringArray(days: boolean[]): string[] {
    const daysArray: string[] = [];

    if (days[0]) {
      daysArray.push('sun');
    }
    if (days[1]) {
      daysArray.push('mon');
    }
    if (days[2]) {
      daysArray.push('tue');
    }
    if (days[3]) {
      daysArray.push('wed');
    }
    if (days[4]) {
      daysArray.push('thu');
    }
    if (days[5]) {
      daysArray.push('fri');
    }
    if (days[6]) {
      daysArray.push('sat');
    }

    return daysArray;
  }

  static translateDaysMessageToString(days: number): any {
    const daysMessage = {
      daysString: '',
      daysArray: [],
    };

    switch (days) {
      case 0x00:
        daysMessage.daysArray = [0, 0, 0, 0, 0, 0, 0];
        break;
      case 0x7f:
        daysMessage.daysString = 'Every Day';
        daysMessage.daysArray = [1, 1, 1, 1, 1, 1, 1];
        break;
      case 0x3e:
        daysMessage.daysString = 'on Weekdays';
        daysMessage.daysArray = [0, 1, 1, 1, 1, 1, 0];
        break;
      case 0x41:
        daysMessage.daysString = 'on Weekends';
        daysMessage.daysArray = [1, 0, 0, 0, 0, 0, 1];
        break;
      case 0x70:
        daysMessage.daysString = 'between Sunday and Tuesday';
        daysMessage.daysArray = [1, 1, 1, 0, 0, 0, 0];
        break;
      case 0x38:
        daysMessage.daysString = 'between Monday and Wednesday';
        daysMessage.daysArray = [0, 1, 1, 1, 0, 0, 0];
        break;
      case 0x28:
        daysMessage.daysString = 'between Tuesday and Thursday';
        daysMessage.daysArray = [0, 0, 1, 1, 1, 0, 0];
        break;
      case 0x7:
        daysMessage.daysString = 'between Wednesday and Friday';
        daysMessage.daysArray = [0, 0, 0, 1, 1, 1, 0];
        break;
      case 0x14:
        daysMessage.daysString = 'between Thursday and Saturday';
        daysMessage.daysArray = [0, 0, 0, 0, 1, 1, 1];
        break;
      case 0x43:
        daysMessage.daysString = 'between Friday and Sunday';
        daysMessage.daysArray = [1, 0, 0, 0, 0, 1, 1];
        break;
      case 0x31:
        daysMessage.daysString = 'between Saturday and Monday';
        daysMessage.daysArray = [1, 1, 0, 0, 0, 0, 1];
        break;
      case 0x78:
        daysMessage.daysString = 'between Sunday and Wednesday';
        daysMessage.daysArray = [1, 1, 1, 1, 0, 0, 0];
        break;
      case 0x3c:
        daysMessage.daysString = 'between Monday and Thursday';
        daysMessage.daysArray = [0, 1, 1, 1, 1, 0, 0];
        break;
      case 0x1e:
        daysMessage.daysString = 'between Tuesday and Friday';
        daysMessage.daysArray = [0, 0, 1, 1, 1, 1, 0];
        break;
      case 0xf:
        daysMessage.daysString = 'between Wednesday and Saturday';
        daysMessage.daysArray = [0, 0, 0, 1, 1, 1, 1];
        break;
      case 0x47:
        daysMessage.daysString = 'between Thursday and Sunday';
        daysMessage.daysArray = [1, 0, 0, 0, 1, 1, 1];
        break;
      case 0x63:
        daysMessage.daysString = 'between Friday and Monday';
        daysMessage.daysArray = [1, 1, 0, 0, 0, 1, 1];
        break;
      case 0x71:
        daysMessage.daysString = 'between Saturday and Tuesday';
        daysMessage.daysArray = [1, 1, 1, 0, 0, 0, 1];
        break;
      case 0x7c:
        daysMessage.daysString = 'between Sunday and Thursday';
        daysMessage.daysArray = [1, 1, 1, 1, 1, 0, 0];
        break;
      case 0x1f:
        daysMessage.daysString = 'between Tuesday and Saturday';
        daysMessage.daysArray = [0, 0, 1, 1, 1, 1, 1];
        break;
      case 0x4f:
        daysMessage.daysString = 'between Wednesday and Sunday';
        daysMessage.daysArray = [1, 0, 0, 1, 1, 1, 1];
        break;
      case 0x67:
        daysMessage.daysString = 'between Thursday and Monday';
        daysMessage.daysArray = [1, 1, 0, 0, 1, 1, 1];
        break;
      case 0x73:
        daysMessage.daysString = 'between Friday and Tuesday';
        daysMessage.daysArray = [1, 1, 1, 0, 0, 1, 1];
        break;
      case 0x79:
        daysMessage.daysString = 'between Saturday and Wednesday';
        daysMessage.daysArray = [1, 1, 1, 1, 0, 0, 1];
        break;
      case 0x7e:
        daysMessage.daysString = 'between Sunday and Friday';
        daysMessage.daysArray = [1, 1, 1, 1, 1, 1, 0];
        break;
      case 0x3f:
        daysMessage.daysString = 'between Monday and Saturday';
        daysMessage.daysArray = [0, 1, 1, 1, 1, 1, 1];
        break;
      case 0x5f:
        daysMessage.daysString = 'between Tuesday and Sunday';
        daysMessage.daysArray = [1, 0, 1, 1, 1, 1, 1];
        break;
      case 0x6f:
        daysMessage.daysString = 'between Wednesday and Monday';
        daysMessage.daysArray = [1, 1, 0, 1, 1, 1, 1];
        break;
      case 0x77:
        daysMessage.daysString = 'between Thursday and Tuesday';
        daysMessage.daysArray = [1, 1, 1, 0, 1, 1, 1];
        break;
      case 0x7b:
        daysMessage.daysString = 'between Friday and Wednesday';
        daysMessage.daysArray = [1, 1, 1, 1, 0, 1, 1];
        break;
      case 0x7d:
        daysMessage.daysString = 'between Saturday and Thursday';
        daysMessage.daysArray = [1, 1, 1, 1, 1, 0, 1];
        break;
      default:
        if (days & 0x40) {
          daysMessage.daysString += 'Sunday, ';
          daysMessage.daysArray[0] = 1;
        }
        if (days & 0x20) {
          daysMessage.daysString += 'Monday, ';
          daysMessage.daysArray[1] = 1;
        }
        if (days & 0x10) {
          daysMessage.daysString += 'Tuesday, ';
          daysMessage.daysArray[2] = 1;
        }
        if (days & 0x08) {
          daysMessage.daysString += 'Wednesday, ';
          daysMessage.daysArray[3] = 1;
        }
        if (days & 0x04) {
          daysMessage.daysString += 'Thursday, ';
          daysMessage.daysArray[4] = 1;
        }
        if (days & 0x02) {
          daysMessage.daysString += 'Friday, ';
          daysMessage.daysArray[5] = 1;
        }
        if (days & 0x01) {
          daysMessage.daysString += 'Saturday, ';
          daysMessage.daysArray[6] = 1;
        }

        daysMessage.daysString = daysMessage.daysString.substring(
          0,
          daysMessage.daysString.length - 2
        );

        break;
    }

    daysMessage.daysString = daysMessage.daysString.substring(
      0,
      daysMessage.daysString.length
    );

    return daysMessage;
  }
}
