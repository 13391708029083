import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LKCmdResponseType } from '@models/LKCmdResponse';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.manager.service';
import { LoggingService } from '@services/logging.service';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lockers',
  templateUrl: './lockers.component.html',
  styleUrls: ['./lockers.component.scss'],
})
export class LockersComponent implements OnInit {
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['name', 'actions'];
  public loadingLockerData: Boolean;
  public selectedLockerSerial = '';

  constructor(
    private helperService: HelperService,
    private maintenanceService: MaintenanceService,
    private router: Router,
    private loggingService: LoggingService,
    private dialogRef: MatDialogRef<LockersComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.getLockerInfo();
  }

  getLockerInfo() {
    this.loadingLockerData = true;
    const lockerData = [];
    const serial = this.data.sharings
      ? this.helperService.formatSerial(this.data.selectedLocker.serial)
      : this.data.selectedLocker.serial;
    this.data.permissionList.forEach((element) => {
      if (this.helperService.formatSerial(element.device.serial) === serial) {
        if (element.device.kind === 'MULTIPLE') {
          const tableRow = {
            name: element.device.deviceName,
            id: element.device.id,
            unlock: false,
          };
          lockerData.push(tableRow);
        }
      }
    });
    this.dataSource = new MatTableDataSource(lockerData);
    this.loadingLockerData = false;
  }

  unlockDoor(device: any): void {
    device.unlock = true;
    this.maintenanceService
      .unlock(device.id)
      .then((response) => {
        device.unlock = false;
        if (response.successful === true) {
          this.loggingService.gatewayResponse(
            'success',
            `${device.name} successfully unlocked!`
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            `${this.translateService.instant('Error unlocking')}  ${
              device.name
            }: ${this.translateService.instant(
              LKCmdResponseType[response.value]
            )}`
          );
        }
      })
      .catch(() => {
        device.unlock = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Error unlocking')} ${
            device.name
          }: ${this.translateService.instant('device is offline')}!`
        );
      });
  }

  navigate(page: string, id: number) {
    this.dialogRef.close();
    this.router.navigate([`/${page}/${id}`]);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
