import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalsService {
  private corporationId: string = localStorage.getItem('corporation')
    ? JSON.parse(localStorage.getItem('corporation')).id
    : '';
  private siteId: string = localStorage.getItem('site')
    ? JSON.parse(localStorage.getItem('site')).id
    : '';

  setCorporationId = (id: string) => {
    this.corporationId = id;
  };

  getCorporationId = (): string => {
    return this.corporationId;
  };

  setSiteId = (id: string) => {
    this.siteId = id;
  };

  getSiteId = (): string => {
    return this.siteId;
  };
}
