import { LKDevice, ILKDevice } from './LKDevice';
import { LKSite, ILKSite } from './LKSite';
import { LKRole, ILKRole } from './LKRole';
import { LKGroupRestrictions } from './LKGroupRestrictions';
// eslint-disable-next-line import/no-cycle
import { LKUser, ILKUser } from './LKUser';

export interface GroupParams {
  name: string;
  description: string;
  doorList: string;
  roleList: string;
  userList: string;
  siteId: string;
  restrictions: any;
  accessRule: any;
}

export interface ILKGroup {
  id: number;
  name: string;
  description: string;
  doors: ILKDevice[];
  roles: ILKRole[];
  users: ILKUser[];
  site: ILKSite;
  restrictions: any;
  accessRule: any;
  userCount: number;
  doorCount: number;
}

export class LKGroup {
  id: number;
  name: string;
  description: string;
  doors: LKDevice[] = [];
  roles: LKRole[] = [];
  users: LKUser[] = [];
  site: LKSite;
  restrictions: any;
  accessRule: any;
  userCount: number;
  doorCount: number;

  constructor(serverObject: ILKGroup) {
    this.id = serverObject.id;
    this.name = serverObject.name;
    this.description = serverObject.description;
    this.site = new LKSite(serverObject.site);
    this.restrictions = new LKGroupRestrictions(
      serverObject.restrictions,
      serverObject.accessRule
    );
    this.accessRule = serverObject.accessRule;
    this.userCount = serverObject.userCount;
    this.doorCount = serverObject.doorCount;
  }
}
