import { LKCmdResponse } from './LKCmdResponse';

export class LKCmdScheduleCheckResponse extends LKCmdResponse {
  userId: number;
  start: number;
  duration: number;
  startTimestamp: Date;
  endTimestamp: Date;

  static fromServerObject(serverObject: any): LKCmdScheduleCheckResponse {
    const res = new LKCmdScheduleCheckResponse();
    this.fillServerInfo(res, serverObject);
    res.userId = serverObject.userId;
    res.start = serverObject.start;
    res.duration = serverObject.duration;
    res.startTimestamp = new Date(serverObject.startTimestamp);
    res.endTimestamp = new Date(serverObject.endTimestamp);
    return res;
  }
}
