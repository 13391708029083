import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntegrationsService } from '@modules/integrations/integrations.service';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-key',
  templateUrl: './add-key.component.html',
  styleUrls: ['./add-key.component.scss'],
})
export class AddKeyComponent {
  public showCurrentPassword: boolean = false;
  public clientId: number;
  public integrationKey: string = '';
  public showKey: boolean = false;
  public errorPassword: string = '';
  public passwordInvalid = false;
  public currentPassword = new UntypedFormControl('', [
    this.passwordMatchValidator(this.passwordInvalid),
  ]);
  public typeIntegration: string = '';
  public widthSizeSuitable: boolean = false;
  public copied: boolean = false;
  public load: boolean = false;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public dialogRef: MatDialogRef<AddKeyComponent>,
    private integrationsService: IntegrationsService,
    private loggingService: LoggingService,
    private translateService: TranslateService
  ) {
    this.widthSizeSuitable = window.innerWidth <= 500;
    if (this.dialogData.sessionFiltered) {
      this.integrationKey = this.dialogData.sessionFiltered.authorizationString;
    }

    this.clientId = this.dialogData.clientId;
    this.showKey = this.dialogData.showKey;
    this.typeIntegration = this.dialogData.typeIntegration;
  }

  passwordMatchValidator(isPasswordValid: boolean) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === '' && !isPasswordValid) {
        this.passwordInvalid = true;
        return { passwordWrong: true };
      }
      return null;
    };
  }

  configureIntegration() {
    this.integrationsService
      .configureAnIntegration(this.clientId, this.currentPassword.value)
      .subscribe(
        (resp) => {
          this.loggingService.defaultToast(
            'success',
            this.translateService.instant('Integration created successfully.'),
            'center'
          );
          this.integrationKey = resp.authorization;
          this.showKey = true;
        },
        () => {
          if (this.passwordInvalid) {
            this.loggingService.defaultToast(
              'error',

              this.translateService.instant(
                'Password invalid. Please, try again!'
              ),
              'center'
            );
          } else {
            this.loggingService.defaultToast(
              'error',

              this.translateService.instant(
                'Action failed. Please, try again.'
              ),
              'center'
            );
          }
          this.errorPassword = 'Password invalid. Please, try again!';
          this.currentPassword.setValue('');
        }
      );
  }

  async copyToClipboard() {
    this.copied = false;
    this.load = true;
    await navigator.clipboard.writeText(this.integrationKey);
    setTimeout(() => {
      this.load = true;
      this.copied = true;

      setTimeout(() => {
        this.load = false;
        this.copied = false;
      }, 500);
    }, 500);
  }
}
