<div class="dashboard-card" (click)="navigate(label)">
    <div class="loading-div-count" *ngIf="isLoading">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
    </div>
    <span class="dashboard-card__number-info" *ngIf="!isLoading" [ngClass]="numberColor">
        {{ numberInfo }}
        <span *ngIf="totalNumber !== null && totalNumber !== undefined" [ngClass]="numberColor">/{{ totalNumber
            }}</span>
        <div class="dashboard-card__info-divider">
            <div class="dashboard-card__filter-divider"></div>
        </div>
    </span>
    <span *ngIf="!isLoading">
        <span class="dashboard-card__label" translate>{{ label }}</span>
        <!-- <span class="dashboard-card__hover" translate>{{ label }}
            <fa-icon icon="arrow-right"></fa-icon>
        </span></span> -->
    </span>
</div>