import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

const { version } = require('../../../../package.json');

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public activeUrl: Number;
  public version = version;
  public feedbackIcon;
  public hasBooking: boolean = false;
  public hasListings: boolean = false;
  public hasReports: boolean = false;
  public hasDeviceList: boolean = false;
  public largeScreen: boolean = false;
  public faCalendarDays = faCalendarDays;
  @Input() showIcon: boolean;
  @Output() closeSide = new EventEmitter();

  constructor(private router: Router) {
    this.setActivePage(this.router.url);
    this.largeScreen = window.innerWidth >= 1920;
  }

  ngOnInit() {
    this.onPageChange();
    this.feedbackIcon = faCommentAlt;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.largeScreen = window.innerWidth >= 1920;
  }

  verifyAccess() {
    if (localStorage.getItem('corporation') !== null) {
      this.hasBooking = JSON.parse(
        localStorage.getItem('corporation')
      ).hasBooking;
      this.hasListings = JSON.parse(
        localStorage.getItem('corporation')
      ).hasListings;
      this.hasReports = JSON.parse(
        localStorage.getItem('corporation')
      ).hasReports;
      this.hasDeviceList = JSON.parse(
        localStorage.getItem('corporation')
      ).hasDeviceList;
    }
  }

  onPageChange() {
    this.router.events.subscribe((val: any) => {
      if (val.url) {
        this.setActivePage(val.url);
      }
    });
  }

  setActivePage(url: string) {
    this.verifyAccess();
    if (url.includes('general')) {
      this.activeUrl = 0;
    } else if (
      url.includes('devices') ||
      url.includes('permissions') ||
      url.includes('dashboard')
    ) {
      this.activeUrl = 1;
    } else if (url.includes('/groups')) {
      this.activeUrl = 2;
    } else if (url.includes('/users')) {
      this.activeUrl = 3;
    } else if (url.includes('/maintenance')) {
      this.activeUrl = 4;
    } else if (url.includes('/profile')) {
      this.activeUrl = 5;
    } else if (url.includes('/roles')) {
      this.activeUrl = 6;
    } else if (url.includes('/feedback')) {
      this.activeUrl = 7;
    } else if (url.includes('/reports')) {
      this.activeUrl = 8;
    } else if (url.includes('/bookings')) {
      this.activeUrl = 9;
    } else if (url.includes('/listings')) {
      this.activeUrl = 10;
    }
  }

  actionSide() {
    if (window.innerWidth < 500) {
      this.closeSide.emit();
    }
  }
}
