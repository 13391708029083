import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  LKCmdResponseType,
  feedbackLKCmdResponse,
} from '@models/LKCmdResponse';
import { LoggingService } from '@services/logging.service';
import { LKCmdScheduleCheckResponse } from '@models/LKCmdScheduleCheckResponse';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
  public startDate: Date;
  public endDate: Date;
  public activeTimer = false;
  public timeSpent = 0;
  public minutes = 0;
  public hours = 0;
  public timerResponded: boolean = false;
  public checkingTimer = true;
  public timerOn: boolean = false;
  public restartDeviceData: string = '23:59';
  public command: string;
  public invalidTime = false;
  public endTimer: string;

  constructor(
    private maintenanceService: MaintenanceService,
    private loggingService: LoggingService,
    public dialog: MatDialogRef<TimerComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.checkTimer();
  }

  checkTimer(): Promise<void> {
    this.timerResponded = false;
    this.checkingTimer = true;
    this.timerOn = false;
    this.startDate = new Date();

    return this.maintenanceService
      .scheduleCheck(this.data.selectedDevice.id)
      .then((response) => {
        if (response.value === LKCmdResponseType.ERROR_SCHED_STOP) {
          this.timerResponded = true;
          this.timerOn = false;
          this.checkingTimer = false;
          this.calcTimeSpent();
        } else if (
          response.value === LKCmdResponseType.SCHEDULE_REQUEST_RESULT
        ) {
          this.timerResponded = true;
          this.timerOn = true;
          this.checkingTimer = false;
          this.endDate = new Date();
          this.setTimerResponse(response);
        } else {
          this.timerResponded = false;
          this.checkingTimer = false;
        }
      })
      .catch(() => {
        this.timerResponded = false;
        this.checkingTimer = false;
      });
  }

  async setTimer(): Promise<void> {
    this.activeTimer = false;
    this.startDate = new Date();
    this.checkingTimer = true;
    return this.maintenanceService
      .scheduleInit(this.data.selectedDevice.id, 0, this.timeSpent)
      .then(async (res) => {
        this.checkingTimer = false;
        if (res.successful) {
          this.loggingService.gatewayResponse(
            'success',
            this.translateService.instant('Timer successfully set!')
          );
          this.timerResponded = true;
          this.timerOn = true;
          this.checkingTimer = false;
        } else {
          await this.checkTimer();
          if (this.timerOn === false) {
            this.loggingService.gatewayResponse(
              'error',
              `${this.translateService.instant('Failed to set timer:')} ${
                feedbackLKCmdResponse[LKCmdResponseType[res.value]]
              }`
            );
          }
        }
      })
      .catch(() => {
        this.loggingService.gatewayResponse(
          'error',
          this.translateService.instant('Operation timed out.')
        );
        this.checkingTimer = false;
      });
  }

  stopTimer(): void {
    this.activeTimer = false;
    this.checkingTimer = true;
    this.startDate = new Date();

    this.maintenanceService
      .scheduleStop(this.data.selectedDevice.id)
      .then((response) => {
        if (
          response.successful ||
          response.value === LKCmdResponseType.ERROR_SCHED_STOP
        ) {
          this.loggingService.gatewayResponse(
            'success',
            this.translateService.instant('Timer successfully cancelled!')
          );
          this.timerOn = false;
          this.checkingTimer = false;
          this.calcTimeSpent();
        } else {
          this.timerResponded = false;
          this.checkingTimer = false;
          this.loggingService.gatewayResponse(
            'error',
            this.translateService.instant('Failed to cancel timer!')
          );
        }
      })
      .catch(() => {
        // console.log(err);
        this.timerResponded = false;
        this.checkingTimer = false;
        this.loggingService.gatewayResponse(
          'error',
          this.translateService.instant('Failed to cancel timer!')
        );
      });
  }

  calcTimeSpent(): void {
    this.startDate = new Date();
    const [hoursString, minutesString] = this.restartDeviceData.split(':');
    const hours = Number.parseInt(hoursString, 10);
    const minutes = Number.parseInt(minutesString, 10);
    const timerDate = new Date(
      this.startDate.getFullYear(),
      this.startDate.getMonth(),
      this.startDate.getDate(),
      hours,
      minutes
    );
    const nowDailyMinutes =
      this.startDate.getHours() * 60 + this.startDate.getMinutes();
    const timerEndDailyMinutes = hours * 60 + minutes;
    if (timerEndDailyMinutes < nowDailyMinutes) {
      timerDate.setDate(timerDate.getDate() + 1);
    }
    this.endDate = timerDate;
    this.timeSpent = timerDate.getTime() - this.startDate.getTime();
    this.hours = Math.floor(this.timeSpent / (60 * 60 * 1000));
    this.minutes = Math.floor(
      (this.timeSpent - this.hours * 60 * 60 * 1000) / (60 * 1000)
    );
  }

  setTimerResponse(response: LKCmdScheduleCheckResponse): void {
    this.endDate.setTime(this.startDate.getTime() + response.duration);
    this.endTimer = `${String(this.endDate.getHours())}:${String(
      this.endDate.getMinutes()
    )}`;
  }

  closeDialog() {
    this.dialog.close();
  }
}
