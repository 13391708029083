import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LKGroup } from '@models/LKGroup';
import { MatDialog } from '@angular/material/dialog';
import { GroupsService } from '@modules/groups/groups.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { ResultModalComponent } from '@shared/components/result-modal/result-modal.component';
import { DeleteModalComponent } from '@shared/components/delete-modal/delete-modal.component';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { StatusService } from '@services/status.service';
import {
  faCalendarAlt,
  faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FullStoryService } from '@services/fullstory-service.service';
import { EditGroupComponent } from '../edit-group/edit-group.component';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
})
export class GroupDetailsComponent implements OnInit {
  public groupId: string = '';
  public group: LKGroup;
  public loading: boolean = true;
  public sizeWidthSuitable = false;
  public usersAndRoles = [];
  public deletedGroup = [];
  public displayedColumnsUsersAndRoles: string[] = ['name', 'type'];
  public displayedColumnsDevices: string[] = ['name', 'address'];
  public months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  public weekDay: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public totalDevices: Number = 0;
  public totalUsers: Number = 0;
  public totalRoles: Number = 0;
  public pendingLoad: Promise<boolean>;
  public language = 'default';
  public onlineDevices = [];
  public coolOfDevices = [];
  public faCheckCircle = faCheckCircle;
  public faCalendarAlt = faCalendarAlt;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statusService: StatusService,
    public dialog: MatDialog,
    public groupsService: GroupsService,
    public logginService: LoggingService,
    public translateService: TranslateService,
    private cookiesManagerService: CookiesManagerService,
    private fullStoryService: FullStoryService
  ) {}

  async ngOnInit() {
    this.language = this.cookiesManagerService.getLanguage();
    this.sizeWidthSuitable = window.innerWidth <= 500;
    this.pendingLoad = this.getPromise();
    this.statusService.watchOnlineDevices().subscribe(() => {
      this.getDevicesStatus();
    });
    this.statusService.startScan();
    this.groupId = this.route.snapshot.paramMap.get('id');
    await this.loadGroups(0);

    this.fullStoryService.setPageVars({
      pageName: 'Group Details',
      groupId: this.group.id,
    });

    this.groupsService
      .getGroupDoorsList(this.group.id)
      .subscribe((response) => {
        this.group.doors = response;
      });

    this.groupsService
      .getUsersAndRolesList(this.group.id)
      .subscribe((response) => {
        this.group.users = response.users;
        this.group.roles = response.roles;
        this.usersAndRoles = [...this.group.users, ...this.group.roles];
      });

    if (this.group.restrictions.startDateTime !== null) {
      this.group.restrictions.startDateTime = new Date(
        this.group.restrictions.startDateTime
      );
    }

    if (this.group.restrictions.endDateTime !== null) {
      this.group.restrictions.endDateTime = new Date(
        this.group.restrictions.endDateTime
      );
    }

    this.groupsService.getSiteDevices().subscribe((totalOfDevices) => {
      this.totalDevices = totalOfDevices;
    });

    this.groupsService.getUsers().subscribe((totalOfUsers) => {
      this.totalUsers = totalOfUsers;
    });

    this.groupsService.getRolesList().subscribe((totalOfRoles) => {
      this.totalRoles = totalOfRoles;
    });

    this.loading = false;
  }

  async loadGroups(skip: number): Promise<any> {
    this.loading = true;
    this.pendingLoad = this.getPromise();

    return new Promise((resolve, reject) => {
      this.groupsService.getGroupList(skip).subscribe(
        (groups) => {
          const foundGroup = groups.results.find(
            (group) => this.groupId === String(group.id)
          );

          if (foundGroup) {
            this.group = foundGroup;
            this.loading = false;
            resolve(foundGroup);
          } else if (groups.results.length > 0) {
            this.loadGroups(groups.skip + groups.results.length)
              .then(resolve)
              .catch(reject);
          } else {
            this.loading = false;
            resolve(null);
          }
        },
        (error) => {
          this.loading = false;
          reject(error);
        }
      );
    });
  }

  getDevicesStatus() {
    const onlineDevices = this.statusService.returnOnlineDevices().doorStatus;
    this.group.doors.forEach((door) => {
      const hasDoor = onlineDevices.filter(
        (item) =>
          item.id === door.id &&
          (item.status === 'synced' || item.status === 'syncing')
      );
      if (hasDoor.length > 0 && this.onlineDevices.indexOf(door) === -1) {
        this.onlineDevices.push(door);
      }
    });
    this.group.doors.forEach((door) => {
      const hasDoor = onlineDevices.filter(
        (item) => item.id === door.id && item.status === 'cooloff'
      );
      if (hasDoor.length > 0) {
        this.coolOfDevices.push(door);
      }
    });
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loading) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 5000);
    });
  }

  activeUsers(): Number {
    return this.group.users.filter((user) => user.active === true).length;
  }

  editInfo() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        type: 'info',
        group: this.group,
      },
    };
    const dialogRef = this.dialog.open(EditGroupComponent, config);
    dialogRef.componentInstance.groupUpdated.subscribe((group) => {
      this.group = group;
      this.saveOnLocalStorage();
      this.dialog.closeAll();
    });
  }

  editPermission() {
    const config = {
      panelClass: 'dialog-container',
      width: '1000px',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        type: 'restrictions',
        group: this.group,
        restrictions: this.group.restrictions,
      },
    };
    const dialogRef = this.dialog.open(EditGroupComponent, config);
    dialogRef.componentInstance.groupUpdated.subscribe((group) => {
      this.group = group;
      this.saveOnLocalStorage();
      this.dialog.closeAll();
    });
  }

  deleteGroup() {
    const config = {
      panelClass: 'dialog-container',
      width: '450px',
      maxWidth: '100%',
      data: {
        type: 'Group',
        group: this.group,
      },
    };

    const dialogRef = this.dialog.open(DeleteModalComponent, config);
    dialogRef.componentInstance.deleted.subscribe((results) => {
      this.loading = true;
      if (results) {
        this.router.navigate(['/groups']);
        const title = this.translateService.instant(
          `Group Successfully Deleted!`
        );
        const info = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '270px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: true },
        };
        this.dialog.open(ResultModalComponent, info);
        this.loading = false;
      } else {
        const title = this.translateService.instant(`Failed to Delete Group!`);
        const info = {
          panelClass: 'dialog-container',
          width: '450px',
          height: '270px',
          maxHeight: '100%',
          maxWidth: '100%',
          data: { text: title, status: false },
        };
        this.dialog.open(ResultModalComponent, info);
        this.loading = false;
      }
    });
  }

  editDevices() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        type: 'doors',
        group: this.group,
        doors: this.group.doors,
      },
    };

    const dialogRef = this.dialog.open(EditGroupComponent, config);
    dialogRef.componentInstance.groupUpdated.subscribe((group) => {
      this.group = group;
      this.saveOnLocalStorage();
      this.dialog.closeAll();
    });
  }

  editUserOrRoles() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        type: 'users',
        users: this.group.users,
        roles: this.group.roles,
        group: this.group,
      },
    };
    const dialogRef = this.dialog.open(EditGroupComponent, config);
    dialogRef.componentInstance.groupUpdated.subscribe((group) => {
      this.group = group;
      this.usersAndRoles = [...this.group.users, ...this.group.roles];
      this.saveOnLocalStorage();
      this.dialog.closeAll();
    });
  }

  navigate(module: string, device: any) {
    const { id, name } = device;
    localStorage.setItem('name', name);
    this.router.navigate([`/${module}/${id}`]);
  }

  private saveOnLocalStorage() {
    const groups = JSON.parse(localStorage.getItem('groups'));
    if (groups) {
      const index = groups.findIndex(
        (group: LKGroup) =>
          String(group.id) === this.route.snapshot.paramMap.get('id')
      );

      groups[index] = this.group;
    }

    localStorage.setItem('groups', JSON.stringify(groups));
  }

  formatDate(date, type) {
    if (type === 'month') {
      return this.months[new Date(date).getUTCMonth()];
    }
    if (type === 'day') {
      return String(new Date(date).getDate());
    }
    if (type === 'year') {
      return String(new Date(date).getFullYear());
    }

    return '';
  }
}
