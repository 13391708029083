<div class="header">

  <app-breadcrumb [path]="path"></app-breadcrumb>

  <span class="spacer"></span>

  <button
    aria-label="user image"
    mat-button
    class="header__user-btn"
    [matMenuTriggerFor]="options"
  >
    <span class="font-size-12">{{ username.toUpperCase() }}</span>
    <img
      alt="user image"
      class="header__user-img"
      *ngIf="userImg"
      [attr.src]="userImg"
    />
    <img
      alt="user image"
      class="header__user-img"
      *ngIf="!userImg"
      src="../assets/images/avatars/default-user.png"
    />
    <fa-icon icon="angle-down"></fa-icon>
  </button>

  <mat-menu #options="matMenu">
    <ng-template matMenuContent>
      <button
        *ngIf="showDisplayProfile()"
        aria-label="profile"
        mat-menu-item
        [routerLink]="['/profile']"
        class="header__menu-btn"
      >
        <fa-icon icon="user"></fa-icon
        ><span class="pdd-left-10" translate>My Profile</span>
      </button>

      <!-- <button
        *ngIf="showDisplayProfile()"
        aria-label="billing"
        mat-menu-item
        [routerLink]="['/billing']"
        class="header__menu-btn"
      >
        <fa-icon icon="credit-card"></fa-icon>
        <span class="pdd-left-10" translate>Billing</span>
      </button> -->

      <button
        *ngIf="showDisplayProfile()"
        aria-label="change-corp"
        mat-menu-item
        [routerLink]="['/corporations']"
        class="header__menu-btn"
      >
        <fa-icon icon="building"></fa-icon>
        <span class="pdd-left-10" translate>Change Corporation</span>
      </button>

      <button
        aria-label="language"
        mat-menu-item
        [matMenuTriggerFor]="translations"
        class="header__menu-btn"
      >
        <div class="header__change-language-wrapper">
          <span>
            <img
              class="translate-icon"
              src="../../../assets/icons/translate-dark.svg"
              alt="translate"
            />
            <span class="pdd-left-10 mrg-right-10" translate>Change Language</span>
          </span>
        </div>
      </button>

      <mat-menu #translations="matMenu" class="primary-color">
        <ng-template matMenuContent>
          <button
            aria-label="portuguese"
            mat-menu-item
            (click)="changeLang('pt-BR')"
            class="header__menu-btn"
          >
            <img
              alt="brazil-flag"
              src="../../../assets/icons/brazil-flag.svg"
            />
            <span class="pdd-left-5 pdd-right-25">Português</span>
          </button>
          <button
            aria-label="english"
            mat-menu-item
            (click)="changeLang('en')"
            class="header__menu-btn"
          >
            <img alt="brazil-flag" src="../../../assets/icons/eua-flag.svg" />
            <span class="pdd-left-5 pdd-right-25">English</span>
          </button>
        </ng-template>
      </mat-menu>

      <!-- <button
        (click)="navigateToIntegrations()"
        *ngIf="showDisplayProfile()"
        aria-label="system-integrations"
        mat-menu-item
        class="header__menu-btn"
      >
        <fa-icon alt="moon icon" icon="code"></fa-icon>
        <span class="pdd-left-10" translate>System Integrations</span>
      </button> -->

      <button
        aria-label="logout"
        mat-menu-item
        (click)="logoutButton()"
        class="header__menu-btn"
      >
        <fa-icon alt="leave icon" [icon]="faRightFromBracket"></fa-icon>
        <span class="pdd-left-10" translate>Logout</span>
      </button>
    </ng-template>
  </mat-menu>
</div>
