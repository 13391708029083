import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { endpoints } from './endpoints';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(public http: HttpClient) {}
  public defaultTimeout = 60000;

  getDeviceInformation(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_DEVICE_INFO}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getOnlineDevicesList(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_ONLINE_DEVICE_LIST}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  setDoorTime(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.SET_DOOR_TIME}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  setDoorTimezone(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.SET_DOOR_TIMEZONE}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  setDoorUnlock(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.UNLOCK_DOOR}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  scheduleStop(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.SCHEDULE_STOP}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  scheduleStart(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.SCHEDULE_START}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  scheduleCheck(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.SCHEDULE_CHECK}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  customCommand(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.GET_ONLINE_DEVICE_LIST}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getDoorEvents(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DOOR_EVENTS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getDoorsByGateway(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DOORS_GATEWAY}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getGatewaysByDoor(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GATEWAY_DOORS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getUserEvents(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.USER_EVENTS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getDoorDailyStats(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DOOR_DAILY_STATS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getDoorHourlyStats(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DOOR_HOURLY_STATS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getUserPermissions(): Observable<any> {
    return this.http
      .get(`${endpoints.USER_PERMISSIONS}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getDevicePermissionList(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DEVICE_PERMISSIONS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  loginUser(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.LOGIN}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getUser(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_USER}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  updateUser(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.UPDATE_USER}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateDevice(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.UPDATE_DOOR}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateGateway(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.UPDATE_GATEWAY}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateCorp(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.UPDATE_CORP}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateSite(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.UPDATE_SITE}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  changePassword(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.CHANGE_PASSWORD}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  resetPassword(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.RESET_PASSWORD}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  requestPassword(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.REQUEST_NEW_PASSWORD}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getAuthMethods(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.AUTHENTICATION_METHODS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  sendToken(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.SEND_TOKEN}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  loginToken(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.LOGIN_TOKEN}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  invalidateSession(data: string): Observable<any> {
    return this.http
      .post(`${endpoints.INVALIDATE_SESSION}`, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getCorporations(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_CORPORATIONS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getCorporationsSites(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_CORPORATION_SITES}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getSiteDoors(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_SITE_DOORS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  createCorporation(data: string): Observable<any> {
    return this.http
      .post(endpoints.CREATE_CORPORATION, data)
      .pipe(timeout(this.defaultTimeout));
  }

  addDoorToSite(data: string): Observable<any> {
    return this.http
      .post(endpoints.ADD_DOOR_TO_SITE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  createRole(data: string): Observable<any> {
    return this.http
      .post(endpoints.CREATE_ROLE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  createSite(data: string): Observable<any> {
    return this.http
      .post(endpoints.CREATE_SITE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getSiteRoles(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_SITE_ROLES}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getExternalRoles(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_EXTERNAL_ROLES}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  createAccessGroup(data: string): Observable<any> {
    return this.http
      .post(endpoints.CREATE_ACCESS_GROUP, data)
      .pipe(timeout(this.defaultTimeout));
  }

  removeAccessGroup(params: string): Observable<any> {
    return this.http
      .post(endpoints.REMOVE_GROUP, params)
      .pipe(timeout(this.defaultTimeout));
  }

  updateAccessGroup(data: string): Observable<any> {
    return this.http
      .post(endpoints.UPDATE_ACCESS_GROUP, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateDevicesAccessGroup(data: string): Observable<any> {
    return this.http
      .post(endpoints.UPDATE_DEVICES_ACCESS_GROUP, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateUsersAndRolesAccessGroup(data: string): Observable<any> {
    return this.http
      .post(endpoints.UPDATE_USERS_AND_ROLES_ACCESS_GROUP, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getAccessGroups(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_ACCESS_GROUPS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  findAccessGroups(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.FIND_ACCESS_GROUPS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  createUserOnCorp(data: string): Observable<any> {
    return this.http
      .post(endpoints.CREATE_USER, data)
      .pipe(timeout(this.defaultTimeout));
  }

  findUserOrRoleOnSite(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.FIND_USER_OR_ROLE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getUserOnCorp(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_CORPORATION_USERS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  changeUserStatus(data: string): Observable<any> {
    return this.http
      .post(endpoints.CHANGE_USER_STATUS, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateLKUser(data: string): Observable<any> {
    return this.http
      .post(endpoints.UPDATE_LKUSER, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getRoleUsers(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_ROLE_USERS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  deleteRole(params: string): Observable<any> {
    return this.http
      .post(endpoints.DELETE_ROLE, params)
      .pipe(timeout(this.defaultTimeout));
  }

  updateRoleUsers(params: string): Observable<any> {
    return this.http
      .post(endpoints.UPDATE_ROLE_USERS, params)
      .pipe(timeout(this.defaultTimeout));
  }

  editRole(data: string): Observable<any> {
    return this.http
      .post(endpoints.EDIT_ROLE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateGlobalRole(data: string): Observable<any> {
    return this.http
      .post(endpoints.UPDATE_GLOBAL_ROLE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getDoorByAccessGroup(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_DOORS_ACCESS_GROUP}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getUserAndRoleByAccessGroup(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_USERS_AND_ROLES_ACCESS_GROUP}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }
  getUserAccessGroups(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_USER_ACCESS_GROUPS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getUserRoles(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_USER_ROLES}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  sendFeedback(params: string): Observable<any> {
    return this.http
      .post(endpoints.SEND_FEEDBACK, params)
      .pipe(timeout(this.defaultTimeout));
  }

  getIntegrations(params: string): Observable<any> {
    return this.http.get(`${endpoints.GET_INTEGRATIONS}?${params}`);
  }

  createIntegration(params: string): Observable<any> {
    return this.http.post(endpoints.CREATE_INTEGRATION, params);
  }

  importListings(params: string): Observable<any> {
    return this.http.post(endpoints.IMPORT_LISTINGS, params);
  }

  createSessionIntegration(params: string): Observable<any> {
    return this.http
      .post(endpoints.CONFIGURE_INTEGRATION, params)
      .pipe(timeout(this.defaultTimeout));
  }

  removeIntegration(params: string): Observable<any> {
    return this.http.post(endpoints.REMOVE_INTEGRATION, params);
  }

  getWeekUsageContent(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GENERAL_EVENT_WEEK_USAGE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getHourlyUsageContent(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GENERAL_EVENT_HOURLY_USAGE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getMonthlyUsageContent(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GENERAL_EVENT_MONTH_USAGE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getGeneralEventsDashboard(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GENERAL_EVENTS_DASHBOARD}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getBookingsStats(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.BOOKINGS_STATS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getDevicesStats(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DEVICES_STATS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getGeneralDashboardEventsSite(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DASHBOARD_GENERAL_EVENTS_SITE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getGraphWeekUsage(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GRAPH_WEEK_USAGE_DEVICE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getDevicesGroupsAndUsers(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DEVICES_GROUPS_AND_USERS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getGraphHourlyUsage(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GRAPH_HOURLY_USAGE_DEVICE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getGraphDailyUsage(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GRAPH_DAILY_USAGE_DEVICE}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getTopAccessedUsers(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.MOST_ACCESSED_USERS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getDeniedAccessedUsers(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DENIED_ACCESS_USERS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getFirmwareVersion(params: string): Observable<any> {
    return this.http
      .post(endpoints.FIRMWARE_VERSION_DEVICE, params)
      .pipe(timeout(this.defaultTimeout));
  }

  getBatteryStateDevice(params: string): Observable<any> {
    return this.http
      .post(endpoints.BATTERY_STATE_DEVICE, params)
      .pipe(timeout(this.defaultTimeout));
  }

  generatePassCodeOrQrcode(params: string): Observable<any> {
    return this.http
      .post(endpoints.GENERATE_QRCODE_PASSCODE, params)
      .pipe(timeout(this.defaultTimeout));
  }

  sendPassCodeOrQrcode(params: string): Observable<any> {
    return this.http
      .post(endpoints.SEND_QRCODE_PASSCODE, params)
      .pipe(timeout(this.defaultTimeout));
  }

  getGatewaysFromSite(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.SITE_GATEWAYS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getReports(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_REPORTS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getReportInfo(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.REPORT_QUERY}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  createReport(data: string): Observable<any> {
    return this.http
      .post(endpoints.CREATE_REPORT, data)
      .pipe(timeout(this.defaultTimeout));
  }

  deleteReport(data: string): Observable<any> {
    return this.http
      .post(endpoints.DELETE_REPORT, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getReportDetail(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.DETAIL_REPORT}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getPermissionsSites(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.SITE_PERMISSIONS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getPersonalInfo(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.PERSONAL_INFO}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getBookings(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_BOOKINGS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getBackupCode(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_BOOKINGS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  createBooking(data: string): Observable<any> {
    return this.http
      .post(endpoints.CREATE_BOOKING, data)
      .pipe(timeout(this.defaultTimeout));
  }

  updateBooking(data: string): Observable<any> {
    return this.http
      .post(endpoints.UPDATE_BOOKING, data)
      .pipe(timeout(this.defaultTimeout));
  }

  findBooking(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.FIND_BOOKING}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  deleteBooking(data: string): Observable<any> {
    return this.http
      .post(endpoints.DELETE_BOOKING, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getGroupsAndUsers(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_GROUPS_AND_USERS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getBillings(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_BILLINGS}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getExternalRoleInfo(params: string): Observable<any> {
    return this.http
      .get(`${endpoints.GET_EXTERNAL_ROLE_INFO}?${params}`)
      .pipe(timeout(this.defaultTimeout));
  }

  getConnectRoleLink(data: string): Observable<any> {
    return this.http
      .post(endpoints.GET_CONNECT_ROLE_LINK, data)
      .pipe(timeout(this.defaultTimeout));
  }

  connectRole(
    headers: any,
    connectRoleLink: string,
    params: string
  ): Observable<any> {
    return this.http
      .post(`${connectRoleLink}?${params}`, { headers })
      .pipe(timeout(this.defaultTimeout));
  }

  disconnectRole(data: string): Observable<any> {
    return this.http
      .post(endpoints.DISCONNECT_ROLE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getAccess(): Observable<any> {
    return this.http
      .get(`${endpoints.GET_ACCESS}`)
      .pipe(timeout(this.defaultTimeout));
  }

  removeDevice(data: string): Observable<any> {
    return this.http
      .post(endpoints.REMOVE_DEVICE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  removeSite(data: string): Observable<any> {
    return this.http
      .post(endpoints.REMOVE_SITE, data)
      .pipe(timeout(this.defaultTimeout));
  }

  getListings(params: string): Observable<any> {
    return this.http.get(`${endpoints.GET_LISTINGS}?${params}`);
  }

  getCorpIntegrations(params: string): Observable<any> {
    return this.http.get(`${endpoints.GET_CORP_INTEGRATIONS}?${params}`);
  }

  getListingById(params: string): Observable<any> {
    return this.http.get(`${endpoints.GET_LISTING_BY_ID}?${params}`);
  }

  upsertDoorsOnListing(data: string): Observable<any> {
    return this.http
      .post(endpoints.UPSERT_DOORS_ON_LISTING, data)
      .pipe(timeout(this.defaultTimeout));
  }

  sendGatewayCommand(data: string): Observable<any> {
    return this.http.post(endpoints.SEND_COMMAND, data);
  }

  generateOfflinePasscode(data: string): Observable<any> {
    return this.http.post(endpoints.GENERATE_OFFLINE_PASSCODE, data);
  }
}
