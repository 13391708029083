import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-booking-result',
  templateUrl: './booking-result.component.html',
  styleUrls: ['./booking-result.component.scss'],
})
export class BookingResultComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() finishEvent = new EventEmitter();
  @Input() stepper: MatStepper;
  @Input() action: string;
  @Input() responseBooking: {
    response: Object;
    error: boolean;
  };
  public sizeWidthSuitable: boolean;

  ngOnInit() {
    this.sizeWidthSuitable = window.innerWidth <= 500;
  }

  finish() {
    this.finishEvent.emit();
  }

  backAction() {
    this.stepper.previous();
    this.previousItem.emit();
  }
}
