<div class="guesty-integration">
  <div class="text-center">
    <h5 mat-dialog-title translate>Guesty Integration</h5>
  </div>
  <div class="guesty-integration__step-header-second">
    <h6>
      <fa-icon *ngIf="currentStepIndex !== 1" (click)="previousStep(stepper)" class="mrg-right-5" icon="arrow-left">
      </fa-icon>{{labelStepSelected}} - {{currentStepIndex}}/2
    </h6>
  </div>

  <div mat-dialog-content>
    <mat-horizontal-stepper class="guesty-integration__stepper" linear="true" #stepper>
      <mat-step [label]="'1. Integration Credentials' | translate">
        <div class="mrg-top-30">
          <span translate>To start an integration between Guesty and LoopKey Corp you need to have access to a Guesty
            API Key and a Guesty API Secret from the account you want to link.</span>
          <div class="mrg-top-20">
            <span class="label-input">{{ 'Integration Token' | translate }}</span>
            <fa-icon class="font-size-14 mrg-left-10" icon="info-circle"></fa-icon>
            <mat-form-field class="width-100" appearance="outline">
              <input matInput [formControl]="guestyControl" type="text"
                placeholder="{{'Insert the token from your Guesty account' | translate}}" />
              <mat-error *ngIf="guestyControl.errors" translate>
                Token is not valid.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="steppers">
            <app-stepper-controller isEditing="true" [actionText]="'Next'"
              [blockButton]="guestyControl.errors ? true : false" (onFormSubmitted)="goForward(stepper)">
            </app-stepper-controller>
          </div>
        </div>
      </mat-step>

      <mat-step [label]="'2. Import Listings' | translate">
        <div mat-dialog-content class="mrg-top-20">
          <mat-list class="no-pdd">
            <mat-card appearance="outlined" class="guesty-integration__card">
              <h5 class="title" translate>Import Details</h5>
              <div class="mrg-top-20">
                <span class="info" translate>
                  Listings imported to LoopKey Corp will use the region (States) to cluster it into a new Site Cards
                  inside the current Corporation you're importing.
                </span>
              </div>
              <div class="mrg-top-20">
                <span class="info" translate>
                  Your imported listings will named as the example below:
                </span>
                <br />
                <span class="info">
                  - São Paulo - Guesty
                </span>
              </div>
            </mat-card>
          </mat-list>
          <div class="attention-message" *ngIf="!loadingData">
            <span>
              <fa-icon class="mrg-right-5" icon="exclamation-triangle"></fa-icon>
              <span translate>Attention: This process can take up to 1 minute. All listings will be available after it
                finishes.</span>
            </span>
          </div>
          <div class="loading-spinner" *ngIf="loadingData && !(pendingLoad | async)">
            <mat-spinner diameter="40" color="accent"></mat-spinner>
          </div>
          <div class="steppers">
            <app-stepper-controller isEditing="false" [actionText]="'Import'" (onFormCleared)="previousItem.emit()"
              (onFormSubmitted)="onSubmit()">
            </app-stepper-controller>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>