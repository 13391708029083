import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LKDeviceEvents } from '@models/LKDeviceEvents';
import { LKEvent } from '@models/LKEvent';
import { DashboardService } from '@modules/dashboard/dashboard.service';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { UserService } from '@modules/users/users.service';
import { LKUserEvent } from '@models/LKUserEvent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SnackAccessComponent } from '../snack-access/snack-access.component';

@Component({
  selector: 'app-accesses',
  templateUrl: './accesses.component.html',
  styleUrls: ['./accesses.component.scss'],
})
export class AccessesComponent implements OnInit, OnDestroy {
  @Input() selectedPermission: any;
  @Input() selectedScreen: string;
  public images = [];
  public imagesID = [];
  public hasLogs = true;
  public renderedData = [];
  public currentPage = 0;
  public scrollCallback;
  public scanCallback;
  public imgRef = '../../../assets/images/avatars/default-user.png';
  public lastLogsInterval;
  public totalResults = 0;
  public logsHistoryAux = [];
  public events: LKDeviceEvents | LKUserEvent = { events: [], count: 0 };
  public filterData = [];
  public event: LKEvent;
  public userImage: any;
  public hasAccessToday: boolean = false;
  public language = 'default';
  public reachedTheEnd: boolean = false;
  public filterName: string = '';
  public isNotInUserPage: boolean = true;
  public faExclamationTriangle = faExclamationTriangle;

  constructor(
    private dashboardService: DashboardService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private cookiesManagerService: CookiesManagerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    if (this.router.url.toLowerCase().includes('users')) {
      this.isNotInUserPage = false;
    }
    this.scrollCallback = this.getLogs.bind(this);
    this.scanCallback = this.scanLogs.bind(this);
    this.language = this.cookiesManagerService.getLanguage();
  }

  getLogs(): Observable<any> {
    if (!this.isNotInUserPage) {
      return this.userService
        .getUserEvent(this.route.snapshot.params.id, 30 * this.currentPage, 30)
        .pipe(tap(<any>this.processData));
    }
    return this.dashboardService
      .getDeviceEvents(
        this.route.snapshot.params.id,
        null,
        null,
        30 * this.currentPage,
        30,
        'access,management',
        'DESC'
      )
      .pipe(tap(<any>this.processData));
  }

  scanLogs(): Observable<any> {
    if (!this.isNotInUserPage) {
      return this.userService.getUserEvent(this.route.snapshot.params.id, 0, 5);
    }
    return this.dashboardService.getDeviceEvents(
      this.route.snapshot.params.id,
      null,
      null,
      0,
      5,
      'access,management',
      'DESC'
    );
  }

  private processData = async (response) => {
    const eventsResponse = response;
    this.currentPage++;
    if (eventsResponse.events.length > 0) {
      this.events.events = this.events.events.concat(eventsResponse.events);
      this.filterData = this.events.events;
      this.events.count = eventsResponse.count;
      if (this.events.events.length < 30) {
        this.reachedTheEnd = true;
      }
    } else {
      this.reachedTheEnd = true;
    }
    if (this.events.events.length === 0) {
      this.hasLogs = false;
    }
  };

  ngOnInit() {
    this.getDayFirstLog();
  }

  ngOnDestroy() {
    clearInterval(this.lastLogsInterval);
  }

  private notifyNewLogs = async (response) => {
    if (!this.hasAccessToday) {
      this.getDayFirstLog();
    }
    this.events.events = response.events.concat(this.events.events);
    for (let index = 0; index < response.count - this.events.count; index++) {
      const event = this.events.events[index];

      this.openSnackBar(
        event.getUserNameOrContact(),
        event.getDateLogAsString(this.language)
      );
    }
    this.events.count = response.count;
  };

  openSnackBar(name: String, date: string) {
    this.snackBar.openFromComponent(SnackAccessComponent, {
      duration: 7000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { name, date },
    });
  }

  formatDateEvent(event: LKEvent, isLogHour = false): string {
    if (!isLogHour) {
      return event.getDateEvent().split(' - ')[0];
    }

    return event.getDateEvent().split(' - ')[1];
  }

  formatDate(event: LKEvent): string {
    return event.getDateLogAsString(this.language);
  }

  getDayFirstLog() {
    let startDate = new Date();
    startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
      0
    );

    if (this.isNotInUserPage) {
      this.dashboardService
        .getDeviceEvents(
          this.route.snapshot.params.id,
          startDate,
          null,
          0,
          1,
          'access',
          'ASC'
        )
        .subscribe(
          (response) => {
            if (response.count > 0) {
              this.hasAccessToday = true;
              [this.event] = response.events;
            }
          },
          () => {}
        );
    }
  }

  clearFilter() {
    this.filterName = '';
    this.filterData = this.events.events;
  }

  filterContainIn(action: String, filterString: String) {
    return action
      .toLocaleLowerCase()
      .includes(filterString.toLocaleLowerCase());
  }

  applyFilter(filterString: string): void {
    this.filterName = filterString;

    this.filterData = this.events.events.filter((event) => {
      return (
        this.filterContainIn(event.getDescription(), filterString) ||
        this.filterContainIn(event.getTargetNameOrContact(), filterString) ||
        this.filterContainIn(event.getUserNameOrContact(), filterString)
      );
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getMessageEvent(event: LKEvent) {
    switch (event.operation) {
      case 'timerSet':
        return this.translateService.instant('Timer Set');
      case 'timerCancel':
        return this.translateService.instant('Timer Canceled');
      case 'timerCancelButton':
        return this.translateService.instant('Timer Canceled Button');
      case 'timerStart':
        return this.translateService.instant('Timer Started');
      case 'alarmDoorHalfOpen':
        return this.translateService.instant('Timer Canceled');
      case 'timerEnd':
        return this.translateService.instant('Timer End');
      case 'privateModeChange':
        return this.translateService.instant('Private Mode Changed');
      case 'permissionCreate':
        return this.translateService.instant('Shared');
      case 'permissionUpdate':
        return this.translateService.instant('Permission Updated');
      case 'permissionRemove':
        return this.translateService.instant('Timer Removed');
      case 'alarmOutOfRestrictionsGuestCommand':
        return this.translateService.instant('Alarm out of restriction');
      case 'alarmUnlockDenied':
        return this.translateService.instant('Denied');
      case 'unlockExitButton':
        return this.translateService.instant('Unlock Exit Button');
      case 'unlockCommand':
        if (event.commandMethod === 'otp') {
          return this.translateService.instant('Unlocked (Backup Passcode)');
        }
        return this.translateService.instant('Unlocked');

      case 'unlockPhysicalKey':
        return this.translateService.instant('Unlock using physical key');
      case 'create':
        return this.translateService.instant('Created');
      case 'update':
        return this.translateService.instant('Updated');
      default:
        return '';
    }
  }
}
