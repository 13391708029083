import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingsService } from '@modules/bookings/bookings.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'app-offline-passcode',
  templateUrl: './offline-passcode.component.html',
  styleUrls: ['./offline-passcode.component.scss'],
})
export class OfflinePasscodeComponent implements OnInit {
  @Output() passcodeGenerated = new EventEmitter<boolean>();
  public sizeSuitableWidth: boolean = false;
  public loadingData: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialogRef<OfflinePasscodeComponent>,
    private bookingService: BookingsService,
    private loggingService: LoggingService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.sizeSuitableWidth = window.innerWidth <= 500;
  }

  generatePasscode() {
    this.loadingData = true;

    this.bookingService
      .generateOfflinePasscode(this.data.booking.id)
      .subscribe({
        complete: () => {
          this.loadingData = false;
          this.loggingService.customAlert(
            {
              title: this.translateService.instant(
                'Backup Password created successfully!'
              ),
              text: `${this.translateService.instant(
                'Backup passwords have a lifetime limit of up to 1 year.'
              )}\n
                ${this.translateService.instant(
                  'The backup password must be used within 12 hours of being generated, otherwise, it will be automatically removed.'
                )}`,
            },
            'success'
          );
          this.passcodeGenerated.emit(true);
          this.close();
        },
        error: (e) => {
          this.loadingData = false;
          this.loggingService.customAlert(
            {
              title: this.translateService.instant(
                'Error creating Backup Passcode'
              ),
              text: this.getErrorMessage(e.error),
            },
            'error'
          );
          this.dialog.close({ successful: false });
        },
      });
  }

  getErrorMessage(error: any) {
    if (error.errorCode === 101) {
      return this.translateService.instant(error.errorDescription);
    }
    if (error.errorCode === 666) {
      return this.translateService.instant(
        'There was an unexpected error when trying to create a backup password. Please try again soon.'
      );
    }
    if (error.errorCode === 108) {
      return this.translateService.instant(
        'The backup passwords are not active for your corporation or this user does not have permission to create them.'
      );
    }
    if (error.errorCode === 136) {
      return this.translateService.instant(
        'The backup password cannot be created for reservations without having at least one port configured.'
      );
    }
    if (error.errorCode === 150) {
      return this.translateService.instant(
        'Your lock has an old firmware version. Update to access the latest version of the features.'
      );
    }
    return null;
  }

  close() {
    this.dialog.close({ successful: true });
  }
}
