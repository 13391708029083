<div class="timer">
  <div class="timer__title">
    <h4 mat-dialog-title>{{ 'TIMER' | translate }}</h4>
  </div>

  <div mat-dialog-content class="timer__content">
    <div class="timer__loading" *ngIf="checkingTimer">
      <div>
        <mat-spinner diameter="40" color="accent" class="center-spinner">
        </mat-spinner>
      </div>
    </div>

    <div *ngIf="!checkingTimer">
      <div *ngIf="timerResponded; else loopkeyNotAnswering" class="text-center">
        <div *ngIf="timerOn; else timerOff">
          <span class="warning_label">{{ 'This device has an active timer!' | translate }}</span>
          <div class="mrg-vertical-25">
            <div>
              <span class="warning_label">{{ 'Timer will end: ' | translate }}</span>
            </div>
            <input
              class="mrg-top-5"
              id="activeTimer"
              type="time"
              disabled="true"
              [value]="endTimer"
            />
          </div>
        </div>

        <ng-template #timerOff>
          <span class="warning_label">{{ 'There is no active timer!' | translate }}</span>
          <div class="mrg-vertical-25">
            <div>
              <span class="warning_label">{{ 'Keep door open until:' | translate }}</span>
            </div>
            <input
              id="startTime"
              type="time"
              class="allow-input"
              [(ngModel)]="restartDeviceData"
              [ngxTimepicker]="pickerTime"
              [format]="24"
              readonly
            />
            <ngx-material-timepicker #pickerTime>
            </ngx-material-timepicker>
          </div>
          <div *ngIf="!invalidTime; else invalidTimer">
            <span class="warning_label">{{ 'Door will be open for: ' | translate }}</span
            ><br />
            <span class="warning_label">
              <b>{{ hours }}{{ 'h and ' }}{{ minutes }}min</b>
            </span>
          </div>
          <ng-template #invalidTimer class="text-center">
            <span class="warning_label">{{ 'Set a valid timer to continue!' | translate }}</span>
          </ng-template>
        </ng-template>
      </div>

      <ng-template class="mrg-top-20" #loopkeyNotAnswering>
        <div class="timer__no-answer">
          <fa-icon class="warn-text font-size-35" icon="exclamation-triangle">
          </fa-icon>
          <div class="mrg-top-15">
            <span class="warning_label">{{ 'Loopkey is not answering!' | translate }}</span>
          </div>
        </div>
      </ng-template>

      <div class="row mrg-top-30">
        <div class="col-md-6">
          <button
            aria-label="close modal"
            mat-raised-button
            class="secondary-color"
            (click)="closeDialog()"
          >
            <span>{{ 'Cancel' | translate }}</span>
          </button>
        </div>
        <div class="col-md-6 text-right">
          <button
            aria-label="set timer"
            mat-raised-button
            class="accent"
            (click)="setTimer()"
            *ngIf="timerResponded && !timerOn"
          >
            <span>{{ 'Activate' | translate }}</span>
          </button>
          <button
            aria-label="cancel timer"
            mat-raised-button
            class="accent"
            (click)="stopTimer()"
            *ngIf="timerResponded && timerOn"
          >
            <span>{{ 'Deactivate' | translate }}</span>
          </button>
          <button
            aria-label="try again"
            mat-raised-button
            class="accent"
            (click)="checkTimer()"
            *ngIf="!timerResponded"
          >
            <span>{{ 'Try again' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
