import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookiesManagerService } from '@services/cookies.manager.service';
import { UserSessionService } from '@services/user.session.service';
import { faMoon } from '@fortawesome/free-regular-svg-icons';
import { UiStyleToggleService } from 'app/ui-service.service';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() username: string;
  @Input() path: string[];
  @Input() userImg: string | ArrayBuffer;
  public iconMoonRegular = faMoon;
  public faRightFromBracket = faRightFromBracket;

  showReturn = false;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private userSessionService: UserSessionService,
    private cookiesManagerService: CookiesManagerService,
    private uiStyleToggleService: UiStyleToggleService
  ) {}

  ngOnInit() {
    if (this.showButton()) {
      this.showReturn = true;
    } else {
      this.showReturn = false;
    }

    this.router.events.subscribe(() => {
      if (this.showButton()) {
        this.showReturn = true;
      } else {
        this.showReturn = false;
      }
    });
  }

  showButton() {
    return (
      this.router.url.includes('dashboard') ||
      this.router.url.includes('permissions')
    );
  }

  logoutButton() {
    this.userSessionService.removeSession();
    this.router.navigate(['/authentication']);
  }

  changeLang(language: string) {
    this.translateService.use(language);
    this.cookiesManagerService.setLanguage(language);
  }

  return() {
    this.router.navigate(['/devices']);
  }

  navigateToIntegrations() {
    this.router.navigate(['/integrations']);
  }

  showDisplayProfile(): boolean {
    return !(
      this.router.url.includes('corporations') ||
      this.router.url.includes('sites')
    );
  }
}
