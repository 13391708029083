<div class="edit-group">

    <div class="text-center" [ngClass]="data.type === 'info' ? 'edit-group__info-group': ''">
        <h5 mat-dialog-title translate>Edit {{data.type}}</h5>
    </div>
    <mat-dialog-content>

        <app-group-info
          (infoSet)="setInfo($event)"
          *ngIf="data.type === 'info'"
          [info]="data.group"
          [editInfo]="true">
        </app-group-info>

        <app-group-restrictions
          (restrictionsSet)="setRestrictions($event)"
          *ngIf="data.type === 'restrictions'"
          [restrictionsEdit]="data.restrictions"
          [group]="data.group"
          [editPermission]="true">
        </app-group-restrictions>

        <app-group-doors
          (devicesSet)="setDoors($event)"
          *ngIf="data.type === 'doors'"
          [doors]="data.doors"
          [group]="data.group"
          [editDoors]="true">
        </app-group-doors>

        <app-search-members
          (usersSet)="setUsersAndRoles($event)"
          *ngIf="data.type === 'users'"
          [users]="data.users"
          [roles]="data.roles"
          [group]="data.group"
          [editUsers]="true"
          [roleSearch]="false">
        </app-search-members>

    </mat-dialog-content>
</div>
