import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DevicesService } from '@modules/devices/devices.service';
import { LKDevice } from '@models/LKDevice';
import { ListingsService } from '@modules/listings/listings.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-select-device',
  templateUrl: './select-device.component.html',
  styleUrls: ['./select-device.component.scss'],
})
export class SelectDeviceComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() devicesSet = new EventEmitter<{}>();
  @Input() selectedDevices: LKDevice[] = [];
  @Input() devices: LKDevice[] = [];
  @Input() screen: string;
  public widthSizeSuitable: boolean = false;
  public siteDevices: any[] = [];
  public myControl = new UntypedFormControl();
  public selectedOptions: any[] = [];
  public filteredOptions: any[] = [];
  public isImageLoading = false;
  public loadingData: boolean = false;
  public pendingLoad: Promise<boolean>;
  public deviceInfos = {
    info: [],
    ids: [],
  };
  public faSearch = faSearch;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private devicesService: DevicesService,
    private listingsService: ListingsService
  ) {
    this.myControl = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
  }

  ngOnInit() {
    this.widthSizeSuitable = window.innerWidth <= 500;
    this.loadDevices();
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 40000);
    });
  }

  loadDevices() {
    if (this.data && this.data.screen === 'Listings') {
      this.loadingData = true;
      this.pendingLoad = this.getPromise();
      this.devicesService.getSiteDevices().subscribe(
        (deviceList) => {
          this.siteDevices = deviceList;
          this.listingsService
            .getListingById(this.data.listingId)
            .subscribe((listingDetails) => {
              this.selectedOptions = deviceList.filter(
                (item) => listingDetails.doorIds.indexOf(item.id) > -1
              );
              this.siteDevices = deviceList.filter(
                (item) => listingDetails.doorIds.indexOf(item.id) === -1
              );
              this.loadingData = false;
            });
        },
        () => {}
      );
    } else if (this.screen === 'Devices') {
      if (this.selectedDevices) {
        this.selectedOptions = this.selectedDevices;
      }
      setTimeout(async () => {
        this.devicesService.getDevicesAccess().subscribe(async (response) => {
          response.doors.forEach((door) => {
            const hasDevice = this.selectedOptions.filter(
              (deviceSelected) =>
                door instanceof LKDevice && deviceSelected.id === door.id
            );
            if (hasDevice.length === 0 && door.accessLevel === 'app.owner') {
              this.siteDevices.push(door);
            }
          });
        });
      }, 200);
    }
  }

  removeOption(option: LKDevice) {
    this.siteDevices.push(option);
    this.selectedOptions = this.selectedOptions.filter((selectOption) => {
      return selectOption !== option;
    });
  }

  private clearInfos() {
    this.deviceInfos.ids = [];
    this.deviceInfos.info = [];
  }

  injectDevices() {
    this.devices.map((device) =>
      this.selectedOptions.push(new LKDevice(device))
    );
  }

  onSubmit() {
    const addDevice = [];
    const removeDevice = [];

    this.clearInfos();
    this.devices.forEach((device) => {
      const deviceSelected = this.selectedOptions.filter(
        (u) => u.id === device.id
      );
      if (deviceSelected.length === 0) {
        removeDevice.push(device.id.toString());
      }
    });
    this.selectedOptions.forEach((device) => {
      const deviceSelected = this.devices.filter((u) => u.id === device.id);
      if (deviceSelected.length === 0) {
        addDevice.push(device.id.toString());
      }
      this.deviceInfos.ids.push(device.id);
      this.deviceInfos.info.push(device);
    });
    this.devicesSet.emit(this.deviceInfos);
  }

  addOption(option: LKDevice) {
    this.selectedOptions.push(option);
    /* eslint-disable consistent-return */
    /* eslint-disable array-callback-return */
    this.siteDevices = this.siteDevices.filter((device) => {
      if (device.id !== option.id) {
        return device !== option;
      }
    });
    this.filteredOptions = [];
  }

  addDevice(event) {
    this.selectedOptions.push(event.source.value);
    this.myControl.reset();
  }
}
