<div class="feedback">
  <app-top-loading [loadingProp]="loadingData && !(pendingLoad | async)">
  </app-top-loading>
  <app-no-connection *ngIf="pendingLoad | async" (refreshPage)="loadInfoSession()">
  </app-no-connection>
  <div class="centered" *ngIf="loadingData && !(pendingLoad | async)">
    <mat-spinner diameter="40" color="accent"></mat-spinner>
  </div>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
  <div *ngIf="!(pendingLoad | async) && !loadingData" class="feedback row">
    <div class="col-md-6 feedback-card" *ngIf="!sizeWidthSuitable || sizeWidthSuitable && showSupport">
      <div class="card-info mrg-left-20" [formGroup]="feedbackForm">
        <h5 *ngIf="!sizeWidthSuitable" translate>Support</h5>
        <div class="row" style="justify-content: space-around;" *ngIf="sizeWidthSuitable">
          <div [ngClass]="!showSupport ? 'feedback-tab' : 'feedback-tab-selected'">
            <h5 translate [ngClass]="!showSupport ? 'feedback-title' : 'feedback-title-accent'" (click)="viewSupport()">
              Support</h5>
          </div>
          <div [ngClass]="!showFeedback ? 'feedback-tab' : 'feedback-tab-selected'">
            <h5 translate [ngClass]="!showFeedback ? 'feedback-title' : 'feedback-title-accent'"
              (click)="viewFeedback()">Feedback</h5>
          </div>
        </div>
        <hr *ngIf="sizeWidthSuitable" />
        <div class="mrg-top-20">
          <div class="row g-0 support">
            <div class="support-icon col-2">
              <span class="dot">
                <fa-icon icon="envelope" class="action-icon"></fa-icon>
              </span>
            </div>
            <div class="support-description col">
              <h6 translate>Email Support</h6>
              <span translate>
                Create a new ticket support via email. Describe your problem and attach files to help on your
                request.
              </span>
            </div>
            <div class="action-buttons col">
              <button class="btn-action" onClick="window.open('mailto:suporte@loopkey.com.br', '_top')"
                aria-label="new ticket">
                <span class="label-color">{{ 'Create new ticket' | translate }}</span>
              </button>
            </div>
          </div>
          <div class="row g-0 support">
            <div class="support-icon col-2">
              <span class="dot">
                <fa-icon [icon]="whatsappIcon" class="action-icon"></fa-icon>
              </span>
            </div>
            <div class="support-description col">
              <h6 translate>Whatsapp Chat</h6>
              <span translate>
                Start a message Chat with our Customer Service and we’ll help you with.
              </span>
            </div>
            <div class="action-buttons col">
              <button class="btn-action" aria-label="new ticket"
                onClick="window.open('https://api.whatsapp.com/send?phone=551130902909&text=Olá,%20preciso%20tirar%20uma%20dúvida%20sobre%20o%20LoopKey%20Corp', '_blank')">
                <span class="label-color">{{ 'Start Chating' | translate }}</span>
              </button>
            </div>
          </div>
          <div class="row g-0 support">
            <div class="support-icon col-2">
              <span class="dot">
                <fa-icon [icon]="faQuestionCircle" class="action-icon"></fa-icon>
              </span>
            </div>
            <div class="support-description col">
              <h6 translate>LoopKey’s FAQ</h6>
              <span translate>
                Navigate through our Frequently Asked Questions to find more about LoopKey’s products.
              </span>
            </div>
            <div class="action-buttons col">
              <button class="btn-action" aria-label="new ticket"
                onClick="window.open('https://loopkey.freshdesk.com/support/home', '_blank')">
                <span class="label-color">{{ 'See LoopKey’s FAQ' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 feedback-card" *ngIf="!sizeWidthSuitable || sizeWidthSuitable && showFeedback">
      <div class="card-info" [formGroup]="feedbackForm">
        <h5 *ngIf="!sizeWidthSuitable" translate>Feedback and Feature Request</h5>
        <div class="row" style="justify-content: space-around;" *ngIf="sizeWidthSuitable">
          <div [ngClass]="!showSupport ? 'feedback-tab' : 'feedback-tab-selected'">
            <h5 translate [ngClass]="!showSupport ? 'feedback-title' : 'feedback-title-accent'" (click)="viewSupport()">
              Support</h5>
          </div>
          <div [ngClass]="!showFeedback ? 'feedback-tab' : 'feedback-tab-selected'">
            <h5 translate [ngClass]="!showFeedback ? 'feedback-title' : 'feedback-title-accent'"
              (click)="viewFeedback()">Feedback</h5>
          </div>
        </div>
        <hr *ngIf="sizeWidthSuitable" />
        <div class="mrg-left-5 mrg-top-20">
          <span>
            <span translate>Hi</span>, {{ currentUsername }}!<br /><br /></span>
          <span translate>
            Here at LoopKey we believe that our users feedback are the best way to
            improve our products and make our solution perfect for you.<br /><br />
          </span>
          <span translate>
            Please, add more details as possible so we can fully understand your
            needs.<br /><br />
          </span>
          <span translate>
            Oh, if you need to send some print screens or file to illustrate it,
            feel free :)
          </span>
        </div>
        <div class="mrg-top-25">
          <span translate>Feedback Type*</span>
          <mat-form-field class="width-100 mrg-top-5" appearance="outline">
            <mat-select placeholder="{{ 'Please choose one option' | translate }}" id="category"
              formControlName="category" matInput type="text">
              <mat-option value="Bugs" style="background-color: #181B1E; color: white;">{{ 'Bug' | translate }}
              </mat-option>
              <mat-option value="Novas Funcionalidades" style="background-color: #181B1E; color: white">
                {{ 'Feature' | translate }}</mat-option>
              <mat-option value="Melhorias" style="background-color: #181B1E; color: white">
                {{ 'Improvements' | translate }}</mat-option>
            </mat-select>
            <mat-error *ngIf="f.category.errors" translate>
              Required. Please choose one option.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mrg-top-10">
          <span translate>Brief Description*</span>
          <mat-form-field class="width-100 mrg-top-5" appearance="outline">
            <input placeholder="{{
              'Please provide a brief description of your feedback' | translate
            }}" id="feature" formControlName="feature" matInput type="text" class="secondary-color" />
            <mat-error *ngIf="f.category.errors" translate>
              Required. Must have at least 3 characters.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mrg-top-10">
          <span translate>Description Details</span>
          <mat-form-field class="width-100 mrg-top-5 text-area" appearance="outline">
            <textarea placeholder="{{
              'Please provide as much details as possible.' | translate
            }}" id="feedbackDescription" formControlName="feedbackDescription" matInput class="secondary-color"
              rows="10" cols="40"></textarea>
          </mat-form-field>
        </div>
        <div class="mrg-top-10">
          <div class="files mrg-top-5">
            <label *ngIf="printscreens.length < 1" style="cursor: pointer;" (click)="fileInput.click()">
              <mat-chip-listbox class="mrg-top-25" #chipList disabled>
                <mat-chip-option *ngIf="printscreens.length < 1" class="feedback__bubble">
                  {{ 'Add file' | translate }}
                  <fa-icon icon="plus" class="mrg-left-5 font-size-10"></fa-icon>
                </mat-chip-option>
              </mat-chip-listbox>
            </label>
            <mat-chip-listbox *ngIf="printscreens.length > 0" class="mrg-top-25" #chipList>
              <mat-chip-option *ngFor="let printscreen of printscreens" class="feedback__bubble__printscreen"
                [removable]="true" (removed)="remove(printscreen)">
                {{ printscreen }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </div>
        <input id="fileInput" #fileInput type="file" (change)="fileChangeEvent($event)" />
        <div class="col-md-12">
          <button aria-label="submit" mat-raised-button class="accent submit-button mrg-top-50 right"
            [disabled]="!feedbackForm.valid" (click)="onSubmit()">
            <span>{{ 'Submit my Feedback' | translate }}</span>
          </button>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</div>