import { Injectable } from '@angular/core';
import { ResultModalComponent } from '@shared/components/result-modal/result-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class LoggingService {
  constructor(private dialog: MatDialog) {}

  defaultToast(toastType: string, toastTitle: string, toastPosition: any) {
    const config = {
      panelClass: 'dialog-container',
      width: '500px',
      height: '350px',
      maxHeight: '100%',
      maxWidth: '100%',
      data: {
        text: toastTitle,
        status: toastType !== 'error',
        warning: toastType === 'warning',
        toastPosition,
      },
    };
    this.dialog.open(ResultModalComponent, config);
  }

  customAlert(toastObj, type: string) {
    const config = {
      panelClass: 'dialog-container',
      width: '500px',
      height: '400px',
      maxHeight: '100%',
      maxWidth: '100%',
      data: {
        text: toastObj.title,
        status: type !== 'error',
        warning: type === 'warning',
        subtitle: toastObj.text,
      },
    };

    this.dialog.open(ResultModalComponent, config);
  }

  gatewayResponse(type: string, message: string) {
    this.customAlert(
      {
        title: message,
      },
      type
    );
  }
}
