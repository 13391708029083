import * as qs from 'querystring';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LKCmdResponse } from '@models/LKCmdResponse';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/http/api.service';
import { LKCmdScheduleCheckResponse } from '@models/LKCmdScheduleCheckResponse';
import { environment } from 'environments/environment';
import { LKDevice } from '@models/LKDevice';
import { GlobalsService } from '@services/globals.service';
import { HelperService } from '@services/helper.manager.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MaintenanceService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private globalsService: GlobalsService,
    private helperService: HelperService
  ) {}

  getDevicePermissionList(deviceId: number): Observable<any> {
    return this.apiService.getDevicePermissionList(
      qs.stringify({ id: deviceId })
    );
  }

  getSiteDevices = (
    sortBy?: string,
    direction?: string,
    query?: string,
    filterByStatus?: string
  ): Observable<any> => {
    const params = qs.stringify(
      this.filterNonNull({
        siteId: this.globalsService.getSiteId(),
        query,
        filterByStatus,
        sortBy,
        direction,
      })
    );
    return this.apiService.getSiteDoors(params);
  };

  filterNonNull(obj) {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
      if (v === Object(v)) {
        newObj[k] = this.filterNonNull(v);
      } else if (v != null) {
        newObj[k] = obj[k];
      }
    });
    return newObj;
  }

  updateDevice(device): Observable<any> {
    const params = {
      id: device.id,
      name: device.name,
      address: device.address,
      battery: device.battery,
      latitude: device.latitude,
      longitude: device.longitude,
      key: device.key,
      adminKey: device.adminKey,
      useRealLogs: device.useRealLogs,
    };

    return this.apiService.updateDevice(qs.stringify(params));
  }

  updateGateway(gateway): Observable<any> {
    const params = {
      gatewayId: gateway.id,
      address: gateway.address,
    };

    return this.apiService.updateGateway(qs.stringify(params));
  }

  async setTime(doorId: string): Promise<LKCmdResponse> {
    return this.sendCommand(doorId, '/corp/site/door/send/update_time');
  }

  async setTimeZone(doorId: string): Promise<LKCmdResponse> {
    return this.sendCommand(doorId, '/corp/site/door/send/update_timezone');
  }

  async unlock(doorId: string): Promise<LKCmdResponse> {
    return this.sendCommand(doorId, '/corp/site/door/send/unlock');
  }

  async scheduleStop(doorId: string): Promise<LKCmdResponse> {
    return this.sendCommand(doorId, '/corp/site/door/send/schedule_stop');
  }

  async restart(doorId: string): Promise<LKCmdResponse> {
    return this.sendCommand(doorId, '/corp/site/door/send/restart');
  }

  async forceSync(doorId: string): Promise<LKCmdResponse> {
    const params = {
      doorId,
      siteId: this.globalsService.getSiteId(),
      removeDoorInSyncing: true,
    };
    return this.sendCommandWithParams(params, '/corp/site/door/sync');
  }

  sendGatewayCommand(gatewayId: string, command: string): Observable<any> {
    const params = {
      gatewayId,
      siteId: this.globalsService.getSiteId(),
      command,
    };
    return this.apiService.sendGatewayCommand(qs.stringify(params));
  }

  async scheduleInit(
    doorId: string,
    start: number,
    duration: number
  ): Promise<LKCmdResponse> {
    const params = {
      id: doorId,
      start: String(start),
      duration: String(duration),
      siteId: this.globalsService.getSiteId(),
    };
    return this.sendCommandWithParams(
      params,
      '/corp/site/door/send/schedule_start'
    );
  }

  async scheduleCheck(doorId: string): Promise<LKCmdScheduleCheckResponse> {
    const params = {
      id: doorId,
      siteId: this.globalsService.getSiteId(),
    };
    return this.sendPost(params, '/corp/site/door/send/schedule_check').then(
      (res) => {
        return LKCmdScheduleCheckResponse.fromServerObject(res);
      }
    );
  }

  async custom(doorId: string, command: string): Promise<LKCmdResponse> {
    const params = {
      id: doorId,
      customCommand: command,
    };
    return this.sendCommandWithParams(params, '/corp/site/door/send/custom');
  }

  private async sendCommandWithParams(
    params,
    endpoint: string
  ): Promise<LKCmdResponse> {
    return this.sendPost(params, endpoint).then((res) => {
      return LKCmdResponse.fromServerObject(res);
    });
  }

  private async sendCommand(
    doorId: string,
    endpoint: string
  ): Promise<LKCmdResponse> {
    const params = {
      id: doorId,
      siteId: this.globalsService.getSiteId(),
    };
    return this.sendCommandWithParams(params, endpoint);
  }

  private sendPost(params: Record<string, string>, endpoint: string) {
    const body = new URLSearchParams(params);
    return this.http
      .post(`${environment.endpoint}${endpoint}`, String(body))
      .toPromise();
  }

  getFirmwareVersion(data: any): Observable<any> {
    return this.apiService.getFirmwareVersion(
      qs.stringify({ id: data.id, siteId: this.globalsService.getSiteId() })
    );
  }

  getBatteryDevice(data: any): Observable<any> {
    return this.apiService.getBatteryStateDevice(
      qs.stringify({
        id: data.id,
        siteId: this.globalsService.getSiteId(),
      })
    );
  }

  getSiteGateways = (
    sortBy?: string,
    direction?: string,
    query?: string,
    filterByStatus?: string
  ): Observable<LKDevice[]> => {
    const params = {
      siteId: this.globalsService.getSiteId(),
      query,
      filterByStatus,
      sortBy,
      direction,
    };
    return this.apiService.getGatewaysFromSite(qs.stringify(params));
  };

  getDoorsByGateway = (gatewayId: string): Observable<LKDevice[]> => {
    return this.apiService.getDoorsByGateway(qs.stringify({ gatewayId }));
  };

  getGatewaysByDoor = (doorId: string): Observable<[any]> => {
    return this.apiService.getGatewaysByDoor(qs.stringify({ doorId })).pipe(
      map((response: [any]) => {
        return response;
      })
    );
  };
}
