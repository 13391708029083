<div class="search-toggle">
  <mat-button-toggle-group #group="matButtonToggleGroup" class="width-100">
    <mat-button-toggle
      aria-label="toggle option"
      *ngFor="let element of filters; let i = index"
      value="element.value"
      [checked]="i == 0"
      (click)="applyFilter(element.value)"
      [disabled]="isDisabled"
    >
      <span>
        {{ element.value | translate }}&nbsp;
      </span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
