import * as qs from 'querystring';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private apiService: ApiService) {}

  sendFeedback(feedback: any): Observable<any> {
    return this.apiService.sendFeedback(
      qs.stringify({
        ...feedback,
      })
    );
  }

  sendFeedbackPicture(feedback: any, print: string): Observable<any> {
    return this.apiService.sendFeedback(
      qs.stringify({
        ...feedback,
        files: print,
        mimeType: 'PNG',
      })
    );
  }
}
