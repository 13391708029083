<div class="booking-summary">
  <span class="booking-summary__subtitle" translate>Please check all information and make sure it match to your
    specifications. You can Edit it by clicking on the Edit button at each section or after you have completed.</span>
  <div mat-dialog-content>
    <mat-list class="no-pdd">
      <mat-card appearance="outlined" class="booking-summary__card">
        <div class="title">
          <h5 translate>Guest Info</h5>
          <span class="link" (click)="changeStep(0)" translate>Edit</span>
        </div>
        <div class="row">
          <div class="col">
            <h6 translate>Name</h6>
            <mat-list-item class="width-100" appearance="outline">
              {{ booking.userName }} {{ booking.userSurname }}
            </mat-list-item>
          </div>
          <div class="col">
            <h6 translate>Phone</h6>
            <mat-list-item class="width-100" appearance="outline">
              <span *ngIf="booking.userPhone && booking.userId">
                {{ booking.userPhone.number }}
              </span>
              <span *ngIf="booking.userPhone && !booking.userId">
                {{ booking.userPhone.internationalNumber }}
              </span>
              <span *ngIf="!booking.userPhone">
                -
              </span>
            </mat-list-item>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6 translate>Email</h6>
            <mat-list-item class="width-100" appearance="outline">
              <span *ngIf="booking.userEmail">
                {{ booking.userEmail }}
              </span>
              <span *ngIf="!booking.userEmail">
                -
              </span>
            </mat-list-item>
          </div>
          <div class="col">
            <h6 translate>CPF</h6>
            <mat-list-item class="width-100" appearance="outline">
              <span *ngIf="booking.userCpf">{{ booking.userCpf }}</span>
              <span *ngIf="!booking.userCpf">
                -
              </span>
            </mat-list-item>
          </div>
        </div>
        <div class="booking-summary__divider"></div>

        <div class="title">
          <h5 translate>Booking Info</h5>
          <span class="link" (click)="changeStep(1)" translate>Edit</span>
        </div>
        <div class="row">
          <div class="col">
            <h6 translate>Checkin</h6>
            <span class="width-100" appearance="outline">
              {{ getDateAsString(booking.startDateTime) }}
            </span>
          </div>
          <div class="col">
            <h6 translate>Checkout</h6>
            <span class="width-100" appearance="outline">
              {{ getDateAsString(booking.endDateTime) }}
            </span>
          </div>
        </div>
        <div class="booking-summary__divider"></div>

        <div class="title">
          <h5 translate>Rooms</h5>
          <span class="link" (click)="changeStep(2)" translate>Edit</span>
        </div>
        <div *ngIf="booking.doors.length !== 0; else doorListEmpty">
          <mat-chip-listbox #chipList>
            <mat-chip-option *ngFor="let door of booking.doors" class="booking-summary__bubble">
              <fa-icon icon="door-closed" class="mrg-right-5"></fa-icon>
              <span>{{ door.name }}</span>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>

        <ng-template #doorListEmpty>
          <mat-list-item class="width-100" appearence="outline">
            <span translate>No door was selected</span>
          </mat-list-item>
        </ng-template>
        <div class="booking-summary__divider"></div>

        <div class="mrg-top-20">
          <mat-checkbox [formControl]="sendPasscodeBySms" class="check-sms">
            <span translate>Send passcode via SMS</span>
          </mat-checkbox>
        </div>
      </mat-card>
    </mat-list>

    <div class="booking-summary__lgpd">
      <div class="action-lgpd">
        <mat-checkbox [formControl]="allowedPersonalData" translate>
          <span class="lgpd-info">
            <span translate>I claim, assuming responsibility for any damages caused, that the holder's personal
              data was collected in accordance with the General Data Protection Law, having been informed to the
              holder about its sharing with LoopKey, as well as providing access to the privacy policy of the
              company through the link:</span>
            <a href="www.loopkey.com.br/privacidade">
              www.loopkey.com.br/privacidade
            </a>.
          </span>
          <mat-error *ngIf="allowedPersonalData.value !== true" translate>
            {{
            'Required field' | translate
            }}
          </mat-error>
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="steppers">
    <app-stepper-controller isEditing="false" [blockButton]="(!allowedPersonalData.value || isSubmiting) ? true : false"
      [actionText]="action === 'Create' ? 'Create' : 'Save'" (onFormCleared)="previousItem.emit()"
      (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>