import { LKDevice, ILKDevice } from './LKDevice';
import { LKUser, ILKUser } from './LKUser';
import { ILKSite, LKSite } from './LKSite';
import {
  ILKGroupRestrictions,
  LKGroupRestrictions,
} from './LKGroupRestrictions';

export interface ILKBooking {
  id: number;
  name: string;
  description: string;
  site: ILKSite;
  state: string;
  restrictions: ILKGroupRestrictions;
  deleted: boolean;
  client: string;
  users: Array<ILKUser>;
  doors: Array<ILKDevice>;
  showPasscode: boolean;
  showOfflinePasscode: boolean;
}

export class LKBooking {
  id: number;
  name: string;
  description: string;
  site: LKSite;
  state: string;
  restrictions: LKGroupRestrictions;
  users: [LKUser];
  doors: [LKDevice];
  showPasscode: boolean;
  showOfflinePasscode: boolean;

  constructor(serverObject: Partial<ILKBooking>) {
    Object.assign(this, serverObject);
  }
}
