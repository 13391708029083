import * as qs from 'querystring';
import { Observable } from 'rxjs';
import { HelperService } from '@services/helper.manager.service';
import { ApiService } from '@core/http/api.service';
import { LKDeviceEvents } from '@models/LKDeviceEvents';
import { LKEvent, ILKEvent } from '@models/LKEvent';
import { map } from 'rxjs/operators';
import { GlobalsService } from '@services/globals.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardService {
  constructor(
    private helperService: HelperService,
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getDeviceEvents(
    doorId: string,
    startTimestamp: Date,
    endTimestamp: Date,
    skip: number,
    limit: number = 30,
    eventKind: string = 'access',
    order: string = 'DESC'
  ): Observable<any> {
    const params = qs.stringify({
      siteId: this.globalsService.getSiteId(),
      doorId,
      startTimestamp: this.helperService.getDateTimeString(startTimestamp),
      endTimestamp: this.helperService.getDateTimeString(endTimestamp),
      skip,
      limit,
      eventKind,
      order,
    });

    return this.apiService
      .getDoorEvents(params)
      .pipe(map((response) => this.processEvents(response)));
  }

  async getDeviceEventsAsync(
    doorId: string,
    startTimestamp: Date,
    endTimestamp: Date,
    skip: number,
    limit: number = 30,
    eventKind: string = 'access',
    order: string = 'DESC'
  ): Promise<any> {
    const params = qs.stringify({
      siteId: this.globalsService.getSiteId(),
      doorId,
      startTimestamp: this.helperService.getDateTimeString(startTimestamp),
      endTimestamp: this.helperService.getDateTimeString(endTimestamp),
      skip,
      limit,
      eventKind,
      order,
    });

    return this.apiService.getDoorEvents(params).toPromise();
  }

  processEvents(data: any): LKDeviceEvents {
    const events: LKEvent[] = [];
    data.events.forEach((event: ILKEvent) => {
      events.push(new LKEvent(event));
    });
    return new LKDeviceEvents({ events, count: data.count });
  }

  getStats(
    deviceId: number,
    startDate: string,
    daysCount: number,
    type: string
  ): Observable<any> {
    const params = qs.stringify({
      siteId: this.globalsService.getSiteId(),
      startDate,
      doorId: deviceId,
      daysCount,
    });

    if (type === 'daily') {
      return this.apiService.getDoorDailyStats(params);
    }
    return this.apiService.getDoorHourlyStats(params);
  }

  getDeviceInfo(deviceId: string): Observable<any> {
    return this.apiService.getDeviceInformation(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        doorId: deviceId,
      })
    );
  }

  getDevicesGroupsAndUsers(doorId: string): Observable<any> {
    return this.apiService.getDevicesGroupsAndUsers(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        doorId,
      })
    );
  }

  getGraphWeekUsage(
    doorId: string,
    previousDaysCount: number,
    startDate: string
  ): Observable<any> {
    return this.apiService.getGraphWeekUsage(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        doorId,
        previousDaysCount,
        startDate,
      })
    );
  }

  getGraphHourlyUsage(
    doorId: string,
    previousDaysCount: number,
    startDate: string
  ): Observable<any> {
    const hoursSplit = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24];
    return this.apiService.getGraphHourlyUsage(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        doorId,
        previousDaysCount,
        startDate,
        hoursSplit,
      })
    );
  }

  getGraphDailyUsage(
    doorId: string,
    daysCount: number,
    startDate: string
  ): Observable<any> {
    return this.apiService.getGraphDailyUsage(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        doorId,
        daysCount,
        startDate,
      })
    );
  }

  getMostAccessedUsers(
    doorId: string,
    startTimestamp: string,
    endTimestamp: string,
    limit: number = 3
  ) {
    return this.apiService.getTopAccessedUsers(
      qs.stringify({
        doorId,
        startTimestamp,
        endTimestamp,
        limit,
      })
    );
  }

  getDeniedAccessedUsers(
    doorId: string,
    startTimestamp: string,
    endTimestamp: string,
    limit: number = 3
  ) {
    return this.apiService.getDeniedAccessedUsers(
      qs.stringify({
        doorId,
        startTimestamp,
        endTimestamp,
        limit,
      })
    );
  }
}
