<div class="group-restriction-wrapper">
  <div class="group-restrictions" [ngStyle]="{ marginBottom: editPermission && sizeWidthSuit ? '20px' : '' }">
    <div>
      <div class="row">
        <div class="column left width-100">
          <span class="group-restrictions__title" translate>
            Allowed Devices
          </span>
          <section>
            <mat-slide-toggle [(ngModel)]="restrictions.passcodeAllowed">
              <span translate>Passcode</span>
            </mat-slide-toggle>
          </section>

          <section>
            <mat-slide-toggle [(ngModel)]="restrictions.cardAllowed">
              <span translate>Card</span>
            </mat-slide-toggle>
          </section>

          <section>
            <mat-slide-toggle [(ngModel)]="restrictions.smartphoneAllowed">
              <span translate>Smartphone</span>
            </mat-slide-toggle>
          </section>

          <section>
            <mat-slide-toggle [(ngModel)]="restrictions.remoteAllowed">
              <span translate>Remote</span>
            </mat-slide-toggle>
          </section>

          <section>
            <mat-slide-toggle [(ngModel)]="restrictions.qrcodeAllowed">
              <span translate>QRCode</span>
            </mat-slide-toggle>
          </section>
        </div>

        <div class="column right width-100">
          <span class="group-restrictions__title" translate>
            Access device without internet
          </span>
          <section>
            <mat-slide-toggle [(ngModel)]="restrictions.offlineAllowed" [disabled]="accessType !== 1"><span
                translate>Offline access</span>
              <fa-icon icon="question-circle" class="mrg-left-5 question-icon"
                [matTooltip]="offlineTooltip | translate">
              </fa-icon>
            </mat-slide-toggle>
          </section>
          <div *ngIf="restrictions.offlineAllowed">
            <span>
              <p class="group-restrictions__warning-offline">
                <fa-icon class="warn-text" icon="exclamation-triangle">
                </fa-icon>
                <b translate>
                  Atention: If you want to remove this permission, it will only
                  be removed when the user connects to the internet again!
                </b>
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="accessType == 1 && !restrictions.offlineAllowed">
      <div class="column left width-100">
        <span class="group-restrictions__title" translate>
          Date Restrictions
        </span>
        <section>
          <mat-slide-toggle [(ngModel)]="allDates" [disabled]="!allTimes">
            <span translate>Permanent Access</span>
          </mat-slide-toggle>
        </section>
        <div *ngIf="!allDates" class="mrg-top-10 group-restrictions__date-restr">
          <div class="inline-block">
            <span>{{ 'Access starts on' | translate }}:</span>
            <div>
              <mat-form-field appearance="outline">
                <input matInput class="pdd-left-10 primary-color width-70" (click)="pickerStartDate.open()" type="text"
                  [matDatepicker]="pickerStartDate" [(ngModel)]="restrictions.startDateTime" readonly />
                <mat-datepicker-toggle matSuffix [for]="pickerStartDate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerStartDate disabled="false">
                </mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <br />
          <div class="inline-block">
            <span>{{ 'Access ends on' | translate }}:</span>
            <div>
              <mat-form-field appearance="outline">
                <input class="pdd-left-10 primary-color width-70" (click)="pickerEndDate.open()" type="text" matInput
                  [matDatepicker]="pickerEndDate" [(ngModel)]="restrictions.endDateTime" readonly />
                <mat-datepicker-toggle matSuffix [for]="pickerEndDate">
                </mat-datepicker-toggle>
                <mat-datepicker #pickerEndDate disabled="false">
                </mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="column right width-100">
        <span class="group-restrictions__title" translate>
          Daily Restrictions
        </span>
        <div class="mrg-vertical-10">
          <section class="group-restrictions__access-day">
            <mat-slide-toggle [(ngModel)]="allTimes" (change)="allTimesToggleChanged()">
            </mat-slide-toggle>
            <span translate>Access every day all day long</span>
          </section>
        </div>
        <div *ngIf="!allTimes" class="mrg-vertical-10">
          <div class="group-restrictions__day-of-week">
            <div>
              <span>{{ 'Access days' | translate }}:</span>
            </div>
            <div class="group-restrictions__day-box" (click)="daysList[0] = !daysList[0]" [ngStyle]="{
                backgroundColor: daysList[0] ? '#1382AB' : '#979797'
              }">
              <span class="span-day-label" translate>{{ 'Sun' | translate }}</span>
            </div>
            <div class="group-restrictions__day-box" (click)="daysList[1] = !daysList[1]" [ngStyle]="{
                backgroundColor: daysList[1] ? '#1382AB' : '#979797'
              }">
              <span class="span-day-label" translate>Mon</span>
            </div>
            <div class="group-restrictions__day-box" (click)="daysList[2] = !daysList[2]" [ngStyle]="{
                backgroundColor: daysList[2] ? '#1382AB' : '#979797'
              }">
              <span class="span-day-label" translate>Tue</span>
            </div>
            <div class="group-restrictions__day-box" (click)="daysList[3] = !daysList[3]" [ngStyle]="{
                backgroundColor: daysList[3] ? '#1382AB' : '#979797'
              }">
              <span class="span-day-label" translate>Wed</span>
            </div>
            <div class="group-restrictions__day-box" (click)="daysList[4] = !daysList[4]" [ngStyle]="{
                backgroundColor: daysList[4] ? '#1382AB' : '#979797'
              }">
              <span class="span-day-label" translate>Thu</span>
            </div>
            <div class="group-restrictions__day-box" (click)="daysList[5] = !daysList[5]" [ngStyle]="{
                backgroundColor: daysList[5] ? '#1382AB' : '#979797'
              }">
              <span class="span-day-label" translate>Fri</span>
            </div>
            <div class="group-restrictions__day-box" (click)="daysList[6] = !daysList[6]" [ngStyle]="{
                backgroundColor: daysList[6] ? '#1382AB' : '#979797'
              }">
              <span class="span-day-label" translate>Sat</span>
            </div>
          </div>
          <div class="mrg-top-20 group-restrictions__range-time">
            <div class="inline-block">
              <span>{{ 'Allow after' | translate }}:</span>
              <div>
                <mat-form-field class="input-time">
                  <input id="startTime" type="time" class="allow-input" [(ngModel)]="restrictions.startTimeOfDay"
                    [ngxTimepicker]="pickerStartTime" [format]="24" readonly matInput />
                  <button (click)="clearStartDate()" class="button_clear" matSuffix>
                    <fa-icon icon="times"></fa-icon>
                  </button>
                  <ngx-material-timepicker #pickerStartTime [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
                  </ngx-material-timepicker>
                  <ng-template #cancelBtn>
                    <button mat-raised-button class="cancel-button cancel-btn-float"><span translate
                        style="color: #fff !important;">Cancel</span></button>
                  </ng-template>
                  <ng-template #confirmBtn>
                    <button mat-raised-button class="accent"><span translate
                        style="color: #fff !important;">Set</span></button>
                  </ng-template>
                </mat-form-field>
              </div>
            </div>
            <br />
            <div class="inline-block">
              <span>{{ 'Allow until' | translate }}:</span>
              <div>
                <mat-form-field class="input-time">
                  <input id="endTime" type="time" class="allow-input" [(ngModel)]="restrictions.endTimeOfDay"
                    [ngxTimepicker]="pickerEndTime" [format]="24" readonly matInput />
                  <button (click)="clearEndDate()" class="button_clear" matSuffix>
                    <fa-icon icon="times"></fa-icon>
                  </button>
                  <ngx-material-timepicker #pickerEndTime [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
                  </ngx-material-timepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="steppers">
    <app-stepper-controller isEditing="{{ editPermission }}" [actionText]="editPermission ? 'Save' : 'Next'"
      (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>