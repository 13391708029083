import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LKDevice } from '@models/LKDevice';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-listings-integration',
  templateUrl: './listings-integration.html',
  styleUrls: ['./listings-integration.scss'],
})
export class ListingIntegrationComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() devicesSet = new EventEmitter<{}>();
  @Input() selectedDevices: LKDevice[] = [];
  @Input() devices: LKDevice[] = [];
  public fullModal: boolean = false;
  public widthSizeSuitable: boolean = false;
  public integration = new UntypedFormControl();
  public listing = new UntypedFormControl();
  public deviceInfos = {
    info: [],
    ids: [],
  };
  public siteDevices = [
    {
      name: 'New Name',
    },
  ];
  public faSearch = faSearch;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog) {
    this.integration = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
    this.listing = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);
  }

  ngOnInit() {
    this.widthSizeSuitable = window.innerWidth <= 500;
  }

  onSubmit() {
    this.fullModal = true;
  }
}
