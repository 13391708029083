import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { GlobalsService } from '@services/globals.service';

@Injectable()
export class CorporationGuard implements CanActivate {
  constructor(private router: Router, private globalsService: GlobalsService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.globalsService.getCorporationId()) {
      return true;
    }
    this.router.navigate(['/corporations']);
    return false;
  }
}
