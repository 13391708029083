import * as qs from 'querystring';
import { ApiService } from '@core/http/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalsService } from '@services/globals.service';
import { LKGroup, ILKGroup, GroupParams } from '@models/LKGroup';
import { ILKUser, LKUser } from '@models/LKUser';
import { ILKRole, LKRole } from '@models/LKRole';
import { ILKDevice, LKDevice } from '@models/LKDevice';
import { Injectable } from '@angular/core';

@Injectable()
export class GroupsService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getRolesList(): Observable<Number> {
    return this.apiService
      .getSiteRoles(
        qs.stringify({
          siteId: this.globalsService.getSiteId(),
          corpId: this.globalsService.getCorporationId(),
        })
      )
      .pipe(map((response) => response.length));
  }

  getUsers = (): Observable<Number> => {
    return this.apiService
      .getUserOnCorp(
        qs.stringify({ corpId: this.globalsService.getCorporationId() })
      )
      .pipe(map((response) => response.count));
  };

  getSiteDevices = (): Observable<Number> => {
    return this.apiService
      .getSiteDoors(qs.stringify({ siteId: this.globalsService.getSiteId() }))
      .pipe(
        map((response: ILKDevice[]) => {
          return response.length;
        })
      );
  };

  getUsersAndRolesList(id: number): Observable<any> {
    return this.apiService
      .getUserAndRoleByAccessGroup(qs.stringify({ id }))
      .pipe(
        map((response) => this.processList(response.users, response.roles))
      );
  }

  getGroupDoorsList(id: number): Observable<any> {
    return this.apiService
      .getDoorByAccessGroup(qs.stringify({ id }))
      .pipe(map((response) => this.processDoorList(response)));
  }

  processDoorList(response: ILKDevice[]): LKDevice[] {
    const doors: LKDevice[] = [];
    response.forEach((responseDoor: ILKDevice) => {
      const door = new LKDevice(responseDoor);
      doors.push(door);
    });

    return doors;
  }

  getGroupList(skip: number, order?: string): Observable<any> {
    return this.apiService
      .getAccessGroups(
        qs.stringify({
          siteId: this.globalsService.getSiteId(),
          skip,
          order,
        })
      )
      .pipe(map((response) => this.processGroupList(response)));
  }

  findGroup(query: string): Observable<any> {
    return this.apiService.findAccessGroups(
      qs.stringify({
        siteId: this.globalsService.getSiteId(),
        query,
      })
    );
  }

  processGroupList(response: { count: number; results: ILKGroup[] }): {
    count: number;
    results: LKGroup[];
  } {
    const groups: LKGroup[] = [];
    response.results.forEach((responseGroup: ILKGroup) => {
      const group = new LKGroup(responseGroup);
      groups.push(group);
    });

    return { ...response, results: groups };
  }

  createNewGroup = (groupParams: GroupParams): Observable<any> => {
    groupParams.siteId = this.globalsService.getSiteId();

    return this.apiService.createAccessGroup(
      qs.stringify({
        ...groupParams,
      })
    );
  };

  removeGroup(groupId: number): Promise<Object> {
    const body = qs.stringify({ groupId });
    return this.apiService.removeAccessGroup(body).toPromise();
  }

  editGroup = (groupParams: any): Observable<any> => {
    const group = this.decodeToFormURLEncoded(groupParams, '');

    return this.apiService.updateAccessGroup(group);
  };

  editDevices = (deviceEdit: {
    id: number;
    doorListAdd: string;
    doorListRemove: string;
  }): Observable<any> => {
    const group = this.decodeToFormURLEncoded(deviceEdit, '');

    return this.apiService.updateDevicesAccessGroup(group);
  };

  editUsersAndRoles = (userAndRoleEdit: {
    id: number;
    userListAdd: string;
    userListRemove: string;
    roleListAdd: string;
    roleListRemove: string;
  }): Observable<any> => {
    const group = this.decodeToFormURLEncoded(userAndRoleEdit, '');

    return this.apiService.updateUsersAndRolesAccessGroup(group);
  };

  createUser(user: any): Observable<any> {
    return this.apiService
      .createUserOnCorp(
        qs.stringify({
          ...user,
        })
      )
      .pipe(map((response) => this.processUser(response)));
  }

  processUser(response: ILKUser): LKUser {
    return new LKUser(response);
  }

  findUserOrRole(query: string): Observable<any> {
    return this.apiService
      .findUserOrRoleOnSite(
        qs.stringify({
          query,
          corpId: this.globalsService.getCorporationId(),
          siteId: this.globalsService.getSiteId(),
          maskedValues: true,
        })
      )
      .pipe(
        map((response) => this.processList(response.users, response.roles))
      );
  }

  private processList(
    responseUsers: ILKUser[],
    responseRoles: ILKRole[]
  ): { users: LKUser[]; roles: LKRole[] } {
    const users: LKUser[] = [];
    const roles: LKRole[] = [];

    responseUsers.forEach((responseUser: ILKUser) => {
      const user = new LKUser(responseUser);
      users.push(user);
    });

    responseRoles.forEach((responseRole: ILKRole) => {
      const role = new LKRole(responseRole);
      roles.push(role);
    });

    return { users, roles };
  }

  private decodeToFormURLEncoded(object, father) {
    let query = '';
    const values = Object.values(object);

    values.forEach((value: string | number | boolean, index) => {
      const keys = Object.keys(object);
      if (typeof value === 'object' && value != null) {
        if (keys[index] === 'restrictions') {
          if (
            value &&
            Object(value).startDateTime &&
            Object(value).startDateTime !== null
          ) {
            value = {
              ...Object(value),
              startDateTime: Object(value).startDateTime.toISOString(),
            };
          }

          if (
            value &&
            Object(value).endDateTime &&
            Object(value).endDateTime !== null
          ) {
            value = {
              ...Object(value),
              endDateTime: Object(value).endDateTime.toISOString(),
            };
          }
        }
        query += this.decodeToFormURLEncoded(value, keys[index]);
      } else if (father === '') {
        query += `${encodeURIComponent(keys[index])}=${encodeURIComponent(
          value.toString()
        )}&`;
      } else {
        query += `${father}.${encodeURIComponent(
          keys[index]
        )}=${encodeURIComponent(value === null ? '' : value.toString())}&`;
      }
    });

    if (query !== '' && query.substr(-1) === '&') {
      return query.slice(0, -1);
    }

    return query;
  }
}
