<div class="sidenav">
  <div class="overlay" *ngIf="sendingCommand">
    <div class="spinner-wrapper">
      <mat-spinner diameter="40" color="accent" class="center"></mat-spinner>
    </div>
  </div>

  <div class="sidenav__content">
    <span class="sidenav__title">
      {{ selectedDevice.name.toUpperCase() }}
    </span>
    <span translate class="sidenav__subtitle">Advanced Settings</span>

    <span class="sidenav__info--section" translate>General Info</span>

    <span *ngIf="selectedDevice.status === 'ONLINE'"
      class="right sidenav__info--label-connection sidenav__info--status-connected" translate>
      SYNCED
    </span>
    <span *ngIf="selectedDevice.status === 'PENDING'"
      class="right sidenav__info--label-connection sidenav__info--status-pending" translate>
      PENDING
    </span>
    <span *ngIf="selectedDevice.status === 'SYNCING'"
      class="right sidenav__info--label-connection sidenav__info--status-connected blinker-status" translate>
      SYNCING
    </span>
    <span *ngIf="selectedDevice.status === 'OFFLINE'"
      class="right sidenav__info--label-connection sidenav__info--status-disconnected">
      OFFLINE
    </span>

    <hr />

    <div class="sidenav__info">
      <span class="attribute"> ID </span>
      <span class="sidenav__info-content"> {{ selectedDevice.id }} </span>
    </div>

    <div class="sidenav__info">
      <span class="attribute">Serial Number</span>
      <span class="sidenav__info-content">
        {{ selectedDevice.prettySerial }}
      </span>
    </div>

    <div class="sidenav__info">
      <span class="attribute" translate>Device Model</span>
      <span class="sidenav__info-content">
        {{ selectedDevice.deviceKind }}
      </span>
    </div>

    <div class="sidenav__info">
      <span class="attribute">Firmware Version</span>
      <span class="sidenav__info-content">
        {{ selectedDevice.firmwareVersion }}
      </span>
    </div>

    <div class="sidenav__info">
      <span translate class="attribute">Timezone</span>
      <span class="sidenav__info-content"> {{ selectedDevice.timeZone }} </span>
    </div>

    <span *ngIf="gatewayList.lenght > 0" class="display-block sidenav__info--section" translate>Devices List</span>

    <hr *ngIf="gatewayList.lenght > 0" />

    <span class="display-block sidenav__info--section" *ngIf="selectedDevice.status !== 'OFFLINE'" translate>Available
      Gateways</span>

    <hr *ngIf="selectedDevice.status !== 'OFFLINE'">

    <div *ngIf="gatewayList.length === 0 && selectedDevice.status !== 'OFFLINE'" class="no-gateways">
      <span translate>No gateways available</span>
    </div>

    <div class="sidenav__gateway-list" *ngFor="let gateway of gatewayList">
      <div class="row sidenav__gateway-content">
        <div class="sidenav__gateway-info">
          <div class="sidenav__info">
            <span class="gateway-attribute name">{{ gateway.name }}</span>
          </div>
          <div class="sidenav__info">
            <span class="gateway-attribute">ID {{ gateway.id }}</span>
          </div>
        </div>
        <div class="sidenav__gateway-signal">
          <div>
            <span class="bold green" translate *ngIf="gateway.rssi >= -70">Strong</span>
            <span class="bold yellow" translate *ngIf="-70 > gateway.rssi && gateway.rssi > -80">Medium</span>
            <span class="bold red" translate *ngIf="-80 >= gateway.rssi">Weak</span>
            <fa-icon icon="wifi"
              [ngClass]="{'red': -80 >= gateway.rssi, 'yellow': -70 > gateway.rssi && gateway.rssi > -80, 'green': gateway.rssi >= -70}">
            </fa-icon>
          </div>
          <div>
            <span class="gateway-rssi">{{ gateway.rssi }} dBm</span>
          </div>
        </div>
        <div class="sidenav__gateway-actions">
          <button mat-icon-button class="sidenav__btn-action" [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()">
            <fa-icon icon="ellipsis-vertical"></fa-icon>
          </button>
        </div>
      </div>
      <mat-menu xPosition="before" #menu="matMenu">
        <p class="title_section" translate>Actions</p>
        <button (click)="sendGatewayCommand(gateway, 'reboot')" mat-menu-item>
          <fa-icon class="mrg-right-10" icon="power-off"></fa-icon>
          <span translate>Restart</span>
        </button>
        <button (click)="sendGatewayCommand(gateway, 'version')" mat-menu-item>
          <fa-icon class="mrg-right-10" icon="microchip"></fa-icon>
          <span translate>Firmware Version</span>
        </button>
      </mat-menu>
    </div>
    <div *ngIf="!strongSignal" class="sidenav__signal-alert">
      <div class="exclamation">
        <fa-icon icon="exclamation-triangle"></fa-icon>
      </div>
      <div style="width: 90%;">
        <span class="sidenav__warning-offline" translate>
          Weak connection! Please verify the distance between your smartlock and gateway for proper data synchronization
          and seamless operation.
        </span>
      </div>
    </div>
    <div *ngIf="strongSignal && selectedDevice.status === 'PENDING'" class="sidenav__signal-alert">
      <div class="exclamation">
        <fa-icon icon="exclamation-triangle"></fa-icon>
      </div>
      <div style="width: 90%;">
        <span class="sidenav__warning-offline" translate>
          Humm... Maybe occurred an issue during automatic synchronisation but there is at least one strong connection
          between your smartlock and gateway. Please try to Force Sync. If the 'Pending' status continue, please contact
          us at support@loopkey.com.br
        </span>
      </div>
    </div>


    <span class="display-block sidenav__info--section" translate>
      Edit Information
    </span>

    <hr />

    <div class="mrg-top-10">
      <span translate>Name</span>
      <mat-form-field appearance="outline" class="width-100">
        <input matInput value="updatedDevice.name" [(ngModel)]="updatedDevice.name" />
      </mat-form-field>
    </div>
    <div>
      <span translate>Address</span>
      <mat-form-field appearance="outline" class="width-100">
        <input matInput value="updatedDevice.address" [(ngModel)]="updatedDevice.address" />
      </mat-form-field>
    </div>
    <div>
      <div class="sidenav__sync-log">
        <span translate>Sync log data through Gateways</span>
        <mat-slide-toggle [(ngModel)]="updatedDevice.useRealLogs"></mat-slide-toggle>
      </div>
      <!-- <div class="mrg-top-10">
        <mat-slide-toggle [(ngModel)]="updatedDevice.useRealLogs">
          <span class="mrg-left-20" *ngIf="updatedDevice.useRealLogs" translate>Active</span>
          <span class="mrg-left-20" *ngIf="!updatedDevice.useRealLogs" translate>Deactive</span>
        </mat-slide-toggle>
      </div> -->
    </div>

    <div class="element">
      <button class="sidenav__update-btn" (click)="updateDevice()" [disabled]="!verifyAddressUpdate()">
        <span class="display-block update-label bold" translate>Save Name and Address</span>
      </button>
    </div>

    <div *ngIf="!selectedDevice.serial.startsWith('TLK')">
      <span class="display-block sidenav__info--section mrg-btm-10" translate>
        Timer
      </span>

      <hr />
      <span translate class="gray-text">
        {{
        !timerOn
        ? 'There is no active timer!'
        : 'This device has an active timer!'
        }}
      </span>
      <span translate class="mrg-top-10 display-block" translate>
        {{ !timerOn ? ' ' : 'Timer is set until' }}
      </span>
      <div class="sidenav__timer">
        <input id="timerOpen" class="timer-open" type="time" [(ngModel)]="endTimer" [ngxTimepicker]="pickerTime"
          [format]="24" [disabled]="selectedDevice.status === 'OFFLINE' || timerOn || disableButtons" />
        <ngx-material-timepicker #pickerTime>
        </ngx-material-timepicker>
        <div>
          <div class="element">
            <button aria-label="updateTime" class="sidenav__activate-time"
              [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons" (click)="controllerTime()">
              <span class="display-block activate-label" translate>
                {{ timerOn ? 'Deactivate' : 'Activate' }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <span class="display-block sidenav__info--section mrg-btm-10" translate>
      Actions
    </span>

    <hr />

    <div class="sidenav__btn-container">
      <div class="element" *ngIf="selectedDevice.type === 'Gateways'">
        <button aria-label="updateTime" mat-mini-fab [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          (click)="restart()">
          <fa-icon icon="redo"></fa-icon>
        </button>
        <span translate>Restart</span>
        <span translate>application</span>
      </div>

      <div class="element">
        <button aria-label="Unlock" [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_device' : 'disable_actions'" mat-mini-fab
          (click)="unlockDevices(selectedDevice)">
          <fa-icon icon="door-open"></fa-icon>
        </button>
        <span translate>Unlock</span>
      </div>

      <div class="element">
        <button aria-label="ForceSync" mat-mini-fab [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_device' : 'disable_actions'"
          (click)="readBatteryState(selectedDevice)">
          <fa-icon icon="battery-full"></fa-icon>
        </button>
        <span translate>Battery Status</span>
      </div>

      <div class="element">
        <button aria-label="ForceSync" mat-mini-fab [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_device' : 'disable_actions'"
          (click)="forceSync($event, selectedDevice)">
          <fa-icon [icon]="faSync"></fa-icon>
        </button>
        <span translate>Force Sync</span>
      </div>
    </div>

    <div class="sidenav__btn-container">
      <div class="element" *ngIf="selectedDevice.type !== 'Gateways'">
        <button aria-label="updateTime" [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_device' : 'disable_actions'" mat-mini-fab
          (click)="restart()">
          <fa-icon icon="power-off"></fa-icon>
        </button>
        <span translate>Reboot</span>
      </div>

      <div class="element sidenav__btn-fake" *ngIf="selectedDevice.type === 'Gateways'">
        <button aria-label="updateTime" [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons" mat-mini-fab
          (click)="updateTime()">
          <fa-icon icon="door-open"></fa-icon>
        </button>
        <span translate>Time</span>
      </div>

      <div class="element">
        <button aria-label="updateTime" [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_device' : 'disable_actions'" mat-mini-fab
          (click)="readFirmwareVersion(selectedDevice)">
          <fa-icon icon="microchip"></fa-icon>
        </button>
        <span translate>Firmware Version</span>
      </div>

      <div class="element">
        <button aria-label="updateTime" [disabled]="selectedDevice.status === 'OFFLINE' || disableButtons"
          [ngClass]="selectedDevice.status !== 'OFFLINE' ? 'actions_device' : 'disable_actions'" mat-mini-fab
          (click)="updateTime()">
          <fa-icon icon="clock"></fa-icon>
        </button>
        <span translate>Update</span>
        <span translate>Time</span>
      </div>
    </div>

    <div class="sidenav__btn-container">
      <div class="element">
        <button aria-label="ForceSync" mat-mini-fab class="remove-btn" (click)="openRemoveDeviceDialog(selectedDevice)">
          <fa-icon [icon]="faTrashAlt"></fa-icon>
        </button>
        <span translate>Remove Device</span>
      </div>
    </div>
  </div>
</div>