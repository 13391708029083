<div class="delete-modal">
  <div class="pdd-horizon-15 mrg-btm-20">
    <h5 class="text-center" mat-dialog-title translate>Delete {{data.type}}</h5>
    <h6 *ngIf="data.type === 'Group'" translate>Are you sure you want to delete? The users will continue in the corporation</h6>
  </div>
  <div class="delete-modal__wrapper-content" mat-dialog-content>
    <h6 class="mrg-top-10" translate>Selected {{data.type}}</h6>
    <mat-card appearance="outlined" class="delete-modal__groups centered">
      <mat-card-content>
        <mat-chip-listbox #chipList>
          <mat-chip-option style="cursor: pointer;" class="delete-modal__selected-group">
            <span *ngIf="data.type === 'Group'" class="group-name">
              {{ data.group.name }}
            </span>
            <span *ngIf="data.type === 'Site'" class="group-name">
              {{ data.site.name }}
            </span>
          </mat-chip-option>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
    <div class="row">
      <div class="col-auto mr-auto">
        <button aria-label="cancel" mat-raised-button class="cancel-button modal-button" matDialogClose>
          <span translate>Cancel</span>
        </button>
      </div>
      <div class="col-auto">
        <button aria-label="delete" mat-raised-button class="delete-button modal-button" (click)="deleteComponent()"
          matDialogClose>
          <span translate>Delete</span>
        </button>
      </div>
    </div>
  </div>
</div>