import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-modal-result',
  templateUrl: './modal-result.component.html',
  styleUrls: ['./modal-result.component.scss'],
})
export class ModalResultComponent {
  @Output() previousItem = new EventEmitter<any>();
  @Output() finishAll = new EventEmitter<any>();
  @Input() errorMessage: string;
  @Input() successMessage: string;
  @Input() result: {
    hasError: boolean;
    error: string;
  };
  @Input() stepper: MatStepper;
  public widthSizeSuitable: boolean;

  ngOnInit() {
    this.widthSizeSuitable = window.innerWidth <= 500;
  }

  backAction() {
    this.stepper.previous();
    this.previousItem.emit();
  }
}
