import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorporationService } from '@modules/corporation/corporation.service';
import { SitesService } from '@modules/sites/sites.service';
import { RolesService } from '@modules/roles/roles.service';
import { GlobalsService } from '@services/globals.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'app-role-invitation',
  templateUrl: './role-invitation.component.html',
  styleUrls: ['./role-invitation.component.scss'],
})
export class RoleInvitationComponent implements OnInit {
  public roleForm: UntypedFormGroup;
  public widthSuitableSize = false;
  public sites: any = [];
  public corps: any = [];
  public role = {
    name: '',
    corp: '',
    site: '',
  };
  result: {
    hasError: boolean;
    error: string;
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sitesService: SitesService,
    private corporationsService: CorporationService,
    private globalsService: GlobalsService,
    private roleService: RolesService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private loggingService: LoggingService
  ) {
    this.roleForm = new UntypedFormGroup({
      corp: new UntypedFormControl('', [Validators.required]),
      site: new UntypedFormControl('', [Validators.required]),
    });
    this.globalsService.setCorporationId('0');
    this.roleService
      .getExternalRoleInfo(this.data.roleId, this.data.siteId)
      .subscribe((roleInfo) => {
        this.role = roleInfo;
      });
  }

  ngOnInit() {
    this.widthSuitableSize = window.innerWidth <= 500;

    this.corporationsService.getUserCorporations().subscribe((response) => {
      this.corps = response;
    });

    this.roleForm.get('corp').valueChanges.subscribe((value) => {
      if (value !== '') {
        this.sitesService.getSitesFromCorp(value).subscribe((response) => {
          this.sites = response;
        });
      }
    });
  }

  async connectRole() {
    this.roleService
      .getConnectRoleLink(this.data.roleId)
      .subscribe((response) => {
        if (response) {
          this.roleService
            .connectRole(response.connect, this.roleForm.value.site)
            .subscribe(
              () => {
                this.loggingService.customAlert(
                  {
                    title: this.translateService.instant(
                      'Role successfully connected!'
                    ),
                    text: '',
                  },
                  'success'
                );
              },
              (error) => {
                this.loggingService.customAlert(
                  {
                    title: this.translateService.instant(
                      'Error connecting role!'
                    ),
                    text: error.error.errorDescription
                      ? this.translateService.instant(
                          error.error.errorDescription
                        )
                      : '',
                  },
                  'error'
                );
              }
            );
        }
      });
  }
}
