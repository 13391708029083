<div class="no-connection__no-conection centered">
  <img
    class="no-connection-icon"
    src="../../../../assets/icons/wifi-black.svg"
    alt="no-connection"
    width="60px"
  />
  <span class="no-connection__label-warning" translate
    >You appears to be offline</span
  >
  <button
    aria-label="refresh"
    mat-raised-button
    class="refresh-button right mrg-right-5"
    (click)="refresh()"
    translate
  >
    <span>{{ 'TRY AGAIN' | translate }}</span>
  </button>
</div>
