import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { PermissionsService } from '@modules/permissions/permissions.service';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss'],
})
export class DeviceSettingsComponent implements OnInit {
  public device: any;
  public updateField = new UntypedFormControl('', [Validators.required]);
  public loading: boolean = true;
  @Output() deviceUpdated = new EventEmitter<any>();

  constructor(
    private maintenanceService: MaintenanceService,
    private permissionsService: PermissionsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.permissionsService
      .getDeviceInfo(this.data.selectedDevice.id)
      .subscribe(
        (device) => {
          this.device = device;
          if (this.data.modalType === 'NAME') {
            this.updateField.setValue(this.device.name);
          } else {
            this.updateField.setValue(this.device.address);
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  updateDevice() {
    this.loading = true;
    if (this.data.modalType === 'NAME') {
      this.device.name = this.updateField.value;
    } else {
      this.device.address = this.updateField.value;
    }
    this.maintenanceService.updateDevice(this.device).subscribe(
      () => {
        this.deviceUpdated.emit(true);
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
}
