<div class="group-doors-wrappers" [ngClass]="
    editDoors && sizeWidthSuit ? 'edit-group-doors' : 'create-group-doors'
  ">
  <div class="group-doors">
    <div class="group-doors__header">
      <span class="label-search-device" translate>
        Search Devices by name
      </span>
      <app-search-button [filterValue]="filterName" [color]="'form-colors'" [placeholder]="'Search device' | translate"
        (clearEvent)="clearFilter()" (filterApplied)="filterChanged($event)">
      </app-search-button>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span translate>
          Available Devices
        </span>
        <mat-card appearance="outlined" class="group-doors__devices centered">
          <!-- <mat-card-content> -->
            <div class="no-devices" *ngIf="!verifyDevicesFilter()">
              <fa-icon class="pdd-right-5 font-size-15" icon="exclamation-triangle"></fa-icon>
              <span translate>No device was found with the selected filter(s)</span>
            </div>
            <mat-list role="list" *ngIf="verifyDevicesFilter()">
              <mat-list-item *ngFor="let dev of devices" (click)="dev.selected = true"
                [class.display-none]="dev.selected || !dev.filtered">
                <span>{{ dev.device.name }}</span>
              </mat-list-item>
            </mat-list>
          <!-- </mat-card-content> -->
        </mat-card>
      </div>

      <div class="col-md-6">
        <span translate>
          Selected Devices
        </span>
        <mat-card appearance="outlined" class="group-doors__devices centered">
          <!-- <mat-card-content> -->
            <div class="no-devices" *ngIf="!verifyDevicesSelect()">
              <fa-icon class="pdd-right-5 font-size-15" icon="exclamation-triangle"></fa-icon>
              <span translate>No device was found with the selected filter(s)</span>
            </div>
            <mat-list role="list" *ngIf="verifyDevicesSelect()">
              <mat-list-item *ngFor="let dev of devices; let i = index" (click)="dev.selected = false"
                [class.display-none]="!dev.selected">
                <span>{{ dev.device.name }}</span>
              </mat-list-item>
            </mat-list>
          <!-- </mat-card-content> -->
        </mat-card>
      </div>
    </div>
  </div>
  <div class="steppers">
    <app-stepper-controller isEditing="{{ editDoors }}" [actionText]="editDoors ? 'Save' : 'Next'"
      (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>