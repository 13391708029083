<app-top-loading [loadingProp]="loadingTableData && !(pendingLoad | async)">
</app-top-loading>

<app-no-connection *ngIf="pendingLoad | async" (refreshPage)="loadlistings()">
</app-no-connection>

<div class="centered" *ngIf="loadingTableData && !(pendingLoad | async)">
    <mat-spinner diameter="40" color="accent"></mat-spinner>
</div>

<mat-sidenav-container class="listings" (backdropClick)="snav.close()"
    *ngIf="!(pendingLoad | async) && !loadingTableData">

    <mat-sidenav-content class="listings__content">
        <div *ngIf="sizeWidthSuitable && !loadingTableData" class="listings__container-header">
            <h2 class="listings__page-name" translate>Listings</h2>
        </div>

        <div *ngIf="!loadingTableData">
            <div *ngIf="listingsSize === 0; else showlistings" class="no-listing centered">
                <fa-icon icon="exclamation" class="no-listing icon"></fa-icon>
                <span class="no-listing-warning" translate>There's no Listing available to display</span>
                <button mat-raised-button class="btn-label" (click)="openUpdateGuestyIntegrationDialog()">
                    <span translate>Update Listings</span>
                </button>
            </div>

            <ng-template #showlistings>
                <div class="listings__table-paginator" *ngIf="validResults(); else emptyMessage">
                    <div class="listings__table-container">
                        <table mat-table [dataSource]="dataSource" matSort class="listings__table"
                            (matSortChange)="sortData($event)" matSortActive="status" matSortDirection="desc"
                            matSortDisableClear>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
                                    <span translate>Name</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>{{ element.externalConnections[0].externalListingName }}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="idPMS">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="id">
                                    <span translate>PMS ID</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span>{{ element.externalConnections[0].id }}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="devices">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span translate>Assigned Devices</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element.doorIds.length === 0" translate>No
                                        device</span>
                                    <ng-container *ngFor="let item of element.doorIds; let i = index">
                                        <span *ngIf="element.doorIds && element.doorIds.length > 0 && 2 > i"
                                            (mouseover)="showDoorName(element.doorIds[i])" [matTooltip]="deviceName">{{
                                            element.doorIds[i]
                                            }}</span>
                                        <span *ngIf="element.doorIds.length > 1 && i == 0">, </span>
                                        <span *ngIf="element.doorIds.length > 2 && i == 2">...</span>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="integration">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span translate>Integrations</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element.externalConnections.length === 0" translate>No
                                        integration</span>
                                    <ng-container *ngFor="let item of element.externalConnections; let i = index">
                                        <span
                                            *ngIf="element.externalConnections && element.externalConnections.length > 0">{{
                                            element.externalConnections[i].externalListingName
                                            }}</span>
                                        <span *ngIf="element.externalConnections.length > 1 && i == 0">, </span>
                                        <span *ngIf="element.externalConnections.length > 2 && i == 1">, </span>
                                        <span *ngIf="element.externalConnections.length > 2 && i == 2">...</span>
                                    </ng-container>
                                </td>
                            </ng-container> -->

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="bold" translate>Actions</span>
                                </th>
                                <td mat-cell *matCellDef="let element" class="listings__actions">
                                    <div *ngIf="!sizeWidthSuitable">
                                        <span (click)="openDevicesDialog(element)"
                                            class="listings__btn-actions listings__btn-edit listings__hidden-btn mrg-right-10"
                                            *ngIf="element.doorIds.length === 0" translate>
                                            Add Devices
                                        </span>
                                        <span (click)="openDevicesDialog(element)"
                                            class="listings__btn-actions listings__btn-edit listings__hidden-btn mrg-right-10"
                                            *ngIf="element.doorIds.length > 0" translate>
                                            Edit Devices
                                        </span>
                                        <!-- <span (click)="openIntegrationDialog(element)"
                                            class="listings__btn-actions listings__btn-edit listings__hidden-btn"
                                            *ngIf="element.externalConnections.length === 0" translate>
                                            Add Integrations
                                        </span>
                                        <span (click)="openIntegrationDialog(element)"
                                            class="listings__btn-actions listings__btn-edit listings__hidden-btn"
                                            *ngIf="element.externalConnections.length > 0" translate>
                                            Edit Integrations
                                        </span> -->
                                    </div>

                                    <button mat-icon-button class="listings__btn-action-mobile"
                                        [matMenuTriggerFor]="menu" *ngIf="sizeWidthSuitable">
                                        <fa-icon icon="ellipsis-vertical"></fa-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button (click)="openDevicesDialog(element)" mat-menu-item>
                                            <span *ngIf="element.doorIds.length === 0" translate>Add Devices</span>
                                            <span *ngIf="element.doorIds.length > 0" translate>Edit Devices</span>
                                        </button>
                                        <!-- <button (click)="openIntegrationDialog(element)" mat-menu-item>
                                            <span *ngIf="element.externalConnections.length === 0" translate>Add
                                                Integrations</span>
                                            <span *ngIf="element.externalConnections.length > 0" translate>Edit
                                                Integrations</span>
                                        </button> -->
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr class="table-header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-rows"></tr>
                        </table>
                    </div>

                    <mat-paginator *ngIf="!sizeWidthSuitable" (page)="changePage()" [pageSizeOptions]=[numberListingsShow]
                        class="primary-color">
                    </mat-paginator>
                </div>
            </ng-template>

            <ng-template #emptyMessage>
                <div class="centered">
                    <span class="listings__warning inline-block pdd-left-15" translate>
                        <fa-icon class="pdd-left-5 font-size-15" icon="exclamation-triangle"></fa-icon>
                        No listing was found with the selected filter(s)
                    </span>
                </div>
            </ng-template>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>