import * as qs from 'querystring';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/http/api.service';
import { LKPermission, ILKPermission } from '@models/LKPermission';
import { Injectable } from '@angular/core';

@Injectable()
export class PermissionsService {
  constructor(private apiService: ApiService) {}

  getDeviceInfo(deviceId: string): Observable<any> {
    return this.apiService.getDeviceInformation(qs.stringify({ id: deviceId }));
  }

  getDevicePermissionList(deviceId: number): Observable<any> {
    return this.apiService
      .getDevicePermissionList(qs.stringify({ id: deviceId }))
      .pipe(map((response) => this.processPermissionList(response, 'device')));
  }

  getUserPermissionList(): Observable<LKPermission[]> {
    return this.apiService
      .getUserPermissions()
      .pipe(map((response) => this.processPermissionList(response, 'user')));
  }

  processPermissionList(serverResponse: any, type: string): LKPermission[] {
    let arrPermissionList: LKPermission[] = [];
    serverResponse.forEach((permissionServer: ILKPermission) => {
      arrPermissionList.push(new LKPermission(permissionServer));
    });
    if (type === 'user') {
      arrPermissionList = arrPermissionList.filter(
        (permission) =>
          permission.type === 'owner' || permission.type === 'admin'
      );
    }

    return arrPermissionList;
  }
}
