import { ApiService } from '@core/http/api.service';
import { Observable } from 'rxjs';
import { LKSite } from '@models/LKSite';
import { GlobalsService } from '@services/globals.service';
import * as qs from 'querystring';
import { LKDevice, ILKDevice } from '@models/LKDevice';
import { map } from 'rxjs/operators';
import { HelperService } from '@services/helper.manager.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SitesService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService,
    private helperService: HelperService
  ) {}

  getUserSites = (): Observable<LKSite[]> => {
    return this.getSites(this.globalsService.getCorporationId());
  };

  updateSite = (site: any, lat: any, lng: any): Observable<any> => {
    return this.apiService.updateSite(
      qs.stringify({
        ...site,
        latitude: lat,
        longitude: lng,
      })
    );
  };

  createSite = (site: any, lat: any, lng: any): Observable<any> => {
    return this.apiService.createSite(
      qs.stringify({
        ...site,
        corpId: this.globalsService.getCorporationId(),
        latitude: lat,
        longitude: lng,
      })
    );
  };

  removeSite(siteId: number, password: string): Promise<Object> {
    const body = qs.stringify({
      password,
      siteId,
    });
    return this.apiService.removeSite(body).toPromise();
  }

  getSitesFromCorp = (corpId): Observable<LKSite[]> => {
    return this.getSites(corpId);
  };

  getSites = (corpId): Observable<LKSite[]> => {
    return this.apiService.getCorporationsSites(qs.stringify({ corpId }));
  };

  getSiteDoors = (siteId): Observable<LKDevice[]> => {
    return this.apiService.getSiteDoors(qs.stringify({ siteId }));
  };

  getSiteDevices = (): Observable<LKDevice[]> => {
    return this.apiService
      .getSiteDoors(qs.stringify({ siteId: this.globalsService.getSiteId() }))
      .pipe(
        map((response: ILKDevice[]) => {
          return this.processSiteDevices(response);
        })
      );
  };

  processSiteDevices = (response: ILKDevice[]): LKDevice[] => {
    const devices = [];
    response.forEach((element: ILKDevice) => {
      devices.push(new LKDevice(element));
    });
    devices.sort((a, b) => this.helperService.compare(a.name, b.name, true));

    return devices;
  };
}
