<div class="group-info">
  <form [formGroup]="groupForm" [ngStyle]="{
      height: editInfo && sizeWidthSuit ? 'calc(100vh - 140px)' : ''
    }">
    <div>
      <span translate>Name</span>
      <mat-form-field class="width-100" appearance="outline">
        <input matInput type="text" formControlName="name" placeholder="{{ 'Maintenance' | translate }}"
          class="secondary-color" />
        <mat-error *ngIf="f.name.errors" translate>
          {{ getNameError() }}
        </mat-error>
      </mat-form-field>
      <span translate>Description</span>
      <mat-form-field class="width-100" appearance="outline">
        <input matInput type="text" formControlName="description"
          placeholder="{{ 'Group for maintenance staff' | translate }}" class="secondary-color" />
        <mat-error *ngIf="f.description.errors" translate>
          Optional. Must have at least 3 characters.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="steppers">
      <app-stepper-controller isInfo="true" isEditing="{{ editInfo }}" [actionText]="editInfo ? 'Save' : 'Next'"
        (onFormSubmitted)="onSubmit()">
      </app-stepper-controller>
    </div>
  </form>
</div>