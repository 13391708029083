<div class="addkey">
  <div *ngIf="!showKey" class="addkey__auth">
    <span class="addkey__auth-title" translate>New Integration</span>
    <div class="addkey__auth-content">
      <mat-label>
        <span class="label-input" translate>Type your Password</span>
      </mat-label>
      <mat-form-field class="width-100" appearance="outline">
        <input
          matInput
          [type]="showCurrentPassword ? 'text' : 'password'"
          [formControl]="currentPassword"
          (keyup.enter)="configureIntegration()"
        />
        <fa-icon
          matSuffix
          class="pointer pdd-btm-15 font-size-20"
          (click)="showCurrentPassword = !showCurrentPassword"
          [icon]="!showCurrentPassword ? faEye : faEyeSlash"
        >
        </fa-icon>
      </mat-form-field>
      <mat-error *ngIf="passwordInvalid" translate>
        {{ errorPassword }}
      </mat-error>
    </div>
    <div *ngIf="!widthSizeSuitable" class="addkey__auth-actions">
      <button class="button-cancel" (click)="dialogRef.close()" translate>
        Cancel
      </button>
      <button (click)="configureIntegration()" class="main-button" translate>
        Create
      </button>
    </div>
    <div *ngIf="widthSizeSuitable" class="addkey__auth-actions">
      <button class="button-cancel-float" (click)="dialogRef.close()" translate>
        Cancel
      </button>
      <button (click)="configureIntegration()" class="main-button-float" translate>
        Create
      </button>
    </div>
  </div>

  <div *ngIf="showKey" class="addkey__key">
    <div>
      <span class="addkey__key-actions-title" translate>New Integration</span>
      <span class="addkey__key-actions-subtitle" translate>
        {{ typeIntegration }} Integration
      </span>
    </div>
    <div class="addkey__auth-content">
      <mat-label>
        <span class="label-input" translate>Key Phrase</span>
      </mat-label>
      <mat-form-field class="width-100" appearance="outline">
        <textarea
          disabled
          id="keyIntegration"
          [(ngModel)]="integrationKey"
          matInput
          class="key-text"
          rows="10"
          cols="70"
        >
        </textarea>
      </mat-form-field>
    </div>
    <div *ngIf="!widthSizeSuitable" class="addkey__auth-actions">
      <button class="button-cancel" (click)="dialogRef.close()" translate>
        Close
      </button>
      <button (click)="copyToClipboard()" class="main-button" translate>
        <fa-icon *ngIf="!copied && !load" icon="copy"></fa-icon>
        <fa-icon *ngIf="!copied && load" icon="spinner" pulse="true"></fa-icon>
        <fa-icon *ngIf="copied && load" icon="check"></fa-icon>
      </button>
    </div>
    <div *ngIf="widthSizeSuitable" class="addkey__auth-actions">
      <button class="button-cancel-float" (click)="dialogRef.close()" translate>
        Close
      </button>
      <button (click)="copyToClipboard()" class="main-button-float" translate>
        <fa-icon *ngIf="!copied && !load" icon="copy"></fa-icon>
        <fa-icon *ngIf="!copied && load" icon="spinner" pulse="true"></fa-icon>
        <fa-icon *ngIf="copied && load" icon="check"></fa-icon>
      </button>
    </div>
  </div>
</div>
