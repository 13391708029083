import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-extended-dashboard-card',
  templateUrl: './extended-dashboard-card.component.html',
  styleUrls: ['./extended-dashboard-card.component.scss'],
})
export class ExtendedDashboardCardComponent {
  @Input() numberColor: string;
  @Input() isLoading: boolean;
  @Input() labelOne: string;
  @Input() labelTwo: string;
  @Input() numberInfoOne: string;
  @Input() numberInfoTwo: string;
  @Input() totalNumberOne: string;
  @Input() totalNumberTwo: string;
  @Input() title: string;
  @Input() action: string;
  @Input() list: [];
}
