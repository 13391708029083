<div class="delete-booking">
  <div class="pdd-horizon-15 mrg-btm-20">
    <h5 class="text-center" mat-dialog-title translate>Delete Booking</h5>
    <h6 translate>
      Are you sure you want to delete?
    </h6>
  </div>
  <div class="delete-booking__wrapper-content" mat-dialog-content>
    <mat-card appearance="outlined" class="delete-booking__info centered">
      <mat-card-content>
        <mat-chip-listbox #chipList>
          <mat-chip-option
            style="cursor: pointer;"
            class="delete-booking__selected-booking"
          >
            <span class="booking-name">
              {{ 'Booking of' | translate }} {{ data.booking.users[0].name + ' ' + data.booking.users[0].surname}} 
            </span>
          </mat-chip-option>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
    <div *ngIf="!sizeWidthSuitable" class="row">
      <div class="col-auto mr-auto">
        <button
          aria-label="cancel"
          mat-raised-button
          class="cancel-button modal-button"
          matDialogClose
        >
          <span class="btn-label" translate>Cancel</span>
        </button>
      </div>
      <div class="col-auto">
        <button
          aria-label="delete"
          mat-raised-button
          class="delete-button modal-button"
          (click)="deleteBooking()"
          matDialogClose
        >
          <span class="btn-label" translate>Delete</span>
        </button>
      </div>
    </div>
    <div *ngIf="sizeWidthSuitable">
      <button
        aria-label="cancel"
        mat-raised-button
        class="cancel-button modal-button cancel-btn-float"
        matDialogClose
      >
        <span class="btn-label" translate>Cancel</span>
      </button>
      <button
        aria-label="delete"
        mat-raised-button
        class="delete-button modal-button delete-btn-float"
        (click)="deleteBooking()"
        matDialogClose
      >
        <span class="btn-label" translate>Delete</span>
      </button>
    </div>
  </div>
</div>
