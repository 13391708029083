import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@shared/shared.module';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faPlus,
  faTimes,
  faEnvelope,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { FeedbackRoutingModule } from './feedback-routing.module';
import { FeedbackComponent } from './pages/feedback.component';

@NgModule({
  declarations: [FeedbackComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FeedbackRoutingModule,
    MaterialModule,
    SharedModule,
    FontAwesomeModule,
    MatDialogModule,
    MatIconModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class FeedbackModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faPlus, faTimes, faEnvelope, faQuestionCircle);
  }
}
