import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { FullStoryService } from '@services/fullstory-service.service';

@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.component.html',
  styleUrls: ['./create-booking.component.scss'],
})
export class CreateBookingComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  createBookingParams = {
    userId: null,
    userName: '',
    userSurname: '',
    userEmail: '',
    userPhone: '',
    userCpf: '',
    passcode: '',
    startDateTime: null,
    endDateTime: null,
    startTimeOfDay: '',
    endTimeOfDay: '',
    forms: null,
    action: '',
    doors: [],
  };
  editBookingParams = {
    userId: null,
    userName: '',
    userSurname: '',
    userEmail: '',
    userPhone: '',
    userCpf: '',
    passcode: '',
    startDateTime: null,
    endDateTime: null,
    startTimeOfDay: '',
    endTimeOfDay: '',
    bookingId: null,
    updatedEmail: false,
    updatedCpf: false,
    updatedCard: false,
    updatedPasscode: false,
    forms: null,
    action: '',
    doors: [],
  };
  public responseBooking = {
    response: {},
    error: false,
  };
  public messageError = '';
  public currentStepIndex: number = 1;
  public labelStepSelected: string = '';
  public action: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private translateService: TranslateService,
    private fullStoryService: FullStoryService
  ) {}

  ngOnInit() {
    if (this.data.bookingId) {
      this.fullStoryService.setPageVars({
        pageName: 'Edit Booking',
        bookingId: this.data.bookingId,
      });
    }
    this.labelStepSelected = this.translateService.instant('Guest');
  }

  setInfo = (bookingInfo, stepper: MatStepper) => {
    this.action = bookingInfo.action;
    if (bookingInfo.action === 'Create') {
      this.createBookingParams.userId = bookingInfo.userId;
      this.createBookingParams.userName = bookingInfo.userName;
      this.createBookingParams.userSurname = bookingInfo.userSurname;
      this.createBookingParams.userEmail = bookingInfo.userEmail;
      this.createBookingParams.userPhone = bookingInfo.userPhone;
      this.createBookingParams.userCpf = bookingInfo.userCpf;
      this.createBookingParams.forms = bookingInfo.forms;
      this.createBookingParams.action = bookingInfo.action;
    } else {
      this.editBookingParams.userId = bookingInfo.userId;
      this.editBookingParams.userName = bookingInfo.userName;
      this.editBookingParams.userSurname = bookingInfo.userSurname;
      this.editBookingParams.userEmail = bookingInfo.userEmail;
      this.editBookingParams.userPhone = bookingInfo.userPhone;
      this.editBookingParams.userCpf = bookingInfo.userCpf;
      this.editBookingParams.bookingId = bookingInfo.bookingId;
      this.editBookingParams.updatedEmail = bookingInfo.updatedEmail;
      this.editBookingParams.updatedCpf = bookingInfo.updatedCpf;
      this.editBookingParams.updatedCard = bookingInfo.updatedCard;
      this.editBookingParams.updatedPasscode = bookingInfo.updatedPasscode;
      this.editBookingParams.forms = bookingInfo.forms;
      this.editBookingParams.action = bookingInfo.action;
    }
    this.goForward(stepper);
  };

  setDateInfo = (bookingInfo, stepper: MatStepper) => {
    if (this.action === 'Create') {
      this.createBookingParams.startDateTime = bookingInfo.startDateTime;
      this.createBookingParams.endDateTime = bookingInfo.endDateTime;
      this.createBookingParams.startTimeOfDay = bookingInfo.startTimeOfDay;
      this.createBookingParams.endTimeOfDay = bookingInfo.endTimeOfDay;
    } else {
      this.editBookingParams.startDateTime = bookingInfo.startDateTime;
      this.editBookingParams.endDateTime = bookingInfo.endDateTime;
      this.editBookingParams.startTimeOfDay = bookingInfo.startTimeOfDay;
      this.editBookingParams.endTimeOfDay = bookingInfo.endTimeOfDay;
    }
    this.goForward(stepper);
  };

  setRooms = (roomsInfo, stepper: MatStepper) => {
    if (this.action === 'Create')
      this.createBookingParams.doors = roomsInfo.doors;
    else this.editBookingParams.doors = roomsInfo.doors;
    this.goForward(stepper);
  };

  submitBooking = (
    response: { response: {}; error: boolean },
    stepper: MatStepper
  ) => {
    stepper.steps.forEach((step, index) => {
      if (!response.error) {
        if (index < 5) {
          step.editable = false;
        }
      }
    });

    this.responseBooking = response;
    this.goForward(stepper);
  };

  goForward = (stepper: MatStepper) => {
    stepper.selected.completed = true;
    stepper.next();
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  changeStep = (labelIndex) => {
    this.stepper.selectedIndex = labelIndex;
  };

  backStep = (stepper) => {
    this.labelStepSelected = stepper.selected.label;
    this.currentStepIndex = stepper.selectedIndex + 1;
  };

  onChangeHeader = (event, stepper) => {
    if (event.selectedIndex === 0) {
      stepper.steps.forEach((step) => {
        step.editable = true;
      });
    }
    event.selectedStep.completed = false;
  };

  triggerClick = (event, stepper) => {
    let clickedStep: number;
    stepper.steps.forEach((step, index) => {
      if (step.label === event.target.textContent) {
        clickedStep = index;
      }
    });

    if (clickedStep > stepper.selectedIndex) {
      this.messageError = "Click on the 'Next' button to continue!";

      setTimeout(() => {
        this.messageError = '';
      }, 5000);
    }
  };
}
