import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  LKCmdResponseType,
  feedbackLKCmdResponse,
} from '@models/LKCmdResponse';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FirmwareVersionComponent } from '@modules/maintenance/components/firmware-version/firmware-version.component';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FullStoryService } from '@services/fullstory-service.service';
import { TimerComponent } from '@shared/components/modals/timer/timer.component';
import { DeviceSettingsComponent } from '@shared/components/modals/device-settings/device-settings.component';
import { BateryStateComponent } from '@modules/maintenance/components/batery-state/batery-state.component';
import * as moment from 'moment';

@Component({
  selector: 'app-gateway-config',
  templateUrl: './gateway-config.component.html',
  styleUrls: ['./gateway-config.component.scss'],
})
export class GatewayConfigComponent {
  @Input() selectedDevice: any;
  @Input() snav: MatSidenav;
  @Output() deviceUpdated = new EventEmitter<boolean>();
  public disableButtons: boolean = false;
  public updatedGateway: any;
  public pendingLoad: Promise<boolean>;
  public deviceList: any = [];
  public loadingGatewayInfo: boolean = true;
  public faSync = faSync;

  constructor(
    private maintenanceService: MaintenanceService,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private fullStoryService: FullStoryService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.snav.openedStart.subscribe(() => {
      this.getDoorsByGateway(this.selectedDevice.id);
    });
    this.snav.closedStart.subscribe(() => {
      this.updatedGateway = JSON.parse(JSON.stringify(this.selectedDevice));
    });
    this.fullStoryService.setPageVars({
      pageName: 'Gateway Configuration',
      gatewayId: this.selectedDevice.id,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updatedGateway = JSON.parse(
      JSON.stringify(changes.selectedDevice.currentValue)
    );
  }

  async getDoorsByGateway(deviceId) {
    this.deviceList = await this.maintenanceService
      .getDoorsByGateway(deviceId)
      .toPromise();
  }

  verifyAddressUpdate() {
    return this.selectedDevice.address !== this.updatedGateway.address;
  }

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingGatewayInfo) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 30000);
    });
  }

  getDateAndHour(lastOnline, option) {
    const date = new Date(lastOnline);
    if (option === 'hour') return moment.utc(date).format('HH:mm');
    if (option === 'date') return moment(date).format('DD/MM/YYYY');
    return null;
  }

  forceSync(event, device: any) {
    event.stopPropagation();
    this.disableButtons = true;

    this.maintenanceService
      .forceSync(device.id)
      .then(() => {
        this.loggingService.gatewayResponse(
          'success',
          this.translateService.instant(`Started synchronization successfully!`)
        );
        this.disableButtons = false;
      })
      .catch(() => {
        device.unlock = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Error while try started sync')}
            ${device.name}: ${this.translateService.instant(
            'device is offline'
          )}!`
        );

        this.disableButtons = false;
      });
  }

  restartDevice(device) {
    this.disableButtons = true;
    this.maintenanceService
      .restart(device.id)
      .then((response) => {
        if (response.successful) {
          this.disableButtons = false;
          this.loggingService.gatewayResponse(
            'success',
            `${device.name} ${this.translateService.instant(
              'successfully restarted!'
            )}`
          );
        } else {
          this.disableButtons = false;
          this.loggingService.gatewayResponse(
            'error',
            `${device.name} ${this.translateService.instant(
              'restarted failed:'
            )} ${this.translateService.instant(
              feedbackLKCmdResponse[LKCmdResponseType[response.value]]
            )}`
          );
        }
      })
      .catch(() => {
        this.disableButtons = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Failed to connect with')} ${
            device.name
          }`
        );
      });
  }

  unlockDevices(element) {
    if (element.type === 'Access Point') {
      this.unlockDoor(element);
    } else if (element.type === 'Gates') {
      this.maintenanceGate(element, 'unlock', 'Unlock');
    }
  }

  maintenanceGate(
    gate: any,
    actionType: string,
    actionDescription: string
  ): void {
    gate.unlock = true;
    this.disableButtons = true;
    this.maintenanceService
      .custom(gate.id, actionType)
      .then((response) => {
        if (response.successful) {
          this.loggingService.gatewayResponse(
            'success',
            gate.name +
              this.translateService.instant(' successfully did action: ') +
              this.translateService.instant(actionDescription)
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            this.translateService.instant('Action failed. Please, try again.')
          );
        }

        this.disableButtons = false;
        gate.unlock = false;
      })
      .catch(() => {
        this.loggingService.gatewayResponse(
          'error',
          this.translateService.instant('Failed to communicate with gate: ') +
            gate.name
        );

        this.disableButtons = false;
        gate.unlock = false;
      });
  }

  unlockDoor(device: any): void {
    this.pendingLoad = this.getPromise();

    device.unlock = true;
    this.maintenanceService
      .unlock(device.id)
      .then((response) => {
        device.unlock = false;
        if (response.successful === true) {
          this.loggingService.gatewayResponse(
            'success',
            `${device.name} ${this.translateService.instant(
              'successfully unlocked!'
            )}`
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            `${this.translateService.instant('Error unlocking')} ${
              device.name
            }: ${this.translateService.instant(
              LKCmdResponseType[response.value]
            )}`
          );
        }
      })
      .catch(() => {
        device.unlock = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Error unlocking')}
            ${device.name}: ${this.translateService.instant(
            'device is offline'
          )}!`
        );
      });
  }

  readFirmwareVersion(element: any): void {
    const config = {
      panelClass: 'dialog-container',
      width: '700px',
      maxWidth: '100%',
      data: {
        id: element.id,
        type: 'Door',
      },
    };

    this.dialog.open(FirmwareVersionComponent, config);
  }

  readBatteryState(element: any): void {
    const config = {
      panelClass: 'dialog-container',
      width: '700px',
      maxWidth: '100%',
      data: {
        battery: element.battery,
        idDoor: element.id,
      },
    };

    this.dialog.open(BateryStateComponent, config);
  }

  openDialog(element: any, dialog: string) {
    const config = {
      panelClass: 'dialog-container',
      width: '700px',
      maxWidth: '100%',
      data: {},
    };
    config.data = { selectedDevice: element };
    switch (dialog) {
      case 'timer':
        this.dialog.open(TimerComponent, config);
        break;
      default: {
        const dialogRef = this.dialog.open(DeviceSettingsComponent, config);
        dialogRef.componentInstance.deviceUpdated.subscribe(
          () => {
            this.loggingService.customAlert(
              {
                position: 'center',
                title: this.translateService.instant(
                  'Device successfully updated!'
                ),
              },
              'success'
            );
            dialogRef.close();
          },
          () => {
            this.loggingService.customAlert(
              {
                position: 'center',
                title: this.translateService.instant(
                  'Error updating device. Try again!'
                ),
              },
              'error'
            );
          }
        );
        break;
      }
    }
  }

  sendCommand(command: string) {
    if (command === 'reboot') {
      this.maintenanceService
        .sendGatewayCommand(this.selectedDevice.id, command)
        .subscribe(
          (response) => {
            if (response.response.includes('okok')) {
              this.loggingService.gatewayResponse(
                'success',
                `Gateway ${this.translateService.instant(
                  'restarted successfully!'
                )}`
              );
            } else {
              this.loggingService.gatewayResponse(
                'error',
                `${this.translateService.instant(
                  'Error while restarted the gateway'
                )}`
              );
            }
          },
          () => {
            this.loggingService.gatewayResponse(
              'error',
              `${this.translateService.instant(
                'Error while restarted the gateway'
              )}: ${this.translateService.instant('gateway is offline')}!`
            );
          }
        );
    } else if (command === 'version') {
      const config = {
        panelClass: 'dialog-container',
        width: '700px',
        maxWidth: '100%',
        data: {
          id: this.selectedDevice.id,
          type: 'Gateway',
        },
      };

      this.dialog.open(FirmwareVersionComponent, config);
    }
  }

  updateTimezone(device: any) {
    this.pendingLoad = this.getPromise();

    this.maintenanceService
      .setTimeZone(device.id)
      .then((response) => {
        device.unlock = false;
        if (response.successful === true) {
          this.loggingService.gatewayResponse(
            'success',
            `${device.name} ${this.translateService.instant(
              'successfully updated timezone!'
            )}`
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            `${this.translateService.instant(
              'Error while try update timezone from device'
            )} ${device.name}: ${this.translateService.instant(
              LKCmdResponseType[response.value]
            )}`
          );
        }
      })
      .catch(() => {
        device.unlock = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant(
            'Error while try update timezone from device'
          )}
            ${device.name}: ${this.translateService.instant(
            'device is offline'
          )}!`
        );
      });
  }

  updateTime(device: any) {
    this.pendingLoad = this.getPromise();

    this.maintenanceService
      .setTime(device.id)
      .then((response) => {
        if (response.successful) {
          this.loggingService.gatewayResponse(
            'success',
            `${device.name} ${this.translateService.instant(
              'updated time successfully!'
            )}`
          );
        } else {
          this.loggingService.gatewayResponse(
            'error',
            `${device.name} ${this.translateService.instant(
              'update time failed:'
            )} ${this.translateService.instant(
              feedbackLKCmdResponse[LKCmdResponseType[response.value]]
            )}`
          );
        }
      })
      .catch(() => {
        this.loggingService.gatewayResponse(
          'error',
          `${this.translateService.instant('Failed to connect with')} ${
            device.name
          }`
        );
      });
  }

  updateGateway() {
    this.maintenanceService.updateGateway(this.updatedGateway).subscribe(
      (response) => {
        this.selectedDevice.address = response.address;
        this.deviceUpdated.emit(true);
        this.loggingService.gatewayResponse(
          'success',
          `${this.selectedDevice.address} ${this.translateService.instant(
            'successfully updated!'
          )}`
        );
        this.disableButtons = false;
      },
      () => {
        this.disableButtons = false;
        this.loggingService.gatewayResponse(
          'error',
          `${this.selectedDevice.address} ${this.translateService.instant(
            'failed updating. Try again.'
          )}`
        );
      }
    );
  }
}
