export interface ILKDevice {
  id: number;
  name: string;
  address: string;
  battery: number;
  latitude: number;
  longitude: number;
  picture: string;
  kind: string;
  transport: string;
  allowGuestsToSetTimer: boolean;
  firmwareVersion: number;
  timeZone: string;
  readerMode: string;
  hasPasscode: boolean;
  hasBattery: boolean;
  serialCode: string;
  deviceConfiguration: any;
  hasCard: boolean;
  key: string;
  openDuration: string;
  disableTouch: boolean;
  invertSensor: boolean;
  touchSensitivity: string;
  operationMode: string;
  adminKey: string;
  owner: { id: string; name: string; surname: string; picture: string };
  accessLevel: string;
  useRealLogs: boolean;
  version: string;
  lastOnline: string;
}

export enum LKDeviceOperationMode {
  normallyClosed = 1,
  normallyOpen = 2,
  latch = 3,
}

export class LKDevice {
  id: number;
  name: string;
  picture: string;
  image: any;
  address: string = '';
  serial: string = '';
  latitude: number = 0;
  longitude: number = 0;
  battery: number = 100;
  firmwareVersion: number;
  kind: string;
  transport: string;
  deviceConfiguration: any;
  hasPasscode: boolean;
  hasBattery: boolean;
  hasCard: boolean;
  timeZone: string;
  serialCode: string = '';
  allowGuestsToSetTimer: boolean;
  readerMode: string;
  key: string;
  openDuration: string;
  disableTouch: boolean;
  invertSensor: boolean;
  touchSensitivity: string;
  operationMode: string;
  adminKey: string;
  owner: { id: string; name: string; surname: string; picture: string };
  accessLevel: string;
  useRealLogs: boolean;
  version: string = '';
  lastOnline: string;

  constructor(serverObject: Partial<ILKDevice>) {
    Object.assign(this, serverObject);
  }

  static getDeviceType(serialCode: string): string {
    switch (serialCode.substr(1, 3)) {
      case 'LKO':
        return 'LKOne';
      case 'LKC':
        return 'LKGate';
      case 'LKB':
        return 'LKMini';
      case 'LKL':
        return 'LKLocker';
      default:
        return 'LK81';
    }
  }
}
