import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BookingsService } from '@modules/bookings/bookings.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss'],
})
export class BookingSummaryComponent implements OnInit {
  @Output() previousItem = new EventEmitter<any>();
  @Output() submitBooking = new EventEmitter<{
    response: string;
    error: Boolean;
  }>();
  @Output() stepIndex = new EventEmitter<any>();
  @Input() createBooking = {
    userId: null,
    userName: '',
    userSurname: '',
    userEmail: '',
    userPhone: '',
    userCpf: '',
    passcode: '',
    startDateTime: null,
    endDateTime: null,
    forms: null,
    action: '',
    doors: [],
    doorIds: [],
  };
  @Input() editBooking = {
    userId: null,
    userName: '',
    userSurname: '',
    userEmail: '',
    userPhone: '',
    userCpf: '',
    passcode: '',
    startDateTime: null,
    endDateTime: null,
    bookingId: null,
    updatedEmail: false,
    updatedCpf: false,
    updatedCard: false,
    updatedPasscode: false,
    forms: null,
    action: '',
    doors: [],
    doorIds: [],
  };
  @Input() action: string;
  public sizeWidthSuitable = false;
  public doorsIds: any = [];
  public booking = {
    userId: '',
    userName: '',
    userSurname: '',
    userEmail: '',
    userPhone: '',
    userCpf: '',
    passcode: '',
    startDateTime: null,
    endDateTime: null,
    doors: [],
  };
  public allowedPersonalData = new UntypedFormControl(false, []);
  public sendPasscodeBySms = new UntypedFormControl(false, []);
  public language = 'default';
  public isSubmiting = false;

  constructor(private bookingsService: BookingsService) {}

  ngOnInit() {
    this.sizeWidthSuitable = window.innerWidth <= 500;
  }

  ngOnChanges() {
    if (this.action === 'Create') this.booking = this.createBooking;
    else this.booking = this.editBooking;
  }

  getDateAsString(date) {
    date = new Date(date);
    const year = date.getFullYear();
    const month = this.capitalizeFirstLetter(
      date.toLocaleString(this.language, { month: 'short' })
    );
    const day = date.getDate();
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    return `${month} ${
      day <= 9 ? `0${day}` : day
    }, ${year} - ${hour}:${minute}`;
  }

  private capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  changeStep(stepIndex: number) {
    this.stepIndex.emit(stepIndex);
  }

  onSubmit() {
    this.isSubmiting = true;
    this.doorsIds = [];
    // eslint-disable-next-line prefer-const
    let bookingInfo: any = JSON.parse(JSON.stringify(this.booking));

    if (bookingInfo.doors) {
      bookingInfo.doors.forEach((door) => {
        this.doorsIds.push(door.id);
      });
      bookingInfo.doorIds = this.doorsIds.toString();
    }
    if (bookingInfo.forms.userPhone) {
      bookingInfo.forms.userPhone = bookingInfo.forms.userPhone.dialCode.concat(
        bookingInfo.forms.userPhone.number
      );
    }
    delete bookingInfo.doors;

    Object.keys(bookingInfo).map((value) => {
      if (bookingInfo[value] == null || bookingInfo[value] === '')
        delete bookingInfo[value];
      return bookingInfo[value];
    });

    if (this.action === 'Create') {
      this.bookingsService
        .createBooking({
          ...bookingInfo.forms,
          doorIds: bookingInfo.doorIds,
          startDateTime: bookingInfo.startDateTime,
          endDateTime: bookingInfo.endDateTime,
          allowedPersonalData: this.allowedPersonalData.value,
          sendPasscodeBySms: this.sendPasscodeBySms.value,
        })
        .subscribe(
          (response) => {
            this.submitBooking.emit({ response, error: false });
          },
          (err) => {
            if (err.error.errorCode === 102 || err.error.errorCode === 156) {
              this.submitBooking.emit({
                response: err.error.errorDescription,
                error: true,
              });
            } else {
              this.submitBooking.emit({
                response: 'Action failed. Please, try again.',
                error: true,
              });
            }
          }
        );
    } else {
      this.bookingsService
        .updateBooking({
          ...bookingInfo.forms,
          doorIds: bookingInfo.doorIds,
          bookingId: bookingInfo.bookingId,
          startDateTime: bookingInfo.startDateTime,
          endDateTime: bookingInfo.endDateTime,
          updatedEmail: bookingInfo.updatedEmail,
          updatedCpf: bookingInfo.updatedCpf,
          updatedCard: bookingInfo.updatedCard,
          updatedPasscode: bookingInfo.updatedPasscode,
          allowedPersonalData: this.allowedPersonalData.value,
          sendPasscodeBySms: this.sendPasscodeBySms.value,
        })
        .subscribe(
          (response) => {
            this.submitBooking.emit({ response, error: false });
          },
          () => {
            this.submitBooking.emit({
              response: 'Action failed. Please, try again.',
              error: true,
            });
          }
        );
    }
    this.isSubmiting = false;
  }
}
