import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomMatPaginatorIntlService extends MatPaginatorIntl {
  length: number = 0;

  constructor(private translateService: TranslateService) {
    super();
  }

  itemsPerPageLabel = this.translateService.instant(
    'Number of items per page: '
  );
  nextPageLabel = this.translateService.instant('Next');
  previousPageLabel = this.translateService.instant('Previous');

  getRangeLabel = (page: number, pageSize: number, totalResults: number) => {
    if (!totalResults) {
      return this.translateService.instant('No result');
    }

    totalResults = this.length === 0 ? totalResults : this.length;

    const of = this.translateService
      ? this.translateService.instant('of')
      : 'of';
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < totalResults
        ? Math.min(startIndex + pageSize, totalResults)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${of} ${totalResults}`;
  };
}
