<div class="edit-site">
    <div class="text-center">
        <h4 mat-dialog-title *ngIf="data.action === 'Edit'" translate>Edit Site</h4>
        <h4 mat-dialog-title *ngIf="data.action === 'Create'" translate>Create Site</h4>
    </div>
    <div mat-dialog-content>
            <mat-list [formGroup]="siteForm">
                <div class="row">
                    <div class="col-md-6">
                        <span class="mrg-right-15">{{ 'Name' | translate }}</span>
                        <mat-form-field class="width-100" appearance="outline" required>
                            <input id="name" formControlName="name" matInput type="text" class="third-color" />
                            <mat-error *ngIf="f.name.errors" translate>
                                Required. Must have at least 3 characters.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <span class="mrg-right-15">{{ 'Address' | translate }}</span>
                        <mat-form-field class="width-100" appearance="outline" required>
                            <input id="searchAddressField" matInput formControlName="address" type="text"
                                class="third-color" />
                            <mat-error *ngIf="f.address.errors" translate>
                                Required. Must have at least 3 characters.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <agm-map id="map" [latitude]="lat" [longitude]="lng">
                        <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
                    </agm-map>
                </div>
            </mat-list>
    </div>

    <div *ngIf="widthSuitableSize" class="buttons steppers">
        <div>
            <button mat-raised-button class="edit-site__cancel-button-mobile" (click)="cancel()">
                <span translate>Cancel</span>
            </button>
            <button mat-raised-button class="edit-site__delete-button-mobile" (click)="removeSite()">
                <span translate>Remove Site</span>
            </button>
            <button mat-raised-button class="edit-site__submit-button-mobile" color="accent" type="submit"
                (click)="onSubmit()" [disabled]="
          !siteForm.valid
        ">
                <span translate>Save</span>
            </button>
        </div>
    </div>
    <div *ngIf="!widthSuitableSize" class="buttons-big-size-mode steppers">
        <div>
            <button mat-raised-button class="edit-site__cancel-button" (click)="cancel()">
                <span translate>Cancel</span>
            </button>
        </div>
        <div>
            <button mat-raised-button class="edit-site__delete-button" (click)="removeSite()">
                <span translate>Remove Site</span>
            </button>
            <button mat-raised-button class="edit-site__submit-button" color="accent" type="submit" (click)="onSubmit()"
                [disabled]="
              !siteForm.valid 
            ">
                <span translate>Save</span>
            </button>
        </div>
    </div>
</div>