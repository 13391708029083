import { ApiService } from '@core/http/api.service';
import { Observable } from 'rxjs';
import { Corporation } from '@models/LKCorporation';
import * as qs from 'querystring';
import { Injectable } from '@angular/core';

@Injectable()
export class CorporationService {
  constructor(private apiService: ApiService) {}

  getUserCorporations = (): Observable<Corporation[]> => {
    const body = qs.stringify({
      corpAccessLevel: 'admin,owner,siteAdmin',
    });

    return this.apiService.getCorporations(body);
  };

  updateCorp = (corp: any): Observable<any> => {
    return this.apiService.updateCorp(
      qs.stringify({
        ...corp,
      })
    );
  };
}
