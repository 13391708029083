<app-top-loading [loadingProp]="loadingData && !(pendingLoad | async)">
</app-top-loading>

<div
  class="confirm-password field row center"
  *ngIf="loadingData && !(pendingLoad | async)"
>
  <div class="loading-wrapper">
    <mat-spinner diameter="40" color="accent"></mat-spinner>
  </div>
</div>
<app-no-connection
  class="confirm-password field"
  *ngIf="pendingLoad | async"
  (refreshPage)="updatePassword()"
>
</app-no-connection>
<div
  *ngIf="!loadingData && !(pendingLoad | async)"
  class="confirm-password field row"
>
  <div class="confirm-password__title">
    <h5 mat-dialog-title translate>Remove Site</h5>
    <span translate>To remove<span> {{data.site.name}} </span><span translate>you must insert your account password to finish the process.</span></span>
  </div>
  <span class="confirm-password__input-wrapper">
    <div class="confirm-password__input mrg-top-20">
      <mat-label>
        <span class="label-input">{{ 'Confirm Password' | translate }}</span>
      </mat-label>
      <mat-form-field class="width-100" appearance="outline">
        <input
          matInput
          [type]="getType('currentPassword')"
          [(ngModel)]="currentPassword"
        />
        <fa-icon
          matSuffix
          class="pointer pdd-btm-15 font-size-20"
          (click)="showCurrentPassword = !showCurrentPassword"
          icon="{{ !showCurrentPassword ? 'eye' : 'eye-slash' }}"
        >
        </fa-icon>
      </mat-form-field>
    </div>
  </span>
  <div class="mrg-top-50 btn-options">
    <button
      arial-label="cancel dialog"
      (click)="closeDialog()"
      class="confirm-password__cancel left"
    >
      <span translate>Cancel</span>
    </button>
    <button
      aria-label="update password"
      class="btn-update right"
      (click)="removeSite()"
      [disabled]="currentPassword === ''"
    >
      <span translate>Confirm</span>
    </button>
  </div>
</div>
