import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieModule } from 'ngx-cookie';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AuthGuard } from '@core/guards/auth.guard';
import { SidebarComponent } from '@core/sidebar/sidebar.component';
import { HeaderComponent } from '@core/header/header.component';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faLanguage,
  faUser,
  faInfoCircle,
  faDoorClosed,
  faServer,
  faSignInAlt,
  faSignOutAlt,
  faUsers,
  faAngleRight,
  faAngleDown,
  faBars,
  faTimes,
  faUserTag,
  faShareAlt,
  faWrench,
  faComment,
  faDesktop,
  faMoon,
  faCode,
  faChartBar,
  faWifi,
  faCalendarAlt,
  faCreditCard,
  faEye,
  fas,
} from '@fortawesome/free-solid-svg-icons';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { StatusService } from '@services/status.service';
import { GlobalsService } from '@services/globals.service';
import { CorporationGuard } from '@core/guards/corporation.guard';
import { SitesGuard } from '@core/guards/sites.guard';
import { BreadcrumbComponent } from '@core/breadcrumb/breadcrumb.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RolesService } from '@modules/roles/roles.service';
import { UserService } from '@modules/users/users.service';
import { MatIconModule } from '@angular/material/icon';
import { RangeDailyUsageComponent } from '@modules/dashboard/components/range-daily-usage/range-daily-usage.component';
import { DevicesService } from '@modules/devices/devices.service';
import { BillingModule } from '@modules/billing/billing.module';
import { SitesModule } from '@modules/sites/sites.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { GroupsService } from './modules/groups/groups.service';
import { DashboardService } from './modules/dashboard/dashboard.service';
import { GeneralDashboardModule } from './modules/general-dashboard/general-dashboard.module';
import { FeedbackModule } from './modules/feedback/feedback.module';
import { BookingsModule } from './modules/bookings/bookings.module';
import { IntegrationsModule } from './modules/integrations/integrations.module';
import { UiStyleToggleService } from './ui-service.service';
import { ListingsModule } from './modules/listings/listings.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BsDropdownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatIconModule,
    SharedModule,
    FontAwesomeModule,
    NgxMaterialTimepickerModule,
    GeneralDashboardModule,
    FeedbackModule,
    BookingsModule,
    IntegrationsModule,
    BillingModule,
    ListingsModule,
    SitesModule,
  ],
  exports: [FontAwesomeModule],
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    BreadcrumbComponent,
    RangeDailyUsageComponent,
  ],
  providers: [
    StatusService,
    AuthGuard,
    CorporationGuard,
    SitesGuard,
    Title,
    GlobalsService,
    GroupsService,
    DevicesService,
    DashboardService,
    RolesService,
    UserService,
    UiStyleToggleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(
      faLanguage,
      faUser,
      faInfoCircle,
      faDoorClosed,
      faServer,
      faSignInAlt,
      faSignOutAlt,
      faUsers,
      faAngleRight,
      faAngleDown,
      faBars,
      faTimes,
      faUserTag,
      faShareAlt,
      faWrench,
      faComment,
      faDesktop,
      faMoon,
      faCode,
      faChartBar,
      faWifi,
      faCalendarAlt,
      faCreditCard,
      faEye
    );
  }
}

declare global {
  interface Window {
    analytics: any;
  }
}
