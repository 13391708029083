import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupsService } from '@modules/groups/groups.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {
  @Output() deleted = new EventEmitter<boolean>();
  private results: { hasError: boolean }[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private groupsService: GroupsService
  ) {}

  deleteComponent() {
    if (this.data.type === 'Group') {
      this.groupsService
        .removeGroup(this.data.group.id)
        .then(() => {
          this.deleted.emit(true);
          this.results.push({ hasError: false });
        })
        .catch(() => {
          this.results.push({ hasError: true });
        });
    }
  }
}
