import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceService } from '@modules/maintenance/maintenance.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';

@Component({
  selector: 'app-batery-state',
  templateUrl: './batery-state.component.html',
  styleUrls: ['./batery-state.component.scss'],
})
export class BateryStateComponent implements OnInit {
  public batteryEnergy: number = 0;
  public sizeSuitableWidth: boolean = false;
  public batteryVoltage: number = 0;
  public pendingLoad: Promise<boolean>;
  public loadingData: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialogRef<BateryStateComponent>,
    private maintenanceService: MaintenanceService,
    private loggingService: LoggingService,
    private translateService: TranslateService
  ) {}

  getPromise(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.loadingData) {
          resolve(true);
          this.dialog.close({ successful: false });
          this.loggingService.customAlert(
            {
              position: 'center',
              title: this.translateService.instant(
                'It was not possible to read battery status.'
              ),
            },
            'error'
          );
        } else {
          resolve(false);
        }
      }, 60000);
    });
  }

  ngOnInit() {
    this.sizeSuitableWidth = window.innerWidth <= 500;
    this.batteryEnergy = this.data.battery;
    this.getBatteryState();
  }

  getBatteryState() {
    this.loadingData = true;
    this.pendingLoad = this.getPromise();

    this.maintenanceService
      .getBatteryDevice({ id: this.data.idDoor })
      .subscribe(
        (response) => {
          setTimeout(() => {
            this.loadingData = false;
          }, 1000);
          if (response && response.successful) {
            this.batteryEnergy = response.level;
          } else {
            this.dialog.close({ successful: false });
            this.loadingData = false;
            this.loggingService.customAlert(
              {
                position: 'center',
                title: this.translateService.instant(
                  'It was not possible to read battery status.'
                ),
              },
              'error'
            );
          }
        },
        (err) => {
          setTimeout(() => {
            this.loadingData = false;
          }, 1000);
          this.dialog.close({ successful: false });
          const { errorDescription } = err.error;
          this.loggingService.customAlert(
            {
              position: 'center',
              title: this.translateService.instant(errorDescription),
            },
            'error'
          );
        }
      );
  }

  close() {
    this.dialog.close({ successful: true });
  }
}
