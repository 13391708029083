import * as qs from 'querystring';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/http/api.service';
import { GlobalsService } from '@services/globals.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getBillings = (): Observable<any> => {
    return this.apiService.getBillings(
      qs.stringify({ corpId: this.globalsService.getCorporationId() })
    );
  };
}
