<div class="extended-dashboard-card">
    <div class="loading-div-count" *ngIf="isLoading">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
    </div>
    <div class="row" *ngIf="!isLoading">
        <div class="col-6" [ngClass]="labelOne === 'Today\'s Occupancy' ? 'border-right' : 'first-column'" style="text-align: center">
            <span *ngIf="labelOne === 'Low Battery'" class="extended-dashboard-card__label" translate>Access Point</span>
            <span class="number-info">
                <span *ngIf="numberInfoOne !== null && numberInfoOne !== undefined" [ngClass]="numberColor">{{
                    numberInfoOne }}</span>
                <span *ngIf="totalNumberOne == null && totalNumberOne == undefined" [ngClass]="numberColor">%</span>
                <span *ngIf="totalNumberOne !== null && totalNumberOne !== undefined" [ngClass]="numberColor">/{{
                    totalNumberOne }}</span>
            </span>
            <span class="extended-dashboard-card__label" translate>{{ labelOne }}</span>
        </div>
        <div class="col-6" [ngClass]="[list && list.length === 0 || totalNumberTwo === 0 ? 'extended-dashboard-card__empty-info' : '', list && list.length > 0 ? 'second-column' : '']"
            style="text-align: center">
            <span *ngIf="totalNumberTwo !== 0" class="number-info">
                <span *ngIf="numberInfoTwo !== null && numberInfoTwo !== undefined" [ngClass]="numberColor">{{
                    numberInfoTwo }}</span>
                <span *ngIf="totalNumberTwo !== null && totalNumberTwo !== undefined" [ngClass]="numberColor">/{{
                    totalNumberTwo }}</span>
            </span>
            <div *ngIf="list && list.length > 0" class="extended-dashboard-card__list-devices">
                <span *ngFor="let device of list.slice(0,3);" class="device-list">
                    <span>
                        {{ device.name }}
                    </span>
                    <span>
                        {{ device.battery }}%
                    </span>
                </span>
            </div>
            <div *ngIf="!list && totalNumberTwo === 0">
                <span class="empty-alert-message" translate>You have no Bookings today!</span>
            </div>
            <div *ngIf="list && list.length === 0 && totalNumberOne !== 0">
                <span class="empty-alert-message" translate>You are safe!</span>
                <span class="empty-message" translate>All bateries are OK</span>
            </div>
            <div *ngIf="list && list.length === 0 && totalNumberOne === 0">
                <span class="empty-alert-message" translate>You have no Devices</span>
                <span class="empty-message" translate>Add Devices to you Site</span>
            </div>
            <span *ngIf="totalNumberTwo !== 0" class="extended-dashboard-card__label" translate>{{ labelTwo }}</span>
        </div>
    </div>
</div>