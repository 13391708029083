import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LKGroup } from '@models/LKGroup';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss'],
})
export class EditGroupComponent implements OnInit {
  @Output() groupUpdated = new EventEmitter<LKGroup>();
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
  ngOnInit() {}

  setInfo(groupInfo: { name: string; description: string }) {
    const accessGroup = {
      ...this.data.group,
      name: groupInfo.name,
      description: groupInfo.description,
    };

    this.groupUpdated.emit(accessGroup);
  }

  setRestrictions(groupRestrictions: {}) {
    const accessGroup = {
      ...this.data.group,
      restrictions: groupRestrictions,
    };

    this.groupUpdated.emit(accessGroup);
  }

  setDoors(doors: { info: []; ids: [] }) {
    const accessGroup = {
      ...this.data.group,
      doors: doors.info,
    };

    this.groupUpdated.emit(accessGroup);
  }

  setUsersAndRoles(info: { users: []; roles: [] }) {
    const accessGroup = {
      ...this.data.group,
      users: info.users,
      roles: info.roles,
    };

    this.groupUpdated.emit(accessGroup);
  }
}
