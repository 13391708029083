<div class="select-device-wrapper">
  <div class="select-device">
    <div mat-dialog-content>
      <mat-list class="no-pdd">
        <span class="label-search-device" translate>
          Search Devices by name
        </span>
        <div class="row">
          <div class="col-12">
            <mat-form-field [ngClass]="widthSizeSuitable ? 'width-100' : 'width-100 pdd-right-40'" appearance="outline">
              <span matPrefix class="pdd-right-10">
                <fa-icon [icon]="faSearch" class="font-size-15"></fa-icon>
              </span>
              <input matInput type="text" [formControl]="myControl" [matAutocomplete]="auto" class="primary-color"
                placeholder="{{ 'Must have at least 3 characters.' | translate }}" />
              <mat-error *ngIf="myControl.errors" translate>
                Must have at least 3 characters.
              </mat-error>
              <mat-autocomplete #auto="matAutocomplete">
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </mat-list>

      <div class="row">
        <div class="col-md-6">
          <span translate>
            Available Devices
          </span>
          <mat-card appearance="outlined" class="select-device__devices centered">
            <mat-card-content>
              <div class="no-devices" *ngIf="siteDevices.length === 0">
                <fa-icon class="font-size-25" icon="door-closed"></fa-icon>
                <span translate>No devices available</span>
              </div>
              <mat-list *ngIf="filteredOptions.length === 0 && siteDevices.length !== 0">
                <mat-list-item *ngFor="let option of siteDevices" style="cursor: pointer;" (click)="addOption(option)"
                  class="select-device__selected-device">
                  <span class="device-name">
                    {{ option.name }}
                  </span>
                </mat-list-item>
              </mat-list>
              <mat-list *ngIf="filteredOptions.length !== 0 && siteDevices.length !== 0">
                <mat-list-item *ngFor="let option of filteredOptions" style="cursor: pointer;"
                  (click)="addOption(option)" class="select-device__selected-device">
                  <span class="device-name">
                    {{ option.name }}
                  </span>
                </mat-list-item>
              </mat-list>
              <div class="loading-spinner" *ngIf="loadingData && !(pendingLoad | async)">
                <mat-spinner diameter="40" color="accent"></mat-spinner>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="col-md-6">
          <span translate>
            Selected Devices
          </span>
          <mat-card appearance="outlined" class="select-device__devices centered">
            <mat-card-content>
              <mat-list>
                <mat-list-item *ngFor="let option of selectedOptions" style="cursor: pointer;"
                  (click)="removeOption(option)" class="select-device__selected-device">
                  <span class="device-name">
                    {{ option.name }}
                  </span>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="data && data.screen === 'Listings' ? 'steppers buttons' : 'steppers'">
    <app-stepper-controller [actionText]="data && data.screen === 'Listings' ? 'Save' : 'Next'"
      [blockButton]="false" (onFormCleared)="previousItem.emit()" (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>