import { Injectable } from '@angular/core';
import { ApiService } from '@core/http/api.service';
import { Observable } from 'rxjs';
import * as qs from 'querystring';
import { GlobalsService } from '@services/globals.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(
    private apiService: ApiService,
    private globalsService: GlobalsService
  ) {}

  getIntegrations(): Observable<any> {
    return this.apiService.getIntegrations(
      qs.stringify({ corpId: this.globalsService.getCorporationId() })
    );
  }

  createIntegration(integrationToken): Observable<any> {
    return this.apiService.createIntegration(
      qs.stringify({
        integrationToken,
        corpId: this.globalsService.getCorporationId(),
      })
    );
  }

  importListings(): Observable<any> {
    return this.apiService.importListings(
      qs.stringify({
        corpId: this.globalsService.getCorporationId(),
      })
    );
  }

  configureAnIntegration(clientId: number, password: string): Observable<any> {
    return this.apiService.createSessionIntegration(
      qs.stringify({ password, clientId })
    );
  }

  removeIntegration(integrationId: number) {
    return this.apiService.removeIntegration(
      qs.stringify({
        integrationId,
        corpId: this.globalsService.getCorporationId(),
      })
    );
  }
}
