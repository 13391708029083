import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';

import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { SitesService } from '@modules/sites/sites.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '@services/logging.service';
import { MapsAPILoader } from '@agm/core';
import { DeleteSiteComponent } from '@modules/sites/components/delete-site/delete-site.component';

declare let google: any;
@Component({
  selector: 'app-update-site-info',
  templateUrl: './update-site-info.component.html',
  styleUrls: ['./update-site-info.component.scss'],
})
export class UpdateSiteInfoComponent implements OnInit {
  public siteForm: UntypedFormGroup;
  public widthSuitableSize = false;
  public lat;
  public lng;
  public zoom;
  public address;
  private geoCoder;

  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private sitesService: SitesService,
    private translateService: TranslateService,
    private loggingService: LoggingService,
    private dialog: MatDialogRef<UpdateSiteInfoComponent>,
    public dialogRef: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.siteForm = new UntypedFormGroup({
      name: new UntypedFormControl(data.site ? data.site.name : '', [
        Validators.required,
        Validators.minLength(3),
      ]),
      address: new UntypedFormControl(data.site ? data.site.address : '', [
        Validators.minLength(3),
      ]),
      siteId: new UntypedFormControl(data.site ? data.site.id : '', []),
    });
  }

  ngOnInit() {
    this.widthSuitableSize = window.innerWidth <= 500;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      if (!this.data.site.latitude || !this.data.site.longitude) {
        this.setCurrentLocation();
      } else {
        this.initMap(this.data.site.latitude, this.data.site.longitude);
      }
    });
    const input = document.getElementById(
      'searchAddressField'
    ) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['address'],
    });
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // eslint-disable-next-line no-multi-assign
        const place = (google.maps.places.PlaceResult =
          autocomplete.getPlace());
        this.siteForm.value.address = '';

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();
        this.initMap(this.lat, this.lng);
        this.address = place.formatted_address;
        this.zoom = 15;
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  initMap(latitude, longitude) {
    const myLatlng = { lat: latitude, lng: longitude };

    const map = new google.maps.Map(document.getElementById('map')!, {
      zoom: 15,
      center: myLatlng,
    });

    const marker = new google.maps.Marker({
      position: myLatlng,
      map,
      draggable: true,
    });

    google.maps.event.addListener(marker, 'drag', () => {
      this.lat = marker.getPosition().lat();
      this.lng = marker.getPosition().lng();
    });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 15;
            this.address = results[0].formatted_address;
          } else {
            this.loggingService.customAlert(
              {
                title: this.translateService.instant('No results found'),
                text: '',
              },
              'error'
            );
          }
        } else {
          this.loggingService.customAlert(
            {
              title: this.translateService.instant(
                `Geocoder failed due to: ${status}`
              ),
              text: '',
            },
            'error'
          );
        }
      }
    );
  }

  get f() {
    return this.siteForm.controls;
  }

  cancel() {
    this.dialog.close();
  }

  removeSite() {
    const config = {
      panelClass: 'dialog-container',
      width: '800px',
      maxWidth: '100vw',
      data: {
        type: 'Site',
        site: this.data.site,
      },
    };

    const dialogRef = this.dialogRef.open(DeleteSiteComponent, config);
    dialogRef.componentInstance.deleted.subscribe((results) => {
      this.dialog.close(results);
    });
  }

  onSubmit() {
    if (this.siteForm.valid) {
      Object.keys(this.siteForm.value).map((value) => {
        if (
          this.siteForm.value[value] == null ||
          this.siteForm.value[value] === ''
        )
          delete this.siteForm.value[value];
        return this.siteForm.value[value];
      });
      if (
        this.address !== undefined &&
        this.siteForm.value.address === undefined
      ) {
        this.siteForm.value.address = this.address;
      }
      if (this.data.action === 'Edit') {
        this.sitesService
          .updateSite(this.siteForm.value, this.lat, this.lng)
          .subscribe(
            (siteResponse) => {
              this.loggingService.customAlert(
                {
                  title: this.translateService.instant(
                    'Site successfully updated!'
                  ),
                  text: '',
                },
                'success'
              );
              this.dialog.close(siteResponse);
            },
            (error) => {
              this.loggingService.customAlert(
                {
                  title: this.translateService.instant('Error updating site!'),
                  text:
                    this.translateService.instant(
                      error.error.errorDescription
                    ) || '',
                },
                'error'
              );
            }
          );
      }
      if (this.data.action === 'Create') {
        this.sitesService
          .createSite(this.siteForm.value, this.lat, this.lng)
          .subscribe(
            (siteResponse) => {
              this.loggingService.customAlert(
                {
                  title: this.translateService.instant(
                    'Site successfully created!'
                  ),
                  text: '',
                },
                'success'
              );
              this.dialog.close(siteResponse);
            },
            (error) => {
              this.loggingService.customAlert(
                {
                  title: this.translateService.instant('Error creating site!'),
                  text:
                    this.translateService.instant(
                      error.error.errorDescription
                    ) || '',
                },
                'error'
              );
            }
          );
      }
    }
  }
}
