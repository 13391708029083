<div class="group-summary">
  <div mat-dialog-content>
    <mat-list class="no-pdd">
      <mat-card appearance="outlined" class="group-summary__card">
        <h5 translate>Name and Description</h5>
        <mat-list-item class="width-100" appearance="fill">
          {{ accessGroup.name }}
        </mat-list-item>
        <h6 *ngIf="accessGroup.description" translate>Description</h6>
        <mat-list-item class="width-100" appearance="fill" *ngIf="accessGroup.description">
          {{ accessGroup.description }}
        </mat-list-item>
      </mat-card>

      <mat-card appearance="outlined" class="group-summary__card">
        <div class="group-summary__section-device">
          <div class="group-summary__device width-100">
            <div class="group-summary__mrg-device" [ngClass]="
                  sizeWidthSuitable
                    ? 'width-100 group-summary__column'
                    : 'width-50 group-summary__row'
                ">
              <h6 translate>Allowed Devices</h6>
              <section>
                <mat-slide-toggle [(ngModel)]="accessGroup.restrictions.passcodeAllowed" disabled>
                  <span translate>Passcode</span>
                </mat-slide-toggle>
              </section>

              <section>
                <mat-slide-toggle [(ngModel)]="accessGroup.restrictions.cardAllowed" disabled>
                  <span translate>Card</span>
                </mat-slide-toggle>
              </section>

              <section>
                <mat-slide-toggle [(ngModel)]="accessGroup.restrictions.smartphoneAllowed" disabled>
                  <span translate>Smartphone</span>
                </mat-slide-toggle>
              </section>

              <section>
                <mat-slide-toggle [(ngModel)]="accessGroup.restrictions.remoteAllowed" disabled>
                  <span translate>Remote</span>
                </mat-slide-toggle>
              </section>

              <section>
                <mat-slide-toggle [(ngModel)]="accessGroup.restrictions.qrcodeAllowed" disabled>
                  <span translate>QRCode</span>
                </mat-slide-toggle>
              </section>
            </div>

            <div [ngClass]="
                  sizeWidthSuitable
                    ? 'width-100 group-summary__column'
                    : 'width-50 group-summary__row'
                ">
              <h6 translate>Access device without internet</h6>
              <section>
                <mat-slide-toggle [(ngModel)]="accessGroup.restrictions.offlineAllowed" disabled><span translate>Offline
                    access</span>
                </mat-slide-toggle>
              </section>
            </div>
          </div>
        </div>
        <div class="group-summary__section-device" *ngIf="!accessGroup.restrictions.offlineAllowed">
          <div class="group-summary__mrg-device" [ngClass]="
                sizeWidthSuitable
                  ? 'width-100 group-summary__column'
                  : 'width-50 group-summary__row'
              ">
            <h6 translate>Date Restrictions</h6>
            <section>
              <mat-slide-toggle [checked]="
                    this.accessGroup.restrictions.endDateTime === null ||
                    this.accessGroup.restrictions.startDateTime === null
                  " disabled>
                <span translate>Permanent Access</span>
              </mat-slide-toggle>
            </section>
            <div *ngIf="
                  this.accessGroup.restrictions.endDateTime !== null ||
                  this.accessGroup.restrictions.startDateTime !== null
                " class="mrg-top-10 group-summary__access_start-end">
              <div class="inline-block group-summary__start-end">
                <h6 translate>Access starts on</h6>
                <div>
                  <mat-form-field>
                    <input matInput class="pdd-left-10 primary-color width-70" type="text"
                      [matDatepicker]="pickerStartDate" [(ngModel)]="accessGroup.restrictions.startDateTime" readonly />
                    <mat-datepicker #pickerStartDate disabled="true">
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <br />
              <div class="inline-block group-summary__start-end">
                <h6 translate>Access ends on</h6>
                <div>
                  <mat-form-field>
                    <input class="pdd-left-10 primary-color width-70" type="text" matInput
                      [matDatepicker]="pickerEndDate" [(ngModel)]="accessGroup.restrictions.endDateTime" readonly />
                    <mat-datepicker #pickerEndDate disabled="true">
                    </mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div [ngClass]="
                sizeWidthSuitable
                  ? 'width-100 group-summary__column'
                  : 'width-50 group-summary__row'
              ">
            <h6 translate>Daily Restrictions</h6>
            <div class="mrg-vertical-10">
              <section>
                <mat-slide-toggle [checked]="
                      this.accessGroup.restrictions.endTimeOfDay === null ||
                      this.accessGroup.restrictions.startTimeOfDay === null
                    " disabled>
                  <span translate>
                    Access every day all day long
                  </span>
                </mat-slide-toggle>
              </section>
            </div>
            <div *ngIf="
                  this.accessGroup.restrictions.endTimeOfDay !== null ||
                  this.accessGroup.restrictions.startTimeOfDay !== null
                " class="mrg-vertical-10">
              <div>
                <div>
                  <h6 translate>Access days</h6>
                </div>
                <div class="group-summary__day-box" [ngStyle]="changeStyleONListOfDays('sun')">
                  <span translate>Sun</span>
                </div>
                <div class="group-summary__day-box" [ngStyle]="changeStyleONListOfDays('mon')">
                  <span translate>Mon</span>
                </div>
                <div class="group-summary__day-box" [ngStyle]="changeStyleONListOfDays('tue')">
                  <span translate>Tue</span>
                </div>
                <div class="group-summary__day-box" [ngStyle]="changeStyleONListOfDays('wed')">
                  <span translate>Wed</span>
                </div>
                <div class="group-summary__day-box" [ngStyle]="changeStyleONListOfDays('thu')">
                  <span translate>Thu</span>
                </div>
                <div class="group-summary__day-box" [ngStyle]="changeStyleONListOfDays('fri')">
                  <span translate>Fri</span>
                </div>
                <div class="group-summary__day-box" [ngStyle]="changeStyleONListOfDays('sat')">
                  <span translate>Sat</span>
                </div>
              </div>
              <div class="mrg-top-20 group-summary__range-time">
                <div class="inline-block">
                  <h6 translate>Allow after</h6>
                  <div>
                    <input id="startTime" placeholder="08:30" type="time" class="primary-color"
                      [(ngModel)]="accessGroup.restrictions.startTimeOfDay" readonly />
                  </div>
                </div>
                <br />
                <div class="inline-block">
                  <h6 translate>Allow until</h6>
                  <div>
                    <input id="endTime" placeholder="16:30" type="time" class="primary-color"
                      [(ngModel)]="accessGroup.restrictions.endTimeOfDay" readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card appearance="outlined" class="group-summary__card">
        <h5 translate>Doors</h5>
        <div *ngIf="accessGroup.doorList.length !== 0; else doorListEmpty">
          <mat-chip-listbox #chipList>
            <mat-chip-option *ngFor="let door of accessGroup.doorNameList" class="group-summary__bubble">
              {{ door.name }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>

        <ng-template #doorListEmpty>
          <mat-list-item class="width-100" appearence="fill">
            <span translate>No door was selected</span>
          </mat-list-item>
        </ng-template>
      </mat-card>

      <mat-card appearance="outlined" class="group-summary__card users">
        <h5 translate>Users</h5>
        <div *ngIf="accessGroup.userList.length !== 0; else userListEmpty">
          <mat-chip-listbox #chipList>
            <mat-chip-option *ngFor="let user of accessGroup.userNameList" class="group-summary__bubble">
              {{ user.name }} {{ user.surname }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>

        <ng-template #userListEmpty>
          <mat-list-item class="width-100" appearence="fill">
            <span translate>No user was selected</span>
          </mat-list-item>
        </ng-template>
      </mat-card>

      <mat-card appearance="outlined" class="group-summary__card roles">
        <h5 translate>Roles</h5>
        <div *ngIf="accessGroup.roleList.length !== 0; else roleListEmpty">
          <mat-chip-listbox #chipList>
            <mat-chip-option *ngFor="let role of accessGroup.roleNameList" class="group-summary__bubble">
              {{ role.name }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>

        <ng-template #roleListEmpty>
          <mat-list-item class="width-100" appearence="fill">
            <span translate>No role was selected</span>
          </mat-list-item>
        </ng-template>
      </mat-card>
    </mat-list>
  </div>

  <div class="steppers">
    <app-stepper-controller isEditing="false" [actionText]="'Create'" (onFormCleared)="previousItem.emit()"
      (onFormSubmitted)="onSubmit()">
    </app-stepper-controller>
  </div>
</div>