<div class="create-role">
  <div class="text-center">
    <h5 mat-dialog-title translate>New Role</h5>
  </div>
  <div class="create-role__step-header-second">
    <h6><fa-icon *ngIf="currentStepIndex !== 1" (click)="previousStep(stepper)" class="mrg-right-5"
        icon="arrow-left"></fa-icon>{{labelStepSelected}} - {{currentStepIndex}}/3 </h6>
  </div>

  <mat-horizontal-stepper class="create-role__stepper" linear="true" #stepper>
    <mat-step [label]="'1. Information' | translate">
      <app-role-info [data]="data" class="create-role__info" (infoSet)="setInfo($event, stepper)" [isEditing]="false"
        (previousItem)="backStep(stepper)">
      </app-role-info>
    </mat-step>

    <mat-step [label]="'2. Users' | translate">
      <app-search-members (usersSet)="setUsers($event, stepper)" (previousItem)="backStep(stepper)"
        [selectedUsers]="data ? data.users : null" [roleSearch]="true">
      </app-search-members>
    </mat-step>

    <mat-step [label]="'3. Result' | translate">
      <app-modal-result [stepper]="stepper" errorMessage="Error creating role"
        successMessage="Role successfully created!" [result]="result" (previousItem)="backStep(stepper)"
        (finishAll)="closeDialog()">
      </app-modal-result>
    </mat-step>
  </mat-horizontal-stepper>
</div>