export interface ILKSharingRestrictions {
  beginDate: string;
  finishDate: string;
  beginTime: string;
  finishTime: string;
  days: number;
  offline: boolean;
  daysList: string[];
}

export class LKSharingRestrictions {
  public beginDate?: string;
  public finishDate?: string;
  public beginTime?: string;
  public finishTime?: string;
  public days?: number;
  public offline?: boolean;
  public daysList?: string[];

  constructor(serverObject?: ILKSharingRestrictions) {
    if (serverObject) {
      Object.assign(this, serverObject);
    } else {
      this.beginDate = LKSharingRestrictions.getDayString(new Date());
      this.finishDate = LKSharingRestrictions.getDayString(
        new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30)
      );
      this.beginTime = '08:00';
      this.finishTime = '18:00';
      this.days = 127;
      this.offline = false;
      this.daysList = ['mon', 'tue', 'wed', 'thu', 'fri'];
    }
  }

  static stringDateToDate = (date: string): Date => {
    const [year, month, day] = date.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  static getDayString = (date: Date): string => {
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
}
