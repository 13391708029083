<div class="booking-result">
  <mat-dialog-content>
    <div class="mrg-top-20 booking-result__icon-div">
      <div *ngIf="
          responseBooking && !responseBooking.error;
          else responseError
        ">
        <div class="booking-result__icon">
          <img alt="result-icon" class="sucess"
            src="../../../../../assets/icons/black_success.svg" width="120" height="120" />
        </div>
        <br />
        <h5 *ngIf="action === 'Create'" translate>Created successfully!</h5>
        <h5 *ngIf="action === 'Edit'" translate>Updated successfully!</h5>
      </div>
      <ng-template #responseError>
        <div class="booking-result__icon">
          <img alt="result-icon" class="error"
            src="../../../../../assets/icons/black_error.svg" width="120" height="120" />
        </div>
        <div>
          <h5 *ngIf="responseBooking" translate>
            {{ responseBooking.response }}
          </h5>
        </div>
      </ng-template>
    </div>
  </mat-dialog-content>

  <div *ngIf="!sizeWidthSuitable" class="no-mrg mrg-top-15 width-100" [ngClass]="
      responseBooking && responseBooking.error
        ? 'booking-result__btn-action-error'
        : 'booking-result__btn-action'
    ">
    <div *ngIf="responseBooking && responseBooking.error">
      <button aria-label="back" class="back-btn" mat-raised-button (click)="backAction()">
        <span class="booking-result__btn-label">{{ 'Back' | translate }}</span>
      </button>
    </div>
    <div>
      <button aria-label="finish" matDialogClose mat-raised-button class="accent" (click)="finish()">
        <span class="booking-result__btn-label">{{ 'Finish' | translate }}</span>
      </button>
    </div>
  </div>
  <div *ngIf="sizeWidthSuitable" class="mrg-top-15 width-100" [ngClass]="
    responseBooking && responseBooking.error
      ? 'booking-result__btn-action-error steppers'
      : 'booking-result__btn-action steppers'
  ">
    <div>
      <button aria-label="finish" matDialogClose mat-raised-button class="accent" (click)="finish()">
        <span class="booking-result__btn-label">{{ 'Finish' | translate }}</span>
      </button>
    </div>
    <div *ngIf="responseBooking && responseBooking.error">
      <button aria-label="back" class="back-btn" mat-raised-button (click)="backAction()">
        <span class="booking-result__btn-label">{{ 'Back' | translate }}</span>
      </button>
    </div>
  </div>
</div>