import * as qs from 'querystring';
import { ApiService } from '@core/http/api.service';
import { LKUser } from '@models/LKUser';
import { Injectable } from '@angular/core';

@Injectable()
export class ProfileService {
  constructor(private apiService: ApiService) {}

  changePassword(
    obj: any,
    success: (response: any) => void,
    failed: (error: any) => void
  ) {
    const body = qs.stringify({
      newPassword: obj.newPassword,
      oldPassword: obj.currentPassword,
    });

    this.apiService.changePassword(body).subscribe(
      (data) => {
        success(data);
      },
      (error) => {
        failed(error);
      }
    );
  }

  updateUser(
    newUser: LKUser,
    success: (updatedUser: LKUser) => void,
    failed: (error: any) => void
  ) {
    const body = qs.stringify({
      picture: newUser.picture,
      mimeType: 'JPG',
      name: newUser.name,
      surname: newUser.surname,
    });
    this.apiService.updateUser(body).subscribe(
      (data) => {
        const user = new LKUser(data);
        success(user);
      },
      (error) => {
        failed(error);
      }
    );
  }

  getUser() {
    return this.apiService.getUser('');
  }
}
