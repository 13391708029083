import { LKEvent, ILKEvent } from './LKEvent';

export interface ILKUserEvent {
  events: ILKEvent[];
  count: number;
}

export class LKUserEvent {
  events: LKEvent[];
  count: number;

  constructor(serverObject: Partial<ILKUserEvent>) {
    Object.assign(this, serverObject);
  }
}
