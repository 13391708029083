export interface ILKRole {
  id: number;
  name: string;
  description: string;
  isGlobal: boolean;
  siteId: number;
  corpId: number;
  users: Array<number>;
}

export interface RoleParams {
  name: string;
  description: string;
  isGlobal: boolean;
  siteId: number;
  corpId: number;
  users: [number];
}

export class LKRole {
  id: number;
  name: string;
  description: string;
  isGlobal: boolean;

  constructor(serverObject: Partial<ILKRole>) {
    Object.assign(this, serverObject);
  }
}
