<div
  class="change-image"
  id="myModal"
>
  <div class="modal-content">
    <span class="title" translate>Change User Photo</span>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      [resizeToWidth]="220"
      format="jpg"
      [imageBase64]="userImg"
      (imageCropped)="imageCropped($event)"
      [canvasRotation]="rotationLevel[0]"
    >
    </image-cropper>
    <div class="actions row text-center">
      <div class="button-actions  col-auto mr-auto set-btn">
        <button
          mat-raised-button
          class="cancel-button modal-button rotate-btn"
          (click)="rotateImage('left')"
        >
        <fa-icon [icon]="faUndo"></fa-icon>
        </button>
        <button
          aria-label="cancel"
          mat-raised-button
          class="cancel-button modal-button"
          (click)="imageCancel()"
          data-dismiss="modal"
        >
          <span translate>Cancel</span>
        </button>
      </div>
      <div class="button-actions col-auto set-btn float-btn">
        <button
          mat-raised-button
          class="cancel-button modal-button rotate-btn"
          (click)="rotateImage('right')"
        >
          <fa-icon [icon]="faUndo" flip="horizontal"></fa-icon>
        </button>
        <button
          aria-label="save"
          mat-raised-button
          class="modal-button accent"
          (click)="imageUpload()"
          data-dismiss="modal"
        >
          <span translate>Save</span>
        </button>
      </div>
    </div>
  </div>
  <input
    id="fileInput"
    type="file"
    (change)="fileChangeEvent($event)"
  />
</div>
