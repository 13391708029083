import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from 'app/material.module';
import { MatGridListModule } from '@angular/material/grid-list';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faAngleRight,
  faBuilding,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { SitesService } from '@modules/sites/sites.service';
import { UserService } from '@modules/users/users.service';
import { RoleInvitationComponent } from '@modules/roles/components/role-invitation/role-invitation.component';
import { CorporationRoutingModule } from './corporation-routing.module';
import { CorporationService } from './corporation.service';
import { CorporationsComponent } from './pages/corporations/corporations.component';
import { UpdateInfoComponent } from './pages/update-info/update-info.component';

@NgModule({
  declarations: [
    CorporationsComponent,
    UpdateInfoComponent,
    RoleInvitationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CorporationRoutingModule,
    SharedModule,
    MaterialModule,
    FontAwesomeModule,
    MatGridListModule,
  ],
  providers: [CorporationService, SitesService, UserService],
})
export class CorporationModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faAngleRight, faBuilding, faUsers);
  }
}
