<div class="remove-device">
  <div class="pdd-horizon-15 mrg-btm-20">
    <h5 class="text-center" mat-dialog-title translate>Remove Device</h5>
  </div>
  <div class="remove-device__delete-text">
    <fa-icon class="icon-warning" icon="exclamation-triangle"></fa-icon>
    <h6 translate>Are you sure you want to remove this device from the Site?</h6>
  </div>
  <div class="remove-device__wrapper-content" mat-dialog-content>
    <div *ngIf="!sizeWidthSuitable" class="row">
      <div class="col-auto mr-auto">
        <button aria-label="cancel" mat-raised-button class="cancel-button modal-button" matDialogClose>
          <span translate>Cancel</span>
        </button>
      </div>
      <div class="col-auto">
        <button aria-label="delete" mat-raised-button class="delete-button modal-button"
          (click)="removeDevice()" matDialogClose>
          <span translate>Remove Device</span>
        </button>
      </div>
    </div>
    <div *ngIf="sizeWidthSuitable" class="row">
      <button aria-label="cancel" mat-raised-button class="cancel-button-float" matDialogClose>
        <span translate>Cancel</span>
      </button>
      <button aria-label="delete" mat-raised-button class="delete-button-float"
        (click)="removeDevice()" matDialogClose>
        <span translate>Remove Device</span>
      </button>
    </div>
  </div>
</div>
