import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from '@services/helper.manager.service';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
})
export class InvoiceDetailsComponent {
  public widthSizeSuitable: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService
  ) {
    this.widthSizeSuitable = window.innerWidth <= 500;
  }

  getDateString(date: string, option: string): string {
    return this.helperService.getDateFromString(date, option);
  }

  showInvoice(pdf: string) {
    window.open(pdf, '_blank');
  }
}
